import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Spinner, { SpinnerSize } from '../Spinner';

interface Props {
  visible?: boolean;
  speed?: 500 | 1000;
  className?: string;
  initialWithoutFade?: boolean;
  loadingText?: string;
  isFixed?: boolean;
}

export default function Loading({
  isFixed = true,
  className,
  speed = 500,
  visible = false,
  initialWithoutFade,
  loadingText,
}: Props): JSX.Element {
  const [isVisible, setIsVisible] = useState<boolean>();
  const [isAnimating, setIsAnimating] = useState<boolean>();
  const [showed, setShowed] = useState<number>(0);

  const cssClasses = classNames(
    'w-full h-full block bg-white z-50 flex flex-col justify-center items-center',
    {
      'fixed top-0 left-0': isFixed,
      [`animate-fadein-${speed}`]: visible && !(initialWithoutFade && showed === 1),
      [`animate-fadeout-${speed}`]: !visible,
    },
    className,
  );

  /**
   * Based on the visible var we are setting the visible state with a timeout of the speed + 100
   * to be sure we are outside the animation
   * (Act ONLY on the Visible variable)
   */
  useEffect(() => {
    if (!isAnimating) {
      if (!visible) {
        setTimeout(() => {
          setIsVisible(false);
          setIsAnimating(false);
        }, speed + 100);
      } else {
        // when we do not show the fade at initial load, set the timeout to zero (0)
        const timer = initialWithoutFade && showed === 0 ? 0 : speed + 100;

        setTimeout(() => {
          setIsVisible(true);
          setIsAnimating(false);
          setShowed(prevState => prevState + 1);
        }, timer);
      }
    }
  }, [visible]); // eslint-disable-line

  if (isVisible) {
    return (
      <div className={cssClasses}>
        <Spinner inverseColor={true} size={SpinnerSize['3xlarge']} />
        {loadingText && <span className='text-gray-500 inline-block mt-5'>{loadingText}</span>}
      </div>
    );
  }

  return <></>;
}
