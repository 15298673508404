import { PortalSession } from 'chargebee-typescript/lib/resources';

export enum SubscriptionStatus {
  Trial = 'in_trial',
  Active = 'active',
  NonRenewing = 'non_renewing',
  Cancelled = 'cancelled',
  TrialExpired = 'trial_expired',
}
export type SubscriptionPeriod = 'yearly' | 'monthly';

export interface RealmPlan {
  // From Plan
  plan: string;
  planRef: string;
  maxGuestsPerUser: number;
  storagePerUser: string; // too large for number type
  minLicenseCount: number;
  quickShareLimit: string | undefined; // too large for number type
  reverseQuickShareLimit: string | undefined; // too large for number type

  // From Realm
  billingCustomerId: string;
  hasValidPayment: boolean;
  subscriptionStatus: SubscriptionStatus;
  maxUsers: number;
  extraStorageBytes: string; // too large for number type

  // From Chargebee
  period: SubscriptionPeriod;
  inTrial: boolean;
  trialDaysLeft: number;
  nextPayment?: Date;
  licenseId: string; // The subscription reference e.g. Storro-trial-EUR-Monthly
  subscriptionId: string; // The subscription identifier known at Chargebee
  licensePrice: number;
  licenseQuantity: number;
  storagePrice?: number;
  storageQuantity?: number;
  paymentDetails?: {
    type: string;
    ccMaskedNumber?: string; // e.g ************4242
    ccType?: string; // e.g. visa
  };
  customerDetails: {
    firstName?: string;
    lastName?: string;
    email?: string;
    company?: string;
    billingDetails: {
      firstName?: string;
      lastName?: string;
      email?: string;
      line1?: string;
      line2?: string;
      line3?: string;
      city?: string;
      state?: string;
      country?: string;
      zip?: string;
    };
  };

  // A portal session to get a session at Chargebee.
  portalSession: PortalSession;

  // flag that indicate if a realm has been updated
  realmUpdated?: boolean;

  // flag that indicate if we deal with an Appl InAppPurchase
  isInAppPurchase?: boolean;
}

// The data structure of a license plan. Every realm is connected to a license plan.
export interface Plan {
  // The id of the plan as we know it at our Payment Service Provider (Chargebee).
  reference: string;

  // The name of the license plan
  name: string;

  // The description of the license plan
  description: string;

  // The amount of available storage (added to the total realm storage bucket)
  // for every user license. (in bytes)
  storagePerUser: string | undefined;

  // The amount of days that the users of the realm are able to go back in
  // history and recover files.
  revisionControlDays: number | undefined;

  // The maximum amount of active (non removed) devices per user.
  maxDevicesPerUser: number;

  // The amount of guest users that can be created (added to the realm total)
  // for every user license in the realm.
  maxGuestsPerUser: number;

  // The maximum size of a public share (web share / quick share, however we
  // name it). (in bytes)
  quickShareLimit: number | undefined;

  // Allow branding of the public share portal. Like realm logo and color.
  clientPortalBrandingAllowed: boolean;

  // All policies and controls for the realm admins to configure.
  policiesAllowed: boolean;

  // Allow realm admins to setup connections with other platforms. For example
  // with Slack or Microsoft.
  integrationsAllowed: boolean;

  // The Storro team will take care of migrating your data into Storro.
  migrationsAllowed: boolean;

  // Do we provide phone support for this license.
  fullCustomerSupport: boolean;

  // The minimum amount of licenses that need to be bought in order to use this plan.
  minLicenseCount: number;

  // Price when billed monthly
  monthly: UnitPrice | undefined;

  // Price when billed yearly
  yearly: UnitPrice | undefined;
}

export interface UnitPrice {
  id: string; // This can be used for the checkout
  price: number; // Price in cents.
}

export interface PricingModel {
  currency: 'EUR';
  plans: Plan[];
  storageMonthly: UnitPrice | undefined;
  storageYearly: UnitPrice | undefined;
}

/**
 * Invoice status
 * @see https://apidocs.chargebee.com/docs/api/invoices?prod_cat_ver=2#invoice_status
 */
export enum InvoiceStatus {
  Paid = 'paid',
  Posted = 'posted',
  PaymentDue = 'payment_due',
  NotPaid = 'not_paid',
  Voided = 'voided',
  Pending = 'pending',
}

export interface Invoice {
  id: string;
  status: InvoiceStatus;
  date: number;
  total: number;
  description: string;
}

export interface InvoiceResponse {
  nextOffset?: string;
  invoices: Invoice[];
}
