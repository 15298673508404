import { CaretUpDown } from '@phosphor-icons/react';
import classNames from 'classnames';
import React from 'react';
import { Realm } from 'types';
import maxLength from 'util/MaxLength';

interface Props {
  realm: Realm | undefined;
  showDropdownIcon: boolean;
  onClick?: () => void;
}

/**
 * Small component to show the realm name and a dropdown icon as a button
 * We use this component to show the realm name in the sidebar and where you can click
 * on to switch realms
 */
function RealmSwitcherButton({ realm, showDropdownIcon, onClick }: Props): JSX.Element {
  return (
    <div className='border-zinc-500 px-3 py-3 text-zinc-50 hover:text-zinc-50 cursor-pointer group' onClick={onClick}>
      <div className='flex gap-x-1 border border-zinc-500 group-hover:border-zinc-400 p-2 rounded-md items-center'>
        <strong>{maxLength(realm ? realm.name : 'Select your realm', 18)}</strong>
        <button className='ml-auto'>
          <CaretUpDown
            className={classNames('ml-1', {
              visible: showDropdownIcon,
            })}
          />
        </button>
      </div>
    </div>
  );
}

export default RealmSwitcherButton;
