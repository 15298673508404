import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { logger } from 'util/Logger';
import { useStorroApi } from '../../context/StorroApiContext';
import useRealm from '../../hooks/realm/UseRealm';
import { BinaryDownload } from '../../types';
import ListClientDownloads from './ListClientDownloads';

/**
 * This hook will create a modal for creating a project
 */
export default function DesktopClientDownloadsForRealm(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);
  const [binaries, setBinaries] = useState<BinaryDownload[]>([]);
  const [version, setVersion] = useState<string>('');

  const { realm } = useRealm();
  const { storroApi } = useStorroApi();

  /**
   * Fetch the binaries and set the primary and other downloads state
   */
  useEffect(() => {
    if (!realm) {
      return;
    }

    const abortController = new AbortController();

    setVersion(realm.currentVersion);

    storroApi
      .getBinariesForVersionNumber(realm.currentVersion, abortController.signal)
      .then(result => {
        setBinaries(result);
      })
      .catch(e => {
        if (!abortController.signal.aborted) {
          logger.error(e);
          toast.error('Cannot fetch the binaries');
        }
      })
      .finally(() => setLoading(false));

    return () => abortController.abort();
  }, [realm, storroApi]);

  return (
    <>
      <ListClientDownloads loading={loading} binaries={binaries} version={version} />
    </>
  );
}
