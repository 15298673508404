import React from 'react';
import { INPUT_CLASSNAMES } from '../../../helpers/CssClasses';
import classNames from 'classnames';
import useScreenSize from 'hooks/UseScreenSize';
import { ScreenSize } from 'types/ScreenSize';

interface Props {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export default function PageSelectionInput({ label, onChange, className }: Props): JSX.Element {
  const { width } = useScreenSize();

  return (
    <div className={classNames('flex items-center gap-x-1', className)}>
      <p className='hidden md:block'>{label}:</p>
      <input placeholder={width < ScreenSize.md ? label : undefined} className={INPUT_CLASSNAMES} onChange={onChange} />
    </div>
  );
}
