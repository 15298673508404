// Copyright 2022 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
import { ApiStore } from './ApiStore';
import { DelayStore } from './DelayStore';
import { KeyValueStore } from './KeyValueStore';
import { MemoryStore } from './MemoryStore';
import { S3Factory } from './S3Factory';

export interface StoreRecipe {
  type: string;
  // As StoreRecipe is a polymorphic type (MemoryRecipe, S3Recipe, ...),
  // we need to allow more unspecified member variables.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [others: string]: any;
}

export interface StoreConfig {
  type: string;
  // As StoreConfig is a polymorphic type,
  // we need to allow more unspecified member variables.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [others: string]: any;
}

export class StoreFactory {
  // For testing we sometimes need memory stores which can be recalled
  // as if they are persistent. We keep them alive here in memory.
  private counter = 1;
  private persistentMemoryStores: Map<number, MemoryStore>;

  constructor() {
    this.persistentMemoryStores = new Map<number, MemoryStore>();
  }

  // Uses a 'recipe' describing what type of store to create.
  // The result is an Object containing the new store config
  // which can be used to load the store.
  // The caller should probably save that somewhere (as json).
  async create(recipe: StoreRecipe): Promise<StoreConfig> {
    if (recipe.type === 'S3') return new S3Factory().create(recipe);
    if (recipe.type === 'Memory') return this.memoryStoreCreator(recipe);
    if (recipe.type === 'Delay') {
      const childConfig = await this.create(recipe.child);
      if (recipe.delay) {
        return {
          type: 'Delay',
          delay: recipe.delay,
          variance: recipe.variance,
          child: childConfig,
        };
      }
      return { type: 'Delay', child: childConfig };
    }
    throw 'Unknown store type in recipe';
  }

  // config should be a json configuration, equal to
  // the specification in Zooid/Source/Serialization/StoreFactory.h
  load(config: StoreConfig): KeyValueStore {
    if (config.type === '') throw 'KeyValueStore type missing from StoreConfig';
    if (config.type === 'Memory') return this.memoryStoreLoader(config);
    if (config.type === 'S3') return new S3Factory().load(config);
    if (config.type === 'Delay') {
      const child = this.load(config.child);
      return new DelayStore(child, config);
    }
    if (config.type === 'ApiStore') {
      return ApiStore.fromStoreConfig(config);
    }
    throw 'Unknown KeyValueStore type: ' + config.type;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  memoryStoreCreator(config: any): StoreConfig {
    if (config['persistent'] !== 'true') {
      // Just a throw-away MemoryStore StoreConfig
      return { type: 'Memory' };
    }
    // If we want a 'persistent' one, it will still only exist in memory,
    // but we can load() it multiple times and get the same one back.
    const thisId = this.counter++;
    this.persistentMemoryStores.set(thisId, new MemoryStore());
    const result = { type: 'Memory', id: thisId };
    return result;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  memoryStoreLoader(config: any): MemoryStore {
    if (!config['id']) {
      // Just a throw-away MemoryStore
      return new MemoryStore();
    }
    // Find the id of the store in the persistent stores.
    const result = this.persistentMemoryStores.get(config['id']);

    if (!result) throw new Error('Could not find persistent MemoryStore');
    return result;
  }
}
