import { decode, encode } from '@stablelib/utf8';
import base64url from 'base64url';
import { ProjectDetails } from 'types';
import { ConvergentEncryption } from '../util/Cryptography/ConvergentEncryption';
import { SymmetricKey } from '../util/Cryptography/SymmetricKey';
import { logger } from '../util/Logger';
import { ApiStore, StoreType } from '../util/Serialization/ApiStore';
import { UserDeviceApiJwtGenerator } from '../util/Serialization/ApiStoreAuthorization';
import { Cas } from '../util/Serialization/Cas';
import { Ecas } from '../util/Serialization/Ecas';
import { EcasKey } from '../util/Serialization/EcasKey';
import { EcasValue } from '../util/Serialization/EcasValue';
import { StreamKey } from '../util/Serialization/StreamKey';
import { equal } from '../util/Util/Equal';
import { Uint8ArraySet } from '../util/Util/Uint8ArraySet';
import { Diff, fromJsonDiffSummary, Operation, Result, Summary } from './Diff';
import { Dir, Entry, EntryType, File as FileEntry, Path } from './Entry';
import Storro from './index';
import { JsonWebToken } from './JsonWebToken';
import { ListingCache } from './ListingCache';
import { PrivateKey } from './PrivateKey';
import { PublicKey } from './PublicKey';
import { UploadQueue, UploadQueueItem } from './UploadQueue';

export interface FaultyProject {
  projectId: string;
  projectName?: string;
  // error should be of type any as the error is unknown and return as type any
  // eslint-disable-next-line
  error: any;
}

// A class that loads 'diffs' for a project.
export class ProjectList {
  private projects = new Map<string, Promise<Project>>();
  private date: Date | undefined;

  private processCallbackHandlers: { (uploadSpeed: number, queue: UploadQueueItem[]): void }[] = [];
  private lastProcessCallback: NodeJS.Timeout | undefined = undefined;

  private uploadFinishedCallbackHandlers: { (project: Project, items: UploadQueueItem[]): void }[] = [];

  constructor(private storroApi: Storro) {}

  public clear(): void {
    this.projects.clear();
  }

  public get count(): number {
    return this.projects.size;
  }

  /**
   * Delete a project from the cache
   */
  public delete(projectId: string): void {
    this.projects.delete(projectId);
  }

  public async onProjectUpdated(projectId: string): Promise<void> {
    const ph = this.projects.get(projectId);
    if (ph) {
      try {
        await (await ph).refresh();
      } catch (e) {
        logger.warn('Failed to get project from api. This was needed to update the version history.', e);
      }
    }
  }

  public async onProjectRemoved(projectId: string): Promise<void> {
    const proj = this.projects.get(projectId);
    if (proj) {
      // remove the localstorage key if this one has been set
      const currentLocalStorageId = localStorage.getItem('selectedProjectId');
      if (currentLocalStorageId === projectId) {
        localStorage.removeItem('selectedProjectId');
      }

      // remove from cache
      this.delete(projectId);

      // fire the Removed callback
      (await proj).onProjectRemoved();
    }
  }

  private async progressCallback(done?: UploadQueueItem[]): Promise<void> {
    if (done && done.length > 0) {
      // One of the projects is done uploading. Call the uploadFinishedCallbackHandlers
      for (const handler of this.uploadFinishedCallbackHandlers.slice(0)) {
        const proj = this.projects.get(done[0].projectId);
        if (proj) handler(await proj, done);
      }
    }

    const callHandlers = async () => {
      // Build a list of project upload queue items from all projects.
      let items: UploadQueueItem[] = [];

      // And create a total bytes per second
      let bytesPerSec = 0;
      for (const [, projectPromise] of this.projects) {
        try {
          const project = await projectPromise;
          if (project.uploadQueue) {
            bytesPerSec += project.uploadQueue.uploadSpeed;
            items = items.concat(project.uploadQueue.items);
          }
        } catch (error) {
          // the projectPromise could hold projects that are broken/notFound or something
          // else could be wrong with this project
          // In order to keep the train going, we should ignore those errors at this stage
        }
      }
      this.processCallbackHandlers.slice(0).forEach(handler => {
        try {
          handler(bytesPerSec, items);
        } catch (error) {
          // the handler() could hold projects that are broken/notFound or something
          // else could be wrong with this project
          // In order to keep the train going, we should ignore those errors at this stage
        }
      });
    };

    if (this.lastProcessCallback) {
      return;
    }

    this.lastProcessCallback = setTimeout(() => {
      if (this.lastProcessCallback) {
        callHandlers();
        clearTimeout(this.lastProcessCallback);
        this.lastProcessCallback = undefined;
      }
    }, 1000);
    callHandlers();
  }

  public subscribeProgressCallback(handler: { (uploadSpeed: number): void }): void {
    this.processCallbackHandlers.push(handler);
  }

  public unsubscribeProgressCallback(handler: { (uploadSpeed: number): void }): void {
    this.processCallbackHandlers = this.processCallbackHandlers.filter(h => h !== handler);
  }

  public subscribeUploadFinishedCallback(handler: { (project: Project, queue: UploadQueueItem[]): void }): void {
    this.uploadFinishedCallbackHandlers.push(handler);
  }

  public unsubscribeUploadFinishedCallback(handler: { (project: Project, queue: UploadQueueItem[]): void }): void {
    this.uploadFinishedCallbackHandlers = this.uploadFinishedCallbackHandlers.filter(h => h !== handler);
  }

  public async uploadQueueItems(realmId: number): Promise<UploadQueueItem[]> {
    let uploadQueueItems: UploadQueueItem[] = [];
    for (const projectPromise of this.projects.values()) {
      try {
        const project = await projectPromise;
        if (project.details.realmId === realmId && project.uploadQueue) {
          uploadQueueItems = uploadQueueItems.concat(project.uploadQueue.items);
        }
      } catch (error) {
        // the projectPromise could hold projects that are broken/notFound or something
        // else could be wrong with this project
        // In order to keep the train going, we should ignore those errors at this stage
      }
    }
    return uploadQueueItems;
  }

  /**
   * List only the cached projects for a specific realm
   */
  public async listCachedProjects(realmId: number): Promise<Project[]> {
    const result: Project[] = [];

    for (const projectPromise of this.projects.values()) {
      try {
        const project = await projectPromise;
        if (project.details.realmId === realmId) {
          result.push(project);
        }
      } catch (error) {
        // the projectPromise could hold projects that are broken/notFound or something
        // else could be wrong with this project
        // In order to keep the train going, we should ignore those errors at this stage
      }
    }
    return result;
  }

  public async listProjects(realmId: number, signal?: AbortSignal): Promise<Project[]> {
    const list = await this.storroApi.listProjects(realmId, signal);
    const result: Project[] = [];

    const constructNewProject = (projectDetails: ProjectDetails) => {
      return new Promise<Project>(resolve => {
        resolve(
          new Project(this.storroApi, projectDetails, EcasKey.fromString(projectDetails.headVersionId), (done?: UploadQueueItem[]) => {
            this.progressCallback(done);
          }),
        );
      });
    };

    for (const projectDetails of list) {
      const findPromise = this.projects.get(projectDetails.id);
      if (!projectDetails.invitationAccepted) {
        // fetching the ${findPromise} will result in a 401 if the user did not accept the invitation
        // there for we should just push the project to the list and not reload it
        result.push(await constructNewProject(projectDetails));
      } else if (findPromise) {
        try {
          const find = await findPromise;
          find.refreshWithDetails(projectDetails);
          result.push(find);
        } catch (error) {
          // if the project cannot be fetched, just skip it or it will fail to construct the whole list of projects
          logger.error(`Cannot load Project: ${projectDetails.name}`, error);
        }
      } else {
        const project = constructNewProject(projectDetails);
        this.projects.set(projectDetails.id, project);
        result.push(await project);
      }
    }
    return result;
  }

  public async getProject(projectId: string, signal?: AbortSignal): Promise<Project> {
    if (projectId === '') {
      throw Error('Failed to get project with empty project id');
    }

    // check if the project already exists
    const found = this.projects.get(projectId);
    if (found) {
      return found;
    }

    const project = new Promise<Project>((resolve, reject) => {
      this.storroApi
        .getProject(projectId, signal)
        .then(projectDetails => {
          const project = new Project(
            this.storroApi,
            projectDetails,
            EcasKey.fromString(projectDetails.headVersionId),
            (done?: UploadQueueItem[]) => {
              this.progressCallback(done);
            },
          );

          resolve(project);
        })
        .catch(error => reject(error));
    });

    this.projects.set(projectId, project);
    return project;
  }

  /**
   * Load project versions from one or more projects.
   * @param limit The amount of versions we want to fetch. This could be more ore less depending on `fetchFullDays`, chain forks or simply being at the root version.
   * @param projects The projects we want to fetch the versions from.
   * @param fetchFullDays Fetch a full day of changes.
   * @param filter Returns versions that have a diff for the given path
   * @returns A list of project versions from the given projects (all fetched versions).
   */
  public async loadVersions(
    abortSignal: AbortSignal | undefined,
    limit: number,
    projectIds: string[],
    fetchFullDays: boolean,
    filter?: Path,
  ): Promise<{ faultyProjects: FaultyProject[]; projects: ProjectVersion[] }> {
    // holds the projects that are not loaded properly
    const faultyProjects: FaultyProject[] = [];
    // holds the healty projects
    const result: ProjectVersion[] = [];

    if (!this.date && fetchFullDays) {
      const now = new Date();
      this.date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    }

    const startDate = new Date(this.date ?? new Date());
    for (let counter = 0; counter < limit; counter++) {
      if (abortSignal?.aborted) {
        return Promise.reject(Error('Aborted'));
      }
      let atEnd = true;
      for (const projectId of projectIds) {
        if (!this.projects.has(projectId)) {
          if (abortSignal?.aborted) {
            return Promise.reject(Error('Aborted'));
          }
          this.projects.set(
            projectId,
            new Promise((resolve, reject) => {
              this.storroApi
                .getProject(projectId)
                .then(projectDetails => {
                  resolve(
                    new Project(
                      this.storroApi,
                      projectDetails,
                      EcasKey.fromString(projectDetails.headVersionId),
                      (done?: UploadQueueItem[]) => {
                        this.progressCallback(done);
                      },
                    ),
                  );
                })
                .catch(error => reject(error));
            }),
          );
        }
        const projectPromise = this.projects.get(projectId);
        if (!projectPromise) {
          throw Error('Failed to get ProjectHistory object');
        }

        // in case the promise is failing, push it to the faultyProjects object
        // so we can continue feeding our resuklt array and not bail out in the middle

        // first check the ProjectPromise
        // so we can cache the name in case we detect an error further down the road
        let ph: Project | undefined = undefined;
        try {
          ph = await projectPromise;
          if (ph.hasMoreVersions) {
            atEnd = false;
          }
        } catch (error) {
          if (!faultyProjects.find(proj => proj.projectId === projectId)) {
            faultyProjects.push({ projectId: projectId, error });
          }
        }

        // Secondly, if the ProjectPromise has been resoled, call the .loadMore()
        // in case of an error, we could use the projectName now instead of the projectId
        if (ph) {
          try {
            let loadMoreResult: ProjectVersion[];
            if (fetchFullDays && this.date) {
              loadMoreResult = await ph.loadMore(abortSignal, this.date, filter);
            } else {
              loadMoreResult = await ph.loadMore(abortSignal, limit, filter);
            }

            loadMoreResult.forEach(pv => {
              if (!result.find(i => i.versionIdString === pv.versionIdString)) {
                result.push(pv);
              }
            });
          } catch (error) {
            if (!faultyProjects.find(proj => proj.projectId === projectId)) {
              faultyProjects.push({ projectId: projectId, projectName: ph.details.name, error });
            }
          }
        }
      }

      if (atEnd || (abortSignal && abortSignal.aborted)) {
        break;
      }
      if (this.date) {
        this.date.setDate(this.date.getDate() - 1);
      }
    }

    let logMsg = `Loaded ${result.length} versions from history.`;
    if (fetchFullDays && this.date) {
      logMsg += `From '${startDate.toLocaleDateString()}' to '${this.date.toLocaleDateString()}'.`;
    }
    if (abortSignal && abortSignal.aborted) {
      return Promise.reject(Error('Aborted'));
    } else {
      logger.debug(logMsg);
    }

    return { faultyProjects, projects: [...result].sort(ProjectVersion.sortOnTimestamp) };
  }
}

export interface ProjectHistoryVersionParent {
  id: EcasKey;
  timestamp?: Date;
  rootDir?: Uint8Array;
}

/**
 * Represents a single version of a project.
 *
 * In order to add a File and create a new ProjectVersion, this needs to be done:
 * 1. Load the current root dir as follows:
 *    new Dir(ecas, encryptedName, mod, size, key, plaintextName, contentKey);
 *    The fields ecas, key (the locator in the ecas) and contentKey (the decryption key)
 *    should be filled in correctly.
 *    encryptedName, mod, size and plaintextName do not really matter as these are not visible
 *    and do not get serialized.
 * 2. Perform Dir.insert() or Dir.remove() or other operations
 *    -- always on the root Dir object.
 * 3. When done, call serialize() on the root Dir. This creates a new root key for that filesystem.
 * 4. Create a new ProjectVersion with that root key.
 * 5. Pass the new ProjectVersion to addHeadVersionToQueue() on api.storro.com.
 *    This api will enqueue the ProjectVersion to the C++ code on the PSP that merges
 *    the new version into the version tree and creates a new head version.
 */
export class ProjectVersion {
  private parentArray: ProjectVersion[] | undefined = undefined;
  private diffResults: Promise<Result[]> | undefined = undefined;
  private hasPathChange = new Map<string, boolean>();
  public readonly rootDirLocator: StreamKey | undefined = undefined;
  public readonly timestamp: Date | undefined = undefined;
  public readonly parentIds: EcasKey[] = [];
  public readonly devicePublicKey: PublicKey | undefined = undefined;
  public readonly root: Dir;

  /**
   * @param projectId The id of the project where this ProjectVersion belongs to
   * @param ecas The meta ecas of the project
   * @param contentKey The content and entry-name decryption key. Should be added when you're not a facilitator.
   * @param bytes The serialized ProjectVersion contents from the ecas.
   * @param id The ECAS key (identifier) of this project version.
   * @param listingCache The listing cache
   */
  constructor(
    public readonly projectId: string,
    private ecas: Ecas,
    private contentKey: SymmetricKey | undefined,
    bytes: Uint8Array,
    public readonly id: EcasKey,
    private listingCache: ListingCache,
    private _diffSummary: Summary[] | undefined,
  ) {
    const jwt = JsonWebToken.parse(decode(bytes));

    const protocolVersion = jwt.getValue('version');
    if (!protocolVersion) {
      throw new Error('ProjectVersion version number not found in ProjectVersion JWT');
    }
    const parsed = parseInt(protocolVersion);
    if (isNaN(parsed)) {
      throw new Error(`Failed to parse ProjectVersion to number ${protocolVersion}`);
    }
    if (parsed !== 2) {
      throw new Error(`Unsupported ProjectVersion of ${parsed}`);
    }

    // Parse root locator
    const root = jwt.getValue('root');
    if (root) {
      this.rootDirLocator = StreamKey.fromPlaintextString(root);
    }

    // Parse timestamp
    // ProjectVersions with multiple parents (merges) don't have timestamp.
    const ts = jwt.getValue('timestamp');
    if (ts) {
      const parsed = parseInt(ts);
      if (isNaN(parsed)) {
        throw new Error(`Failed to parse ProjectVersion timestamp`);
      }
      this.timestamp = new Date(parsed);
    }

    // Could be empty for the initial version.
    const parIds: unknown = jwt.getValue('parents');
    if (parIds) {
      this.parentIds = (parIds as string[]).map(item => EcasKey.fromUint8Array(new Uint8Array(base64url.toBuffer(item))));
    }

    // Parse the author of the project version.
    // ProjectVersions with multiple parents (merges) don't have an author.
    const pk = jwt.getValue('peerKey');
    if (pk) {
      this.devicePublicKey = PublicKey.fromBase64(pk);
    }

    this.root = new Dir(this.listingCache, this.ecas, this.contentKey, new Uint8Array(), new Date(), 0n, this.rootDirLocator);
  }

  public static async recover(
    storroApi: Storro,
    proj: Project,
    projectVersion: ProjectVersion,
    diffResult: Result,
    asCopyPrefix: string | undefined = undefined,
  ): Promise<void> {
    const contentKey = proj.contentKey;
    if (contentKey === undefined && asCopyPrefix !== undefined) {
      throw Error('ContentKey should be provided when recovering with asCopyPrefix flag');
    }

    // We can only recover as non-facilitator / non-super-admin. In theory we can
    // but we need to fix StreamKey first to handle encrypted content keys.
    if (!projectVersion.contentKey) {
      throw Error('Project version does not have a content key set');
    }

    let sourceEntry: Entry | undefined = undefined;
    let destinationPath: Path[] = [];
    let removeSource = false;

    switch (diffResult.operation) {
      case Operation.Create:
        sourceEntry = await projectVersion.getEntry(diffResult.path, false);
        destinationPath = [diffResult.path];
        break;
      case Operation.Delete:
      case Operation.Change:
      case Operation.Touch:
        sourceEntry = await projectVersion.getEntry(diffResult.path, true);
        destinationPath = [diffResult.path];
        break;
      case Operation.Move:
      case Operation.Rename:
        destinationPath = diffResult.origins;
        sourceEntry = await projectVersion.getEntry(diffResult.path, false);
        removeSource = true;
        break;
      case Operation.Copy:
        sourceEntry = await projectVersion.getEntry(diffResult.path, false);
        destinationPath = [];
        break;
    }

    if (!sourceEntry) {
      throw Error('Source entry is not found');
    }

    const headRootDir = (await proj.head()).root.clone() as Dir;

    for (const path of destinationPath) {
      const pathCp = path.clone();
      let encryptedName = pathCp.pop();
      if (asCopyPrefix && contentKey) {
        const name = ConvergentEncryption.decryptName(contentKey, encryptedName);
        encryptedName = ConvergentEncryption.encryptName(contentKey, asCopyPrefix + name);
      }
      const destEntry = sourceEntry.clone();
      destEntry.setName(encryptedName);
      await headRootDir.insert(destEntry, true, pathCp);
      if (removeSource && !asCopyPrefix) {
        headRootDir.remove(diffResult.path);
      }
    }

    const newProjectVersion = await storroApi.finalize(headRootDir, proj, proj.details.headVersionId);
    logger.debug(
      `Recovered entry at ${diffResult.path.toEncryptedString()} resulting in new head version id of ${newProjectVersion.versionIdString}`,
    );
  }

  /**
   * Return the data that is needed to download the entry from a diff result
   */
  public static async getDownloadData(
    project: Project,
    projectVersion: ProjectVersion,
    diffResult: Result,
  ): Promise<{ ecas: Ecas; contentKey: SymmetricKey | undefined; entries: Entry[] }> {
    let entry: Entry | undefined = undefined;
    switch (diffResult.operation) {
      case Operation.Delete:
      case Operation.Change:
      case Operation.Create:
      case Operation.Touch:
        entry = await projectVersion.getEntry(diffResult.path, false);
        break;
      case Operation.Move:
      case Operation.Rename:
        entry = await projectVersion.getEntry(diffResult.path, false);
        break;
    }

    if (!entry) {
      throw Error('Entry not found');
    }

    return {
      ecas: project.ecas(StoreType.Content),
      contentKey: project.contentKey,
      entries: [entry],
    };
  }

  public static async updateRootDir(
    parent: ProjectVersion,
    devicePrivateKey: PrivateKey,
    newRootDir: Dir,
    contentLayerKey: SymmetricKey,
  ): Promise<ProjectVersion> {
    const rootLocator = await newRootDir.serializeToString(contentLayerKey);
    const parents: string[] = [];
    parents.push(base64url.encode(Buffer.from(parent.id.toUint8Array())));
    const jwt = JsonWebToken.generate(devicePrivateKey, {
      version: 2,
      root: rootLocator ?? '',
      timestamp: Date.now(),
      peerKey: devicePrivateKey.publicKey().toBase64(),
      parents: parents,
    });

    // Convert the JWT to a string and encode that to a Uint8Array.
    const bytes = encode(jwt.toString());

    // Store it in the ecas
    const versionId = await parent.ecas.putValue(new EcasValue(bytes));

    return new ProjectVersion(parent.projectId, parent.ecas, parent.contentKey, bytes, versionId, parent.listingCache, undefined);
  }

  public async getEntry(path: Path, fromParent = false): Promise<Entry | undefined> {
    const abortController = new AbortController();
    if (fromParent) {
      for (const parent of await this.parents(abortController.signal)) {
        const foundEntry = parent.root.findEntry(path);
        if (foundEntry) {
          return foundEntry;
        }
      }
      return undefined;
    } else {
      return this.root.findEntry(path);
    }
  }

  public get parentCount(): number {
    return this.parentIds.length;
  }

  public get versionIdString(): string {
    return base64url.encode(Buffer.from(this.id.toUint8Array()));
  }

  public async parents(abortSignal: AbortSignal | undefined): Promise<ProjectVersion[]> {
    if (abortSignal?.aborted) {
      return Promise.reject(Error('Aborted'));
    }
    if (!this.parentArray) {
      const result = new Array<ProjectVersion>();
      for (const id of this.parentIds) {
        if (!(id instanceof EcasKey)) throw new Error('id is not a EcasKey');
        const bytes = await this.ecas.getValue(id);
        if (abortSignal?.aborted) {
          return Promise.reject(Error('Aborted'));
        }
        result.push(new ProjectVersion(this.projectId, this.ecas, this.contentKey, bytes.getValue(), id, this.listingCache, undefined));
      }
      this.parentArray = result;
      return result;
    }
    return this.parentArray;
  }

  /*
   * sortOnTimestamp() is a helper function to sort ascending on timestamp.
   */
  public static sortOnTimestamp(a: ProjectVersion, b: ProjectVersion): number {
    if (!a.timestamp && !b.timestamp) return 0;
    if (!a.timestamp) return -1;
    if (!b.timestamp) return 1;
    if (a.timestamp > b.timestamp) return -1;
    if (a.timestamp < b.timestamp) return 1;
    else return 0;
  }

  private static filterDiffResults(array: Result[], path: Path): Result[] {
    return array.filter(res => {
      return (
        res.origins.concat(res.path).find((p: Path) => {
          return p.toEncryptedString().startsWith(path.toEncryptedString());
        }) !== undefined
      );
    });
  }

  // Get the diff summary of the project version. This could be fetched from the
  // api or generated locally.
  public async diffSummary(
    storroApi: Storro,
    abortSignal: AbortSignal | undefined,
    allowCacheFromApi = true,
    allowLocalGenerate = true,
  ): Promise<Summary[]> {
    // First see if we already have it.
    if (this._diffSummary) {
      return this._diffSummary;
    }

    if (abortSignal?.aborted) {
      return Promise.reject(Error('Aborted'));
    }

    if (allowCacheFromApi) {
      // Check if we have it cached at the api.
      this._diffSummary = await storroApi.getCachedDiff(this.projectId, this.id, abortSignal);

      if (abortSignal?.aborted) {
        return Promise.reject(Error('Aborted'));
      }

      if (this._diffSummary) {
        return this._diffSummary;
      }
    }

    if (allowLocalGenerate) {
      // The server doesn't have it yet. Generate it locally.
      this._diffSummary = Diff.summarize(await this.fullDiff(abortSignal));
      return this._diffSummary;
    }

    throw Error(`Failed to build diff summary (api: ${allowCacheFromApi}, local: ${allowLocalGenerate}).`);
  }

  // Is undefined for a merge commit
  public async fullDiff(abortSignal: AbortSignal | undefined, path?: Path): Promise<Result[]> {
    if (abortSignal?.aborted) {
      return Promise.reject(Error('Aborted'));
    }

    if (path) {
      const parents = await this.parents(abortSignal);
      if (abortSignal?.aborted) {
        return Promise.reject(Error('Aborted'));
      }
      if (parents.length !== 1) {
        return [];
      }
      const hasChange: boolean | undefined = this.hasPathChange.get(path.toEncryptedString());
      if (hasChange !== undefined) {
        if (hasChange === false) {
          return [];
        } else {
          if (!this.diffResults) {
            throw Error('Diff results should be set.');
          }
          return ProjectVersion.filterDiffResults(await this.diffResults, path);
        }
      }
      const parentEntry = await parents[0].root.findEntry(path);
      const versionEntry = await this.root.findEntry(path);
      let hasChanges = false;
      if (!parentEntry && !versionEntry) {
        this.hasPathChange.set(path.toEncryptedString(), false);
        return [];
      }
      if (parentEntry && versionEntry) {
        hasChanges =
          parentEntry.type !== versionEntry.type ||
          !equal(parentEntry.encryptedName, versionEntry.encryptedName) ||
          parentEntry.mod.valueOf() !== versionEntry.mod.valueOf() ||
          parentEntry.size !== versionEntry.size ||
          !(parentEntry.key === undefined && versionEntry.key === undefined) ||
          !equal(parentEntry.key, versionEntry.key);
      } else {
        hasChanges = true;
      }
      if (hasChanges) {
        this.hasPathChange.set(path.toEncryptedString(), true);
        if (!this.diffResults) {
          this.diffResults = Diff.diff(parents[0].root, this.root);
        }
        return ProjectVersion.filterDiffResults(await this.diffResults, path);
      } else {
        this.hasPathChange.set(path.toEncryptedString(), false);
        return [];
      }
    }

    if (!this.diffResults) {
      const parents = await this.parents(abortSignal);
      if (abortSignal?.aborted) {
        return Promise.reject(Error('Aborted'));
      }
      if (parents.length === 1) {
        this.diffResults = Diff.diff(parents[0].root, this.root);
      } else {
        this.diffResults = Promise.resolve([]);
      }
    }
    return this.diffResults;
  }
}

class ProjectVersionIterator {
  // The heads should stay sorted in ascending datetime.
  private currentHeads: Array<ProjectVersion>;

  // Need to find some kind of performant lookup
  // (std::unordered_hash like) for this.
  private alreadyVisited: Uint8ArraySet;

  constructor(
    public readonly projectId: string,
    private ecas: Ecas,
    version: ProjectVersion,
    private listingCache: ListingCache,
    private contentKey: SymmetricKey | undefined,
  ) {
    this.currentHeads = new Array<ProjectVersion>();
    this.currentHeads.push(version);
    this.alreadyVisited = new Uint8ArraySet();
  }

  // This means that we're iterating and encountered a version with 2 or more
  // parents. This means we're iterating over multiple heads at this point.
  public get inFork(): boolean {
    return this.currentHeads.length > 1;
  }

  public get currentVersion(): ProjectVersion | undefined {
    if (this.currentHeads.length === 0) {
      return undefined;
    }
    return this.currentHeads[this.currentHeads.length - 1];
  }

  /* Returns the next version in the list.
   * The version tree is lazily evaluated.
   * Returns undefined when the end is reached.
   * Throws on any problem.
   */
  public async nextVersion(): Promise<ProjectVersion | undefined> {
    // Pop the most recent head as a result.
    const mostRecentHead = this.currentHeads.pop();

    if (!mostRecentHead) {
      if (this.currentHeads.length !== 0) {
        // This should never happen.
        throw new Error('Undefined version in queue while current heads is not empty.');
      }
      // The queue is empty. We are done.
      return undefined;
    }

    // Get the children of the most recent head.
    const parentIds = mostRecentHead.parentIds;

    // Add children to current heads while filtering alreadyVisited.
    for (const id of parentIds) {
      // We need all parent versions to have an id.
      if (!id) throw new Error('Parent without id found.');
      if (this.alreadyVisited.has(id.getLocator())) {
        // If the id is already present in visited, skip it this time.
        continue;
      }
      // We did not visit this version so far.
      // Load it and add it to the queue of current heads,
      // keeping it ordered on timestamp.
      const bytes = await this.ecas.getValue(id);
      const version = new ProjectVersion(this.projectId, this.ecas, this.contentKey, bytes.getValue(), id, this.listingCache, undefined);
      this.currentHeads.push(version);

      // Add result to alreadyVisited.
      this.alreadyVisited.insert(id.getLocator());
    }

    // Return result.
    return mostRecentHead;
  }
}

// A class that loads 'diffs' for a project.
export class Project {
  private metaEcas: Ecas;
  private contentEcas: Ecas | undefined = undefined;
  private versions = new Map<string, ProjectVersion>();
  private versionIterator: ProjectVersionIterator | undefined;
  private projectUpdatedHandlers: { (headUpdated: boolean): void }[] = [];
  private projectRemovedHandlers: { (): void }[] = [];
  private fileUploadQueue: UploadQueue | undefined = undefined;
  private headPromise: Promise<ProjectVersion> | undefined = undefined;
  public readonly realmSalt: Uint8Array;
  public listingCache: ListingCache;

  constructor(
    private storroApi: Storro,
    private project: ProjectDetails,
    private headVersionId: EcasKey,
    private progressCallback: (done?: UploadQueueItem[]) => void,
  ) {
    this.realmSalt = new Uint8Array(base64url.toBuffer(project.realmSalt));
    this.metaEcas = this.createEcas(StoreType.Meta);
    this.listingCache = new ListingCache(this.metaEcas);
  }

  public subscribeOnUpdated(handler: { (headUpdated: boolean): void }): void {
    this.projectUpdatedHandlers.push(handler);
  }

  public unsubscribeOnUpdated(handler: { (headUpdated: boolean): void }): void {
    this.projectUpdatedHandlers = this.projectUpdatedHandlers.filter(h => h !== handler);
  }

  // Gets called via the websocket.
  public onProjectRemoved(): void {
    this.projectRemovedHandlers.slice(0).forEach(handler => handler());
  }

  public subscribeOnRemoved(handler: { (): void }): void {
    this.projectRemovedHandlers.push(handler);
  }

  public unsubscribeOnRemoved(handler: { (): void }): void {
    this.projectRemovedHandlers = this.projectRemovedHandlers.filter(h => h !== handler);
  }

  public get id(): string {
    return this.project.id;
  }

  public get details(): ProjectDetails {
    return this.project;
  }

  public get contentKey(): SymmetricKey | undefined {
    if (this.project.encryptedContentKey && !this.storroApi.superAdminMode) {
      return this.storroApi.decryptProjectContentKey(this.project);
    } else if (this.project.encryptedGroupContentKey && !this.storroApi.superAdminMode) {
      return this.storroApi.decryptGroupProjectContentKey(this.project);
    }
    return undefined;
  }

  public get uploadQueue(): UploadQueue | undefined {
    return this.fileUploadQueue;
  }

  public async head(): Promise<ProjectVersion> {
    const versionIdString = this.headVersionId.toString();
    const found = this.versions.get(versionIdString);
    if (found) {
      return found;
    }
    if (this.headPromise) {
      return this.headPromise;
    }
    this.headPromise = this.getVersion(this.headVersionId);
    this.versions.set(versionIdString, await this.headPromise);
    return this.headPromise;
  }

  public ecas(storeType: StoreType): Ecas {
    if (storeType === StoreType.Meta) {
      return this.metaEcas;
    } else if (storeType === StoreType.Content) {
      if (!this.contentEcas) {
        this.contentEcas = this.createEcas(StoreType.Content);
      }
      return this.contentEcas;
    } else {
      throw new Error('Unknown store type');
    }
  }

  public async getVersion(id: EcasKey): Promise<ProjectVersion> {
    const bytes = await this.metaEcas.getValue(id);

    // Get the diff summary for the ProjectDetails object (if this version is
    // the head version) or download it from the api.
    let diffSummary: Summary[] | undefined = undefined;
    if (this.project.headVersionId === id.toString()) {
      if (this.project.diff) {
        diffSummary = fromJsonDiffSummary(this.project.diff);
      }
    }
    return new ProjectVersion(this.project.id, this.metaEcas, this.contentKey, bytes.getValue(), id, this.listingCache, diffSummary);
  }

  public async refresh(): Promise<void> {
    return this.refreshWithDetails(await this.storroApi.getProject(this.id));
  }

  public async refreshWithDetails(details: ProjectDetails): Promise<void> {
    const headUpdated = this.project.headVersionId !== details.headVersionId;
    this.project = details;
    if (headUpdated) {
      await this.setHead(EcasKey.fromString(this.project.headVersionId));
    }
    this.projectUpdatedHandlers.slice(0).forEach(handler => handler(headUpdated));
  }

  public async upload(path: Path, files: File[], commitCallback: (newProjectVersion: ProjectVersion) => void): Promise<void> {
    if (!this.contentKey) {
      throw new Error('Uploading files requires project content key');
    }

    if (!this.fileUploadQueue) {
      this.fileUploadQueue = new UploadQueue(
        this.details.name,
        this.contentKey,
        this.id,
        (await this.head()).root,
        this.ecas(StoreType.Content),
        this.progressCallback,
        () => {
          this.commit().then(newProjectVersion => {
            if (newProjectVersion) {
              commitCallback(newProjectVersion);
            }
          });
        },
      );
    }
    const promises: Array<Promise<void>> = [];
    for (const file of files) {
      const uploadPath = new Path(path.segments.concat(Path.fromPlaintext(file.name, this.contentKey).segments));
      promises.push(this.fileUploadQueue.add(uploadPath, file));
    }
    await Promise.all(promises);
    this.progressCallback();
  }

  // Create a new project version on top of the head version with the items from the upload queue.
  public async commit(): Promise<ProjectVersion | undefined> {
    // holds our new ProjectVersion returned from storroApi.finalize
    let newProjectVersion: ProjectVersion | undefined = undefined;

    const queue = this.fileUploadQueue;
    if (!queue) {
      return;
    }

    const root = (await this.head()).root.clone() as Dir;
    let changed = false; // Is there at least one change root dir?

    for (const item of queue.items) {
      if (item.cancelled) {
        // Skip items that are cancelled.
        continue;
      }
      if (item.failed) {
        // Skip failed items
        continue;
      }
      const parentDir = item.path.clone();
      const name = parentDir.pop();
      const makePath = new Path([]);
      for (const segment of parentDir.segments) {
        const find = await root.findEntry(new Path(makePath.clone().segments.concat(segment)));
        if (!find) {
          await root.insert(new Dir(this.listingCache, this.metaEcas, this.contentKey, segment, new Date()), false, makePath);
        } else if (find.type === EntryType.File) {
          throw new Error('Cannot insert files in dir that is of file type');
        }
        makePath.push(segment);
      }
      await root.insert(new FileEntry(name, new Date(), item.size, item.streamKey), item.overwrite, parentDir);
      changed = true;
    }
    if (changed) {
      // Finalize the project version.
      try {
        newProjectVersion = await this.storroApi.finalize(root, this, this.headVersionId.toString());
      } catch (error) {
        // if this fails, we need to mark all items as failed.
        // and log the error.
        logger.error('Failed to finalize project version', error);
        queue.items.forEach(item => {
          if (!item.failed) {
            item.failureMessage = 'Failed to upload, please try again or contact support';
          }
        });
      }

      this.progressCallback(queue.items);
    }

    this.progressCallback(queue.items);
    this.fileUploadQueue = undefined;

    return newProjectVersion;
  }

  /*
   * Check a Project for unknown algorithms.
   * Call this function before starting to use a Project.
   * Returns void on success and an Error on failure.
   *
   * @todo: Once we have multiple options for one algorithm
   * we should pass on the value to the relevant subsystem.
   */
  public checkAlgorithms(): void {
    // Is there a better way? Tell Arthur about it.
    const pconv = this.project.convergentEncryptionAlgo;
    const convencAlgo = pconv['convenc' as keyof typeof pconv];
    if (convencAlgo !== 'xchacha20') throw new Error('Unknown algorithm for convergent encryption');

    const phash = this.project.hashingAlgo;
    const hashAlgo = phash['hash' as keyof typeof phash];
    if (hashAlgo !== 'blake2btree') throw new Error('Unknown algorithm for hashing');

    const pchunkZip = this.project.chunkZipAlgo;
    const chunkZipAlgo = pchunkZip['chunkzip' as keyof typeof pchunkZip];
    if (chunkZipAlgo !== 'none') throw new Error('Unknown algorithm for chunk zipping');

    const pmerge = this.project.mergingAlgo;
    const mergeAlgo = pmerge['merge' as keyof typeof pmerge];
    if (mergeAlgo !== 'defaultmerge') throw new Error('Unknown algorithm for merging');
  }

  private async setHead(headVersionId: EcasKey): Promise<ProjectVersion[]> {
    this.headPromise = this.getVersion(headVersionId);
    this.headVersionId = headVersionId;
    const versionIterator = new ProjectVersionIterator(
      this.project.id,
      this.metaEcas,
      await this.headPromise,
      this.listingCache,
      this.contentKey,
    );
    const addedVersions: ProjectVersion[] = [];

    let foundHead = false;
    const processCurrentVersion = async () => {
      if (!versionIterator) {
        throw Error('VersionIterator is undefined');
      }
      if (!versionIterator.currentVersion) {
        throw Error('Current version in VersionIterator is undefined');
      }
      if (versionIterator.currentVersion.id.isEqual(this.headVersionId)) {
        foundHead = true;
      }
      const versionIdString = versionIterator.currentVersion.id.toString();
      if (!this.versions.has(versionIdString)) {
        if (!versionIterator.currentVersion.id) {
          throw new Error('ProjectVersion id not set');
        }
        const bytes = await this.metaEcas.getValue(versionIterator.currentVersion.id);
        const newPhv = new ProjectVersion(
          this.project.id,
          this.metaEcas,
          this.contentKey,
          bytes.getValue(),
          versionIterator.currentVersion.id,
          this.listingCache,
          undefined,
        );
        addedVersions.push(newPhv);
        this.versions.set(versionIdString, newPhv);
      }
    };

    while (versionIterator.currentVersion && (!foundHead || versionIterator.inFork)) {
      await processCurrentVersion();
      await versionIterator.nextVersion();
    }
    return addedVersions;
  }

  // Are we at the end of the project history or can we fetch more?
  public get hasMoreVersions(): boolean {
    if (!this.versionIterator) {
      return true;
    }
    return this.versionIterator.currentVersion !== undefined;
  }

  // Loads more versions in the version cache.
  async loadMore(abortSignal: AbortSignal | undefined, limit: number | Date, filter?: Path): Promise<ProjectVersion[]> {
    if (abortSignal?.aborted) {
      return Promise.reject(Error('Aborted'));
    }
    if (!this.versionIterator) {
      this.versionIterator = new ProjectVersionIterator(
        this.project.id,
        this.metaEcas,
        await this.head(),
        this.listingCache,
        this.contentKey,
      );
    }
    if (abortSignal?.aborted) {
      return Promise.reject(Error('Aborted'));
    }
    let versionsToAdd: ProjectVersion[] = Array.from(this.versions.values()).sort(ProjectVersion.sortOnTimestamp);
    if (filter) {
      const filteredArray: ProjectVersion[] = [];
      for (const phv of versionsToAdd) {
        if ((await phv.fullDiff(abortSignal, filter)).length > 0) {
          filteredArray.push(phv);
        }
      }
      versionsToAdd = filteredArray;
    }

    if (typeof limit === 'number') {
      versionsToAdd = this.versions.size <= limit ? versionsToAdd : versionsToAdd.slice(0, limit);
      while (versionsToAdd.length < (limit as number) && this.versionIterator.currentVersion) {
        if (!this.versionIterator.currentVersion.id) {
          throw new Error('ProjectVersion id not set');
        }
        const newPhv = this.versionIterator.currentVersion;

        if (!versionsToAdd.find(v => v.id === newPhv.id) && (!filter || (await newPhv.fullDiff(abortSignal, filter)).length > 0)) {
          versionsToAdd.push(newPhv);
        }

        if (this.versions.has(newPhv.versionIdString)) {
          if (!this.headVersionId.isEqual(newPhv.id)) {
            logger.warn('Creating ProjectHistoryVersion for second time');
          }
        } else {
          this.versions.set(newPhv.versionIdString, newPhv);
        }

        await this.versionIterator.nextVersion();
        if (abortSignal?.aborted) {
          return Promise.reject(Error('Aborted'));
        }
      }
    } else {
      versionsToAdd = versionsToAdd.filter(v => v.timestamp && v.timestamp >= (limit as Date));

      if (!this.versionIterator.currentVersion) {
        return versionsToAdd;
      }

      let atDate: Date | undefined = this.versionIterator.currentVersion.timestamp;
      while ((!atDate || atDate > (limit as Date)) && this.versionIterator.currentVersion) {
        if (abortSignal?.aborted) {
          return Promise.reject(Error('Aborted'));
        }
        logger.debug(`Loaded version from ${atDate?.toString()} to diff for project '${this.project.name}'.`);
        if (!this.versionIterator.currentVersion.id) {
          throw new Error('ProjectVersion id not set');
        }
        const newPhv = this.versionIterator.currentVersion;

        if (!this.versions.get(newPhv.versionIdString)) {
          this.versions.set(newPhv.versionIdString, newPhv);
        }

        if (!filter || (await newPhv.fullDiff(abortSignal, filter)).length > 0) {
          versionsToAdd.push(newPhv);
        }

        await this.versionIterator.nextVersion();
        if (this.versionIterator.currentVersion) {
          atDate = this.versionIterator.currentVersion.timestamp;
        }
        if (abortSignal?.aborted) {
          return Promise.reject(Error('Aborted'));
        }
      }
    }

    return versionsToAdd;
  }

  private createEcas(storeType: StoreType): Ecas {
    if (!this.storroApi.keys?.webDevicePrivateKey) {
      throw Error('No device private key is set');
    }
    // ApiStore forwards calls to api.storro.com.
    const store = new ApiStore(
      this.storroApi.baseURL,
      new UserDeviceApiJwtGenerator(this.storroApi.keys.webDevicePrivateKey),
      this.id,
      storeType,
      this.storroApi.superAdminMode,
    );

    // Wrap the memory store in a CAS and ECAS in the usual way.
    const cas = new Cas(store);

    return new Ecas(cas, new ConvergentEncryption(this.realmSalt));
  }
}
