import classNames from 'classnames';
import React from 'react';
import getExtensionIcon from '../../util/FileExtension/GetExtensionIcon';
import { IconContext } from '@phosphor-icons/react';

interface Props {
  className?: string;
  width?: number;
  name: string;
}

export default function FileIcon({ name, className, width = 20 }: Props): JSX.Element {
  const fileInfo = getExtensionIcon(name);

  return (
    <IconContext.Provider value={{ size: width, className: classNames(fileInfo.color, className) }}>{fileInfo.icon}</IconContext.Provider>
  );
}
