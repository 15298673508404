import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

export enum SpinnerSize {
  Small,
  Medium,
  Large,
  '2xlarge',
  '3xlarge',
}

interface Props {
  className?: string;
  inverseColor?: boolean;
  size?: SpinnerSize;
}

export default function Spinner({ size = SpinnerSize.Medium, inverseColor = false, className }: Props): JSX.Element {
  // NOTE: we cannot use dynamic classes for Tailwind, we should construct them as a whole class name
  // SEE: https://tailwindcss.com/docs/content-configuration#dynamic-class-names
  const [parsedSizeClasses, setParsedSizeClasses] = useState<string>('w-5 h-5');

  useEffect(() => {
    switch (size) {
      case SpinnerSize.Small:
        setParsedSizeClasses('w-2 h-2');
        break;
      case SpinnerSize.Medium:
        setParsedSizeClasses('w-4 h-4');
        break;
      case SpinnerSize.Large:
        setParsedSizeClasses('w-7 h-7');
        break;
      case SpinnerSize['2xlarge']:
        setParsedSizeClasses('w-14 h-14');
        break;
      case SpinnerSize['3xlarge']:
        setParsedSizeClasses('w-20 h-20');
        break;
    }
  }, [size]);

  return (
    <svg
      className={classNames(
        'animate-spin',
        'ml-1',
        parsedSizeClasses,
        {
          'text-white': !inverseColor,
          'text-gray-400': inverseColor,
        },
        className,
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
    >
      <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' />
      <path
        className='opacity-75'
        fill='currentColor'
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      />
    </svg>
  );
}
