import { Check } from '@phosphor-icons/react';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function ListItem({ children }: Props): JSX.Element {
  return (
    <div className='flex items-center text-gray-700 mb-1'>
      <Check className='mr-2 text-primary' />
      {children}
    </div>
  );
}
