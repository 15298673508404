// This is a functional equivalent to integerToByteArray()
// in Zooid/Source/Util/Numeric.h.
//
/** randomInteger() is taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random */
export function randomInteger(min = 0, max = 1000000): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

/** Encodes a number in Big Endian encoding. result[0] contains MSB, result[7] contains LSB. */
export function number64ToUint8Array(input: number): Uint8Array {
  const result = new Uint8Array(8);
  for (let i = 0; i < 8; i++) {
    result[7 - i] = input % 256;
    input /= 256;
  }
  return result;
}

export function number32ToUint8Array(input: number): Uint8Array {
  const result = new Uint8Array(4);
  for (let i = 0; i < 4; i++) {
    result[3 - i] = input % 256;
    input /= 256;
  }
  return result;
}

export function uint8ArrayToNumber64(input: Uint8Array): number {
  if (input.byteLength !== 8) throw 'Need 8 bytes to parse number64';
  let result = 0;
  for (let i = 0; i < 8; i++) {
    result *= 256;
    result += input[i];
  }
  return result;
}

export function uint8ArrayToNumber32(input: Uint8Array): number {
  if (input.byteLength !== 4) throw 'Need 4 bytes to parse number32';
  let result = 0;
  for (let i = 0; i < 4; i++) {
    result *= 256;
    result += input[i];
  }
  return result;
}
