import { useEffect, useMemo, useState } from 'react';
import { UploadQueueItem } from '../../api/UploadQueue';
import { useStorroApi } from '../../context/StorroApiContext';
import useRealm from '../../hooks/realm/UseRealm';

export default function useUploadQueue(): {
  queue: UploadQueueItem[];
  speed: number;
  progress: number;
  hasDecidedOnOverwrite: boolean;
} {
  const [queue, setQueue] = useState<UploadQueueItem[]>([]);
  const [speed, setSpeed] = useState<number>(0);
  const { realm } = useRealm();

  // The overall progress from 0 to 100
  const progress = useMemo<number>(() => {
    if (queue.length === 0) {
      return 0;
    }
    const sumSizes = queue.map(item => item.size).reduce((prev, next) => prev + next);
    const sumAt = queue.map(item => item.at).reduce((prev, next) => prev + next);
    if (sumSizes === 0 || sumSizes === sumAt || sumAt > sumSizes) {
      return 100;
    }
    if (sumAt === 0) {
      return 0;
    }
    return Math.round((sumAt / sumSizes) * 100);
  }, [queue]);

  const hasDecidedOnOverwrite = useMemo<boolean>(() => {
    return queue.filter(item => !item.hasExplicitOverwriteDecision).length === 0;
  }, [queue]);

  const { storroApi } = useStorroApi();

  useEffect(() => {
    const event = (uploadSpeed: number) => {
      if (!realm) {
        return;
      }
      setSpeed(uploadSpeed);
      storroApi.projectList.uploadQueueItems(realm.id).then(res => setQueue(res));
    };
    storroApi.projectList.subscribeProgressCallback(event);
    return () => {
      storroApi.projectList.unsubscribeProgressCallback(event);
    };
  }, [setQueue, storroApi.projectList, realm, queue]);

  return {
    queue,
    speed,
    progress,
    hasDecidedOnOverwrite,
  };
}
