// Copyright 2022 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// ApiStore is a type of KeyValueStore used for storing key-value pairs
// through the api.storro.com HTTP interface.
import { formatUrl } from 'util/FormatUrl';
import { getRandomString } from '../../util/getRandomString';
import delay from '../../util/Util/Delay';
import { logger } from '../Logger';
import { ApiJwtGenerator, apiStoreAuthorizationFromJson } from './ApiStoreAuthorization';
import { KeyValueStore } from './KeyValueStore';
import { StoreConfig, StoreFactory } from './StoreFactory';

// The Meta or Content store of a project.
export enum StoreType {
  Meta = 'Meta',
  Content = 'Content',
}

export class ApiStore implements KeyValueStore {
  // For direct S3 access we should cache the store. Undefined is returned if
  // not cache is in store, and the has/get call should go via the API.
  private directStore: KeyValueStore | undefined;
  private directStoreExpiration: Date | undefined;

  constructor(
    private baseUrl: string,
    private apiStoreAuthorization: ApiJwtGenerator,
    private projectId: string,
    private storeType: StoreType,
    // Pass this flag to the api has/get/put calls to indicate that we want to
    // do the request from an account that is in super admin mode.
    private superAdminMode: boolean,
  ) {
    if (!baseUrl || baseUrl === '') {
      throw new Error('baseUrl is not set');
    }
    if (!projectId || projectId === '') {
      throw new Error('projectId is not set');
    }
    if (!storeType) {
      throw new Error('storeType is not set');
    }
    if (storeType !== StoreType.Meta && storeType !== StoreType.Content) {
      throw new Error('Unknown StoreType');
    }
  }

  public toStoreConfig(): StoreConfig {
    return {
      type: 'ApiStore',
      baseUrl: this.baseUrl,
      apiStoreAuthorization: this.apiStoreAuthorization.toJson(),
      projectId: this.projectId,
      storeType: this.storeType,
      directStore: this.directStore ? this.directStore.toStoreConfig() : undefined,
      directStoreExpiration: this.directStoreExpiration,
      superAdminMode: this.superAdminMode === true ? true : undefined,
    };
  }

  public static fromStoreConfig(storeConfig: StoreConfig): ApiStore {
    if (storeConfig.type !== 'ApiStore') {
      throw new Error('Store is not of ApiStore type');
    }
    const apiStoreAuthorization = apiStoreAuthorizationFromJson(storeConfig['apiStoreAuthorization']);
    const store = new ApiStore(storeConfig['baseUrl'], apiStoreAuthorization, storeConfig['projectId'], storeConfig['storeType'], false);

    // Load the directStore if it's already set.
    if (storeConfig['directStore']) {
      const storeFactory = new StoreFactory();
      store.directStore = storeFactory.load(storeConfig['directStore']);
      store.directStoreExpiration = storeConfig['directStoreExpiration'];
    }

    // Load the super admin mode flag if it was set.
    if (storeConfig['superAdminMode'] && (storeConfig['superAdminMode'] as boolean) === true) {
      store.superAdminMode = true;
    }

    return store;
  }

  /**
   * Parse a store from the headers if any. If there is a store in the headers
   * set it in cache for future requests.
   */
  private parseStoreFromHeader(headers: Headers): void {
    if (this.superAdminMode) {
      // We don't support direct stores in super admin mode.
      return;
    }
    const configFromHeader = headers.get('store-config');
    if (!configFromHeader) {
      return;
    }
    try {
      const storeFactory = new StoreFactory();
      const storeConfigWithSessionToken = JSON.parse(configFromHeader);
      storeConfigWithSessionToken['uuid'] = getRandomString(22);
      storeConfigWithSessionToken['storageLimit'] = 0;
      const dateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z$/;
      if (storeConfigWithSessionToken['expiration_date'].match(dateRegex) === null)
        throw new Error('expiration_date in config of invalid format');
      this.directStore = storeFactory.load(storeConfigWithSessionToken);
      this.directStoreExpiration = new Date(storeConfigWithSessionToken['expiration_date']);
    } catch (error) {
      logger.error(error);
    }
  }

  public async hasValue(key: Uint8Array): Promise<boolean> {
    if (this.directStore && this.directStoreExpiration) {
      try {
        // Store session is expired.
        if (this.directStoreExpiration.valueOf() <= Date.now().valueOf()) {
          this.directStore = undefined;
          this.directStoreExpiration = undefined;
        } else {
          // await the result because if it fails,
          // we throw, catch and try using the normal api store.
          return await this.directStore.hasValue(key);
        }
      } catch (e) {
        logger.warn(`Direct has call failed: ${e}`, this.directStoreExpiration);
        this.directStore = undefined;
        this.directStoreExpiration = undefined;
      }
    }

    try {
      // TODO .head call should be wrapped in a try catch
      const keyAsHexString = Buffer.from(key).toString('hex');
      const storeTypePath = this.storeType === StoreType.Content ? 'content' : 'meta';
      let url = formatUrl(`${this.baseUrl}/projects/${this.projectId}/storage/${storeTypePath}/${keyAsHexString}`);
      if (this.superAdminMode) {
        url += '?super_admin';
      }

      const response = await fetch(url, {
        method: 'HEAD',
        headers: {
          Authorization: `Bearer ${this.apiStoreAuthorization.jwt().toString()}`,
        },
      });

      // Check if we have a recipe in our header. No need to await for this.
      this.parseStoreFromHeader(response.headers);

      if (response.status === 200) return Promise.resolve(true);
      if (response.status === 404) return Promise.resolve(false);
      throw new Error(response.statusText);
    } catch (e) {
      return Promise.reject('has() call failed: ' + e);
    }
  }

  public async getValue(key: Uint8Array): Promise<Uint8Array> {
    if (this.directStore && this.directStoreExpiration) {
      try {
        // Store session is expired.
        if (this.directStoreExpiration.valueOf() <= Date.now().valueOf()) {
          this.directStore = undefined;
          this.directStoreExpiration = undefined;
        } else {
          // We must await to be able to catch errors.
          return await this.directStore.getValue(key);
        }
      } catch (e) {
        logger.warn(`Direct get call failed: ${e}`, this.directStoreExpiration);
        this.directStore = undefined;
        this.directStoreExpiration = undefined;
      }
    }

    const keyAsHexString = Buffer.from(key).toString('hex');
    const storeTypePath = this.storeType === StoreType.Content ? 'content' : 'meta';

    try {
      let url = formatUrl(`${this.baseUrl}/projects/${this.projectId}/storage/${storeTypePath}/${keyAsHexString}`);
      if (this.superAdminMode) {
        url += '?super_admin';
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.apiStoreAuthorization.jwt().toString()}`,
        },
      });
      if (response.status !== 200) return Promise.reject(response.statusText);
      this.parseStoreFromHeader(response.headers);
      return Promise.resolve(new Uint8Array(await response.arrayBuffer()));
    } catch (e) {
      return Promise.reject('Could not get value');
    }
  }

  private async privPutValue(key: Uint8Array, value: Uint8Array, attempt: number): Promise<void> {
    const keyAsHexString = Buffer.from(key).toString('hex');
    const storeTypePath = this.storeType === StoreType.Content ? 'content' : 'meta';

    let url = formatUrl(`${this.baseUrl}/projects/${this.projectId}/storage/${storeTypePath}/${keyAsHexString}`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Content-Length': value.length.toString(),
        Authorization: `Bearer ${this.apiStoreAuthorization.jwt().toString()}`,
      },
      body: value,
    });

    if (response.status === 200) return Promise.resolve();

    // HA-Proxy could throw a 504 when the request takes too long to handle by
    // the api. This indicates that the api is too busy. We should retry the
    // request once more to a limit of 8 retries. The total added delay is 32
    // seconds max. If the server still comes up with a 504, we fail the request.
    if (response.status === 504 && attempt <= 8) {
      logger.info('Getting a 504, Retrying... ' + attempt);
      await delay(2 * attempt * 1000);
      await this.privPutValue(key, value, attempt + 1);
    }

    return Promise.reject('Could not put value: ' + response.statusText);
  }

  public async putValue(key: Uint8Array, value: Uint8Array): Promise<void> {
    return this.privPutValue(key, value, 1);
  }
}
