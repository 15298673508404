import React, { DetailedHTMLProps, FormHTMLAttributes, ForwardedRef, forwardRef } from 'react';

/**
 * Simple Form wrapper that adds some defaults to the form
 *
 * - Added action="#" as this will trigger to add the blue enter button on mobile devices keyboard
 */
const Form = forwardRef(function Form(
  { children, ...props }: DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
  ref: ForwardedRef<HTMLFormElement>,
): JSX.Element {
  return (
    <form ref={ref} action='#' {...props}>
      {children}
    </form>
  );
});

export default Form;
