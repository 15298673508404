import useDocumentTitle from '../../hooks/UseDocumenTitle';

interface Props {
  title?: string;
  children: JSX.Element;
}
export default function RouteComponentWithTitle({ children, title }: Props): JSX.Element {
  useDocumentTitle(title);
  return children;
}
