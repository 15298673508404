import { DialogProvider } from 'context/DialogContext';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { logger } from 'util/Logger';
import Modal, { ModalAction, ModalWidth } from '../index';
import SideIcon, { SideIconType } from '../SideIcon';
import { ButtonVariant } from 'components/Button';

export enum ConfirmModalType {
  Notice,
  Warning,
  Danger,
}

interface ConfirmModalProps {
  type?: ConfirmModalType;
  showSideIcon?: boolean;
  sideIcon?: ReactNode;
  isVisible?: boolean;
  loading?: boolean;
  title?: string;
  text?: string | ReactNode;
  confirmText?: string;
  showCancelButton?: boolean; // in some cases we need to force a confirm, without the option to bailout
  onCancel?: () => void;
  onConfirm?: () => void | Promise<void | any>; //eslint-disable-line
  destroy?: () => void; // the destroy function to destroy the element in the DOM
  isSkippable?: boolean; // mark a confirm as skipable, this let the user skip the confirm next time
  id?: string; // ID is mostly used for the local storage key in combination with the isSkipable bool
  isSkipped?: boolean; // mark this confirm as skipped, we can fire the onConfirm directly
}

/**
 * ConfirmModal is a Modal that set some default vars for the Modal component so it act as an confirm modal
 */
export default function ConfirmModal({
  type,
  showSideIcon,
  sideIcon,
  isVisible,
  text,
  title,
  onCancel,
  onConfirm,
  destroy,
  confirmText = 'Confirm',
  showCancelButton = true,
  isSkippable,
  isSkipped,
  id,
}: ConfirmModalProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>();
  const [markAsSkipped, setMarkAsSkipped] = useState<boolean>();
  const [modalIsVisible, setModalIsVisible] = useState<boolean>();

  /**
   * Determine the sideIconType based on the type
   */
  const sideIconType = useMemo(() => {
    switch (type) {
      case ConfirmModalType.Danger:
        return SideIconType.Danger;
      case ConfirmModalType.Notice:
        return SideIconType.Notice;
      case ConfirmModalType.Warning:
        return SideIconType.Warning;
    }
  }, [type]);

  const sideIconElement = showSideIcon ? <SideIcon type={sideIconType} icon={sideIcon} /> : <></>;

  /**
   * Render the checkbox and helpText to skip the confirm modal next time
   */
  const skippableInput = useMemo(() => {
    if (isSkippable && !id) {
      logger.error('ID is required when using the skippable={true} function. e.g. Confirm({id="your-id"});');
      return null;
    }
    if (!isSkippable || !id) return null;

    return (
      <div className='flex gap-x-1 items-center'>
        <input
          type='checkbox'
          id='skip-confirm-next-time'
          autoFocus={false}
          onChange={({ currentTarget }) => setMarkAsSkipped(currentTarget.checked)}
        />
        <label htmlFor='skip-confirm-next-time' className='text-sm text-gray-500 cursor-pointer select-none'>
          Do not show this confirmation again
        </label>
      </div>
    );
  }, [id, isSkippable]);

  /**
   * We can either receive a void function or a promise.
   * In case of a promise we also enable the loading icon
   *
   * We also check if the user has marked this confirm to be skipped next time.
   */
  const confirm = useCallback(() => {
    if (markAsSkipped && id) {
      const safeId = id.toLowerCase().replaceAll(' ', '-');
      const localStorageId = `confirm-skipable-${safeId}`;
      localStorage.setItem(localStorageId, '1');
    }

    // Execute the onConfirm function
    // when no onConfirm is provided, we just close the modal
    if (onConfirm) {
      const isPromise = onConfirm();
      if (isPromise?.then) {
        setLoading(true);
        isPromise.then().finally(() => {
          setLoading(false);
          setModalIsVisible(false);
        });
      } else {
        setModalIsVisible(false);
      }
    } else {
      setModalIsVisible(false);
    }
  }, [id, markAsSkipped, onConfirm]);

  /**
   * onClosed event, fired when the modal is closed
   */
  const onClosed = () => {
    destroy?.();
  };

  /**
   * onClose event, fired when we click on the cancel button
   */
  const onClose = () => {
    onCancel?.();
    setModalIsVisible(false);
  };

  /**
   * Define the action
   */
  const actions = useMemo((): ModalAction | undefined => {
    return {
      label: confirmText,
      variant: type === ConfirmModalType.Danger ? ButtonVariant.Danger : ButtonVariant.Primary,
      loading,
      onClick: confirm,
    };
  }, [confirm, confirmText, loading, type]);

  /**
   * set the visibility of the modal and check if we need to perform the confirm()
   * directly because its a skipped confirm modal
   */
  useEffect(() => {
    if (isVisible && isSkipped) {
      confirm();
      // fire the destroy to remove the DOM
      setTimeout(() => {
        destroy?.();
      }, 500);
    } else {
      setModalIsVisible(isVisible);
    }
  }, [confirm, destroy, isSkipped, isVisible]);

  return (
    <Modal
      remainSizeOnSmallerScreens={true}
      sideNode={sideIconElement}
      isVisible={modalIsVisible}
      title={title}
      onClosed={onClosed}
      onRequestCloseModal={showCancelButton ? onClose : undefined}
      action={actions}
      width={ModalWidth.md}
      footerSideNode={skippableInput}
    >
      {text}
    </Modal>
  );
}

type ConfirmProps = Pick<
  ConfirmModalProps,
  | 'text'
  | 'title'
  | 'confirmText'
  | 'onCancel'
  | 'onConfirm'
  | 'type'
  | 'showSideIcon'
  | 'sideIcon'
  | 'id'
  | 'isSkippable'
  | 'showCancelButton'
>;

/**
 * The Confirm() is an API method that will create the <ConfirmModal> with createRoot()
 *
 * Example:
 *
 * Confirm({
 *  title: 'title',
 *  text: 'test',
 *  onConfirm: () => {
 *    alert('confirm');
 *  },
 *  onCancel: () => {
 *    alert('cancel');
 *  },
 * });
 */
export function Confirm({ onCancel, onConfirm, ...props }: ConfirmProps): void {
  const div = document.createElement('div');
  const root = createRoot(div!); //eslint-disable-line
  document.body.appendChild(div);

  /**
   * This method will destroy and cleanup the created div and the components that are attached to
   */
  const destroy = () => {
    root.unmount();
    if (div.parentNode) {
      div.parentNode.removeChild(div);
    }
  };

  /**
   * Check if the user has marked this Confirm as skipped,
   * If so, we can fire the confirm() method directly instead of showing the confirmModal
   */
  const safeId = props.id?.toLowerCase().replaceAll(' ', '-');
  const localStorageId = `confirm-skipable-${safeId}`;

  root.render(
    <BrowserRouter>
      <DialogProvider dialogsItems={[]}>
        <ConfirmModal
          {...props}
          isVisible={true}
          onCancel={onCancel}
          onConfirm={onConfirm}
          destroy={destroy}
          isSkipped={localStorage.getItem(localStorageId) === '1'}
        />
      </DialogProvider>
    </BrowserRouter>,
  );
}
