import { Directory, Filesystem, WriteFileResult } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { FileOpener } from '@capacitor-community/file-opener';
import base64url from 'base64url';
import { Dir, Entry } from '../../api/Entry';
import { IS_MOBILE_APP } from '../../const';
import { DownloadDataConfig, DownloadEntryData } from '../../service-worker';
import { logger } from '../Logger';
import { MerkleTreeReader } from '../Serialization/MerkleTreeReader';
import Serviceworker from '../ServiceWorker';
import { SymmetricKey } from './../Cryptography/SymmetricKey';
import { Ecas, EcasJson } from './../Serialization/Ecas';

export type DownloadCallback = (downloadedSize: number) => void;

export default class Downloader {
  private serviceWorker = new Serviceworker();
  private mobileCachePath = 'storro_cache';

  /**
   * Web: Clear the stream map. This happens on user logout. It's essential that this
   * happens on logout because the new user that logs in should not be able to
   * download files from the previous user.
   *
   * Mobile: we remove the cache folder
   */
  public async clearCache(): Promise<void> {
    if (IS_MOBILE_APP) {
      try {
        await Filesystem.rmdir({
          directory: Directory.Cache,
          recursive: true,
          path: this.mobileCachePath,
        });
      } catch (error) {
        // ignore errors
      }
    } else {
      const reg = await this.serviceWorker.getRegistration();
      if (reg && reg.active) {
        await this.serviceWorker.cleanup(reg);
      }
    }
  }

  public async fileUrl(contentKey: SymmetricKey | undefined, ecas: EcasJson, entry: Entry): Promise<URL> {
    if ((await this.serviceWorker.isAvailable) === false) {
      throw Error('Service worker unavailable');
    }
    const fileName = entry.name(contentKey);
    if (!entry.key) {
      throw Error('Empty file');
    }

    // In some browsers (e.g. Firefox, Chrome), the user can set how the browser handle PDF files.
    // Either the App can open the file (default) to show the content,
    // or the browser override this behaviour by download the file directly to the filesystem.
    // In this last case, when the file will be automatically downloaded to the filesystem,
    // the browser will use the last segment in the url as the filename.
    const url = new URL(
      `${this.serviceWorker.scope}stream/${encodeURIComponent(entry.key.toPlaintextString())}/${encodeURIComponent(fileName)}`,
    );
    url.searchParams.set('size', entry.size.toString());
    url.searchParams.set('ecas', JSON.stringify(ecas));
    return url;
  }

  /**
   * Main entry point for Open an entry
   */
  public async open(
    contentKey: SymmetricKey | undefined,
    entrySets: { ecas: Ecas; entries: Entry[] }[],
    downloadCallback?: DownloadCallback,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    if (!IS_MOBILE_APP) {
      return logger.warn('Downloader::open is only implemented for MOBILE_APP');
    }

    // download all files locally
    const filePaths = await this._downloadForMobile(contentKey, entrySets, downloadCallback, abortSignal);

    // show the native IOS/Android File preview dialog
    FileOpener.open({ filePath: filePaths[0] }).catch(error => logger.error('Error opening file', error));
  }

  /**
   * Main entry point for downloading entries
   */
  public async download(
    contentKey: SymmetricKey | undefined,
    entrySets: { ecas: Ecas; entries: Entry[] }[],
    downloadCallback?: DownloadCallback,
    abortSignal?: AbortSignal,
    // Before we can start an download we need (if needed...) to prepare/download subentries
    // this event is triggered after when all prepare tasks are done
    onDownloadStarted?: () => void,
  ): Promise<void> {
    if (!IS_MOBILE_APP) {
      if ((await this.serviceWorker.isAvailable) === false) {
        throw Error('Service worker unavailable');
      }

      // Construct a keep-alive and listen for broadcast messages from the
      // service worker to tear down the keep-alive. We need a keep-alive
      // during the whole duration of the download as Firefox seems to think
      // the service worker is idle when it has returned the ReadableStream.
      // It can happen that the service worker is stopped mid-download because
      // it is "idle", causing the download to fail.
      // https://stackoverflow.com/a/68552150
      // Putting this in the service worker doesn't work.
      // The service worker pings the /sw_version once and then poof, gone.
      const bc = new BroadcastChannel('download');
      const timerId = setInterval(function () {
        fetch('/sw_version', { method: 'GET' });
      }, 2000);
      bc.onmessage = event => {
        if (event.data === `clearInterval${timerId}`) {
          clearInterval(timerId);
        }
      };

      const downloadDataArray: DownloadDataConfig[] = [];

      for (const set of entrySets) {
        // construct the entries with the parsed name
        // We cannot decode the name with the contentKey inside a service-worker due its limitation of complex transferable objects/classes
        const swEntries: DownloadEntryData[] = [];

        /**
         * Format the entries to a structure where we decrypt
         * the entry name and resolve, if needed, the dir to entries recursively.
         */
        const formatEntryList = async (entries: Entry[], path: string[] = []) => {
          // if the user cancel the download in the preloading state, we should bail out
          if (abortSignal?.aborted) {
            throw Error(`Aborted`);
          }
          for (const entry of entries) {
            if (entry instanceof Dir) {
              await formatEntryList(await entry.entries(), [...path, entry.name(contentKey)]);
            } else {
              swEntries.push({
                type: entry.type,
                size: entry.size.toString(),
                key: entry.key?.toPlaintextString(),
                path: path.join('/'),
                filename: entry.name(contentKey),
                encryptedFilename: base64url.encode(Buffer.from(entry.encryptedName)),
              });
            }
          }
        };

        await formatEntryList(set.entries);
        if (!contentKey) {
          throw Error('ContentLevelKey required for download');
        }
        const contKeyBase64Url = base64url.encode(Buffer.from(contentKey.getRawKey()));
        downloadDataArray.push({ ecasConfig: set.ecas.toJson(), entries: swEntries, contentKey: contKeyBase64Url });
      }

      // fire the event
      onDownloadStarted?.();

      // Create (or re-use) an iFrame as that is used as form target. This is a
      // trick to not refresh the page (and cancel all active uploaded e.g.).
      const iframeName = 'downloadIframe';
      let iframeEl: HTMLIFrameElement | null = document.getElementById(iframeName) as HTMLIFrameElement;
      if (iframeEl === null) {
        iframeEl = document.createElement('iframe');
        iframeEl.id = iframeName;
        iframeEl.name = iframeName;
        iframeEl.hidden = true;
        document.body.appendChild(iframeEl);
      }

      // Create (or re-use) a html form element in the dom. Use it to download the
      // file(s) using the browsers downloader.
      let formEl: HTMLFormElement | null = document.getElementById('downloaderFileForm') as HTMLFormElement;
      if (formEl === null) {
        formEl = document.createElement('form');
        formEl.id = 'downloaderFileForm';
        formEl.target = iframeName;
        formEl.method = 'POST';
        formEl.action = '/download';
        document.body.appendChild(formEl);
      }

      while (formEl.firstChild) {
        formEl.removeChild(formEl.firstChild);
      }

      // Add a new input element with the download data array as value.
      const inputEl = document.createElement('input');
      inputEl.type = 'hidden';
      inputEl.name = 'DownloadDataArray';
      inputEl.value = JSON.stringify(downloadDataArray);
      formEl.appendChild(inputEl);

      // Add the timer id related to our keep-alive
      // Since there may be more downloads in parallel the service worker needs
      // a method of signaling which timer to cancel once it has completed its
      // download. Hence we add the timerId we started for this download to the
      // form so that the service worker can refer to this timerId in its
      // signal back to us of completion.
      const timerEl = document.createElement('input');
      timerEl.type = 'hidden';
      timerEl.name = 'TimerId';
      timerEl.value = `${timerId}`;
      formEl.appendChild(timerEl);

      // Submit the form.
      formEl.submit();
    } else {
      // download all files locally
      const filePaths = await this._downloadForMobile(contentKey, entrySets, downloadCallback, abortSignal);

      // show the native IOS/Android share dialog
      Share.share({
        files: filePaths,
      }).catch(() => {
        // ignore errors
      });
    }
  }

  /**
   * Download function for on a mobile App
   * This function will save the files to the local filesystem and return the file paths
   */
  private async _downloadForMobile(
    contentKey: SymmetricKey | undefined,
    entrySets: { ecas: Ecas; entries: Entry[] }[],
    downloadCallback: DownloadCallback | undefined,
    abortSignal?: AbortSignal,
  ): Promise<string[]> {
    const { encode } = require('uint8-to-base64');

    // save the filePath(s) so we can return it back
    const filePaths: string[] = [];

    // NOTE: we do not need to ask for File Permission
    // The cache folder is always writeable for both IOS and Andrid
    // @see https://capacitorjs.com/docs/v4/apis/filesystem#requestpermissions

    // First delete the cache dir to avoid stacking all (huge) temp files here
    // We are not sure when Android or IOS clear the cache folder, so to be sure we remove the files by our self
    try {
      await Filesystem.rmdir({
        directory: Directory.Cache,
        recursive: true,
        path: this.mobileCachePath,
      });
    } catch (error) {
      // ignore errors
    }

    // (re)create the storro_cache folder
    try {
      await Filesystem.mkdir({
        directory: Directory.Cache,
        path: this.mobileCachePath,
      });
    } catch (e) {
      throw new Error('Cannot create a cache dir');
    }

    // find out the total size of all files
    const totalSize = entrySets.reduce<number>((prevValue, currentValue) => {
      return currentValue.entries.reduce<number>((prevV, curV) => {
        return Number(curV.size) + prevV;
      }, 0);
    }, 0);
    let downloadedBytes = 0;

    for (const set of entrySets) {
      for (const entry of set.entries) {
        if (abortSignal?.aborted) {
          throw Error(`Aborted`);
        }

        if (!entry.key) {
          throw new Error('Entry key should be defined in order to download the file');
        }

        let finished = false;
        let offset = 0;
        const maxReadWriteSize = 4194304; // 4 MiB
        const merkleTreeReader = new MerkleTreeReader(set.ecas, entry.key);
        const filename = `${this.mobileCachePath}/${entry.name(contentKey)}`;

        // create an empty file first
        // so we can reference it
        let file: WriteFileResult;
        try {
          file = await Filesystem.writeFile({
            path: filename,
            directory: Directory.Cache,
            data: '',
          });
          filePaths.push(file.uri);
        } catch (e) {
          logger.error(e);
          const error = e instanceof Error ? e.message : 'unknown error';
          throw new Error(`Cannot write files to ${filename} with error: ${error}`);
        }

        while (!finished) {
          if (abortSignal?.aborted) {
            throw Error(`Aborted`);
          }

          downloadCallback?.((downloadedBytes / totalSize) * 100);

          if (offset >= Number(entry.size)) {
            finished = true;
            break;
          }
          try {
            const readLen = Math.min(maxReadWriteSize, Number(entry.size) - offset);
            const bytes = await merkleTreeReader.read(offset, readLen);

            // convert the uint8Array to base64
            const b64encoded = encode(bytes);

            // append the data to the file
            await Filesystem.appendFile({
              path: filename,
              data: b64encoded,
              directory: Directory.Cache,
            });

            offset += readLen;
            downloadedBytes += readLen;
          } catch (e) {
            const error = e instanceof Error ? e.message : 'unknown error';
            throw new Error(`Cannot write files to ${filename} with error: ${error}`);
          }
        }
      }
    }

    return filePaths;
  }
}
