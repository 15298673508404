// Copyright 2022 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
import { randombytes_buf } from 'libsodium-wrappers';
// A fast pseudo-random data generator
const MersenneTwister = require('mersenne-twister');

// Note: Be sure to call libsodium's 'await ready' before calling this function.
export function randomUint8Array(size: number): Uint8Array {
  return randombytes_buf(size, 'uint8array');
}

export function randomNumber(min: number, max: number): number {
  if (max <= min) throw new Error('Invalid range for randomNumber()');
  const range = max - min;
  const buffer = randomUint8Array(8);
  let n = 0;
  for (let i = 0; i < buffer.length; i++) {
    n *= 256;
    n += buffer[i];
  }
  return min + (n % range);
}

export function test_pseudoRandomUint8Array(size: number): Uint8Array {
  const twister = new MersenneTwister(randomNumber(0, 9876543210987654));
  const result = new Uint8Array(size);
  let i = 0;
  // Take 4 bytes for speed
  while (i + 3 < size) {
    let r = twister.random_int();
    result[i++] = r % 255;
    r /= 255;
    result[i++] = r % 255;
    r /= 255;
    result[i++] = r % 255;
    r /= 255;
    result[i++] = r % 255;
  }
  // If necessary fill up until complete.
  while (i < size) {
    result[i++] = twister.random_int() % 255;
  }
  return result;
}

export function test_pseudoRandomNumber(min: number, max: number): number {
  const twister = new MersenneTwister(randomNumber(0, 9876543210987654));
  if (max <= min) throw new Error('Invalid range for randomNumber()');
  const range = max - min;
  return min + (twister.random_int() % range);
}
