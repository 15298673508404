import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import Sidebar, { SidebarItem } from '../api/Sidebar';
import useScreenSize from '../hooks/UseScreenSize';
import { ScreenSize } from '../types/ScreenSize';
import { logger } from '../util/Logger';

interface Props {
  children: React.ReactNode;
}

type SidebarRightContextType = {
  sidebar: Sidebar;
  items: SidebarItem[];
  itemCount: number;
  isOpen: boolean | undefined;
  setIsOpen: Dispatch<SetStateAction<boolean | undefined>>;
  isHidden: boolean;
  setIsHidden: Dispatch<SetStateAction<boolean>>;
};

const SidebarRightContext = createContext<SidebarRightContextType>({
  sidebar: {} as Sidebar,
  items: [],
  itemCount: 0,
  isOpen: false,
  setIsOpen: () => logger.warn('no SidebarRight provider'),
  isHidden: false,
  setIsHidden: () => logger.warn('no SidebarRight provider'),
});

export function useSidebarRightContext(): SidebarRightContextType {
  return useContext(SidebarRightContext);
}

export function SidebarRightProvider({ children }: Props): JSX.Element {
  const [items, setItems] = useState<SidebarItem[]>([]); // holds all the sidebar items
  const [isOpen, setIsOpen] = useState<boolean>();
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const { width } = useScreenSize();

  /**
   * Open the sidebar when the size of the window is acceptable (only after a resize has been placed)
   * Or the open_context_sidebar session key has been set
   */
  useEffect(() => {
    if (width === undefined) return;

    const openFromSession = sessionStorage.getItem('open_context_sidebar');
    if (isOpen === undefined && openFromSession === '1') {
      setIsOpen(true);
    } else {
      setIsOpen(width > ScreenSize['3xl']);
    }
  }, [width]); //eslint-disable-line

  /**
   * Sideffect for the setIsOpen
   * Save the state to the sessionStorage
   */
  useEffect(() => {
    // to avoid the double re-render from the strictMode, we should check if isOpen has an actual value
    if (isOpen === undefined) return;

    // if we set the sidebar to OPEN, we should save this state to the sessionStorage
    // but only if the value is has not been set
    const openFromSession = sessionStorage.getItem('open_context_sidebar');
    if (isOpen === true && openFromSession !== '1') {
      sessionStorage.setItem('open_context_sidebar', '1');
    } else {
      // all other cases we should get rid of this key in the session
      sessionStorage.removeItem('open_context_sidebar');
    }
  }, [isOpen]); // eslint-disable-line

  // construct a new Sidebar with a update callback.
  // when invoked, it will update the list of items
  const [sidebar] = useState<Sidebar>(new Sidebar(_items => setItems(_items)));

  return (
    <SidebarRightContext.Provider
      value={{
        sidebar,
        items,
        itemCount: items.length,
        isOpen,
        setIsOpen,
        isHidden,
        setIsHidden,
      }}
    >
      {children}
    </SidebarRightContext.Provider>
  );
}
