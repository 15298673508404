import { TypedEmitter } from 'tiny-typed-emitter';
import { logger } from '../util/Logger';

export class UserPublicKey {
  constructor(public base64PublicKey: string) {}
}

export class PeerKey {
  constructor(public base64PublicKey: string) {}
}

// We named the types, so we can see a name in the typescript compiler when calling the function
// with this type
type ProjectId = string;
type What = string;
type Reason = string;
type Devices = Array<PeerKey>;

interface CallbackInterface {
  projectAdded: (projectId: ProjectId) => void;
  projectUpdated: (projectId: ProjectId, what: What) => void;
  projectRemoved: (projectId: ProjectId, reason: Reason) => void;
  projectMemberAdded: (projectId: ProjectId, member: UserPublicKey) => void;
  projectMemberUpdated: (projectId: ProjectId, member: UserPublicKey, what: What) => void;
  projectMemberRemoved: (projectId: ProjectId, member: UserPublicKey, reason: Reason) => void;
  project: (projectId: ProjectId) => void; // general event when something just changed to the project
  userDevicesUpdated: (projectId: ProjectId, user: UserPublicKey, devices: Devices) => void;
  quickShareUpdated: (quickShareId: number, what: What) => void;
  quickShareClosed: (quickShareId: number) => void;
  quickShareReopened: (quickShareId: number) => void;

  // We're reconnected to the websocket.
  websocketReconnected: () => void;
}

interface WebSocketMessage {
  name: string;

  [key: string]: unknown;
}

export class WebSocketEvents extends TypedEmitter<CallbackInterface> {
  constructor() {
    super();
  }

  // Method is called by the websocket when reconnected
  public reconnected(): void {
    this.emit('websocketReconnected');
  }

  messageReceived(message: unknown): void {
    const parsed = JSON.parse(message as string) as WebSocketMessage;

    switch (parsed.name) {
      case 'event_project_added':
        if (!parsed['projectId']) {
          logger.error('Received "event_project_added" but "projectId" field is not set');
          return;
        }
        this.emit('projectAdded', parsed['projectId'] as string);
        this.emit('project', parsed['projectId'] as string);
        break;
      case 'event_project_updated':
        if (!parsed['projectId']) {
          logger.error('Received "event_project_updated" but "projectId" field is not set');
          return;
        }
        if (!parsed['what']) {
          logger.error('Received "event_project_updated" but "what" field is not set');
        }
        this.emit('projectUpdated', parsed['projectId'] as string, parsed['what'] as string);
        this.emit('project', parsed['projectId'] as string);
        break;
      case 'event_project_removed':
        if (!parsed['projectId']) {
          logger.error('Received "event_project_removed" but "projectId" field is not set');
          return;
        }
        if (!parsed['reason']) {
          logger.error('Received "event_project_removed" but "reason" field is not set');
        }
        this.emit('projectRemoved', parsed['projectId'] as string, parsed['reason'] as string);
        break;
      case 'event_project_member_added':
        if (!parsed['projectId']) {
          logger.error('Received "event_project_member_added" but "projectId" field is not set');
          return;
        }
        if (!parsed['member']) {
          logger.error('Received "event_project_member_added" but "member" field is not set');
        }
        this.emit('projectMemberAdded', parsed['projectId'] as string, new UserPublicKey(parsed['member'] as string));
        this.emit('project', parsed['projectId'] as string);
        break;
      case 'event_project_member_updated':
        if (!parsed['projectId']) {
          logger.error('Received "event_project_member_updated" but "projectId" field is not set');
          return;
        }
        if (!parsed['what']) {
          logger.error('Received "event_project_member_updated" but "what" field is not set');
        }
        if (!parsed['member']) {
          logger.error('Received "event_project_member_updated" but "member" field is not set');
        }
        this.emit(
          'projectMemberUpdated',
          parsed['projectId'] as string,
          new UserPublicKey(parsed['member'] as string),
          parsed['what'] as string,
        );
        this.emit('project', parsed['projectId'] as string);
        break;
      case 'event_project_member_removed':
        if (!parsed['projectId']) {
          logger.error('Received "event_project_member_removed" but "projectId" field is not set');
          return;
        }
        if (!parsed['reason']) {
          logger.error('Received "event_project_member_removed" but "reason" field is not set');
        }
        if (!parsed['member']) {
          logger.error('Received "event_project_member_removed" but "member" field is not set');
        }
        this.emit(
          'projectMemberUpdated',
          parsed['projectId'] as string,
          new UserPublicKey(parsed['member'] as string),
          parsed['reason'] as string,
        );
        this.emit('project', parsed['projectId'] as string);
        break;
      case 'event_quick_share_updated': {
        const qsId = parseInt(parsed['quickShareId'] as string);
        if (isNaN(qsId)) {
          logger.error('Received "event_quick_share_updated" but "quickShareId" field is not numeric');
        }
        this.emit('quickShareUpdated', qsId, parsed['what'] as string);
        break;
      }
      case 'event_quick_share_closed': {
        const qsId = parseInt(parsed['quickShareId'] as string);
        if (isNaN(qsId)) {
          logger.error('Received "event_quick_share_closed" but "quickShareId" field is not numeric');
        }
        this.emit('quickShareClosed', qsId);
        break;
      }
      case 'event_quick_share_reopened': {
        const qsId = parseInt(parsed['quickShareId'] as string);
        if (isNaN(qsId)) {
          logger.error('Received "event_quick_share_reopened" but "quickShareId" field is not numeric');
        }
        this.emit('quickShareReopened', qsId);
        break;
      }
      default:
        logger.warn(`Ignoring unknown message ('${parsed.name}') on web socket`);
        break;
    }
  }
}
