import { logger } from '../util/Logger';

export default class FileUploadList {
  /**
   * Convert all files from a Drag N Drop event into a fileList of File[]
   */
  public fromInput(files: FileList | null): File[] {
    const processedFiles: File[] = [];
    if (files) {
      for (const file of files) {
        processedFiles.push(new File([file], file.webkitRelativePath || file.name));
      }
    }

    return processedFiles;
  }

  /**
   * Convert all files from a Drag N Drop event into a fileList of File[]
   */
  public async fromDragDropEvent(items: DataTransferItemList): Promise<File[]> {
    const processedFiles: File[] = [];
    const queuedEntries: FileSystemEntry[] = [];

    // When calling .file() or .createReader() on the result of webkitGetAsEntry() the next item (and others)
    // will return null from calling webkitGetAsEntry()
    // there for we need to fetch first all results from webkitGetAsEntry() to avoid this issue
    // see https://stackoverflow.com/questions/12105900/fileentry-file-method-makes-datatransferitemlist-empty
    if (items) {
      for (const item of items) {
        const entry = item.webkitGetAsEntry();
        if (entry) {
          queuedEntries.push(entry);
        }
      }
    }

    for (const entry of queuedEntries) {
      if (entry && entry.isFile) {
        // single file
        try {
          processedFiles.push(await this._addEntryFile(entry));
        } catch (error) {
          logger.error('Cannot process this item', entry);
        }
      } else if (entry && entry.isDirectory) {
        // Append all files from that directory
        const folderEntries = await this._processFilesFromDirectory(entry);
        for (const folderEntry of folderEntries) {
          try {
            processedFiles.push(await this._addEntryFile(folderEntry));
          } catch (error) {
            logger.error('Cannot process this item', entry);
          }
        }
      }
    }

    return processedFiles;
  }

  /**
   * Add an Entry File to the fileList
   */
  private async _addEntryFile(entry: FileSystemEntry | null): Promise<File> {
    // return custom promise as the .file() resolve the file in the callback
    return new Promise((resolve, reject) => {
      if (entry && entry.isFile) {
        const correctEntryType = entry as FileSystemFileEntry;
        correctEntryType.file(
          file =>
            resolve(
              new File([file], entry.fullPath, {
                type: file.type,
              }),
            ),
          error => reject(error),
        );
      }
    });
  }

  /**
   * Goes through the directory, and adds each file it finds recursively
   */
  private async _processFilesFromDirectory(entry: FileSystemEntry): Promise<FileSystemEntry[]> {
    if (entry.isFile) {
      return [entry];
    }

    return new Promise((resolve, reject) => {
      const dirReader = (entry as FileSystemDirectoryEntry).createReader();
      dirReader.readEntries(
        async entries => {
          let processedFiles: FileSystemEntry[] = [];
          if (entries.length > 0) {
            for (const entry of entries) {
              processedFiles = [...processedFiles, ...(await this._processFilesFromDirectory(entry))];
            }
          }
          return resolve(processedFiles);
        },
        error => reject(error),
      );
    });
  }
}
