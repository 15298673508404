import base64url from 'base64url';
import { logger } from '../util/Logger';
import { StreamKey } from '../util/Serialization/StreamKey';
import { equal } from '../util/Util/Equal';
import { Dir, Entry, EntryType, Path } from './Entry';

export enum Operation {
  Undefined = 0,
  Create,
  Delete,
  Change,
  Touch,
  Move,
  Copy,
  Rename,
}

export interface Result {
  path: Path;
  operation: Operation;
  origins: Array<Path>;
}

export interface Summary {
  path: Path;
  operation: Operation;
  results: Result[];

  // The amount of results that are not part of the summary. This is used for
  // caching the summary results (at the api). This because the 'results' array
  // could get very large. If a user is interested in all the results then the
  // user should generate the diff.
  cutoffCount: number;
}

// JSON.parse-ed version of a diff result. For communication from/to api.
export interface DiffResultJson {
  path: string;
  operation: Operation;
  origins: Array<string>;
}

// JSON.parse-ed version of a diff summary. For communication from/to api.
export interface DiffSummaryJson {
  path: string;
  operation: Operation;
  results: DiffResultJson[];
  cutoffCount: number;
}

// Convert JSON diff summaries to actual Diff summaries.
export const fromJsonDiffSummary = (data: DiffSummaryJson[]): Summary[] => {
  return data.map((summary: DiffSummaryJson): Summary => {
    return {
      path: new Path(summary.path),
      operation: summary.operation,
      results: summary.results.map((result: DiffResultJson): Result => {
        return {
          path: new Path(result.path),
          operation: result.operation,
          origins: result.origins.map(path => new Path(path)),
        };
      }),
      cutoffCount: summary.cutoffCount,
    };
  });
};

// Filter out the Touch results if we have other types of changes like Added
// or Changed. This shows less clutter of meaningless Touch changes in the gui.
// An example:
// You have a commit with the following diff:
// ==============================
// Alice on 2023-11-28:
// - Alice changed a/foo.txt
// - Alice touched a
// ==============================
// Then you want to remove the 'Alice touched a' line because it doesn't add
// much value for the user. But lets look at the following example:
// ==============================
// Bob on 2023-11-28:
// - Bob touched a
// ==============================
// In the commit above you want to keep showing the touched diff result because
// otherwise the diff will be empty resulting in confused users.
// Commits with only a touch event could theoretically occur but we have
// mechanisms in place to prevent them from being generated.
export const filterTouches = (results: Summary[]): Summary[] => {
  const filter = results.filter(res => res.operation !== Operation.Touch);
  return filter.length > 0 ? filter : results;
};

// Generate a diff between two dirs. Use Diff::diff();
export class Diff {
  // Finds the basic differences between 2 dirs.
  // A basic diff means that a entry on a path is not the same anymore.
  // The following is returned for each difference:
  //  - The full path of the entry that differs
  //  - The entry as it was in the 'before' state
  //  - The entry as it is in the 'after' state
  // When both before and after are not nullptr then the entry is change.
  // When before is undefined and after is not undefined then an entry is added at that location.
  // When before is not undefined and after is undefined then an entry is removed at that location.
  static async naiveDiff(
    from: Dir,
    to: Dir,
    path: Path = new Path(),
    result: Map<Path, { from?: Entry; to?: Entry }> | undefined = undefined,
  ): Promise<Map<Path, { from?: Entry; to?: Entry }>> {
    if (result === undefined) result = new Map<Path, { from?: Entry; to?: Entry }>();

    if (from.key !== undefined && to.key !== undefined && from.key.isEqual(to.key)) {
      return Promise.resolve(result);
    }

    if (from.mod.valueOf() !== to.mod.valueOf()) {
      result.set(path.clone(), { from, to });
    }

    const fromEntries: Entry[] = await from.entries();
    const toEntries: Entry[] = await to.entries();

    for (const toEntry of toEntries) {
      const fromEntry = fromEntries.find(e => equal(e.encryptedName, toEntry.encryptedName));

      const subPath: Path = path.clone();
      subPath.push(toEntry.encryptedName);
      if (fromEntry) {
        if (StreamKey.areEqual(fromEntry.key, toEntry.key) && fromEntry.mod.valueOf() === toEntry.mod.valueOf()) {
          continue;
        }
        if (fromEntry.type === EntryType.Dir && toEntry.type === EntryType.Dir) {
          await Diff.naiveDiff(fromEntry.toDir(), toEntry.toDir(), subPath, result);
          continue;
        }
      }
      result.set(subPath, { from: fromEntry, to: toEntry });
    }

    for (const fromEntry of fromEntries) {
      if (!toEntries.find(e => equal(e.encryptedName, fromEntry.encryptedName))) {
        // This entry has been deleted.
        const subPath: Path = path.clone();
        subPath.push(fromEntry.encryptedName);
        result.set(subPath, { from: fromEntry, to: undefined });
      }
    }

    return result;
  }

  // Generate a diff between two dirs. It will try to find the following changes:
  // Create, Delete, Change, Touch, Move, Copy and Rename. This system is not
  // fully waterproof because the actual changes that a user make could differ.
  // For instance coping a file and changing the content in one project version
  // is analized by this method as the creation of a new file because it could
  // not extract the copy-operation from the original dir.
  static async diff(from: Dir, to: Dir): Promise<Result[]> {
    if (from.key && from.key.isEqual(to.key)) {
      return Promise.resolve([]);
    }

    const originHash = async (dir: Dir, path: Path): Promise<Map<string, Path[]>> => {
      const res = new Map<string, Path[]>();
      for (const e of await dir.entries()) {
        const p: Path = path.clone();
        p.push(e.encryptedName);
        const locator = e.key ? base64url.encode(Buffer.from(e.key.getLocator())) : '';
        res.set(locator, [p]);
        if (e.type === EntryType.Dir) {
          // Iterate a level deeper and add the results to `res`.
          for (const [key, path2] of await originHash(e.toDir(), p)) {
            res.set(key, (res.get(key) ?? []).concat(path2));
          }
        }
      }
      return res;
    };

    // Get the naive diff and find alternate origins.
    const basicDiff: Map<Path, { from?: Entry; to?: Entry }> = await Diff.naiveDiff(from, to);

    const addedContentKeys: StreamKey[] = [];

    // It contains a list of paths that have been renamed. It contains both 'to' and 'from' paths.
    const renamePaths: Path[] = [];

    const passedAway: string[] = [];
    const changes: Result[] = [];
    const touches: Result[] = [];

    for (const [path, value] of basicDiff) {
      if (!value.to) {
        passedAway.push(path.toEncryptedString());
      } else {
        const toEntry = value.to;
        if (toEntry.key) addedContentKeys.push(toEntry.key);
        // Here we will try to find the paths that have been renamed.
        // Since an empty file or dir does not have a valid content key
        // we cannot rely on that mechanism to find out paths that have been
        // renamed. The alternative is to find an entry in 'from' paths list
        // that shares the same modification time and is also empty.
        if (!value.from && toEntry.size === 0n) {
          const modificationTime = toEntry.mod;
          // Get modification time of 'to' entry. Compare it with modification
          // time of entries in basicDiff that have only 'from' paths.
          for (const [path2, value2] of basicDiff) {
            if (!value2.to && value2.from) {
              const fromEntry = value2.from;
              if (fromEntry.mod.valueOf() === modificationTime.valueOf() && fromEntry?.size === 0n && fromEntry.type === fromEntry.type) {
                renamePaths.push(path2); // origin path
                renamePaths.push(path); // new renamed path
                changes.push({ path, operation: Operation.Rename, origins: [path2] });
                break;
              }
            }
          }
        }
      }
    }

    // Collection of origins which are required to conclude if a file is created
    // or moved/copied from another location in the file structure.
    let origins: Map<string, Path[]> | undefined = undefined;

    for (const [path, value] of basicDiff) {
      // Ignore entries that have been renamed.
      if (renamePaths.includes(path)) continue;

      let originFilePaths: Path[] = [];
      if (!value.from) {
        // Find alternate origins. There is no source, so there must be a target.
        if (!value.to) {
          logger.error('No from and to entry in diff');
          continue;
        }
        if (value.to.key) {
          const locator = base64url.encode(Buffer.from(value.to.key ? value.to.key.getLocator() : ''));
          if (!origins) {
            origins = await originHash(from, new Path());
          }
          originFilePaths = origins.get(locator) ?? new Array<Path>();
        }
      }
      let operation: Operation = Operation.Undefined;
      if (originFilePaths.length === 0) {
        if (!value.from) {
          // Create
          operation = Operation.Create;
        } else if (!value.to) {
          // Delete
          // Check if the removed file is part of a move operation.
          // When it is, we can ignore this change.
          if (value.from.key && addedContentKeys.find(k => k.isEqual(value.from?.key))) {
            continue;
          }
          operation = Operation.Delete;
        } else {
          if (value.from.type !== value.to.type) {
            // Dir<->File morph.
            operation = Operation.Change;
          } else if (value.from.type === EntryType.File) {
            // Chances of the content key staying the same but the file size
            // changing are extremely small (non-existent). We still support
            // this case because we need the DirDiff to notice file size changes
            // independent of content key changes, for instance when we're
            // correcting an incorrect file size.
            if (!StreamKey.areEqual(value.from.key, value.to.key) || value.from.size !== value.to.size) {
              operation = Operation.Change;
            } else {
              operation = Operation.Touch;
            }
          } else {
            // Dir
            if (value.from.mod !== value.to.mod) {
              operation = Operation.Touch;
            }
          }
        }
      } else {
        if (!value.to) {
          // This should never happen.
          logger.error('Ignoring change where `to` entry is null.');
          continue;
        }
        if (originFilePaths.filter(p => passedAway.includes(p.toEncryptedString())).length > 0) {
          originFilePaths = originFilePaths.filter(p => passedAway.includes(p.toEncryptedString()));
          if (originFilePaths.length === 0) {
            logger.error('Ignoring change where origins is empty.');
            continue;
          }

          // If the 'move' happened in the same parent directory then it's a 'rename'.
          operation = Operation.Move;
          if (originFilePaths.length === 1) {
            const origin = originFilePaths[0];
            if (path.length() !== 0 && path.length() === origin.length()) {
              let equalParentPath = true;
              for (let i = 1; i < path.length(); i++) {
                if (!equal(path.segments[i], origin.segments[i])) {
                  equalParentPath = false;
                  break;
                }
              }
              if (equalParentPath) {
                operation = Operation.Rename;
              }
            }
          }
        } else {
          operation = Operation.Copy;
        }
      }

      if (operation === Operation.Undefined) {
        // This should never happen.
        logger.error('Ignoring undefined change in diff', path.toEncryptedString());
      } else {
        if (operation === Operation.Touch) {
          touches.push({ path, operation, origins: originFilePaths });
        } else {
          changes.push({ path, operation, origins: originFilePaths });
        }
      }
    }

    // Remove the root 'touched' entry when we have other diffs to show.
    if (touches.length > 1 || changes.length > 0) {
      const rootTouchIndex = touches.findIndex(item => item.path.length() === 0);
      if (rootTouchIndex !== -1) {
        touches.splice(rootTouchIndex, 1);
      }
    }

    if (changes.length === 0) {
      // If we have no changes in this project version then return the 'touch'
      // changes (the modification time updates) for this diff.
      return touches;
    } else {
      return changes.concat(touches);
    }
  }

  // Attempts to extract the longest common path from 2 path strings.
  // If nothing is in common, the root (/) will be returned.
  static commonPath(a: Path, b: Path): Path {
    const commonLength = Math.min(a.length(), b.length());
    for (let i = 0; i < commonLength; i++) {
      if (!equal(a.segments[i], b.segments[i])) {
        return new Path(a.segments.slice(0, i));
      }
    }
    return new Path(a.segments.slice(0, commonLength));
  }

  // Creates a summary of a project version. The summary is a grouping of
  // operations that happened in the longest common folder.
  static summarize(diffArray: Result[], maxResults?: number): Summary[] {
    const diffByOperation = new Map<Operation, Result[]>();

    // Group all diff results by operation.
    for (const diff of diffArray) {
      const cat = diffByOperation.get(diff.operation);
      diffByOperation.set(diff.operation, cat ? cat.concat(diff) : [diff]);
    }

    const res: Summary[] = [];
    for (const [operation, results] of diffByOperation) {
      let sharedPath: Path | undefined = undefined;
      // Squash the change to one common path.
      for (const diff of results) {
        sharedPath = sharedPath ? this.commonPath(sharedPath, diff.path) : diff.path;
      }
      if (maxResults && results.length > maxResults) {
        res.push({
          path: sharedPath ?? new Path(),
          operation,
          results: results.slice(0, maxResults),
          cutoffCount: results.length - maxResults,
        });
      } else {
        res.push({ path: sharedPath ?? new Path(), operation, results, cutoffCount: 0 });
      }
    }

    return res;
  }
}
