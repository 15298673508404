import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { ProjectVersion } from '../api/Project';
import { logger } from '../util/Logger';

interface Props {
  head?: ProjectVersion | undefined;
  children: React.ReactNode;
}

type ProjectContextType = {
  head: ProjectVersion | undefined;
  setHead: Dispatch<SetStateAction<ProjectVersion | undefined>>;
};

const ProjectContext = createContext<ProjectContextType>({
  head: undefined,
  setHead: () => logger.warn('no Project provider'),
});

export function useProjectContext(): ProjectContextType {
  return useContext(ProjectContext);
}

export function ProjectProvider({ children }: Props): JSX.Element {
  const [head, setHead] = useState<ProjectVersion>();

  return <ProjectContext.Provider value={{ head, setHead }}>{children}</ProjectContext.Provider>;
}
