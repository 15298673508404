// Copyright 2021 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
export function buf2hex(buffer: Uint8Array): string {
  // buffer is an ArrayBuffer
  return [...new Uint8Array(buffer)].map(x => x.toString(16).padStart(2, '0')).join('');
}

export function hex2buf(hexString: string): Uint8Array {
  if (hexString === null) throw 'hex string should not be null';

  // From https://stackoverflow.com/a/50868276/13746151
  const m = hexString.match(/.{1,2}/g);
  if (m === null) throw 'hex string did not match';
  return new Uint8Array(m.map(byte => parseInt(byte, 16)));

  // Alternate implementation;
  // Uint8Array.from(atob(keyString), c => c.charCodeAt(0))
}
