import { App as CapacitorApp } from '@capacitor/app';
import { ApiJwtGenerator } from 'util/Serialization/ApiStoreAuthorization';
import { IS_MOBILE_APP } from '../const';
import { logger } from '../util/Logger';
import { WebSocketEvents } from './WebSocketEvents';

// A class that will try to maintain a websocket connection to the api server.
export class PersistentWebSocket {
  private socket: WebSocket | undefined;
  private backoff = 2; // binary exponential backoff
  private attempts = 0;
  private readonly maxAttempts: number = 18; // attempts is capt at this number, amounts to ~once per five minutes
  private onlineListener = false;
  private backoffTimer = 0;
  private closed = false;

  // True when the websocket was at one time connected to the api.
  private wasOnceConnected = false;

  constructor(
    private url: string,
    private jwtGenerator: ApiJwtGenerator,
    private webSocketEvents: WebSocketEvents,
  ) {
    if (IS_MOBILE_APP) {
      // Listen to Capacitor to trigger a websocket reconnect.
      CapacitorApp.addListener('appStateChange', () => {
        // No need to reconnect.
        if (this.socket && this.socket.readyState === this.socket.OPEN) {
          return;
        }

        // Websocket is manually closed.
        if (this.closed) {
          return;
        }

        // Clear any running timer and reconnect straight away
        window.clearTimeout(this.backoffTimer);
        this.connectWebSocket();
      }).then();
    }
  }

  public disconnect(): void {
    if (this.closed) {
      return;
    }

    // in case we have a running backoff Timer, clear it!
    window.clearTimeout(this.backoffTimer);

    this.closed = true;

    if (this.socket) {
      this.socket.close();
    }
    this.socket = undefined;
  }

  public static async createConnected(
    url: string,
    jwtGenerator: ApiJwtGenerator,
    webSocketEvents: WebSocketEvents,
  ): Promise<PersistentWebSocket> {
    const pws = new PersistentWebSocket(url, jwtGenerator, webSocketEvents);
    await pws.connectWebSocket();
    return pws;
  }

  get isWebsocketConnected(): boolean {
    return this.socket !== undefined;
  }

  public async connectWebSocket(): Promise<void> {
    if (this.closed) {
      throw Error('Persistent web socket connection is manually closed');
    }
    // Already connected
    if (this.socket !== undefined) {
      // in case we have a running backoff Timer, clear it!
      window.clearTimeout(this.backoffTimer);

      return Promise.resolve();
    }

    const jsonWebToken = this.jwtGenerator.jwt();
    if (!jsonWebToken) {
      throw Error('The JWT token is not defined');
    }

    // Add a listener for online events
    // We can reset the backoff timer if the browser tells us it is online
    // again. This is not entirely reliable. Connection to the router consists
    // as "online", regardless of actual Internet connectivity.
    if (!this.onlineListener) {
      window.addEventListener('online', () => {
        if (this.closed) {
          return;
        }
        this.attempts = 0;

        // Clear any running timer and reconnect straight away
        if (this.backoffTimer !== 0) {
          window.clearTimeout(this.backoffTimer);
          this.connectWebSocket();
        }
      });
      this.onlineListener = true;
    }

    // Create the webSocket
    this.socket = new WebSocket(`${this.url}?jwt=${jsonWebToken}`);

    // Add listeners to events from the webSocket
    this.socket.onopen = () => {
      if (this.closed) {
        return;
      }
      if (this.wasOnceConnected) {
        this.webSocketEvents.reconnected();
      }

      this.wasOnceConnected = true;

      if (this.backoffTimer !== 0) {
        logger.debug('Websocket reconnected');
        window.clearTimeout(this.backoffTimer);
        this.backoffTimer = 0;
      }
    };
    this.socket.onclose = () => {
      if (this.closed) {
        return;
      }
      logger.warn('Websocket connection failed');

      // Remove the current socket
      this.socket = undefined;

      // Try to reconnect after some delay
      const delay = this.backoff ** this.attempts;
      const jitter = Math.random();
      this.backoffTimer = window.setTimeout(() => this.connectWebSocket(), (delay + jitter) * 1000);
      if (this.attempts + 1 < this.maxAttempts) {
        this.attempts += 1;
      }
    };
    this.socket.onerror = (event: Event) => {
      if (this.closed) {
        return;
      }
      logger.warn('Websocket error', event);
    };
    this.socket.onmessage = (event: MessageEvent) => {
      if (this.closed) {
        return;
      }
      this.webSocketEvents.messageReceived(event.data);
    };

    return new Promise(resolve => {
      const maxNumberOfAttempts = 10;
      const intervalTime = 200; //ms
      let currentAttempt = 0;

      // The following code is waiting on the socket to be in state OPEN
      // i.e. connected.
      const interval = setInterval(() => {
        if (this.closed) {
          return;
        }
        if (currentAttempt > maxNumberOfAttempts - 1) {
          clearInterval(interval);
          logger.warn('Websocket connection failed');
        } else if (this.socket !== undefined && this.socket.readyState === this.socket.OPEN) {
          clearInterval(interval);
          resolve();
        }
        currentAttempt++;
      }, intervalTime);
    });
  }
}
