import { Remote, wrap } from 'comlink';

/**
 * Setup the webWorker
 *
 * Note: we put this in a seperated function so we can mock this in the tests and avoid using ESM for Jest
 * See https://github.com/kulshekhar/ts-jest/issues/1174 and https://kulshekhar.github.io/ts-jest/docs/guides/esm-support/
 */
export default function initWebWorker<T>(): Promise<{ worker: Worker; workerApi: Remote<T> }> {
  return new Promise(resolve => {
    import('./index').then(() => {
      // Perform some comlink Web Worker magic here.
      const worker = new Worker(new URL('./index', import.meta.url), {
        name: 'StorroWebWorker',
        type: 'module',
      });

      const workerApi = wrap<T>(worker);

      resolve({
        worker,
        workerApi,
      });
    });
  });
}
