import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { Realm } from '../types';
import { logger } from '../util/Logger';

interface Props {
  realms?: Realm[] | undefined;
  realm?: Realm | undefined;
  realmLogo?: string | undefined;
  realmCount?: number;
  // if false it means we did not yet received a response from the server
  initialLoaded: boolean;
  children: React.ReactNode;
}

type RealmContextType = {
  realms: Realm[];
  setRealms: Dispatch<SetStateAction<Realm[]>>;
  realm: Realm | undefined;
  setRealm: Dispatch<SetStateAction<Realm | undefined>>;
  realmCount: number | undefined;
  setRealmCount: Dispatch<SetStateAction<number | undefined>>;
  realmLogo: string | undefined;
  setRealmLogo: Dispatch<SetStateAction<string | undefined>>;
  initialLoaded: boolean;
  setInitialLoaded: Dispatch<SetStateAction<boolean>>;
};

const RealmContext = createContext<RealmContextType>({
  realms: [],
  setRealms: () => logger.warn('no Realm provider'),
  realm: undefined,
  setRealm: () => logger.warn('no Realm provider'),
  realmCount: undefined,
  setRealmCount: () => logger.warn('no Realm provider'),
  realmLogo: undefined,
  setRealmLogo: () => logger.warn('no Realm provider'),
  initialLoaded: false,
  setInitialLoaded: () => logger.warn('no Realm provider'),
});

export function useRealmContext(): RealmContextType {
  return useContext(RealmContext);
}

export function RealmProvider({ children }: Props): JSX.Element {
  const [realms, setRealms] = useState<Realm[]>([]);
  const [realm, setRealm] = useState<Realm>();
  const [realmCount, setRealmCount] = useState<number>();
  const [realmLogo, setRealmLogo] = useState<string>();
  const [initialLoaded, setInitialLoaded] = useState<boolean>(false);

  return (
    <RealmContext.Provider
      value={{ realms, setRealms, realm, setRealm, realmCount, setRealmCount, realmLogo, setRealmLogo, initialLoaded, setInitialLoaded }}
    >
      {children}
    </RealmContext.Provider>
  );
}
