// Copyright 2022 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// MerkleTree.ts contains data and logic common to the other MerkleTree classes.
//
import base64url from 'base64url';
import { Hash } from '../Cryptography/Hash';
import { Algorithm, SymmetricKey } from '../Cryptography/SymmetricKey';
import { logger } from '../Logger';
import { concat } from '../Util/Concat';
import { equal } from '../Util/Equal';
import { number32ToUint8Array, number64ToUint8Array, uint8ArrayToNumber32, uint8ArrayToNumber64 } from '../Util/Numeric';
import { Cas } from './Cas';
import { Ecas } from './Ecas';
import { StreamKey } from './StreamKey';

export class MerkleTree {}

// 'META' -- Screw the 'TextEncoder is not defined' error:
const metaMarker = new Uint8Array([77, 69, 84, 65]);

export function isMetadataChunk(value: Uint8Array): boolean {
  return equal(value.slice(-4), metaMarker);
}

export function isContentChunk(value: Uint8Array): boolean {
  // 'CONT' -- Screw the 'TextEncoder is not defined' error:
  const cont = new Uint8Array([67, 79, 78, 84]);
  return equal(value.slice(-4), cont);
}

export function contentChunkOverhead(): number {
  return 5;
}

export function metaChunkOverhead(): number {
  return 6;
}

export function extractContent(contentChunk: Uint8Array): Uint8Array {
  if (contentChunk.length <= contentChunkOverhead()) throw 'Content chunk is too small';
  return contentChunk.slice(0, contentChunk.length - contentChunkOverhead());
}

export class Chunk {
  // A Chunk describes one sub-chunk in the merkle tree.
  // This makes it possible to serialize metadata levels to new chunks.
  constructor(
    readonly streamKey: Promise<StreamKey>,
    readonly contentSize: number,
    readonly chunkSize: number,
  ) {}

  public async isEqual(other: Chunk): Promise<boolean> {
    const myStreamKey = await this.streamKey;
    const otherStreamKey = await other.streamKey;
    if (!myStreamKey.isEqual(otherStreamKey)) return false;
    if (this.contentSize !== other.contentSize) return false;
    if (this.chunkSize !== other.chunkSize) return false;
    return true;
  }

  static chunkMetadataSize(depth: number): number {
    if (depth === 0) throw new Error('Invalid depth for metadata size');
    if (depth === 1) {
      // M1 metadata should be 72 bytes per content chunk.
      return Cas.keySize() + 8 + SymmetricKey.keySizeForAlgo(Algorithm.XCHACHA);
    }
    // For depths > 1 the size is larger because of an extra meta key
    // and larger ints for encoding content length: 108 bytes
    return Cas.keySize() + 12 + 2 + SymmetricKey.keySizeForAlgo(Algorithm.XCHACHA);
  }
}

export function parseMetaChunk(realmSalt: Uint8Array, input: Uint8Array, valueHash: Uint8Array): Array<Chunk> {
  // We use XChaCha at this moment for everything.
  // When that changes, we will need to change this function too.
  const minSize = Cas.keySize() + 6 + SymmetricKey.keySizeForAlgo(Algorithm.XCHACHA);
  if (input.length < minSize) throw new Error('Input is too short');

  if (!equal(input.slice(-4), metaMarker)) {
    logger.debug('parseMetaChunk: ', input.length, input.slice(-4), metaMarker);
    throw new Error('Not a meta chunk');
  }
  const versionByteOffset = input.length - 5;
  if (input[versionByteOffset] !== 0) throw new Error('Meta version byte unsupported');

  // Depth 1 (Meta 1) is different from Meta > 1.
  const depth = input[input.length - 6];

  // 22 = tagbytes(16) + depth byte + version byte, "META"
  const overheadSize = SymmetricKey.tagBytesForAlgo(Algorithm.XCHACHA) + 6;
  let publicSize: number;

  if (depth === 1) {
    // 4 bytes for chunk size (gross)
    // 4 bytes for content size inside of chunk (net)
    // Cas.keySize() for locator
    publicSize = 4 + 4 + Cas.keySize();
  } else {
    // 4 bytes for chunk size (gross)
    // 8 bytes for content size inside of chunk (net)
    // Cas.keySize() for locator
    // SymmetricKey::keyBytes for meta key.
    publicSize = 4 + 8 + Cas.keySize() + SymmetricKey.keySizeForAlgo(Algorithm.XCHACHA);
  }
  const privateSize = SymmetricKey.keySizeForAlgo(Algorithm.XCHACHA);
  const variableSize = publicSize + privateSize;

  const result = new Array<Chunk>();

  // Calculate how many ChunkMetadata are in there.
  const netSize = input.length - overheadSize;
  if (netSize % variableSize) throw new Error('Invalid size for M1 meta chunk');
  const metachunkCount = netSize / variableSize;

  let decrypted = new Uint8Array();
  if (valueHash.length > 0) {
    // If we have the value hash (content key) we can decrypt the content keys in this chunk too.
    const contentKey = Hash.blake2bSync(concat([realmSalt, Hash.blake2bSync(valueHash)]));
    const nonceBytes = Hash.blake2bSync(concat([realmSalt, Hash.blake2bSync(contentKey)]));
    const nonce = nonceBytes.slice(0, SymmetricKey.nonceBytesForAlgo(Algorithm.XCHACHA));
    // Decrypt the content keys and parse them too.
    const d = new SymmetricKey(contentKey);
    const privateOffset = publicSize * metachunkCount;
    const decryptedSize = privateSize * metachunkCount;
    const ciphertext = input.slice(privateOffset, privateOffset + decryptedSize + SymmetricKey.tagBytesForAlgo(Algorithm.XCHACHA));
    const associated = null;
    decrypted = d.decrypt(ciphertext, nonce, associated);
  }

  // number32ToUint8Array uint8ArrayToNumber64
  let publicOffset = 0;
  let decryptedOffset = 0;
  for (let i = 0; i < metachunkCount; i++) {
    // Parse individual StreamKeys
    const chunkSize = uint8ArrayToNumber32(input.slice(publicOffset, publicOffset + 4));
    let contentSize = 0;
    let locatorOffset = 8;
    if (depth === 1) {
      contentSize = uint8ArrayToNumber32(input.slice(publicOffset + 4, publicOffset + 8));
    } else {
      contentSize = uint8ArrayToNumber64(input.slice(publicOffset + 4, publicOffset + 12));
      locatorOffset = 12;
    }
    const offset = publicOffset + locatorOffset;
    const locator = input.slice(offset, offset + Cas.keySize());
    const streamKey = new StreamKey(locator);
    if (valueHash.length > 0) {
      const contentKey = decrypted.slice(decryptedOffset, decryptedOffset + privateSize);
      streamKey.setContentKey(contentKey);
      decryptedOffset += privateSize;
    }
    if (depth > 1) {
      const metaKey = input.slice(offset + Cas.keySize(), offset + Cas.keySize() + SymmetricKey.keySizeForAlgo(Algorithm.XCHACHA));
      streamKey.setMetaKey(metaKey);
    }
    result.push(new Chunk(Promise.resolve(streamKey), contentSize, chunkSize));
    publicOffset += publicSize;
  }
  return result;
}

export class GenMetaChunk {
  constructor(
    public metachunk: Uint8Array,
    public valueHash: Uint8Array,
  ) {}
}

export async function generateMetaChunk(realmSalt: Uint8Array, depth: number, metas: Array<Chunk>): Promise<GenMetaChunk> {
  // A sanity check. The code should fail if this changes.
  if (Cas.keySize() !== 32) throw new Error('CAS key size sanity check failed');

  // The first meta chunk is called M1.
  if (depth === 0) throw new Error('Depth needs to be at least one');

  // 22 = tagbytes(16) + depth byte + version byte, "META"
  const constantOverhead = 22;
  let publicSize = 0;
  if (depth === 1) {
    // 4 bytes for chunk size (gross)
    // 4 bytes for content size inside of chunk (net)
    // ContentAddressed::keySize() for locator.
    publicSize = 4 + 4 + Cas.keySize();
  } else {
    // Depth > 1 also has a meta key and can hold more content.
    // 4 bytes for chunk size (gross)
    // 8 bytes for content size inside of chunk (net, recursive)
    // ContentAddressed::keySize() for locator.
    // 32 bytes for meta key.
    publicSize = 4 + 8 + Cas.keySize() + SymmetricKey.keySizeForAlgo(Algorithm.XCHACHA);
  }
  const privateSize = SymmetricKey.keySizeForAlgo(Algorithm.XCHACHA);
  const resultSize = (publicSize + privateSize) * metas.length + constantOverhead;
  const result = new Uint8Array(resultSize);
  result[result.length - 6] = depth;
  result[result.length - 5] = 0; // version byte
  const metaMarker = new Uint8Array([77, 69, 84, 65]);
  if (metaMarker.length !== 4) throw new Error('Meta marker has invalid size');
  result.set(metaMarker, result.length - 4);

  // Privates contains all the private content layer keys.
  // We put these in a row, calculate a key using the hash of this QByteArray
  // and the realm salt, and then encrypt this into the result.
  const privates = new Uint8Array(privateSize * metas.length);

  let pr = 0; // privates
  let d = 0; // result

  for (let i = 0; i < metas.length; i++) {
    const encodedChunkSize = number32ToUint8Array(metas[i].chunkSize);
    if (encodedChunkSize.length !== 4) throw new Error('Encoded chunk size is invalid');
    result.set(encodedChunkSize, d);
    let locatorOffset = 8;
    const streamKey = await metas[i].streamKey;
    if (depth === 1) {
      // For depth 1 we encode the chunk size as a 32-bit integer.
      const encodedContentSize = number32ToUint8Array(metas[i].contentSize);
      if (encodedContentSize.length !== 4) throw new Error('Encoded content size is invalid');
      result.set(encodedContentSize, d + 4);
      if (streamKey.getMetaKey()) throw new Error('StreamKey for depth 1 should not have a metakey');
    } else {
      // For depth > 1 we encode the chunk size as a 64-bit integer.
      const encodedContentSize = number64ToUint8Array(metas[i].contentSize);
      if (encodedContentSize.length !== 8) throw new Error('Encoded content size is invalid');
      result.set(encodedContentSize, d + 4);
      locatorOffset = 12;
      // Copy the meta key into the public part.
      const metaKey = streamKey.getMetaKey();
      if (metaKey.length !== SymmetricKey.keySizeForAlgo(Algorithm.XCHACHA)) throw new Error('StreamKey for depth > 1 needs a metakey');
      result.set(metaKey, d + locatorOffset + streamKey.getLocator().length);
    }
    if (streamKey.getLocator().length !== Cas.keySize()) throw new Error('Encoded content size is invalid');
    const locator = streamKey.getLocator();
    result.set(locator, d + locatorOffset);
    const contentKey = streamKey.getContentKey();
    privates.set(contentKey, pr);

    d += publicSize;
    pr += privateSize;
  }
  // d should now be at the end of the public part and point to the start of the private part.

  const valueHash = Hash.blake2bSync(concat([realmSalt, Hash.blake2bSync(privates)]));
  const contentKey = Hash.blake2bSync(concat([realmSalt, Hash.blake2bSync(valueHash)]));
  const nonceBytes = Hash.blake2bSync(concat([realmSalt, Hash.blake2bSync(contentKey)]));
  const nonce = nonceBytes.slice(0, SymmetricKey.nonceBytesForAlgo(Algorithm.XCHACHA));

  // Encrypt the privates
  const e = new SymmetricKey(contentKey);
  const ciphertext = e.encrypt(privates, nonce, null);
  if (ciphertext.length !== privates.length + SymmetricKey.tagBytesForAlgo(Algorithm.XCHACHA))
    throw new Error('Ciphertext is of wrong size');
  const privateOffset = publicSize * metas.length;
  result.set(ciphertext, privateOffset);

  return new GenMetaChunk(result, valueHash);
}

// Print the tree to logger.info
export async function printTree(
  ecas: Ecas,
  realmSalt: Uint8Array,
  rootKey: StreamKey,
  depth = 0,
  expectedContentLength = 0,
): Promise<string> {
  let s = '';

  // Helper function for space generation.
  const spaces = (n: number) => {
    let result = '';
    while (n-- > 0) result += ' ';
    return result;
  };

  const ecasValue = await ecas.getValue(rootKey.toEcasKey());
  const value = ecasValue.getValue();
  const keyBase64 = base64url.encode(Buffer.from(rootKey.getLocator()));
  if (isContentChunk(value)) {
    const contentLength = value.length - contentChunkOverhead();
    if (expectedContentLength > 0 && contentLength !== expectedContentLength) {
      throw new Error(`Tree 1: Content length is unexpected ${contentLength} expectedContentLength`);
    }
    // logger.info(`${spaces(depth * 3)} Content: size = ${contentLength}`);
    return spaces(depth * 3) + `${keyBase64.slice(0, 8)} CONTENT: ${contentLength}\n`;
  }

  // const chunks = Chunk.fromValue(value);
  const chunks = parseMetaChunk(realmSalt, value, rootKey.getContentKey());

  s += spaces(depth * 3) + `${keyBase64.slice(0, 8)} METADATA: ${chunks.length}\n`;
  // logger.info(`${spaces(depth * 3)} Metadata: ${chunks.length}`);

  let contentLength = 0;
  for (let i = 0; i < chunks.length; i++) {
    const chunk = chunks[i];
    contentLength += chunk.contentSize;
    s += await printTree(ecas, realmSalt, await chunk.streamKey, depth + 1, chunk.contentSize);
  }

  if (expectedContentLength > 0 && contentLength !== expectedContentLength) {
    throw new Error(`Tree 2: Content length is unexpected ${contentLength} ${expectedContentLength}`);
  }

  // if (depth === 0) logger.info(`Total: ${s}`);
  return s;
}
