// Copyright 2023 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.

import base64url from 'base64url';
import { JsonWebToken } from '../../api/JsonWebToken';
import { PrivateKey } from '../../api/PrivateKey';

export enum ApiJwtGeneratorType {
  UserDevice = 'UserDevice',
  QuickShareMember = 'QuickShareMember',
}

/**
 * A class interface that is used for jwt authorization at the api. The
 * jwt's are generated on the fly so they will always have fresh expiration
 * dates.
 * There are currently two ways of authorization (thus the class interface). The
 * ApiStoreQuickShareAuthorization class is for quick share authorization. And
 * the ApiStoreDeviceAuthorization is for normal user authorization.
 */
export interface ApiJwtGenerator {
  toJson(): unknown;
  jwt(): JsonWebToken;
  get type(): ApiJwtGeneratorType;
}

export const apiStoreAuthorizationFromJson = (json: unknown): ApiJwtGenerator => {
  const typedJson = json as {
    type: ApiJwtGeneratorType;
  };
  if (typedJson.type === ApiJwtGeneratorType.UserDevice) {
    return UserDeviceApiJwtGenerator.fromJson(json);
  } else if (typedJson.type === ApiJwtGeneratorType.QuickShareMember) {
    return QuickShareApiJwtGenerator.fromJson(json);
  } else {
    throw Error('Unknown ApiStore authorization type');
  }
};

export class QuickShareApiJwtGenerator implements ApiJwtGenerator {
  constructor(
    private quickSharePrivateKey: PrivateKey,
    private quickShareMemberId: number,
    private apiRefreshToken?: JsonWebToken,
  ) {}

  public jwt(): JsonWebToken {
    return JsonWebToken.generate(this.quickSharePrivateKey, {
      publicKeyBase64Url: this.quickSharePrivateKey.publicKey().toBase64(),
      quickShareMemberId: this.quickShareMemberId,
      type: 'QuickShareMember',
      refreshToken: this.apiRefreshToken ? this.apiRefreshToken.toString() : undefined,
    });
  }

  public setRefreshToken(refreshToken: JsonWebToken): void {
    this.apiRefreshToken = refreshToken;
  }

  public toJson(): unknown {
    return {
      type: this.type.toString(),
      quickSharePrivateKey: this.quickSharePrivateKey.toBase64(),
      quickShareMemberId: this.quickShareMemberId,
      apiRefreshToken: this.apiRefreshToken?.toString(),
    };
  }

  public static fromJson(json: unknown): ApiJwtGenerator {
    const typedJson = json as {
      type: ApiJwtGeneratorType;
      quickSharePrivateKey: string;
      quickShareMemberId: number;
      apiRefreshToken: string;
    };

    if (typedJson.type !== ApiJwtGeneratorType.QuickShareMember) {
      throw Error(`Invalid ApiStoreAuthorization type for QuickShareMember. Value is ${typedJson.type}`);
    }

    const privKey = new PrivateKey(new Uint8Array(base64url.toBuffer(typedJson.quickSharePrivateKey)));

    if (isNaN(typedJson.quickShareMemberId)) {
      throw Error('QuickShareMemberId is not a number');
    }

    let refreshToken: JsonWebToken | undefined = undefined;
    if (typedJson.apiRefreshToken) {
      refreshToken = JsonWebToken.parse(typedJson.apiRefreshToken);
    }

    return new QuickShareApiJwtGenerator(privKey, typedJson.quickShareMemberId, refreshToken);
  }

  public get type(): ApiJwtGeneratorType {
    return ApiJwtGeneratorType.QuickShareMember;
  }
}

export class UserDeviceApiJwtGenerator implements ApiJwtGenerator {
  constructor(private devicePrivateKey: PrivateKey) {}

  public jwt(): JsonWebToken {
    return JsonWebToken.generate(this.devicePrivateKey, {
      devicePublicKeyBase64Url: this.devicePrivateKey.publicKey().toBase64(),
      type: 'UserDevice',
    });
  }

  public toJson(): unknown {
    return {
      type: this.type.toString(),
      devicePrivateKey: this.devicePrivateKey.toBase64(),
    };
  }

  public get type(): ApiJwtGeneratorType {
    return ApiJwtGeneratorType.UserDevice;
  }

  public static fromJson(json: unknown): ApiJwtGenerator {
    const typedJson = json as {
      type: ApiJwtGeneratorType;
      devicePrivateKey: string;
    };

    if (typedJson.type !== ApiJwtGeneratorType.UserDevice) {
      throw Error(`Invalid ApiStoreAuthorization type for UserDevice. Value is ${typedJson.type}`);
    }

    const privKey = new PrivateKey(new Uint8Array(base64url.toBuffer(typedJson.devicePrivateKey)));

    return new UserDeviceApiJwtGenerator(privKey);
  }
}
