import classNames from 'classnames';
import ColorHash from 'color-hash';
import React, { useMemo } from 'react';

interface Props {
  size?: 'base' | 'sm' | 'xs' | '2xs';
  userPublicKeyBase64?: string;
  className?: string;
  textClassName?: string;
  isGray?: boolean;
  isOnline?: boolean;
  color?: string;
  loading?: boolean;
  children: React.ReactNode;
}

/**
 * Shows a circle with a generated background color
 *
 */
export default function Badge({
  userPublicKeyBase64,
  className,
  textClassName,
  isGray,
  isOnline,
  children,
  size = 'base',
  color,
  loading,
}: Props): JSX.Element {
  const generatedColor = useMemo(() => {
    // has a given color
    if (color) return color;

    // return the gray color if either the publicKey is missing or the user explicit ask for
    if (isGray || !userPublicKeyBase64 || userPublicKeyBase64.length < 8 || loading) {
      return '#e5e7eb';
    }

    // We only use the first 8 chars of the user public key to determine the color.
    // This is done because e.g. the project list call returns the
    // publicKey.toTag() so the api call result is more compact.
    const colorHash = new ColorHash();
    return colorHash.hex(userPublicKeyBase64.substring(0, 8));
  }, [color, isGray, loading, userPublicKeyBase64]);

  return (
    <div
      style={{ backgroundColor: generatedColor }}
      className={classNames(
        'relative flex items-center justify-center px-1 py-1 leading-none rounded-full bg-green-400 shrink-0',
        className,
        textClassName ? textClassName : 'text-white',
        {
          'w-9 h-9 text-base': size === 'base',
          'w-8 h-8 text-sm': size === 'sm',
          'w-7 h-7 text-xs': size === 'xs',
          'w-6 h-6 text-2xs': size === '2xs',
          'animate-skeleton': loading,
        },
      )}
    >
      {children}

      {isOnline && (
        <div className='absolute bottom-0 right-0 border border-gray-50 rounded-xl p-[1px] w-[12px] h-[12px] flex items-center justify-center bg-green-700' />
      )}
    </div>
  );
}
