import { IS_MOBILE_APP } from '../const';
import { ScreenSize } from '../types/ScreenSize';
import useScreenSize from './UseScreenSize';

function useIsMobileDevice(): boolean | null {
  const { width } = useScreenSize();

  if (IS_MOBILE_APP) {
    return true;
  }

  if (width === undefined) {
    return null;
  }

  return width <= ScreenSize.md;
}

export default useIsMobileDevice;
