import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import CreateAccount from 'assets/lock.svg';
import Logo from 'assets/logoWithOpacity.svg';
import { useSplitScreenContext } from 'context/SplitScreenContext';
import ListItem from './ListItem';
import { IS_IOS_APP } from 'const';
import LogoLight from 'assets/storro-logo-and-text-light.svg';
import logo1 from 'assets/logo-dark.svg';

interface Props {
  children: React.ReactNode;
}

const DEFAULT_LIST_ITEMS = [
  'Prevent reputation damage',
  'Increase productivity',
  'Save IT costs',
  'Prevent capital loss',
  'Increase job satisfaction',
];

const DEFAULT_TITLE = 'Strengthen your corporate image and increase your productivity';

export default function SplitScreenWrapper({ children }: Props): JSX.Element {
  const { showSidebar, sidebarItems, sidebarTitle, image: imagePath, maxWidthClassName } = useSplitScreenContext();
  const [image, setImage] = useState<string>('');

  /**
   * Define the title of the page by checking the context.Title
   */
  const title = useMemo(() => {
    return sidebarTitle ?? DEFAULT_TITLE;
  }, [sidebarTitle]);

  /**
   * Define the items of the page by checking the context.Items
   */
  const items = useMemo(() => {
    return sidebarItems ?? DEFAULT_LIST_ITEMS;
  }, [sidebarItems]);

  /**
   * Define the title of the page by checking the context.Title
   */
  useEffect(() => {
    if (!imagePath) {
      setImage(CreateAccount);
    } else {
      setImage(imagePath);
    }
  }, [imagePath]);

  return (
    <div className='w-full h-full flex bg-[#263641] sm:p-10 overflow-auto'>
      <div
        style={{
          backgroundImage: `url("${Logo}")`,
          backgroundSize: '175%',
          backgroundPosition: '-10%',
          backgroundRepeat: 'no-repeat',
        }}
        className='z-0 absolute right-0 bottom-0 top-0 left-0'
      />
      <div className='z-10 w-full flex flex-col items-center'>
        <main
          className={classNames(
            'grow',
            'sm:grow-0 sm:h-auto sm:flex w-full sm:w-3/4 lg:3/4 2xl:w-full sm:shadow-xl sm:rounded sm:border sm:mt-auto', // styles for non mobile devices
            {
              'max-w-3xl': showSidebar && !maxWidthClassName,
              'max-w-xl': !showSidebar && !maxWidthClassName,
            },
            maxWidthClassName ?? '',
          )}
        >
          <div
            className={classNames('h-full bg-white w-full', {
              'lg:w-6/12': showSidebar,
            })}
          >
            <div
              className={classNames('h-full flex flex-col pt-8 pb-2 px-6 sm:py-8 sm:px-9', {
                'pt-[calc(30px+env(safe-area-inset-top))] pb-safe': IS_IOS_APP, // For IOS we have to deal with the notch by using the safe-area
              })}
            >
              <div className='sm:hidden flex justify-center mb-10'>
                <img src={logo1} alt='logo' width={100} />
              </div>
              {children}
            </div>
          </div>
          {showSidebar && (
            <div className='hidden lg:flex flex-col bg-gray-200 py-8 px-10 w-6/12'>
              <div className='flex justify-center mb-3'>
                <img src={image} alt='create account' className='w-[80%]' />
              </div>
              <h2 className='text-xl w-full mb-10 text-center'>{title}</h2>
              <div className='mb-10'>
                {items.map(item => (
                  <ListItem key={item}>{item}</ListItem>
                ))}
              </div>
            </div>
          )}
        </main>
        <div className='hidden sm:flex items-center gap-x-2 mt-5 mb-auto'>
          <img src={LogoLight} alt='logo' width='100' />
          <span className='text-gray-500 text-sm'>Copyright © {new Date().getFullYear()} Storro B.V.</span>
        </div>
      </div>
    </div>
  );
}
