import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { logger } from '../util/Logger';

export interface BreadcrumbItem {
  title: string;
  path?: string;
  onClick?: () => void;
}

interface Props {
  breadcrumbs?: BreadcrumbItem[];
  children: React.ReactNode;
}

type BreadcrumbContextType = {
  breadcrumbs: BreadcrumbItem[];
  setBreadcrumbs: Dispatch<SetStateAction<BreadcrumbItem[]>>;
};

const BreadcrumbContext = createContext<BreadcrumbContextType>({
  breadcrumbs: [],
  setBreadcrumbs: () => logger.warn('no Breadcrumb provider'),
});

export function useBreadcrumbContext(): BreadcrumbContextType {
  return useContext(BreadcrumbContext);
}

export function BreadcrumbProvider({ children }: Props): JSX.Element {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  return <BreadcrumbContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>{children}</BreadcrumbContext.Provider>;
}
