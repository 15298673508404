// A time based moving average
export class BytesPerSecond {
  private windowSize = 10;
  private buckets: number[] = Array(this.windowSize);
  private currentSecond: number;

  constructor() {
    this.buckets.fill(0);
    this.currentSecond = this.now;
  }

  // Returns time rounded by the second.
  private get now(): number {
    return Math.floor(new Date().getTime() / 1000);
  }

  private updateTime(): void {
    const now = this.now;

    if (this.now === this.currentSecond) {
      // We're still in the same second.
      return;
    }

    if (now - this.currentSecond > this.windowSize) {
      // The difference between the current time and the last registered time is
      // larger than the window size. This means 0 bytes per second.
      this.buckets.fill(0);
    } else {
      // Move the window to the new second.
      while (now !== this.currentSecond) {
        this.buckets.shift();
        this.buckets.push(0);
        this.currentSecond += 1;
      }
    }
    this.currentSecond = now;
  }

  public addBytes(byteCount: number): void {
    this.updateTime();
    this.buckets[this.buckets.length - 1] += byteCount;
  }

  public bytesPerSecond(): number {
    this.updateTime();
    return this.buckets.reduce((p, c) => p + c, 0) / this.buckets.length;
  }
}
