import classNames from 'classnames';
import React, { ReactNode, useMemo } from 'react';
import { To, useNavigate } from 'react-router-dom';
import Spinner, { SpinnerSize } from '../Spinner';
import Tooltip, { TooltipProps } from '../Tooltip';
import { logger } from 'util/Logger';
import { IconContext } from '@phosphor-icons/react';

export enum ButtonVariant {
  Default,
  Primary,
  DangerOutline,
  Danger,
  Success,
  Link,
}

export enum ButtonSize {
  Small,
  Medium,
  Large,
}

export interface ButtonProps {
  size?: ButtonSize;
  variant?: ButtonVariant;
  loading?: boolean;
  disabled?: boolean;
  active?: boolean;
  responsive?: boolean;
  className?: string;
  onClick?: () => void;
  htmlType?: 'submit' | 'reset' | 'button' | undefined;
  to?: To;
  tabIndex?: number;
  fullWidth?: boolean;
  icon?: ReactNode;
  iconShowOn?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  spinnerInverse?: boolean;
  loadingPosition?: 'left' | 'right';
  children?: ReactNode;
  // Label is just an alias for "children" as its a bit odd to use children for a button label
  // when setting a button via the PageButtons component for example.
  label?: ReactNode;
  htmlForm?: string;
  tooltip?: TooltipProps;
}

export default function Button({
  children,
  label,
  variant = ButtonVariant.Default,
  size = ButtonSize.Medium,
  htmlType = 'button',
  loading,
  disabled,
  active,
  responsive = true,
  onClick,
  className,
  to,
  tabIndex,
  icon,
  iconShowOn = 'xs',
  fullWidth,
  spinnerInverse,
  loadingPosition = 'right',
  htmlForm,
  tooltip,
}: ButtonProps): JSX.Element {
  const inverseSpinnerColor = spinnerInverse === undefined ? variant === ButtonVariant.Default : false;
  const isDisabled = !!loading || !!disabled;
  // Check if the button is just an icon, then we can render the icon only
  const renderIconOnly = children === undefined && label === undefined && !!icon;

  const navigate = useNavigate();

  /**
   * Determine the spinner size based on the button size
   */
  const spinnerSize = useMemo(() => {
    switch (size) {
      case ButtonSize.Small:
        return SpinnerSize.Small;
      case ButtonSize.Medium:
        return SpinnerSize.Medium;
      case ButtonSize.Large:
        return SpinnerSize.Large;
      default:
        return SpinnerSize.Medium;
    }
  }, [size]);

  /**
   * render the icon via the Icon comp
   */
  const renderedIcon = useMemo(() => {
    if (!icon || size === ButtonSize.Small) return;
    return (
      <IconContext.Provider
        value={{
          size: 14,
          className: classNames('hidden mr-1', {
            'xs:block': iconShowOn === 'xs',
            'sm:block': iconShowOn === 'sm',
            'md:block': iconShowOn === 'md',
            'lg:block': iconShowOn === 'lg',
            'xl:block': iconShowOn === 'xl',
            '2xl:block': iconShowOn === '2xl',
          }),
        }}
      >
        {icon}
      </IconContext.Provider>
    );
  }, [icon, iconShowOn, size]);

  /**
   * Event when the user click on the button
   */
  const click = () => {
    if (to) {
      navigate(to);
    } else {
      onClick?.();
    }
  };

  const buttonElement = renderIconOnly ? (
    <button className={classNames('px-2 py-1', className)} onClick={click} disabled={isDisabled} tabIndex={tabIndex}>
      {icon}
    </button>
  ) : (
    <button
      form={htmlForm}
      type={htmlForm ? 'submit' : htmlType}
      className={classNames(
        'bg-clip-padding', // https://stackoverflow.com/questions/71123245/firefox-scaling-issues-causes-pixelated-blurry-borders
        'inline-flex',
        'justify-center',
        'items-center',
        'border',
        'font-medium',
        {
          'rounded-md': variant !== ButtonVariant.Link,
        },
        {
          'sm:w-auto': !fullWidth,
          'w-full': responsive || fullWidth,
          'shadow-sm': variant !== ButtonVariant.Link,
        },
        // Disabled
        {
          'cursor-default': isDisabled,
          'cursor-not-allowed': isDisabled,
          'disabled:opacity-50': isDisabled && !active,
        },
        // Small font size
        {
          'text-sm px-2 py-1': size === ButtonSize.Small,
        },
        // Medium font size
        {
          'text-base py-2': size === ButtonSize.Medium,
          'px-4': size === ButtonSize.Medium && !icon,
          'px-3': size === ButtonSize.Medium && icon,
        },
        // Large font size
        {
          'text-lg py-3': size === ButtonSize.Large,
          'px-6': size === ButtonSize.Large && !icon,
          'px-4': size === ButtonSize.Large && icon,
        },
        // Primary color classes
        {
          'text-white bg-primary hover:bg-opacity-90': variant === ButtonVariant.Primary,
          'bg-opacity-90': variant === ButtonVariant.Primary && active,
        },
        // Default color classes
        {
          'text-gray-600 border-gray-300 bg-white hover:bg-gray-50': variant === ButtonVariant.Default,
          'bg-gray-50 border-gray-400': variant === ButtonVariant.Default && active,
        },
        // Danger color classes
        {
          'text-white bg-danger-600 hover:bg-danger-700': variant === ButtonVariant.Danger,
          'bg-danger-700': variant === ButtonVariant.Danger && active,
        },
        // Danger-outline color classes
        {
          'text-danger-600 border-danger-300 bg-white hover:bg-danger-50': variant === ButtonVariant.DangerOutline,
          'bg-danger-50 border-danger-400': variant === ButtonVariant.DangerOutline && active,
        },
        // Default color classes
        {
          'text-gray-600 hover:text-primary border-0': variant === ButtonVariant.Link,
          'text-gray-800': variant === ButtonVariant.Link && active,
        },
        className,
      )}
      onClick={click}
      disabled={isDisabled}
      tabIndex={tabIndex}
    >
      {renderedIcon}
      {loading && loadingPosition === 'left' && <Spinner className='ml-2' inverseColor={inverseSpinnerColor} size={spinnerSize} />}
      {/* Label is just an alias for "children" as its a bit odd */}
      {label}
      {children}
      {loading && loadingPosition === 'right' && <Spinner className='ml-2' inverseColor={inverseSpinnerColor} size={spinnerSize} />}
    </button>
  );

  // Warn the user when he use both htmlForm and onClick
  // todo is this needed
  if (htmlForm && onClick) {
    logger.warn('[Button]: You are mixing both the "onClick" and the "htmlForm" property. This can lead to submitting a form twice.');
  }

  if (tooltip) {
    return (
      <Tooltip {...tooltip}>
        {/* We define a <div /> with an inline class as the Tooltip cannot determine the position for a native React element  see https://gitlab.coblue.eu/storro/app.storro/-/issues/488 */}
        {/* We also define the <div /> as inline element to follow the <button /> display rule */}
        <div className='inline'>{buttonElement}</div>
      </Tooltip>
    );
  }

  return buttonElement;
}
