import classNames from 'classnames';
import { MODAL_DELAY_MS } from 'components/Modal';
import { INPUT_CLASSNAMES, INPUT_WARNING_CLASSNAMES } from 'helpers/CssClasses';
import React, { ForwardedRef, useEffect, useImperativeHandle, useRef } from 'react';
import FormFieldError from './FormFieldError';
import { FieldError } from 'react-hook-form';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  error?: FieldError | string;
  // in some situation the error cannot be attached to this element because
  // it needs to show in a different place due complex hierarchy of the dom elements
  // therefor we can use this flag to set the correct classes to the <input />
  // element
  hasError?: boolean;
  inputClassName?: string;
}

/**
 * Default wrapper around our <input /> elements
 *
 * - It does some default stuff with error and styling
 * - It also handles some issues with the modal animation via autoFocus
 */
const FormInput = React.forwardRef(function FormInput(
  { error, hasError, autoFocus, className, inputClassName, ...rest }: Props,
  outerRef: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const innerRef = useRef<HTMLInputElement>(null);

  /**
   * We need the ref too. Grab the ref handle in the middle with useImperativeHandle
   */
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(outerRef, () => innerRef.current!, []);

  /**
   * On mobile we have some issues when the input is inside a modal
   * As the keyboard is attached to the body, it will push the modal up and out of
   * the screen as it is currently animating.
   * To avoid this issue, we should add a delay that match the duration of the modal animation
   */
  useEffect(() => {
    if (autoFocus) {
      const timer = setTimeout(() => innerRef.current?.focus(), MODAL_DELAY_MS);
      return () => clearTimeout(timer);
    }
  }, [autoFocus]);

  return (
    <div className={classNames('grow', className)}>
      <input
        ref={innerRef}
        className={classNames('w-full', inputClassName, INPUT_CLASSNAMES, { [INPUT_WARNING_CLASSNAMES]: error || hasError })}
        {...rest}
      />
      {error && <FormFieldError>{typeof error === 'string' ? error : error.message}</FormFieldError>}
    </div>
  );
});

export default FormInput;
