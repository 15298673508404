import MenuItem from 'components/SidebarLeft/MenuItem';
import SwitchUserContext from 'components/SidebarLeft/SwitchUserContext';
import Tag, { TagSize, TagType } from 'components/Tag';
import { useStorroApi } from 'context/StorroApiContext';
import useRealm from 'hooks/realm/UseRealm';
import useMenu, { MenuItem as Item } from 'hooks/UseMenu';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import formatErrorMessage from 'util/FormatErrorMessage';
import { logger } from 'util/Logger';

export default function Menu(): JSX.Element {
  const [showAdminMenu, setShowAdminMenu] = useState<boolean>(false);
  const [mainMenuItems, setMainMenuItems] = useState<Item[]>([]);

  const location = useLocation();
  const { realm: loadedRealm, realms } = useRealm();
  const { storroApi } = useStorroApi();
  const { menuItems, adminMenuItems, accountMenuItemWeb } = useMenu();

  // when the realm is not loaded, e.g. because the url is not pointing to the correct realm and the user is on a different url (e.g. /account)
  // we need to return the first realm from the realm lists.
  const realm = useMemo(() => {
    if (loadedRealm) return loadedRealm;

    return realms.length > 0 ? realms[0] : undefined;
  }, [loadedRealm, realms]);

  /**
   * check if the url holds the /admin/ path so we can mark the menu as inAdmin state
   */
  useEffect(() => {
    if (!realm) return;
    setShowAdminMenu(location.pathname.startsWith(`/realm/${realm.publicKey}/admin`) && realm.isAdmin);
  }, [location, realm]);

  /**
   * Set the main menu items
   * This is a state because we need to change the badgeCount
   */
  useEffect(() => {
    if (!realm) return;

    setMainMenuItems(menuItems);
  }, [realm]); //eslint-disable-line

  const updateRequestBadgeCount = useCallback(
    (signal?: AbortSignal) => {
      if (!realm) return;

      storroApi.quickShareList
        .listQuickShares(realm.id, signal)
        .then(quickShares => {
          const countUpdatedItems = quickShares.filter(qs => qs.details.hasSeen === false).length;
          setMainMenuItems(prevState => {
            return prevState.map(item => {
              if (item.title === 'File Requests') {
                return {
                  ...item,
                  badgeCount: countUpdatedItems,
                };
              }

              return item;
            });
          });
        })
        .catch(error => {
          if (!signal?.aborted) {
            toast.error(formatErrorMessage('fetch the quickshares'));
            logger.error(error);
          }
        });
    },
    [realm, storroApi],
  );

  useEffect(() => {
    const abortController = new AbortController();
    updateRequestBadgeCount(abortController.signal);
    return () => abortController.abort();
  }, [realm, storroApi, updateRequestBadgeCount]);

  useEffect(() => {
    const fn = () => updateRequestBadgeCount();
    storroApi.subscribeOnQuickShareUpdated(fn);
    return () => storroApi.unsubscribeOnQuickShareUpdated(fn);
  }, [storroApi, updateRequestBadgeCount]);

  return (
    <>
      <nav className='mt-2 mb-10'>
        {!showAdminMenu && (
          <>
            <ol>
              {mainMenuItems.map(({ title, path, icon, badgeCount }: Item, index) => (
                <MenuItem key={index} icon={icon} path={path} className='flex justify-between relative' name={title}>
                  {title}
                  {badgeCount !== undefined && badgeCount > 0 && (
                    <Tag className='absolute -right-3 -top-0 w-6 h-6' size={TagSize.Small} rounded={true} type={TagType.Primary}>
                      {badgeCount}
                    </Tag>
                  )}
                </MenuItem>
              ))}
            </ol>

            <SwitchUserContext toAdmin={true} title='Admin section' description={`Manage ${realm?.name}`} />
          </>
        )}
        {showAdminMenu && (
          <>
            <ol>
              {adminMenuItems.map(({ title, path, icon }: Item, index) => (
                <MenuItem key={index} icon={icon} path={path} name={title}>
                  {title}
                </MenuItem>
              ))}
            </ol>

            <SwitchUserContext toAdmin={false} title='User section' description='Back to your files' />
          </>
        )}
      </nav>

      <nav className='mt-auto'>
        <ol>
          <li className='border-t border-zinc-500 px-3 py-3 text-zinc-300 hover:text-zinc-50'>{accountMenuItemWeb()}</li>
        </ol>
      </nav>
    </>
  );
}
