import { logger } from '../Logger';

declare const self: Window &
  typeof globalThis & {
    _env_: {
      [id: string]: string;
    };
  };

/**
 * Because we cannot load the process.env var in production we added a file with window._env_ object that holds all env vars
 *
 * For production, we should load the data from there and for development from process.env
 *
 * @param varName
 * @param useLoggerObject Log via our logger object or via the default console object
 */
export const envVar = (varName: string, useLoggerObject = true): string | undefined => {
  if (process.env && process.env[varName]) {
    return process.env[varName];
  }

  // Self is pointing to the window object and is also working
  // in both a serviceWorker or a webWorker
  // @see https://developer.mozilla.org/en-US/docs/Web/API/Window/self
  if (self._env_ && self._env_[varName]) {
    return self._env_[varName];
  }

  // log via the logger object if the variable does not exists
  const logMessage = `Try to load a non-existing environment variable "${varName}"`;
  if (useLoggerObject) {
    logger.warn(logMessage);
  } else {
    console.warn(logMessage);
  }

  return undefined;
};

/**
 * Check whether an env var is true
 *
 * @param varName
 */
export const envVarIsTrue = (varName: string): boolean => {
  const value = envVar(varName);

  if (value === undefined) {
    return false;
  }

  return value === 'true';
};

/**
 * Get the env Urls, constructed with a protocol
 */
export const getEnvUrls = (url?: string): { apiUrl: string; wsUrl: string; url: string } => {
  const apiUrl = url ?? envVar('STORRO_API_URL');
  const isDevelopment = process.env && process.env.NODE_ENV === 'development';
  const developmentProxySSL = envVarIsTrue('DEVELOPMENT_PROXY_SSL');
  const isSSL = envVarIsTrue('SSL');
  const httpProtocol = isSSL ? 'https://' : 'http://';
  const websocketProtocol = (!isDevelopment && isSSL) || (isDevelopment && developmentProxySSL) ? 'wss://' : 'ws://';
  const apiUrlWithProtocol = isDevelopment && !url ? `${httpProtocol}${location.host}/api` : `${httpProtocol}${apiUrl}`;
  const wsUrlWithProtocol = `${websocketProtocol}${apiUrl}`;

  return {
    apiUrl: apiUrlWithProtocol,
    wsUrl: wsUrlWithProtocol,
    url: `${httpProtocol}${location.host}`,
  };
};

/**
 * Check if all env vars are exists and loaded
 */
export const validEnvVars = (): boolean => {
  const baseUrl = envVar('STORRO_API_URL');
  const isSSL = envVar('SSL');
  const binariesHost = envVar('BINARIES_HOST');

  if (baseUrl === undefined || baseUrl === '') {
    logger.error('ENV Variable "STORRO_API_URL" should be set');
    return false;
  }

  if (isSSL === undefined || isSSL === '') {
    logger.error('ENV Variable "SSL" should be set');
    return false;
  }

  if (binariesHost === undefined || binariesHost === '') {
    logger.warn('ENV Variable "BINARIES_HOST" should be set');
  }

  return true;
};
