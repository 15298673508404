import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  className?: string;
  children: ReactNode;
}

export default function FormFieldError({ children, className }: Props): JSX.Element {
  return <p className={classNames('text-danger-500 text-sm pt-1', className)}>{children}</p>;
}
