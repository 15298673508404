import { crypto_pwhash_SALTBYTES } from 'libsodium-wrappers';
import { PublicKey } from './PublicKey';

// A Salt helper class that is compatible with our c++ client code.
export class Salt {
  private readonly _salt: Uint8Array;
  constructor(argument: PublicKey | Uint8Array) {
    if (argument instanceof PublicKey) {
      if (!crypto_pwhash_SALTBYTES) {
        throw Error('crypto_pwhash_SALTBYTES const is undefined');
      }
      this._salt = argument.key().slice(0, crypto_pwhash_SALTBYTES);
    } else if (argument instanceof Uint8Array) {
      this._salt = argument;
    } else {
      throw new Error('Salt: Unsupported argument type');
    }
  }

  public bytes(): Uint8Array {
    return this._salt;
  }
}
