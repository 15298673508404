import React from 'react';
import { Outlet } from 'react-router-dom';
import ClientPortal from '../ClientPortal';

export default function RouteClientPortal(): JSX.Element {
  return (
    <ClientPortal>
      <Outlet />
    </ClientPortal>
  );
}
