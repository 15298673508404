// Copyright 2021 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// S3Factory.ts is a workalike from Zooid's C++ version of S3Factory.h.
// They accept the same json objects to create() and load() S3 stores.,
// except for the bucket name which needs to be present in create() in JS
// while C++ generates it from the public key of the PSP.
//
// Make a TypeScript version of the StoreFactory to support multiple
// store types just like StoreFactory.h (Openstack Swift would be useful).
//
import { S3 } from '@aws-sdk/client-s3';
import { getRandomString } from '../getRandomString';
import { S3Credentials } from './S3Credentials';
import { S3Store } from './S3Store';
import { StoreConfig, StoreRecipe } from './StoreFactory';

export enum S3Service {
  S3 = 's3',
  STS = 'sts',
  IAM = 'iam',
}

// Forms an endpoint for a given service and credential set.
export function createEndpoint(credentials: S3Credentials, service: S3Service): string {
  if (!credentials.protocol) throw new Error('protocol is not set');
  if (!credentials.host) throw new Error('host is not set');
  if (!credentials.region) throw new Error('region is not set');

  let url: string;
  if (credentials.end_point) url = credentials.end_point.replace(/^(http(s){0,1}:\/\/){0,1}s3\./, `$1${service}.`);
  else url = `${credentials.protocol}://${service}.${credentials.region}.${credentials.host}`;
  return url;
}

export class S3Factory {
  // 'recipe' should describe how to create the Store.
  // and should contain account_details,
  // create() returns the config object for an actual S3Store.
  public async create(recipe: StoreRecipe): Promise<StoreConfig> {
    if (!('type' in recipe)) throw new Error('Missing store type');
    if (recipe['type'] !== 'S3') throw new Error('Unsupported store type');

    // We require the bucket name as a variable (can not generate it)
    // because buckets are a limited resource shared between S3 stores of
    // the same customer and need to have one per customer.
    // The bucket name is different from the C++ version that gets the bucket name
    // from the public key of the PSP.
    const bucket = recipe['bucket'];
    if (!bucket) throw new Error('Need a bucket name');

    // The bucket should exist already.
    const myHasBucket = await S3Factory.hasBucket(recipe);
    if (!myHasBucket) {
      // Bucket creation is at the moment a one-time operation
      // for each customer. This should be done in one place during
      // customer init. We could but do not create the bucket
      // automatically to prevent load-or-create bugs.
      throw new Error('Bucket should have been created previously');
    }

    // The prefix is specific for this store and can be generated.
    let prefix = recipe['prefix'];
    if (!prefix) prefix = getRandomString(16);

    // Verify the prefix does not exist.
    const hasPrefix = await S3Factory.hasPrefix(recipe, prefix);
    if (hasPrefix) {
      throw new Error('Prefix already exists');
    }

    // Create the prefix.
    await S3Factory.createPrefix(recipe, prefix);

    // Create the config object and return it.
    const credentials = S3Factory.parseCredentials(recipe);
    const result = {
      bucket: bucket,
      prefix: prefix,
      storageLimit: 0,
      type: 'S3',
      uuid: getRandomString(22),
      end_point: credentials.end_point,
      access_key: credentials.access_key,
      secret_key: credentials.secret_key,
      host: credentials.host,
      provider: credentials.provider,
    };
    return result;
  }

  // This is a separate function because the bucket
  // should only be created once for each customer.
  public static async hasBucket(storeConfig: StoreConfig): Promise<boolean> {
    if (!('type' in storeConfig)) throw new Error('Missing store type');
    if (storeConfig.type !== 'S3') throw new Error('Unsupported store type');
    const credentials = S3Factory.parseCredentials(storeConfig);
    const s3 = S3Factory.createS3(credentials);
    const bucket = storeConfig.bucket;
    if (!bucket) throw new Error('Bucket must be set to load S3 store');

    // We need to wrap headBucker() call in a try/catch according to
    // https://stackoverflow.com/a/55891553/13746151
    try {
      const bucketHead = await s3.headBucket({ Bucket: bucket });
      const result = bucketHead.$metadata.httpStatusCode === 200;
      if (!result) throw new Error('BucketHead returned an unexpected status code');
      return true;
    } catch (e) {
      // eslint-disable-next-line
      // @ts-ignore
      if (e.code === 'NotFound') return false;
      throw new Error('has bucket has a problem');
    }
  }

  // This is a separate function because the bucket
  // should only be created once for each customer.
  public static async createBucket(storeConfig: StoreConfig): Promise<void> {
    if (storeConfig.type !== 'S3') throw new Error('Unsupported store type');
    if (!('bucket' in storeConfig)) throw new Error('Missing bucket name');
    const credentials = S3Factory.parseCredentials(storeConfig);
    const params = { Bucket: storeConfig.bucket };
    const s3 = S3Factory.createS3(credentials);
    await s3.createBucket(params);
  }

  public static async hasPrefix(recipe: StoreRecipe, prefix: string): Promise<boolean> {
    // We currently just check whether there is any object with this prefix.
    if (recipe.type !== 'S3') throw new Error('Unsupported store type');
    const credentials = S3Factory.parseCredentials(recipe);
    const s3 = S3Factory.createS3(credentials);
    const bucket = recipe.bucket;
    if (!bucket) throw new Error('Bucket must be set to load S3 store');
    const objectList = await s3.listObjects({ Bucket: bucket, Prefix: prefix });
    if (objectList.$metadata.httpStatusCode !== 200) throw new Error('Could not check prefix existence');
    if (!objectList.Contents) return false;
    return objectList.Contents.length > 0;
  }

  public static async createPrefix(recipe: StoreRecipe, prefix: string): Promise<void> {
    if (recipe.type !== 'S3') throw new Error('Unsupported store type');
    const credentials = S3Factory.parseCredentials(recipe);
    const s3 = S3Factory.createS3(credentials);
    const bucket = recipe.bucket;
    if (!bucket) throw new Error('Bucket must be set to create a prefix');

    const ok = await s3.putObject({ Bucket: bucket, Key: prefix + '/' });
    if (!ok) throw new Error('Could not create prefix');
  }

  // Takes a bucket config and returns an S3Store.
  public load(config: StoreConfig): S3Store {
    if (!('type' in config)) throw new Error('Missing store type');
    if (config.type !== 'S3') throw new Error('Unsupported store type');

    // The newer S3 libary adds `?x-id=GetObject` to the request causing a
    // mismatch in signature for Minio.
    if (config.provider.toUpperCase() === 'MINIO') throw new Error('Minio is not supported with the newer AWS S3 library');

    const credentials = S3Factory.parseCredentials(config);

    if (!('uuid' in config)) throw new Error('Missing uuid');
    if (!('storageLimit' in config)) throw new Error('Missing storage limit');
    if (!('bucket' in config)) throw new Error('Missing bucket');

    const prefix = config.prefix;
    if (prefix === null) throw new Error('Prefix must be set to load S3 store');
    const bucket = config.bucket;
    const storageLimit = config.storageLimit;
    const uuid = config.uuid;

    return new S3Store(credentials, bucket, prefix, uuid, storageLimit);
  }

  // We use this to parse incoming stores config jsons for correctness.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static parseCredentials(credentials: any): S3Credentials {
    // Provider and host are used in C++ version but
    // unused in JS version so far.
    if (credentials['access_key'] === undefined) throw new Error('Missing access_key');
    if (credentials['secret_key'] === undefined) throw new Error('Missing secret_key');
    if (credentials['provider'] === undefined) throw new Error('Missing provider');

    if (credentials['end_point'] === undefined && credentials['region'] === undefined) throw new Error('Missing region');

    const access_key = credentials['access_key'];
    const secret_key = credentials['secret_key'];
    const provider = credentials['provider'];
    const session_token = credentials['session_token'];
    const end_point = credentials['end_point'];
    let host = credentials['host'];
    let region = credentials['region'];
    let protocol = credentials['protocol'];

    if (!access_key) throw new Error('Access key needs to be non-empty');
    if (!secret_key) throw new Error('Secret key needs to be non-empty');
    if (!provider) throw new Error('Provider needs to be non-empty');
    if (!end_point && !region) throw new Error('Region needs to be non-empty');
    // Set defaults
    if (!protocol) protocol = 'https';
    if (end_point && !region) {
      region = 'us-east-1';
    }

    if (provider && !host) {
      switch (provider.toUpperCase()) {
        case 'WASABI':
          host = 'wasabisys.com';
          protocol = 'https';
          break;
        case 'AMAZON':
          host = 'amazonaws.com';
          protocol = 'https';
          break;
        case 'MINIO':
          throw new Error('The Minio provider needs to have the "host" set');
        case 'S3':
          throw new Error('The generic S3 provider needs to have the "host" set');
        default:
          throw new Error(`Unsupported provider ${provider}`);
      }
    }

    return {
      access_key,
      secret_key,
      provider,
      session_token,
      host,
      region,
      end_point,
      protocol,
    };
  }

  // Create a new AWS.S3 object
  private static createS3(credentials: S3Credentials): S3 {
    const endpoint = createEndpoint(credentials, S3Service.S3);
    const s3 = new S3({
      endpoint,
      credentials: { accessKeyId: credentials.access_key, secretAccessKey: credentials.secret_key },
      forcePathStyle: true,
      region: credentials.region,
    });
    return s3;
  }
}
