import classNames from 'classnames';
import React from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { UploadQueueItem } from 'api/UploadQueue';
import { UploadsByGroup } from 'hooks/UseProgressModal';
import ProgressModalRowSkeleton from './ProgressModalRowSkeleton';
import { Check, File, XCircle } from '@phosphor-icons/react';
import Spinner, { SpinnerSize } from 'components/Spinner';

interface Props {
  item: UploadQueueItem;
  group: UploadsByGroup;
  onCancel: () => void;
}

function ProgressModalRowInProgress({ item, group, onCancel }: Props): JSX.Element {
  const { ref, isIntersecting: isInViewport } = useIntersectionObserver();

  return (
    <li className='border-b last:border-b-0 last:mb-5 relative h-[60px] overflow-hidden' ref={ref}>
      {!isInViewport && <ProgressModalRowSkeleton />}
      {isInViewport && (
        <div className='hover:bg-gray-50 flex items-center py-3 my-1 px-2'>
          <File size={35} weight='duotone' className='text-primary' />
          <div className='ml-1 flex flex-col w-5/6'>
            <span
              className='mb-1 cursor-default truncate'
              title={group.contentKey ? item.path.toString(group.contentKey) : item.path.toEncryptedString()}
            >
              {group.name}
              {group.contentKey ? item.path.toString(group.contentKey) : item.path.toEncryptedString()}
            </span>
            {item.finished && !item.cancelled && <span className='text-sm text-primary'>100%</span>}
            {!item.finished && item.started && (
              <div className='flex gap-x-1 items-center'>
                <Spinner size={SpinnerSize.Small} inverseColor={true} />
                <span className='text-sm text-gray-700'>{item.progress}%</span>
              </div>
            )}
            {!item.finished && !item.started && <span className='text-sm text-primary'>pending…</span>}

            {item.cancelled && <span className='text-sm text-primary'>Cancelled</span>}
          </div>
          {!item.finished && (
            <button className='ml-auto' onClick={onCancel}>
              <XCircle size={15} />
            </button>
          )}
          {item.finished && group.finished && <Check className='ml-auto' />}
          <div
            className={classNames('absolute bottom-0 left-0 h-[1px] transition-[width]', {
              'bg-transparent': item.finished,
              'bg-primary': !item.finished,
            })}
            style={{ width: `${item.progress}%` }}
          />
        </div>
      )}
    </li>
  );
}

export default ProgressModalRowInProgress;
