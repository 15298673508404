// Copyright 2022 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// Ecas should be compatible with
// Storro/Base/Serialization/EncryptedContentAddressed.h
//
// @TODO:
// Because Ecas is quite a fundamental object to many of the things
// we want to do in Storro, we need ecas to be available inside
// Web Workers and inside Service Workers.
//
// Unfortunately, proxying an ecas is at the moment not a good solution:
// The cpu-heavy crypto operations will still be performed in the original
// thread, and for some objects comlink will refuse to proxy the object entirely
// (not sure why at the moment)
//
// A potential solution for this is to convert the ecas to a json object
// describing the config. Such a string can pass the Comlink thread barrier.
// We then re-construct the ecas from the json config object and have a working
// ecas on the other side. This works for as long as the ecas object itself
// does not have dynamic state that needs to be kept in sync between the ecas
// instances (for instance it would not work for MerkleBuffer).
// Currently no problem I think.
//
// Such Ecas functions could then look like this:
//
// toJson(): string
// static fromJson(string): Ecas
//
import { ConvergentEncryption, ConvergentEncryptionJson, ConvergentValue } from '../Cryptography/ConvergentEncryption';
import { Cas } from './Cas';
import { EcasKey } from './EcasKey';
import { EcasValue } from './EcasValue';
import { StoreConfig } from './StoreFactory';

export interface EcasJson {
  storeConfig: StoreConfig;
  convergentEncryption: ConvergentEncryptionJson;
  contentLayerKey?: string;
}

export class Ecas {
  constructor(
    public readonly cas: Cas,
    private convenc: ConvergentEncryption,
  ) {}

  // Putting a value computes the address and returns the locator / key.
  async putValue(value: EcasValue): Promise<EcasKey> {
    if (value.getValue().length === 0) throw 'ECAS can not store empty value';
    // Encrypt the EcasValue. This will yield a ciphertext and valuehash.
    // We will need the ValueHash later to decrypt the ciphertext.
    const convValue = await this.convenc.encrypt(value);
    const valueHash = convValue.getValueHash();
    const ciphertext = convValue.getCiphertext();
    // Store the ciphertext inside the CAS and get the locator.
    const locator = await this.cas.putValue(ciphertext);
    // Return the locator and valueHash as a EcasKey.
    return new EcasKey(locator, valueHash);
  }

  // Would normally call this 'get()' but that's a keyword in TypeScript. :-)
  async getValue(key: EcasKey): Promise<EcasValue> {
    // First get the ciphertext from the CAS.
    const ciphertext = await this.cas.getValue(key.getLocator());
    // Use the valueHash to decrypt the ciphertext and return the EcasValue.
    return this.convenc.decrypt(new ConvergentValue(key.getValueHash(), ciphertext));
  }

  static keySize(): number {
    return Cas.keySize();
  }

  public convEnc(): ConvergentEncryption {
    return this.convenc;
  }

  public toJson(): EcasJson {
    return {
      storeConfig: this.cas.toStoreConfig(),
      convergentEncryption: this.convenc.toJson(),
    };
  }

  public static async fromJson(json: EcasJson): Promise<Ecas> {
    const cas = await Cas.fromStoreConfig(json.storeConfig);
    const convEnc = ConvergentEncryption.fromJson(json.convergentEncryption);
    return new Ecas(cas, convEnc);
  }
}
