import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { toast } from 'react-toastify';
import FileUploadList from '../../api/FileUploadList';
import { logger } from '../../util/Logger';

interface Props {
  className?: string;
  showDropArea?: boolean; // show the drop area by default
  enabled?: boolean;
  onSelected: (acceptedFiles: File[]) => void;
  children: React.ReactNode;
}

/**
 * The uploadArea component create an area where a user can drop files into
 * As a result, in the callback onSelected(), the selected files are pushed.
 */
export default function UploadArea({ onSelected, children, className, showDropArea = false, enabled = true }: Props): JSX.Element {
  const [showDropAreaElement, setShowDropAreaElement] = useState<boolean>(false);
  const [bounding, setBounding] = useState<{ top: number; left: number; width: number; height: number }>();
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setBounding(() => {
      if (rootRef.current) {
        const boundings = rootRef.current.getBoundingClientRect();

        return {
          top: boundings.top,
          left: boundings.left,
          width: boundings.width,
          height: boundings.height,
        };
      }
    });
  }, [showDropAreaElement]);

  if (!enabled) return <>{children}</>;

  return (
    <>
      <div
        onDragEnter={() => setShowDropAreaElement(true)}
        ref={rootRef}
        className={classNames(className, {
          'bg-blue-400/5 border-2 border-blue-100 border-dashed': showDropArea,
          'border-transparent bg-transparent': showDropArea && showDropAreaElement,
        })}
      >
        {children}
      </div>

      {/* Create an overlay where we can drop the files onto */}
      {/* This will prevent extra events which result in extra re-renders by changing stattes */}
      <Portal node={document && document.getElementById('root')}>
        <div
          className={classNames('z-[9999999] fixed focus:outline-none bg-blue-500/10 border-2 border-blue-200 border-dashed', {
            hidden: !showDropAreaElement,
          })}
          onDragOver={event => event.preventDefault()} // enables it to receive drop events
          onDragLeave={() => setShowDropAreaElement(false)}
          onDrop={async event => {
            event.preventDefault();
            const fileUploadList = new FileUploadList();
            try {
              const files = await fileUploadList.fromDragDropEvent(event.dataTransfer.items);
              onSelected(files);
            } catch (error) {
              toast.error('Cannot progress your selected file(s)');
              logger.error(error);
            }

            setShowDropAreaElement(false);
          }}
          style={{
            top: bounding?.top,
            left: bounding?.left,
            width: bounding?.width,
            height: bounding?.height,
          }}
        />
      </Portal>
    </>
  );
}
