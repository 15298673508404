import React, { createContext, useContext, useState } from 'react';
import { logger } from '../util/Logger';

interface Props {
  skipPlan?: boolean;
  children: React.ReactNode;
}

type SkipPlanContextType = {
  skipPlan: boolean;
  setSkipPlan: (skipPlan: boolean) => void;
};

const SkipPlanContext = createContext<SkipPlanContextType>({
  skipPlan: false,
  setSkipPlan: () => logger.warn('no SkipPlan provider'),
});

export function useSkipPlan(): SkipPlanContextType {
  return useContext(SkipPlanContext);
}

export function SkipPlanProvider({ children }: Props): JSX.Element {
  const [skipPlan, setSkipPlan] = useState<boolean>(false);

  return <SkipPlanContext.Provider value={{ skipPlan, setSkipPlan }}>{children}</SkipPlanContext.Provider>;
}
