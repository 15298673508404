import { logger } from '../util/Logger';
import { PublicKey } from './PublicKey';

export interface PasswordLogin {
  // Encrypted with either the master password or the custom password depending
  // on the hasCustomPassword flag.
  encryptedDevicePrivateKey: string;

  // The hash of the password. Either the master password or the custom password
  // depending on the hasCustomPassword flag. This hash is used to:
  // - check if the password is correct;
  // - check if the master password has changed (from another device).
  passwordHash?: string;

  // The user private encrypted with the device private key. This is optional
  // for the ability of 'working offline'.
  encryptedUserPrivateKey: string;

  passwordHashingAlgo: string;
  keyDerivationAlgo: string;
}

export interface StoredUserDevice {
  // Personal account info to display at the login page.
  firstName: string;
  lastName: string;
  email: string;
  lang: string;

  // Data version of this interface
  version: number;

  // Base64url encode device public key.
  // devicePublicKey: string;

  // Base64url encode user public key.
  userPublicKey: string;

  // Master password login is always available.
  masterPasswordLogin: PasswordLogin;

  // Add an optional custom password login. This is meant for logging in with
  // a custom password (like 5 digits on mobile).
  customPasswordLogin?: PasswordLogin;
}

// Legacy data format. Needs to be supported for migrations.
interface StoredUserDeviceJsonV1 {
  keys: {
    userPublicKey: string;
    encryptedUserPrivKey: string;
    encryptedDevPrivKey: string;
  };
  // TODO rename Profile -> Account
  profile: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    totpAuthenticatorEnabled: boolean;
    lang: string;
    company?: string;
    isRegistered?: boolean;
    isSuperAdmin?: boolean;
  };
}

// A wrapper class for managing locally stored UserDevices.
export class UserDeviceStorage {
  private devices: StoredUserDevice[] = [];

  constructor() {
    this.migrateV1toV2();
    this.load();
  }

  private migrateV1toV2() {
    const v1data = localStorage.getItem('encrypted_sessions');
    if (!v1data) {
      return;
    }
    const newFormat: StoredUserDevice[] = [];
    const oldFormat = JSON.parse(v1data) as StoredUserDeviceJsonV1[];
    for (const v1 of oldFormat) {
      const masterPwdLogin: PasswordLogin = {
        encryptedDevicePrivateKey: v1.keys.encryptedDevPrivKey,
        encryptedUserPrivateKey: v1.keys.encryptedUserPrivKey,
        passwordHashingAlgo: 'blake2b', // outdated, should be updated
        keyDerivationAlgo: 'xchacha', // symmetric key algo
      };
      newFormat.push({
        firstName: v1.profile.firstName,
        lastName: v1.profile.lastName,
        email: v1.profile.email,
        lang: v1.profile.lang,
        version: 2,
        userPublicKey: v1.keys.userPublicKey,
        masterPasswordLogin: masterPwdLogin,
      });
    }
    localStorage.setItem('devices', JSON.stringify(newFormat));
    localStorage.removeItem('encrypted_sessions');
  }

  private load() {
    const data = localStorage.getItem('devices');
    if (data) {
      try {
        this.devices = JSON.parse(data) as StoredUserDevice[];
      } catch (e) {
        logger.warn('Failed to get StoredUserDevice objects from local storage', e);
      }
    }
  }

  public get list(): StoredUserDevice[] {
    return this.devices;
  }

  public find(userPublicKey: PublicKey): StoredUserDevice | undefined {
    return this.devices.find(item => item.userPublicKey === userPublicKey.toBase64());
  }

  public update(userDev: StoredUserDevice): void {
    const index = this.devices.findIndex(item => item.userPublicKey === userDev.userPublicKey);
    if (index === -1) {
      throw Error('Could not find StoredUserDevice for updating');
    }
    this.devices[index] = userDev;
    localStorage.setItem('devices', JSON.stringify(this.devices));
  }

  public add(userDev: StoredUserDevice): void {
    const userPubKey = PublicKey.fromBase64(userDev.userPublicKey);
    if (this.find(userPubKey)) {
      throw Error('Cannot add StoredUserSession. User already exists');
    }
    this.devices.push(userDev);
    localStorage.setItem('devices', JSON.stringify(this.devices));
  }

  public remove(userPublicKey: PublicKey): void {
    const index = this.devices.findIndex(item => item.userPublicKey === userPublicKey.toBase64());
    if (index === -1) {
      throw Error('Could not find StoredUserDevice for removal');
    }

    this.devices = this.devices.slice(0, index).concat(this.devices.slice(index + 1));
    localStorage.setItem('devices', JSON.stringify(this.devices));
  }

  public setLastLogin(userPublicKey: PublicKey): void {
    localStorage.setItem('last_login', userPublicKey.toBase64());
  }

  public get lastLogin(): PublicKey | undefined {
    const val = localStorage.getItem('last_login');
    if (val) {
      return PublicKey.fromBase64('last_login');
    } else {
      return undefined;
    }
  }
}
