import React, { useCallback, useMemo } from 'react';
import DropdownMenu from 'components/DropdownMenu';
import { SortOrder } from 'types';
import classNames from 'classnames';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';
import { Check, FunnelSimple, SortAscending, SortDescending } from '@phosphor-icons/react';

interface Props<T> {
  fields: Array<[keyof T, string]>;
  sortBy: string;
  sortOrder: SortOrder;
  onSortBy: (sortBy: keyof T) => void;
  onSortOrder: (sortOrder: SortOrder) => void;
  asIcon: boolean;
  className?: string;
}

/**
 * Filter button that display the current sort field and order and the option to update them
 */
function MobileFilterButton<T>({ asIcon, fields, sortBy, onSortBy, sortOrder, onSortOrder, className }: Props<T>): JSX.Element {
  const isMobileDevice = useIsMobileDevice();

  /**
   * Small helper function to position the element in the dropdown
   */
  const SortElement = useCallback(
    ({ sortByValue, sortOrderValue }: { sortByValue?: [keyof T, string]; sortOrderValue?: SortOrder }) => {
      const className = 'flex items-center justify-center md:justify-between gap-x-2';

      if (sortByValue) {
        const friendlyName = sortByValue[1].charAt(0).toUpperCase() + sortByValue[1].slice(1);

        return (
          <div className={className}>
            <span className={classNames({ 'font-semibold': sortBy === sortByValue[0] })}>{friendlyName}</span>
            {sortBy === sortByValue[0] && !isMobileDevice && <Check className='text-primary' />}
          </div>
        );
      }

      if (sortOrderValue) {
        const friendlyName = sortOrderValue.charAt(0).toUpperCase() + sortOrderValue.slice(1);

        return (
          <div className={className}>
            <span className={classNames({ 'font-semibold': sortOrder === sortOrderValue })}>{friendlyName}</span>
            {sortOrder === sortOrderValue && !isMobileDevice && <Check className='text-primary' />}
          </div>
        );
      }

      return <></>;
    },
    [isMobileDevice, sortBy, sortOrder],
  );

  // get the current sort field
  const currentSortField = fields.find(field => field[0] === sortBy);

  const sortFields = useMemo(() => {
    return fields.map(field => {
      return {
        element: <SortElement sortByValue={field} />,
        onClick: () => onSortBy(field[0]),
      };
    });
  }, [SortElement, fields, onSortBy]);

  return (
    <DropdownMenu
      ulClassName='min-w-[150px]'
      paddingSize='xs'
      buttonClassName={className}
      menuItems={[
        [
          // This header element should only be visible on mobile
          // as it makes no sense to have it visible on web as by the line
          // separator there is a clear separation between them on web
          {
            element: 'Sort by',
            isHeader: true,
            isVisible: isMobileDevice === true,
          },
          ...sortFields,
        ],
        [
          // This header element should only be visible on mobile
          // as it makes no sense to have it visible on web as by the line
          // separator there is a clear separation between them on web
          {
            element: 'Order by',
            isHeader: true,
            isVisible: isMobileDevice === true,
          },
          {
            element: <SortElement sortOrderValue={SortOrder.Ascending} />,
            onClick: () => onSortOrder(SortOrder.Ascending),
          },
          {
            element: <SortElement sortOrderValue={SortOrder.Descending} />,
            onClick: () => onSortOrder(SortOrder.Descending),
          },
        ],
      ]}
    >
      <button
        type='button'
        className={classNames({
          'flex items-center justify-between gap-x-1 w-full': !asIcon,
          'p-1': asIcon,
        })}
      >
        {!asIcon ? (
          <>
            <p>
              Sort by: <span className='text-primary truncate'>{currentSortField?.[1]}</span>
            </p>{' '}
            {sortOrder === SortOrder.Ascending ? <SortAscending /> : <SortDescending />}
          </>
        ) : (
          <FunnelSimple />
        )}
      </button>
    </DropdownMenu>
  );
}

export default MobileFilterButton;
