// See https://tailwindcss.com/docs/responsive-design for the sizes
export enum ScreenSize {
  'xs' = 475,
  'sm' = 640,
  'md' = 768,
  'lg' = 1024,
  'xl' = 1280,
  '2xl' = 1536,
  '3xl' = 1750,
}
