import { ArrowCounterClockwise, Image, PaintBucket, TrashSimple, UploadSimple } from '@phosphor-icons/react';
import FormField from 'components/FormElements/FormField';
import FormFieldDescription from 'components/FormElements/FormFieldDescription';
import FormFieldError from 'components/FormElements/FormFieldError';
import FormLabel from 'components/FormElements/FormLabel';
import Modal, { ModalWidth } from 'components/Modal';
import UploadArea from 'components/Upload/UploadArea';
import { useStorroApi } from 'context/StorroApiContext';
import { INPUT_WARNING_CLASSNAMES } from 'helpers/CssClasses';
import invert from 'invert-color';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ColorResult, TwitterPicker } from 'react-color';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import formatErrorMessage from 'util/FormatErrorMessage';
import { logger } from 'util/Logger';
import ImageActionButton from './ImageActionButton';
import FormInput from 'components/FormElements/FormInput';
import classNames from 'classnames';
import { HEX_REGEX, isHex } from 'util/Validators';
import Form from 'components/FormElements/Form';
import useRealm from 'hooks/realm/UseRealm';
import { Realm } from 'types';

interface FormModel {
  color: string;
  logo: FileList | null | undefined;
}

const DEFAULT_COLOR = '#2563eb';
const DEFAULT_COLORS = ['#ff6900', '#fcb900', '#00d084', '#8ed1fc', '#0693e3', '#abb8c3', '#eb144c', '#f78da7', '#aa7942', DEFAULT_COLOR];
const ALLOWED_MIME_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
const MAX_SIZE = 250; // in kb

interface Props {
  isVisble: boolean;
  onRequesCloseModal: () => void;
  onSuccess: (realm: Realm) => Promise<void> | void;
}

function BrandRealmModal({ isVisble, onRequesCloseModal, onSuccess }: Props): JSX.Element {
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [realmColor, setRealmColor] = useState<string>(DEFAULT_COLOR);
  const [invertColor, setInvertColor] = useState<string>(invert(DEFAULT_COLOR, true));
  const [logoHasChanged, setLogoHasChanged] = useState<boolean>(false);

  const hiddenInputUploadRef = useRef<HTMLInputElement | null>();

  const { realm, realmCustomLogo } = useRealm();
  const { storroApi } = useStorroApi();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm<FormModel>();

  const logoFormValue = watch('logo');
  const colorFormValue = watch('color');
  const colorFormValueChanged = (realm?.color ?? DEFAULT_COLOR) !== colorFormValue;

  // as we need the ref for the file input, we deconstruct the object here
  const { ref: registerLogoRef, ...registerLogo } = register('logo', {
    validate: files => {
      if (files && files[0]) {
        if (!ALLOWED_MIME_TYPES.includes(files[0].type)) {
          return 'Only JPG or PNG is allowed';
        }

        if (files[0].size / 1000 >= MAX_SIZE) {
          return `Logo should be less than ${MAX_SIZE}kb`;
        }
      }
    },
  });

  /**
   * Flag to indicate if the value is a valid uploaded logo
   */
  const hasValidUploadedFile =
    logoFormValue !== null && logoFormValue !== undefined && logoFormValue.length > 0 && ALLOWED_MIME_TYPES.includes(logoFormValue[0].type);

  /**
   * Get the logo, either it is the custom logo that the user
   * already uploaded, or it is the newly uploaded logo or we return undefined
   * if the user marked the files as deleted.
   */
  const logoUrl = useMemo((): string | undefined => {
    if (hasValidUploadedFile) {
      return URL.createObjectURL(logoFormValue[0]);
    }

    // the user removed the logo
    if (logoFormValue === null) {
      return undefined;
    }

    if (realmCustomLogo) {
      return realmCustomLogo;
    }
  }, [hasValidUploadedFile, logoFormValue, realmCustomLogo]);

  /**
   * Submitting the form
   */
  const onSubmit = async ({ color, logo }: FormModel) => {
    if (!realm) return;

    try {
      const updatedValues: { color: string | undefined; logo: File | undefined } = {
        color: undefined,
        logo: undefined,
      };

      if (logo && logo[0]) {
        updatedValues.logo = logo[0];
      }

      if (colorFormValueChanged) {
        updatedValues.color = color;
      }

      // update the branding
      await storroApi.updateRealmBranding(realm.id, updatedValues);

      // as we use the FormData api to set the file
      // are unable to reset the logo via the FormData as FormData cannot be null
      // Therefor, if the logo is null (basiclly a reset), we should fire a
      // separated call to the API to remove the logo
      if (logo === null) {
        await storroApi.removeRealmLogo(realm.id);
      }

      await onSuccess(realm);

      onRequesCloseModal();
    } catch (error) {
      toast.error(formatErrorMessage('update Realm branding', error instanceof Error ? error.message : undefined));
      logger.error(error);
    }
  };

  const onClosed = () => {
    reset({ color: realm?.color, logo: undefined });
    setShowColorPicker(false);
  };

  /**
   * Callback from the colorpicker when a value changed.
   */
  const onColorChange = (color: ColorResult) => {
    setValue('color', color.hex, { shouldValidate: true, shouldDirty: true });
  };

  /**
   * Remove the realm logo
   */
  const removeLogo = async () => {
    setValue('logo', null);
    setLogoHasChanged(true);
  };

  /**
   * Save action
   */
  const action = useMemo(() => {
    return {
      label: 'Save',
      loading: isSubmitting,
      formId: 'update-realm-branding',
    };
  }, [isSubmitting]);

  /**
   * Update the logoHasChanged flag when we have a valid uploaded file
   */
  useEffect(() => {
    setLogoHasChanged(hasValidUploadedFile);
  }, [hasValidUploadedFile]);

  /**
   * When the color is changing, change the invert and realmColor as well
   */
  useEffect(() => {
    if (colorFormValue && isHex(colorFormValue)) {
      setInvertColor(invert(colorFormValue, true));
      setRealmColor(colorFormValue);
    }
  }, [colorFormValue]);

  /**
   * Set the default values when the realm is loaded
   */

  useEffect(() => {
    if (isVisble && realm) {
      setValue('color', realm.color ?? DEFAULT_COLOR);
      setRealmColor(realm.color ?? DEFAULT_COLOR);
    }
  }, [isVisble, realm, setValue]);

  return (
    <Modal
      title='Brand your realm'
      isVisible={isVisble}
      onRequestCloseModal={onRequesCloseModal}
      action={action}
      onClosed={onClosed}
      className='min-h-[15rem]'
      helpText='* required fields'
      width={ModalWidth.md}
    >
      <Form id='update-realm-branding' onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <FormLabel htmlFor='color'>Primary Color</FormLabel>
          <FormFieldDescription>This is the primary color, used for buttons, links, headers etc.</FormFieldDescription>
          <div className='flex items-center gap-x-1'>
            <FormInput
              hasError={errors.color !== undefined}
              id='color'
              className={classNames({
                'text-gray-500': !colorFormValueChanged,
              })}
              {...register('color', {
                required: 'Please provide a valid color',
                pattern: {
                  value: HEX_REGEX,
                  message: 'invalid color code',
                },
              })}
            />
            <div className='p-1 h-8 w-9 border rounded cursor-pointer' onClick={() => setShowColorPicker(true)}>
              <div style={{ backgroundColor: realmColor }} className='w-full h-full rounded flex justify-center items-center'>
                <PaintBucket size={16} color={invertColor} />
              </div>
            </div>
          </div>

          {showColorPicker && (
            <>
              <div className='fixed top-0 right-0 bottom-0 left-0' onClick={() => setShowColorPicker(false)} />
              <div className='absolute right-0 top-[90px]'>
                <TwitterPicker triangle='top-right' colors={DEFAULT_COLORS} color={realmColor} onChangeComplete={onColorChange} />
              </div>
            </>
          )}

          {errors.color && <FormFieldError>{errors.color.message}</FormFieldError>}
        </FormField>
        <FormField>
          <FormLabel htmlFor='logo'>Logo</FormLabel>
          <FormFieldDescription>
            The logo will be place in the upper-left corner of the page and will replace the default Storro logo.
          </FormFieldDescription>

          <UploadArea
            onSelected={files => {
              const fileList = new DataTransfer();
              fileList.items.add(files[0]);
              setValue('logo', fileList.files);
            }}
            className={classNames('flex flex-col gap-2 items-center justify-center bg-gray-50 p-10 border border-gray-200 rounded-sm', {
              [INPUT_WARNING_CLASSNAMES]: errors.logo,
            })}
          >
            {logoUrl && <img src={logoUrl} width={150} />}
            {!logoUrl && (
              <div className='flex flex-col justify-center items-center p-5 border rounded-md'>
                <Image className='text-gray-400' size={80} />
                <span className='text-xs text-gray-400 italic text-center'>
                  No logo selected, <br />
                  default Storro logo will be used
                </span>
              </div>
            )}

            <div className='flex gap-x-2'>
              <ImageActionButton
                icon={<UploadSimple />}
                toolTip='Upload a new logo'
                onClick={() => hiddenInputUploadRef.current?.click()}
              />

              <ImageActionButton
                disabled={!realmCustomLogo || logoFormValue === null}
                icon={<TrashSimple />}
                toolTip='Remove your custom logo'
                onClick={removeLogo}
              />

              <ImageActionButton
                disabled={!logoHasChanged}
                icon={<ArrowCounterClockwise />}
                toolTip='Undo your just uploaded file'
                onClick={() => setValue('logo', undefined)}
              />
            </div>
          </UploadArea>
          <input
            className='hidden'
            id='logo'
            type='file'
            ref={e => {
              registerLogoRef(e);
              hiddenInputUploadRef.current = e;
            }}
            {...registerLogo}
          />
          {errors.logo && <FormFieldError>{errors.logo.message}</FormFieldError>}
        </FormField>
      </Form>
    </Modal>
  );
}

export default BrandRealmModal;
