import React from 'react';
import Modal, { ModalWidth } from '../../components/Modal';
import { BrowserSupport } from '.';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
}

/**
 * Wrap the browser support list in a modal
 */
export function BrowserSupportModal({ isVisible, onRequestCloseModal }: Props): JSX.Element {
  return (
    <Modal title='Supported browser list' isVisible={isVisible} onRequestCloseModal={onRequestCloseModal} width={ModalWidth.sm}>
      <BrowserSupport />
    </Modal>
  );
}
