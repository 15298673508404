import { QuickShareDetails } from 'types';
import { logger } from '../util/Logger';
import Storro from './index';

export class QuickShareList {
  private quickshares = new Map<number, QuickShare>();

  constructor(private storroApi: Storro) {}

  public clear(): void {
    this.quickshares.clear();
  }

  public async onQuickShareUpdated(quickShareId: number): Promise<void> {
    const qs = this.quickshares.get(quickShareId);
    if (qs) {
      try {
        await qs.refresh();
      } catch (e) {
        logger.warn('Failed to get QuickShare from api.', e);
      }
    }
  }

  public async listQuickShares(realmId: number, signal?: AbortSignal): Promise<QuickShare[]> {
    const list = await this.storroApi.listQuickShares(realmId, signal);
    const result: QuickShare[] = [];
    for (const qsDetails of list) {
      const find = this.quickshares.get(qsDetails.id);
      if (find) {
        result.push(find);
      } else {
        const qs = new QuickShare(this.storroApi, qsDetails);
        this.quickshares.set(qsDetails.id, qs);
        result.push(qs);
      }
    }
    return result;
  }

  public async getQuickShare(quickShareId: number, signal?: AbortSignal): Promise<QuickShare> {
    const found = this.quickshares.get(quickShareId);
    if (found) {
      return found;
    }
    const qsDetails = await this.storroApi.getQuickShareItem(quickShareId, signal);
    const qs = new QuickShare(this.storroApi, qsDetails);
    this.quickshares.set(qs.id, qs);
    return qs;
  }
}

export class QuickShare {
  private qsUpdatedHandlers: { (): void }[] = [];
  private qsRemovedHandlers: { (): void }[] = [];

  constructor(
    private storroApi: Storro,
    private quickShare: QuickShareDetails,
  ) {}

  public onQuickShareUpdated(): void {
    this.qsRemovedHandlers.forEach(handler => handler());
  }

  public subscribeOnUpdated(handler: { (): void }): void {
    this.qsUpdatedHandlers.push(handler);
  }

  public unsubscribeOnUpdated(handler: { (): void }): void {
    this.qsUpdatedHandlers = this.qsUpdatedHandlers.filter(h => h !== handler);
  }

  public onQuickShareRemoved(): void {
    this.qsRemovedHandlers.forEach(handler => handler());
  }

  public subscribeOnRemoved(handler: { (): void }): void {
    this.qsRemovedHandlers.push(handler);
  }

  public unsubscribeOnRemoved(handler: { (): void }): void {
    this.qsRemovedHandlers = this.qsRemovedHandlers.filter(h => h !== handler);
  }

  public get id(): number {
    return this.quickShare.id;
  }

  public get details(): QuickShareDetails {
    return this.quickShare;
  }

  public async refresh(): Promise<void> {
    const qs = await this.storroApi.getQuickShareItem(this.id);
    this.quickShare = qs;
  }

  public async markAsSeen(signal?: AbortSignal): Promise<void> {
    if (this.details.hasSeen === false) {
      try {
        await this.storroApi.markQuickShareAsSeen(this.id, signal);
        this.details.hasSeen = true;
        this.onQuickShareUpdated();
      } catch (error) {
        // ignore error
        logger.error(error);
      }
    }
  }
}
