import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';
import { SidebarItem } from '../../api/Sidebar';
import { useSidebarRightContext } from '../../context/SidebarRightContext';
import Button, { ButtonSize, ButtonVariant } from '../Button';
import { IconContext } from '@phosphor-icons/react';

interface Props {
  item: SidebarItem;
  isOpen: boolean | undefined;
  setIsOpen: Dispatch<SetStateAction<boolean | undefined>>;
}

function Block({ item, isOpen, setIsOpen }: Props): JSX.Element {
  const { sidebar } = useSidebarRightContext();

  /**
   * Only open the sidebar if it is not open
   */
  const openSidebarOnClick = () => {
    if (isOpen) return;
    setIsOpen(true);
  };

  /**
   * Fire the context button
   */
  const contextClick = () => {
    sidebar.fireContextButton(item.title);
  };

  return (
    <div
      className={classNames(
        'group flex',
        'z-30', // to set a higher z-index the scrollbar wont disabpear behind the sticky date headers
        {
          'overflow-y-auto overflow-x-hidden': item.isActive && isOpen,
          'border-b first:mt-0 flex-col': isOpen,
          'py-2 flex justify-center': !isOpen,
          'grow-0': !isOpen || !item.isActive,
          grow: isOpen && item.isActive,
        },
      )}
    >
      <a
        href='#'
        className={classNames('bg-white', {
          'pl-5 py-3 sticky top-0 z-40 flex items-center gap-x-2 h-12': isOpen,
        })}
        onClick={e => {
          e.preventDefault();
          if (!item.isActive) {
            sidebar.active(item.title);
          }
        }}
      >
        <button onClick={openSidebarOnClick}>
          <IconContext.Provider value={{ size: 16 }}>{item.icon}</IconContext.Provider>
        </button>
        {isOpen && <span className='font-semibold'>{item.title}</span>}
        {item.contextButtonText && item.isActive && isOpen && (
          <Button
            size={ButtonSize.Small}
            variant={ButtonVariant.Primary}
            onClick={contextClick}
            className='ml-auto mr-4'
            responsive={false}
          >
            {item.contextButtonText}
          </Button>
        )}
      </a>
      <div
        className={classNames({
          hidden: !isOpen || !item.isActive,
        })}
      >
        {item.children}
      </div>
    </div>
  );
}

export default Block;
