// Copyright 2021 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// Cas implements a Content-addressed store which should be compatible with
// Zooid/Source/Serialization/ContentAddressedStore.h
import { Hash } from '../Cryptography/Hash';
import { KeyValueStore } from './KeyValueStore';
import { StoreConfig, StoreFactory } from './StoreFactory';

export class Cas {
  constructor(private keyValueStore: KeyValueStore) {}

  async hasValue(key: Uint8Array): Promise<boolean> {
    return this.keyValueStore.hasValue(key);
  }

  // Would normally call this 'get()' but that's a keyword in TypeScript. :-)
  async getValue(key: Uint8Array): Promise<Uint8Array> {
    return this.keyValueStore.getValue(key);
  }

  // Putting a value computes the address and returns the locator / key.
  async putValue(value: Uint8Array): Promise<Uint8Array> {
    if (value.length === 0) throw 'CAS can not store empty value';

    const hash = await Hash.blake2bTree(value);
    // Return the 'hash' only after the result
    // of the putKeyValue() function call is successful.
    await this.keyValueStore.putValue(hash, value);
    return hash;
  }

  static keySize(): number {
    return 32;
  }

  public toStoreConfig(): StoreConfig {
    return this.keyValueStore.toStoreConfig();
  }

  public static async fromStoreConfig(storeConfig: StoreConfig): Promise<Cas> {
    return new Cas(await new StoreFactory().load(storeConfig));
  }
}
