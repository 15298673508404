// Copyright 2021 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// StreamChunker.ts is a way simplified version of
// Zooid/Source/Serialization/StreamChunker.h
//
// Import the C++ RollingHash chunker code compiled to WebAssembly:
/* eslint-disable @typescript-eslint/no-explicit-any */
import { logger } from '../Logger';
import { WebAssemblyMemory } from '../Util/WebAssemblyMemory';
import chunkerWebAssembly from './chunker';

export class StreamChunker {
  private _minimumChunkSize: number;
  private _maximumChunkSize: number;
  readonly unitSize: number;

  // This is a WebAssembly Module (the code) which produces Instances
  private chunkerModule: any | undefined = undefined;

  // This is a WebAssembly Instance (code + memory).
  private instance: any | undefined = undefined;

  private wasmOutput: WebAssemblyMemory | undefined = undefined;
  private wasmBuffer: WebAssemblyMemory | undefined = undefined;

  constructor(minimumChunkSize: number, maximumChunkSize: number, unitSize = 1) {
    if (maximumChunkSize % unitSize) {
      logger.warn('Chunker unit size problem: ', maximumChunkSize, unitSize);
      throw new Error('maximumChunkSize is not a multiple of the unit size');
    }
    this._minimumChunkSize = minimumChunkSize;
    this._maximumChunkSize = maximumChunkSize;
    this.unitSize = unitSize;
    this.chunkerModule = chunkerWebAssembly();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  maximumChunkSize(offset = 0): number {
    // In constant-chunking, the maxmimum chunksize
    // is a constant for all offsets.
    // The offset argument is part of the interface to allow for
    // different chunk sizes at different offsets in the stream.
    return this._maximumChunkSize;
  }

  // Returns the minimum and maximum chunk sizes for a given offset in the file.
  // offset specifies the offset inside the stream (not the buffer), as some chunkers will
  // use different min-max chunk sizes depending on the offset in the stream.
  async chunkEnd(streamOffset: number, buffer: Uint8Array): Promise<number> {
    // If the input is too small to chunk, return the length directly.
    const worthyLength = (this._minimumChunkSize + this._maximumChunkSize) / 2;
    if (buffer.length < worthyLength) return buffer.length;

    // Below this size we don't care about chunking.
    if (buffer.length <= this._minimumChunkSize) return buffer.length;

    // RollingHash algo needs at least 256 bytes.
    if (buffer.length <= 256) return buffer.length;

    if (!this.chunkerModule) {
      // Can not call chunkEnd() after calling destroy().
      throw new Error('ChunkerModule is undefined');
    }

    // Once the wasm module is fetched and compiled, we get a wasm instance.
    if (!this.instance) {
      logger.debug('Instantiating WebAssembly instance for StreamChunker');
      this.instance = await this.chunkerModule;
    }

    // Initialize the buffers once.
    // We do this after the Wasm module promise has been completed because we need the wasm instance.
    if (!this.wasmBuffer) {
      this.wasmOutput = new WebAssemblyMemory(this.instance, 16);
      this.wasmBuffer = new WebAssemblyMemory(this.instance, this._maximumChunkSize);
    }

    if (!this.wasmOutput) {
      throw new Error('WasmOutput should have been initialized');
    }

    // Make sure the buffer does not overflow the memory allocated in the wasm instance.
    if (buffer.length > this._maximumChunkSize) buffer = buffer.slice(0, this._maximumChunkSize);

    // Copy buffer to the WebAssembly instance's memory.
    this.wasmBuffer.setMemory(buffer);

    // Where the magic happens.
    const chunkSize = this.instance._maxRollingHash(
      this.wasmBuffer.pointer,
      this._minimumChunkSize - 256,
      buffer.length,
      this.unitSize,
      this.wasmOutput.pointer,
    );

    if (chunkSize === 0) throw new Error('Max Rolling Hash failed');
    // We are going to need the rolling hash when we want to do multi-threaded chunking.
    // This is how you get that hash:
    // const rollingHash = this.uint8arrayToInt32(this.wasmOutput.toUint8Array());
    return chunkSize;
  }

  private uint8arrayToInt32(array: Uint8Array) {
    let result = array[0];
    let factor = 256;
    for (let i = 1; i < 4; i++) {
      result += array[i] * factor;
      factor *= 256;
    }
    return result;
  }

  public destroy(): void {
    if (this.wasmOutput) {
      this.wasmOutput.free();
      this.wasmOutput = undefined;
    }

    if (this.wasmBuffer) {
      this.wasmBuffer.free();
      this.wasmBuffer = undefined;
    }

    if (this.chunkerModule) {
      this.chunkerModule = undefined;
    }

    if (this.instance) {
      this.instance = undefined;
    }
  }
}
