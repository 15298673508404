import { ButtonVariant } from 'components/Button';
import { DropdownMenuItemArray } from 'components/DropdownMenu';
import { PageButtonAction } from 'components/Page/PageButtons';
import { useMemo } from 'react';

interface ReturnType {
  items: PageButtonAction[];
  dropdownItems: DropdownMenuItemArray[];
}

/**
 * Small hook to parse the selection actions into {n} buttons and the rest into a dropdown
 */
function usePageButtons(selectionActionsVisibleCount = 2, selectionActions: PageButtonAction[] | undefined): ReturnType {
  /**
   * Parse the selection actions into 2 buttons and the rest into a dropdown
   * The count from where the dropdown starts is defined by the selectionActionsVisibleCount
   */
  const parsedSelectionActions = useMemo((): { items: PageButtonAction[]; dropdownItems: DropdownMenuItemArray[] } => {
    if (!selectionActions) return { items: [], dropdownItems: [] };

    // define the dropdown items
    const dropdownItems: DropdownMenuItemArray = selectionActions.slice(selectionActionsVisibleCount).map(action => {
      return {
        isDisabled: action.disabled,
        element: action.label,
        onClick: action.onClick,
        label: action.label,
        // we should also handle danger states
        className:
          action.variant && [ButtonVariant.Danger, ButtonVariant.DangerOutline].includes(action.variant) && !action.disabled
            ? 'text-red-700'
            : undefined,
      };
    });

    return {
      items: selectionActions.slice(0, selectionActionsVisibleCount),
      dropdownItems: [dropdownItems],
    };
  }, [selectionActions, selectionActionsVisibleCount]);

  return parsedSelectionActions;
}

export default usePageButtons;
