import { IconContext } from '@phosphor-icons/react';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import React, { ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  toolTip: string;
  onClick: () => void;
  disabled?: boolean;
}

/**
 * Small helper component for image action buttons
 */
function ImageActionButton({ icon, onClick, toolTip, disabled }: Props): JSX.Element {
  return (
    <Tooltip content={toolTip} mode='dark' disabled={disabled}>
      <button
        disabled={disabled}
        type='button'
        onClick={onClick}
        className={classNames('w-10 h-10 rounded bg-white border flex justify-center items-center ', {
          'hover:bg-gray-50': !disabled,
          'cursor-not-allowed text-gray-400': disabled,
        })}
      >
        <IconContext.Provider value={{ size: 18 }}>{icon}</IconContext.Provider>
      </button>
    </Tooltip>
  );
}

export default ImageActionButton;
