import React, { ReactNode, useState } from 'react';
import useRealm from 'hooks/realm/UseRealm';
import classNames from 'classnames';
import { Portal } from 'react-portal';
import ClickAwayListener from 'react-click-away-listener';
import { usePopper } from 'react-popper';
import LogoGray from 'assets/logo-gray.svg';
import { useStorroApi } from 'context/StorroApiContext';
import Button, { ButtonSize } from 'components/Button';
import useRealmSwitcher from 'hooks/UseRealmSwitcher';
import { useNavigate } from 'react-router-dom';

interface Props {
  enabled?: boolean;
  children: ReactNode;
  includeCreateRealm: boolean;
  onRealmSwitch: () => void;
}

function RealmSwitcher({ enabled = true, children, includeCreateRealm, onRealmSwitch }: Props): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(referenceElement, popperElement, {
    placement: 'left-start',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, -5],
        },
      },
    ],
  });

  const { storroApi } = useStorroApi();
  const { realm, realms } = useRealm();
  const { navigateToRealm } = useRealmSwitcher();
  const navigate = useNavigate();

  if (!enabled) {
    return <>{children}</>;
  }

  return (
    <>
      <div ref={setReferenceElement} onClick={() => setIsVisible(prevState => !prevState)}>
        {children}
      </div>

      {isVisible && (
        <Portal>
          <ClickAwayListener onClickAway={() => setIsVisible(false)}>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...popperAttributes.popper}
              className={classNames('z-[99] bg-white rounded-lg border shadow-lg animate-fadeIn animate-faster w-52')}
            >
              <ul className='mt-2 divide-y divide-gray-100 border-b border-b-gray-100'>
                {realms.map(realmList => (
                  <li
                    key={realmList.name}
                    className='flex gap-x-2 items-center py-2 hover:bg-gray-50 px-3 cursor-pointer'
                    onClick={() => navigateToRealm(realmList, onRealmSwitch)}
                  >
                    <img
                      src={realmList.logoHash ? storroApi.getLogoUrl(realmList.id, realmList.logoHash) : LogoGray}
                      alt='logo'
                      className={classNames('w-8 h-8 rounded-md', {
                        'opacity-40': !realmList.logoHash,
                      })}
                    />
                    <p className='font-semibold text-sm'>{realmList.name}</p>
                    {realms.length > 1 && (
                      <input
                        onChange={() => {
                          // avoid the warning that React show for uncontrollable input
                        }}
                        type='radio'
                        className='ml-auto'
                        checked={realmList.id === realm?.id}
                      />
                    )}
                  </li>
                ))}
              </ul>

              {includeCreateRealm && (
                <div className='px-4 py-3 bg-gray-50 rounded-b-lg'>
                  <Button onClick={() => navigate('/realm/create')} className='w-full' fullWidth={true} size={ButtonSize.Small}>
                    Create Realm
                  </Button>
                </div>
              )}
            </div>
          </ClickAwayListener>
        </Portal>
      )}
    </>
  );
}

export default RealmSwitcher;
