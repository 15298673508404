import './animation.css';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

interface Props {
  src: string;
  alt: string;
  timeout?: number;
  className?: string;
}

export default function CrossFadeImage({ src, alt, timeout = 300, className }: Props): JSX.Element {
  const [firstSrc, setFirstSrc] = useState<string>('');
  const [secondSrc, setSecondSrc] = useState<string>('');
  const [lastUpdated, setLastUpdated] = useState<'first' | 'second'>('second');

  useEffect(() => {
    if (src) {
      if (lastUpdated === 'first') {
        setSecondSrc(src);
        setLastUpdated('second');
      } else {
        setFirstSrc(src);
        setLastUpdated('first');
      }
    }
  }, [src]); //eslint-disable-line

  return (
    <div className={classNames('relative h-28', className)}>
      {firstSrc && (
        <div
          className={classNames('h-28 w-full absolute flex justify-center items-center ', {
            'opacity-0': lastUpdated === 'second',
            'opacity-100': lastUpdated === 'first',
          })}
          style={{
            transition: `opacity ${timeout}ms`,
          }}
        >
          <img className='w-full h-full object-contain' alt={alt} src={firstSrc} />
        </div>
      )}
      {secondSrc && (
        <div
          className={classNames('h-28 w-full absolute flex justify-center items-center ', {
            'opacity-100': lastUpdated === 'second',
            'opacity-0': lastUpdated === 'first',
          })}
          style={{
            transition: `opacity ${timeout}ms`,
          }}
        >
          <img className='w-full h-full object-contain' alt={alt} src={secondSrc} />
        </div>
      )}
    </div>
  );
}
