import { useStorroApi } from '../context/StorroApiContext';
import { UserContextType, useUserContext } from '../context/UserContext';

/**
 * Custom hook to manage the user state
 */
function useUser(): UserContextType & {
  loadUser: (signal?: AbortSignal) => Promise<void>;
} {
  const { superAdminMode, setSuperAdminMode, setUser, user } = useUserContext();
  const { storroApi } = useStorroApi();

  /**
   * (re)load the user from the API and update the User context
   */
  const loadUser = async (signal?: AbortSignal) => {
    await storroApi.fetchUserAccount(signal);
    setUser(storroApi.account);
  };

  return {
    superAdminMode,
    setSuperAdminMode,
    user,
    setUser,
    loadUser,
  };
}

export default useUser;
