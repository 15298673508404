// Copyright 2021 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// MerkleTreeWriter.ts should function equal to
// Zooid/Source/Serialization/MerkleTreeWriter.h
//
import { concat } from '../Util/Concat';
import { Cas } from './Cas';
import { Ecas } from './Ecas';
import { EcasValue } from './EcasValue';
import { Chunk, generateMetaChunk } from './MerkleTree';
import { MerkleTreeReader } from './MerkleTreeReader';
import { StreamChunker } from './StreamChunker';
import { StreamKey } from './StreamKey';

function createChunker(depth: number): StreamChunker {
  // We calculate how many of chunkMetadataSize(_depth) fit into the minimum and maximum chunk sizes.
  // Because the chunking is not done on the entire ChunkMetadata, only on the locators,
  // we then by the size of the locators.
  const locatorSize = Cas.keySize();
  const metadataSize = Chunk.chunkMetadataSize(depth);
  const minMetas = Math.floor(262144 / metadataSize);
  const maxMetas = Math.floor(2097152 / metadataSize);
  const unitSize = locatorSize;
  return new StreamChunker(minMetas * locatorSize, maxMetas * locatorSize, unitSize);
}

export class MerkleTreeWriter {
  ecas: Ecas;
  streamChunker: StreamChunker;
  parent: MerkleTreeWriter;
  chunks: Array<Chunk>;
  _rootKey: Uint8Array;

  constructor(
    ecas: Ecas,
    private depth: number,
  ) {
    this.streamChunker = createChunker(depth);
    this.ecas = ecas;
    this.chunks = new Array<Chunk>();
  }

  async numChunksToFlush(): Promise<number> {
    // Calculate the number of chunks to flush based on chunking the locators.
    const locators = new Uint8Array(Cas.keySize() * this.chunks.length);
    let offset = 0;
    for (const chunk of this.chunks) {
      const streamKey = await chunk.streamKey;
      const locator = streamKey.getLocator();
      if (locators.length !== Cas.keySize()) throw new Error('Locators is of wrong size');
      locators.set(locator, offset);
      offset += Cas.keySize();
    }
    const position = await this.streamChunker.chunkEnd(0, locators);
    if (position % this.streamChunker.unitSize) {
      // This sanity check should never trigger.
      throw new Error('Metachunk index is not a multiple of the unitsize');
    }
    const index = position / this.streamChunker.unitSize;
    return index;
  }

  async appendChunk(key: Promise<StreamKey>, totalContentSize: number, chunkContentSize: number): Promise<void> {
    // Reset the cached root key on any change.
    this._rootKey = new Uint8Array();
    // Add the new chunk to our array of chunks.
    const c = new Chunk(key, totalContentSize, chunkContentSize);
    this.chunks.push(c);
    if (this.chunks.length * Chunk.chunkMetadataSize(this.depth) < this.streamChunker.maximumChunkSize(0)) {
      // We added the Chunk. Done for now.
      return;
    }
    // Use the StreamChunker to find the best place to chunk.
    const numChunks = await this.numChunksToFlush();
    await this.flushChunks(numChunks);
  }

  async flushChunks(numChunks: number): Promise<void> {
    if (numChunks === 0) throw new Error('Unable to flush zero chunks to meta chunk');
    if (numChunks > this.chunks.length) throw new Error('Number of chunks to be flushed too high');
    // Calculate the total content size
    let contentSize = 0;
    for (let i = 0; i < numChunks; i++) {
      contentSize += this.chunks[i].contentSize;
    }

    // Put the superchunk and supply the hash to the parent chunker
    const metas = this.chunks.slice(0, numChunks);
    const realmSalt = this.ecas.convEnc().realmSalt;
    const g = await generateMetaChunk(realmSalt, this.depth, metas);

    const ecasKeyPromise = this.ecas.putValue(new EcasValue(g.metachunk));
    if (this.parent === undefined) {
      this.parent = new MerkleTreeWriter(this.ecas, this.depth + 1);
    }
    const streamKey = new Promise<StreamKey>((resolve, reject) => {
      ecasKeyPromise
        .then(ecasKey => {
          const s = new StreamKey(ecasKey.getLocator());
          s.setMetaKey(ecasKey.getValueHash());
          s.setContentKey(g.valueHash);
          resolve(s);
        })
        .catch(reason => reject(reason));
    });
    this.parent.appendChunk(streamKey, contentSize, g.metachunk.length);

    // Remove the chunked chunks from our buffer.
    this.chunks = this.chunks.slice(numChunks);
  }

  // Calculates and returns the rootKey.
  async rootKey(): Promise<StreamKey | undefined> {
    if (this.chunks.length > 1) {
      // If we have multiple chunks we need to merkle up anyway.
      await this.flushChunks(this.chunks.length);
      this.chunks = [];
      return this.parent.rootKey();
    }
    if (this.parent) {
      // If there is already a parent we need to flush
      // everything we have to them and let them return their root key.
      if (this.chunks.length > 0) {
        await this.flushChunks(this.chunks.length);
        this.chunks = [];
      }
      return this.parent.rootKey();
    }
    // We have no parent and either have one or zero chunks.
    if (this.chunks.length > 1) throw 'Bug: Should not have more than one Chunk at this point';
    // If there is only one chunk added return it's hash as the root key.
    if (this.chunks.length === 1) return this.chunks[0].streamKey;
    // There are no chunks. This is an empty MerkleTreeWriter.
    return undefined;
  }

  // Return the total number of content bytes chunked
  size(): number {
    let result = 0;
    this.chunks.forEach(chunk => {
      result += chunk.contentSize;
    });
    if (this.parent) {
      result += this.parent.size();
    }
    return result;
  }

  async read(offset: number, length: number): Promise<Uint8Array> {
    // We accumulate parts of the final result here.
    const result = new Array<Uint8Array>();

    // If we already chunked enough chunks, a parent will be present.
    // We're navigating through the tree before consuming the last added content.
    if (this.parent) {
      const parentSize = this.parent.size();
      if (offset < parentSize) {
        // We have to first read from the parent.
        if (offset + length <= parentSize) {
          // The entire read() request falls inside the parent.
          return this.parent.read(offset, length);
        }
        // The read starts in parent and continues in our buffer.
        const parentResult = await this.parent.read(offset, length);
        if (offset + parentResult.length !== parentSize) throw new Error('MerkleTreeWriter read too much');
        result.push(parentResult);
        // Update length
        length -= parentResult.length;
      }
      // Update offset for reading past the parent
      offset -= parentSize;
    }

    for (let i = 0; i < this.chunks.length; i++) {
      if (length === 0) {
        // We're done reading
        return concat(result);
      }

      const chunk = this.chunks[i];
      const contentSize = chunk.contentSize;
      if (offset >= contentSize) {
        // Skip past this chunk
        offset -= contentSize;
        continue;
      }

      // Read from this chunk
      const reader = new MerkleTreeReader(this.ecas, await chunk.streamKey);
      const content = await reader.read(offset, length);
      // Check the result is sane.
      const expectedSize = Math.min(length, contentSize - offset);
      if (content.length !== expectedSize) throw new Error('MerkleTreeReader read unexpected number of bytes');
      // Accumulate result
      result.push(content);
      // Update offset and length for next chunk
      offset = 0;
      length -= content.length;
    }

    // Content was not enough to satisfy read length.
    // Return what we were able to read.
    return concat(result);
  }

  public destroy(): void {
    if (this.streamChunker) {
      this.streamChunker.destroy();
    }
  }
}
