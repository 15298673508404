import React, { forwardRef, ReactElement } from 'react';
import { Icon, IconBase, IconWeight } from '@phosphor-icons/react';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <>
      <path id='Path' fill='none' stroke='none' d='M 0 0 L 256 0 L 256 256 L 0 256 Z' />
      <path
        id='path1'
        fill='none'
        stroke='currentcolor'
        strokeWidth='16'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M 152 32 L 152 88 L 208 88'
      />
      <path
        id='path2'
        fill='none'
        stroke='currentcolor'
        strokeWidth='16'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M 168 224 L 200 224 C 204.418274 224 208 220.418274 208 216 L 208 88 L 152 32 L 56 32 C 51.581722 32 48 35.581726 48 40 L 48 120'
      />
      <g id='text-t-2'>
        <path id='path3' fill='none' stroke='none' d='M 15 115 L 155 115 L 155 255 L 15 255 Z' />
        <path
          id='path4'
          fill='none'
          stroke='currentcolor'
          strokeWidth='13.884636'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M 85 145.625 L 85 224.375'
        />
        <path
          id='path5'
          fill='none'
          stroke='currentcolor'
          strokeWidth='10.75'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M 45.625 163.125 L 45.625 145.625 L 124.375 145.625 L 124.375 163.125'
        />
        <path
          id='path6'
          fill='none'
          stroke='currentcolor'
          strokeWidth='13.884636'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M 67.5 224.375 L 102.5 224.375'
        />
      </g>
    </>,
  ],
]);

const FileFont: Icon = forwardRef((props, ref) => <IconBase ref={ref} {...props} weights={weights} />);

FileFont.displayName = 'FileFont';

export default FileFont;
