import classNames from 'classnames';
import React, { ForwardedRef, useEffect, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { INPUT_CLASSNAMES } from 'helpers/CssClasses';

export interface SelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectOption[] | string[];
  placeholderOption?: string;
  className?: string;
}

/**
 * Default select element with the correct styles
 *
 * We can use this <Select/> component inside the Forms-Hooks as it is 100% compatible with the hooks
 */
const Select = React.forwardRef(function Select(
  { options, placeholderOption, className, ...rest }: Props,
  ref: ForwardedRef<HTMLSelectElement>,
): JSX.Element {
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    // check if the array has an Option[] interface
    // If not, it has a string[] interface, and we should convert it to an option[] interface
    if (options.length > 0 && typeof options[0] === 'string') {
      setSelectOptions(options.map(value => ({ label: value, value }) as SelectOption));
    } else {
      setSelectOptions(options as SelectOption[]);
    }
  }, [options]);

  return (
    <div className={classNames('relative inline-block w-full', className)}>
      <select className={classNames('w-full appearance-none focus:border-0 pr-6', INPUT_CLASSNAMES)} {...rest} ref={ref}>
        {placeholderOption && (
          <option value='' key='placeholder' className='text-gray-500'>
            {placeholderOption}
          </option>
        )}
        {selectOptions.map(option => (
          <option disabled={option.disabled} value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className='absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none'>
        <svg className='w-4 h-4 fill-current' viewBox='0 0 20 20'>
          <path
            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
            clipRule='evenodd'
            fillRule='evenodd'
          />
        </svg>
      </div>
    </div>
  );
});

interface SelectControlledProps<T extends object = Record<string, unknown>> extends UseControllerProps<T> {
  options: SelectOption[] | string[];
  placeholderOption?: string;
  className?: string;
  id?: string;
  disabled?: boolean;
}

/**
 * This is the controlled Select that can set the default states etc.
 *
 * See https://gitlab.coblue.eu/storro/app.storro/-/issues/248 for detailled info
 */
function SelectControlled<T extends object = Record<string, unknown>>({
  options,
  placeholderOption,
  className,
  id,
  disabled,
  ...props
}: SelectControlledProps<T>): JSX.Element {
  const { field } = useController(props);

  return <Select {...field} id={id} disabled={disabled} className={className} options={options} placeholderOption={placeholderOption} />;
}

export default Select;
export { SelectControlled };
