import debounce from 'debounce';
import { useEffect, useMemo, useState } from 'react';

interface WindowSize {
  width: number;
  height: number;
}

/**
 * Custom hook to get the window screen size
 */
export default function useScreenSize(delay = 200): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  /**
   * use a debounce function to avoid a sluggy UI
   */
  const handleSize = useMemo(
    () =>
      debounce(() => {
        setWindowSize(prevState => {
          // avoid rerenders by setting only the state when something has changed
          // otherwise we just return the current state
          if (prevState.height !== window.innerHeight || prevState.width !== window.innerWidth) {
            return {
              width: window.innerWidth,
              height: window.innerHeight,
            };
          }

          return prevState;
        });
      }, delay),
    [delay],
  );

  /**
   * Listen to the resize on the window object
   */
  useEffect(() => {
    window.addEventListener('resize', handleSize);
    handleSize();
    return () => window.removeEventListener('resize', handleSize);
  }, [handleSize]);

  return windowSize;
}
