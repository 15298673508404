import classNames from 'classnames';
import React, { memo, useMemo } from 'react';
import useMenu from '../../hooks/UseMenu';
import MobileMenuItem from './MobileMenuItem';
import { usePageActionContext } from 'context/PageActionContext';
import DropdownMenu from 'components/DropdownMenu';
import { DotsThree } from '@phosphor-icons/react';
import usePageButtons from 'hooks/UsePageButtons';

interface Props {
  // its a bit odd name, but we cannot just use ref as that
  // name is reserved for React self
  mobileMenuRef: React.LegacyRef<HTMLElement> | undefined;
}

function MobileMenu({ mobileMenuRef }: Props): JSX.Element {
  const { mobileMenuItems } = useMenu();
  const { actions, selectionActions, selectionCount } = usePageActionContext();
  const parsedSelectionElement = usePageButtons(0, selectionActions);

  /**
   * Parse the menu items and actions to determine the left and right side of the mobile menu
   * and the mobile actions
   */
  const menuItems = useMemo(() => {
    if (!actions) return { left: mobileMenuItems, right: [], mobileActions: [] };

    // check if there are mobile actions
    const mobileActions = actions.filter(action => action.includeInMobileAction);

    if (mobileActions.length > 0) {
      // determine where we should split the menu items so we could place the plus icon in the middle
      const split = mobileMenuItems.length % 2 === 0 && actions.length > 0 && mobileActions.length > 0;
      const menuItemsLeft = split ? mobileMenuItems.slice(0, mobileMenuItems.length / 2) : mobileMenuItems;
      const menuItemsRight = split ? mobileMenuItems.slice(mobileMenuItems.length / 2) : [];

      return { left: menuItemsLeft, right: menuItemsRight, mobileActions };
    }

    return { left: mobileMenuItems, right: [], mobileActions };
  }, [actions, mobileMenuItems]);

  return (
    <nav
      ref={mobileMenuRef}
      className={classNames(
        'fixed bottom-0 left-0 z-50 w-full border-t border-gray-200 bg-gray-100 pt-2',
        // env() intial value is not working with safe-area-inset-bottom as it return 0
        // therefor, we can use max to determing an initial value when safe-area-inset-bottom=0
        // by using max()
        'pb-[max(env(safe-area-inset-bottom),10px)]',
      )}
    >
      {/* Show the selection element */}
      {(parsedSelectionElement.items.length > 0 || parsedSelectionElement.dropdownItems.length > 0) && (
        <div className='flex items-center justify-between px-5 gap-x-1 h-12'>
          {/* We should have a select/deselect all button here See https://gitlab.coblue.eu/storro/app.storro/-/issues/663 */}
          <p className='text-gray-700 font-semibold'>{selectionCount} items selected</p>

          {/* Render the dropdown menu */}
          {parsedSelectionElement.dropdownItems.length > 0 && parsedSelectionElement.dropdownItems[0].length > 0 && (
            <DropdownMenu menuItems={parsedSelectionElement.dropdownItems}>
              <button className='text-primary'>
                <DotsThree size={35} />
              </button>
            </DropdownMenu>
          )}
        </div>
      )}

      {/* Our default mobule menu */}
      {!selectionActions && (
        <div className={classNames('flex justify-evenly items-center font-medium h-12')}>
          {menuItems.left.map(item => (
            <MobileMenuItem key={item.path} menuItem={item} />
          ))}

          {menuItems.mobileActions.length > 0 && <MobileMenuItem key='plus' actions={menuItems.mobileActions} />}

          {menuItems.right.map(item => (
            <MobileMenuItem key={item.path} menuItem={item} />
          ))}
        </div>
      )}
    </nav>
  );
}

export default memo(MobileMenu);
