// Copyright 2021 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// concat() concatenates two Uint8Arrays.
//
export function concat(inputs: Uint8Array[]): Uint8Array {
  // Shortcut the trivial case.
  if (inputs.length === 1) return inputs[0];

  // Calculate the total size of the inputs in bytes.
  let totalLength = 0;
  for (let i = 0; i < inputs.length; i++) {
    totalLength += inputs[i].length;
  }
  // Create a result large enough.
  const result = new Uint8Array(totalLength);
  // Copy each of the inputs in.
  let offset = 0;
  for (let i = 0; i < inputs.length; i++) {
    result.set(inputs[i], offset);
    offset += inputs[i].length;
  }
  return result;
}
