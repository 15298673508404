import { decode, encode } from '@stablelib/utf8';
import base64url from 'base64url';
import { HostedPage } from 'chargebee-typescript/lib/resources';
import sodium, { crypto_aead_xchacha20poly1305_ietf_keygen, randombytes_buf } from 'libsodium-wrappers';
import { EcasKey } from 'util/Serialization/EcasKey';
import { OperationSystem } from 'util/Util/DetectOperationSystem';
import {
  ActivityLog,
  AddressBookRecord,
  AuthenticatedQuickShareDetails,
  BinaryDownload,
  CanDeleteUserResponse,
  ClientPortalTheme,
  DeploymentStatus,
  DeviceType,
  ProjectDetails,
  ProjectMember,
  ProjectMembersRequest,
  ProjectPermissions,
  QuickShareAccessRecord,
  QuickShareContentWithRefreshToken,
  QuickShareDetails,
  QuickShareEntryDetails,
  QuickShareEntryInfo,
  QuickShareMemberDetails,
  Realm,
  RealmBrandingRequest,
  RealmClientPortalRequest,
  RealmPolicyRequest,
  RealmQuickShareRequest,
  RealmStats,
  RealmStatus,
  RealmUser,
  User,
  UserCreateResponse,
  UserDevice,
  UserSession,
} from '../types';
import { InvoiceResponse, PricingModel, RealmPlan } from '../types/Payment';
import { Hash } from '../util/Cryptography/Hash';
import { SymmetricKey } from '../util/Cryptography/SymmetricKey';
import Downloader, { DownloadCallback } from '../util/Entry/Downloader';
import { logger } from '../util/Logger';
import { StoreType } from '../util/Serialization/ApiStore';
import { ApiJwtGenerator, UserDeviceApiJwtGenerator } from '../util/Serialization/ApiStoreAuthorization';
import { Ecas } from '../util/Serialization/Ecas';
import { EcasValue } from '../util/Serialization/EcasValue';
import { StreamKey } from '../util/Serialization/StreamKey';
import { concat } from '../util/Util/Concat';
import { envVar, getEnvUrls } from '../util/Util/EnvVar';
import { isEmail } from '../util/Validators';
import { DerivedKey } from './DerivedKey';
import { DiffSummaryJson, fromJsonDiffSummary, Summary } from './Diff';
import { Dir, EncryptedListingEntry, Entry, EntryType } from './Entry';
import { Events } from './Events';
import { JsonWebToken } from './JsonWebToken';
import { LoginError, LoginExceptionType, LoginTotpType } from './LoginError';
import { PersistentWebSocket } from './PersistentWebSocket';
import { PrivateKey } from './PrivateKey';
import { Project, ProjectList, ProjectVersion } from './Project';
import { PublicKey } from './PublicKey';
import { QuickShareList } from './QuickShare';
import { QuickShareError, QuickShareExceptionType } from './QuickShareError';
import { QuickShareUrlHash } from './QuickShareUrlHash';
import { Salt } from './Salt';
import { PasswordLogin, StoredUserDevice, UserDeviceStorage } from './UserDeviceStorage';
import { WebSocketEvents } from './WebSocketEvents';
import { formatUrl } from 'util/FormatUrl';

interface Keys {
  // The user public private key pair.
  userPrivateKey: PrivateKey;
  userPublicKey: PublicKey;

  // The web device public private key pair. The web device used as author for
  // changes from 'storro for web'.
  webDevicePrivateKey: PrivateKey;
  webDevicePublicKey: PublicKey;
}

interface UserAccount {
  keys: Keys;
  // TODO rename Profile -> Account
  profile: User;
}

// A logged out account. We store this in the local storage.
export interface EncryptedSession {
  keys: {
    userPublicKey: string;
    encryptedUserPrivKey: string;
    encryptedDevPrivKey: string;
  };
  // TODO rename Profile -> Account
  profile: User;
}

export interface PreviousLogins {
  sessions: EncryptedSession[]; // A list of all previous logins
  last: number; // index of the most recent login (-1 when unknown)
}

// A logged in account. We store this in the session storage.
interface Session {
  keys: {
    userPublicKey: string;
    webDevicePrivateKey: string; // base 64 encode plaintext web private key
    encryptedUserPrivKey: string; // encrypted user private key with web priv key
  };
  // TODO rename Profile -> Account
  profile: User;

  // A client side boolean indicating that the user is in super admin mode.
  superAdminMode?: boolean;

  host: {
    apiUrl: string;
    wsUrl: string;
  };
}

// Represents a realm in PreRegistrationInfo
export interface PreRegistrationInfoRealm {
  id: number;
  name: string;
  isGuest: boolean;
  isAdmin: boolean;
}

// Information that can be requested with the registration public key.
export interface PreRegistrationInfo {
  email: string;
  firstName: string;
  lastName: string;
  realms: PreRegistrationInfoRealm[];
}

interface LoginRequest {
  email?: string;
  user_public_key?: string;
  device_signature?: string;
  hashed_password?: string;
  hashed_recovery_password?: string;
  totp?: string;
  device_version: string;
  device_os: string;
}

export interface TwoFAInfo {
  enabled: boolean;
  shared_secret: string;
  shared_secret_url: string;
}

export interface AboutInfo {
  version: string;
  storro_git_hash: string;
  zooid_git_hash: string;
  compile_timestamp: string;
}

// A class that represents the interface with the backend api
export default class Storro {
  private sodiumInit = sodium.ready;
  private userAccount: UserAccount | undefined = undefined;
  private persistentWebSocket: PersistentWebSocket | undefined;
  private _webSocketEvents = new WebSocketEvents();
  private jwt: ApiJwtGenerator | undefined;
  private _baseURL: string;
  private _events = new Events(); // Some custom global events that cannot live in the context API
  private _projectList = new ProjectList(this);
  private _quickShareList = new QuickShareList(this);
  private _requirePasswordChange = false;
  private _superAdminMode = false;
  private _downloader: Downloader | undefined;

  private projectUpdatedHandlers: { (projectId: string): void }[] = [];
  private projectDeletedHandlers: { (projectIds: string[]): void }[] = [];
  private quickShareUpdatedHandlers: { (quickShareId: number): void }[] = [];

  private _userDeviceStorage = new UserDeviceStorage();
  private _binariesHost = envVar('BINARIES_HOST') ?? 'https://binaries.storro.com';

  get userDeviceStorage(): UserDeviceStorage {
    return this._userDeviceStorage;
  }

  get baseURL(): string {
    return this._baseURL;
  }

  get events(): Events {
    return this._events;
  }

  get projectList(): ProjectList {
    return this._projectList;
  }

  get quickShareList(): QuickShareList {
    return this._quickShareList;
  }

  get webSocketEvents(): WebSocketEvents {
    return this._webSocketEvents;
  }

  get jwtGenerator(): ApiJwtGenerator | undefined {
    return this.jwt;
  }

  get account(): User | undefined {
    return this.userAccount?.profile;
  }

  get superAdminMode(): boolean {
    return this._superAdminMode;
  }

  get keys(): Keys | undefined {
    return this.userAccount?.keys;
  }

  get isLoggedIn(): boolean {
    return this.userAccount !== undefined;
  }

  // True when the logged in user requires a password change. This can be true
  // when the user e.g. logs in with the recovery password.
  get requirePasswordChange(): boolean {
    return this._requirePasswordChange;
  }

  /**
   * This object should only live once for each application.
   *
   * @param apiUrl The url of the backend (i.e. https://api.storro.com)
   * @param wsUrl The websocket url of the backend (i.e. ws://api.storro.com)
   */
  constructor(
    apiUrl: string,
    private wsUrl: string,
  ) {
    // Check if we're logged in. Get the Session object from the session storage.
    const fromSession: string | null = sessionStorage.getItem('current_user');
    const session = fromSession ? (JSON.parse(fromSession) as Session) : null;

    // first we need to set the API Host from the current session
    if (session) {
      // for older sessions this could be undefined
      if (session.host) {
        // set the host, mostly for debugging by one of the Storro employees or testers
        this.setApiHost(session.host.apiUrl, session.host.wsUrl);
      }
    } else {
      this.setApiHost(apiUrl, wsUrl);
    }

    // Connect the callbacks to the WebSocketEvent class.
    const event = (projectId: string) => this.projectUpdatedEvent(projectId);
    this.webSocketEvents.addListener('project', event);
    const rmEvent = (projectId: string) => this.projectDeletedEvent([projectId]);
    this.webSocketEvents.addListener('projectRemoved', rmEvent);
    const qsUpdateEvent = (quickShareId: number) => this.quickShareUpdatedEvent(quickShareId);
    this.webSocketEvents.addListener('quickShareUpdated', qsUpdateEvent);
    this.webSocketEvents.addListener('quickShareClosed', qsUpdateEvent);
    this.webSocketEvents.addListener('quickShareReopened', qsUpdateEvent);

    // Load the data from the session
    if (session) {
      const webDevicePrivateKey: PrivateKey = new PrivateKey(base64url.toBuffer(session.keys.webDevicePrivateKey));
      const userPrivateKey = new PrivateKey(webDevicePrivateKey.decrypt(base64url.toBuffer(session.keys.encryptedUserPrivKey)));
      const userPublicKey = PublicKey.fromBase64(session.keys.userPublicKey);
      const webDevicePublicKey: PublicKey = webDevicePrivateKey.publicKey();
      this.userAccount = {
        keys: {
          userPrivateKey,
          userPublicKey,
          webDevicePrivateKey,
          webDevicePublicKey,
        },
        // TODO rename Profile -> Account
        profile: session.profile,
      };

      if (session.superAdminMode && session.superAdminMode === true) {
        this._superAdminMode = true;
      }

      logger.debug('Loading user from session storage session', session.profile);

      // Clear all previously cached project.
      this.projectList.clear();

      // set the token, this is needed to make calls to api.storro
      this.jwt = new UserDeviceApiJwtGenerator(webDevicePrivateKey);

      this.persistentWebSocket = new PersistentWebSocket(this.wsUrl, this.jwt, this.webSocketEvents);
      this.persistentWebSocket.connectWebSocket();
    } else {
      this._superAdminMode = false;
    }

    // construct the downloader
    // this downloader class is responsible for fire-up the service-worker, keep it alive
    // and handle downloads (streams) via the service-worker
    try {
      this._downloader = new Downloader();
    } catch (error) {
      logger.error(error instanceof Error ? error.message : 'Unknown error while constructing the Downloader class');
    }
  }

  /**
   * Update our API Host
   */
  public setApiHost(apiUrl: string, wsUrl: string): void {
    // if we have a persistentWebSocket instance, then we are connected (or
    // trying to reconnect) and we should fail to update the API Host.
    if (this.persistentWebSocket !== undefined) {
      return logger.error(`Unable to update the API Host url. Already connected to ${this._baseURL}`);
    }

    // avoid double slashes
    this._baseURL = formatUrl(apiUrl);
    this.wsUrl = wsUrl;
    logger.debug('Using %s as URL for the API server and %s as WS server', this.baseURL, this.wsUrl);
  }

  /**
   * Method that will called when a project is updated via the websocket for example
   */
  public async projectUpdatedEvent(projectId: string): Promise<void> {
    await this._projectList.onProjectUpdated(projectId);
    this.projectUpdatedHandlers.slice(0).forEach(h => h(projectId));
  }

  /**
   * Subscribe method, where the caller can subscribe and invoke logic when the
   * project has been updated
   */
  public subscribeOnProjectUpdated(handler: { (projectId: string): void }): void {
    this.projectUpdatedHandlers.push(handler);
  }

  /**
   * unsubscribe method, where the caller can unsubscribe from the projectUpdated events
   */
  public unsubscribeOnProjectUpdated(handler: { (projectId: string): void }): void {
    this.projectUpdatedHandlers = this.projectUpdatedHandlers.filter(h => h !== handler);
  }

  /**
   * Method that will called when a project is deleted via the websocket for example
   */
  public async projectDeletedEvent(projectIds: string[]): Promise<void> {
    for (const projectId of projectIds) {
      await this._projectList.onProjectRemoved(projectId);
    }

    this.projectDeletedHandlers.slice(0).forEach(h => h(projectIds));
  }

  /**
   * Subscribe method, where the caller can subscribe and invoke logic when the
   * project has been deleted
   */
  public subscribeOnProjectDeleted(handler: { (projectIds: string[]): void }): void {
    this.projectDeletedHandlers.push(handler);
  }

  /**
   * unsubscribe method, where the caller can unsubscribe from the projectDeletedEvent events
   */
  public unsubscribeOnProjectDeleted(handler: { (projectIds: string[]): void }): void {
    this.projectDeletedHandlers = this.projectDeletedHandlers.filter(h => h !== handler);
  }

  public async quickShareUpdatedEvent(quickShareId: number): Promise<void> {
    await this._quickShareList.onQuickShareUpdated(quickShareId);
    this.quickShareUpdatedHandlers.forEach(h => h(quickShareId));
  }

  public subscribeOnQuickShareUpdated(handler: { (quickShareId: number): void }): void {
    this.quickShareUpdatedHandlers.push(handler);
  }

  public unsubscribeOnQuickShareUpdated(handler: { (quickShareId: number): void }): void {
    this.quickShareUpdatedHandlers = this.quickShareUpdatedHandlers.filter(h => h !== handler);
  }

  public logout(): void {
    sessionStorage.removeItem('current_user');
    localStorage.removeItem('profile');
    this.projectList.clear();
    this.quickShareList.clear();
    this.userAccount = undefined;
    this._superAdminMode = false;
    this._requirePasswordChange = false;
    if (this.persistentWebSocket) {
      this.persistentWebSocket.disconnect();
      this.persistentWebSocket = undefined;
    }
    this.jwt = undefined;
    if (this._downloader) {
      this._downloader.clearCache();
    }
  }

  /**
   * Wrapper arround the Fetch API for a secure connection to the API
   * with the same signature as the native Fetch function
   *
   * This wrapper adds a check if the user is logged in and add a default
   * header for the Bearer token
   */
  public async secureFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
    // If there is not JWT token found, we should logout the user
    // and navigate back to the entry point of the App.
    if (!this.jwt) {
      this.logout();
      window.location.href = '/';

      // The return below will never be reached as the href assignment will trigger
      // the browser to redirect to '/' first.
      // But to keep our TS compiler happy, we return an empty Response() to keep the
      // signature the same as the Fetch() function.
      return new Response();
    }

    // define the default headers for our Bearer token
    const defaultHeaders: HeadersInit = {
      Authorization: `Bearer ${this.jwt.jwt().toString()}`,
    };

    // Merge the default header with the given ones
    if (init) {
      init.headers = { ...(init.headers ?? {}), ...defaultHeaders };
    } else {
      init = {
        headers: defaultHeaders,
      };
    }

    // make the actual Fetch call
    const response = await fetch(input, init);

    // When a forbidden status received we logout the user
    // and redirect to the '/' page which is the entry point of the App
    if (response.status === 401) {
      this.logout();
      window.location.href = '/';

      // The return below will never be reached as the href assignment will trigger
      // the browser to redirect to '/' first.
      // But to keep our TS compiler happy, we return an empty Response() to keep the
      // signature the same as the Fetch() function.
      return new Response();
    }

    return response;
  }

  /**
   * Fetch the user account from the api. This also sends some updated device
   * info about our login. This can only be done when logged in.
   * @return Returns the encrypted recovery password (which can be decrypted with the user private key).
   */
  public async fetchUserAccount(signal?: AbortSignal): Promise<Uint8Array> {
    if (!this.userAccount || !this.keys) {
      return Promise.reject(Error('Not logged in'));
    }

    try {
      const url = formatUrl(`${this.baseURL}/user/profile`);
      const response = await this.secureFetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        signal,
      });

      // if the Status code is outside the range of 200-299
      if (!response.ok) {
        throw Error(await response.text());
      }

      // get the user session data from the response
      const userSession = (await response.json()) as UserSession;

      const devPrivKey: PrivateKey = this.userAccount.keys.webDevicePrivateKey;

      const storedUserDevice: StoredUserDevice | undefined = this.userDeviceStorage.find(this.userAccount.keys.userPublicKey);

      if (!storedUserDevice) {
        throw Error('Stored user device is not found');
      }

      // Update the master password login. The password could have been changed
      // from another device.
      storedUserDevice.masterPasswordLogin = {
        encryptedDevicePrivateKey: base64url.encode(
          Buffer.from(this.userAccount.keys.userPrivateKey.encrypt(devPrivKey.key(), this.userAccount.keys.userPrivateKey.publicKey())),
        ),
        encryptedUserPrivateKey: userSession.encryptedPrivateKey,
        passwordHashingAlgo: 'blake2b', // outdated, should be updated
        keyDerivationAlgo: 'xchacha', // symmetric key algo
      };

      // Update the fields, they might have been changed from another device.
      storedUserDevice.firstName = userSession.firstName;
      storedUserDevice.lastName = userSession.lastName;
      storedUserDevice.email = userSession.email;
      storedUserDevice.lang = userSession.lang;
      storedUserDevice.version = 2;
      storedUserDevice.userPublicKey = userSession.publicKey;

      // The password might have been changed. Just update the local storage with
      // the fresh info from the server.
      this.userDeviceStorage.update(storedUserDevice);

      // Update the user session
      const session: Session = {
        keys: {
          userPublicKey: this.keys.userPublicKey.toBase64(),
          webDevicePrivateKey: this.keys.webDevicePrivateKey.toBase64(),
          encryptedUserPrivKey: base64url.encode(Buffer.from(this.keys.webDevicePrivateKey.encrypt(this.keys.userPrivateKey.key()))),
        },
        // TODO rename Profile -> Account
        profile: {
          id: userSession.id,
          firstName: userSession.firstName,
          lastName: userSession.lastName,
          email: userSession.email,
          totpAuthenticatorEnabled: userSession.totpAuthenticatorEnabled,
          lang: userSession.lang,
          company: userSession.company,
          isRegistered: userSession.isRegistered,
          isSuperAdmin: userSession.isSuperAdmin,
        },
        superAdminMode: this._superAdminMode ?? undefined,
        host: {
          apiUrl: this.baseURL,
          wsUrl: this.wsUrl,
        },
      };

      // only update the session if we got one
      if (sessionStorage.getItem('current_user') !== null) {
        sessionStorage.setItem('current_user', JSON.stringify(session));
      }

      // Update personal info in the userAccount object.
      this.userAccount.profile = session.profile;

      return new Uint8Array(base64url.toBuffer(userSession.encryptedRecoveryPassword));
    } catch (e) {
      const error = e instanceof Error ? e.message : 'unknown error';
      return Promise.reject(error);
    }
  }

  public setSuperAdminMode(enable: boolean): void {
    // Check if we're logged in. Get the Session object from the session storage.
    const fromSession = sessionStorage.getItem('current_user');
    if (fromSession === null) {
      throw Error('User is not logged in');
    }

    // Deserialize the session.
    const session = JSON.parse(fromSession) as Session;

    // Set the super admin flag.
    session.superAdminMode = enable ?? undefined;

    // Serialize and store the session.
    sessionStorage.setItem('current_user', JSON.stringify(session));

    this._superAdminMode = enable;

    // Clear all previously cached project.
    this.projectList.clear();
  }

  private saltedPasswordHash(password: string) {
    // We use a static client side salt for our password to prevent generic
    // rainbow table attacks.
    const salt: Uint8Array = base64url.toBuffer('hYxcaGxf8XOWuM9QYCsr4g_P5sbhj0xhf4i7sZttUYQ');
    return base64url.encode(Buffer.from(Hash.blake2bSync(concat([encode(password), salt]))));
  }

  /**
   * Check if the given password match with the current session
   */
  public async passwordCheck(currentPassword: string): Promise<void> {
    if (!this.keys) {
      throw Error('User is not logged in');
    }

    const salt = new Salt(this.keys.userPublicKey);
    const derivedKey = await DerivedKey.fromPasswordWithSalt(currentPassword, salt);
    // Find the user device based on our user public key.
    const userDevice = this.userDeviceStorage.find(this.keys?.userPublicKey);
    if (!userDevice) {
      throw Error('Encrypted private key not found. Could not verify current password');
    }
    try {
      derivedKey.decrypt(base64url.toBuffer(userDevice.masterPasswordLogin.encryptedUserPrivateKey));
    } catch (e) {
      throw Error('Current password is invalid');
    }
  }

  /**
   * A fresh login, we will create and register a new device.
   * The given password can be the user password or the recovery password.
   */
  public async unknownLogin(email: string, password: string, isRecovery: boolean, totp?: string): Promise<void> {
    if (!isEmail(email)) {
      throw new LoginError('Invalid email address');
    }

    const req: LoginRequest = {
      email,
      totp,
      device_version: '1.0.0',
      device_os: navigator.userAgent.toString(),
    };
    if (password.length === 0) {
      throw new LoginError('Empty password provided');
    }

    const pwHash = this.saltedPasswordHash(password);

    if (isRecovery) {
      req.hashed_recovery_password = pwHash;
    } else {
      req.hashed_password = pwHash;
    }

    const url = formatUrl(`${this.baseURL}/user/login`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });

    if (response.status === 401) {
      const totpRequiredHeader = response.headers.get('totp');
      if (totpRequiredHeader) {
        if (totpRequiredHeader === LoginTotpType.Email) {
          throw new LoginError(await response.text(), LoginTotpType.Email);
        } else if (totpRequiredHeader === LoginTotpType.Device) {
          throw new LoginError(await response.text(), LoginTotpType.Device);
        }
      }
      throw new LoginError(await response.text());
    } else if (!response.ok) {
      throw new LoginError(await response.text());
    }

    const userSession = (await response.json()) as UserSession;

    try {
      // Get the user public key from the response.
      const userPublicKey = PublicKey.fromBase64(userSession.publicKey);

      const salt = new Salt(userPublicKey);

      // Generate the DerivedKey from the given password/recovery and salt.
      const derivedKey = await DerivedKey.fromPasswordWithSalt(password, salt);

      // Decrypt the user private key using the (recovery) password.
      const userPrivateKey = derivedKey.decrypt(
        base64url.toBuffer(isRecovery ? userSession.encryptedPrivateKeyRecovery : userSession.encryptedPrivateKey),
      );

      const devicePrivateKey = PrivateKey.random();
      const userDevice = this.updateDevice(userPrivateKey, devicePrivateKey);

      // This api call doesn't require authentication. The signed user device
      // gives us enough signatures to verify. Authentication is not required
      // because the device that we're creating is unknown to the server thus
      // it's impossible to verify us.
      const url = formatUrl(`${this.baseURL}/user/device`);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userDevice, userPublicKey: userPublicKey.toBase64() }),
      });

      // if the Status code is outside the range of 200-299
      if (!response.ok) {
        throw Error(await response.text());
      }

      const storedUserDevice: StoredUserDevice = {
        firstName: userSession.firstName,
        lastName: userSession.lastName,
        email: userSession.email,
        lang: userSession.lang,
        version: 2,
        userPublicKey: userSession.publicKey,
        masterPasswordLogin: {
          encryptedDevicePrivateKey: base64url.encode(Buffer.from(userPrivateKey.encrypt(devicePrivateKey.key(), userPublicKey))),
          encryptedUserPrivateKey: userSession.encryptedPrivateKey,
          passwordHashingAlgo: 'blake2b', // outdated, should be updated
          keyDerivationAlgo: 'xchacha', // symmetric key algo
        },
      };

      // The password might have been changed. Just update the local storage with
      // the fresh info from the server.
      this.userDeviceStorage.add(storedUserDevice);

      const session: Session = {
        keys: {
          userPublicKey: userPublicKey.toBase64(),
          webDevicePrivateKey: devicePrivateKey.toBase64(),
          encryptedUserPrivKey: base64url.encode(Buffer.from(devicePrivateKey.encrypt(userPrivateKey.key()))),
        },
        // TODO rename Profile -> Account
        profile: {
          id: 0, // fake
          firstName: storedUserDevice.firstName,
          lastName: storedUserDevice.lastName,
          email: storedUserDevice.email,
          totpAuthenticatorEnabled: false, // fake
          lang: storedUserDevice.lang,
          isSuperAdmin: userSession.isSuperAdmin,
        },
        host: {
          apiUrl: this.baseURL,
          wsUrl: this.wsUrl,
        },
      };

      // only set the current_user if we are not in a recovery mode
      if (!isRecovery) {
        sessionStorage.setItem('current_user', JSON.stringify(session));
      }

      // mark that the user need to change his password in recovery mode
      if (isRecovery) {
        this._requirePasswordChange = true;
      }
      this._superAdminMode = false;

      this.userAccount = {
        keys: {
          userPrivateKey,
          userPublicKey,
          webDevicePrivateKey: devicePrivateKey,
          webDevicePublicKey: devicePrivateKey.publicKey(),
        },
        // TODO rename Profile -> Account
        profile: {
          id: 0, // fake
          firstName: storedUserDevice.firstName,
          lastName: storedUserDevice.lastName,
          email: storedUserDevice.email,
          totpAuthenticatorEnabled: false, // fake
          lang: storedUserDevice.lang,
          isSuperAdmin: userSession.isSuperAdmin,
        },
      };

      // Clear all previously cached project.
      this.projectList.clear();

      // set the token, this is needed to make calls to api.storro
      this.jwt = new UserDeviceApiJwtGenerator(devicePrivateKey);

      this.persistentWebSocket = await PersistentWebSocket.createConnected(this.wsUrl, this.jwt, this.webSocketEvents);
    } catch (e) {
      throw new LoginError((e as Error).message);
    }
  }

  /**
   * Returns true when a pin login has been set for the user
   */
  public hasPin(userPublicKey: PublicKey): boolean {
    // Find the user based on our user public key.
    const userDevice = this.userDeviceStorage.find(userPublicKey);
    if (!userDevice) {
      throw new LoginError('User is not found in local storage');
    }
    return userDevice.customPasswordLogin !== undefined;
  }

  /**
   * Remove a pin login from the account (if it's set)
   */
  public removePin(userPublicKey: PublicKey): void {
    // Find the user based on our user public key.
    const userDevice = this.userDeviceStorage.find(userPublicKey);
    if (!userDevice) {
      throw new LoginError('User is not found in local storage');
    }

    if (!userDevice.customPasswordLogin) {
      return;
    }

    userDevice.customPasswordLogin = undefined;
    this.userDeviceStorage.update(userDevice);
  }

  /**
   * Log into the current device with a pin. The pin should be set for this device by the user.
   */
  public async pinLogin(userPublicKey: PublicKey, pin: string): Promise<void> {
    if (pin.length === 0) {
      throw new LoginError('Empty password provided');
    }

    // Generate the salt from the user pub key.
    const salt = new Salt(userPublicKey);
    // Generate the DerivedKey from the given password and salt.
    const derivedKey: DerivedKey = await DerivedKey.fromPasswordWithSalt(pin, salt);

    // Find the encrypted session based on our user public key.
    const userDevice = this.userDeviceStorage.find(userPublicKey);
    if (!userDevice) {
      throw new LoginError('EncryptedSession not found in local storage');
    }

    if (!userDevice.customPasswordLogin) {
      throw new LoginError('Pin login not found for local device');
    }

    let userPrivateKey: PrivateKey | undefined = undefined;

    try {
      // Decrypt the user private key using the derived key.
      userPrivateKey = derivedKey.decrypt(base64url.toBuffer(userDevice.customPasswordLogin.encryptedUserPrivateKey));
    } catch (e) {
      throw new LoginError('Invalid pin');
    }

    // Decrypt the device private key.
    const devicePrivateKey: PrivateKey = new PrivateKey(
      userPrivateKey.decrypt(new Uint8Array(base64url.toBuffer(userDevice.customPasswordLogin.encryptedDevicePrivateKey)), userPublicKey),
    );

    // set the token, this is needed to make calls to api.storro
    this.jwt = new UserDeviceApiJwtGenerator(devicePrivateKey);
    this.persistentWebSocket = await PersistentWebSocket.createConnected(this.wsUrl, this.jwt, this.webSocketEvents);

    // get the user account
    // TODO rename API endpoint to /user/account
    const url = formatUrl(`${this.baseURL}/user/profile`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as User Session data out of the response
    const userSession = (await response.json()) as UserSession;

    const session: Session = {
      keys: {
        userPublicKey: userPublicKey.toBase64(),
        webDevicePrivateKey: devicePrivateKey.toBase64(),
        encryptedUserPrivKey: base64url.encode(Buffer.from(devicePrivateKey.encrypt(userPrivateKey.key()))),
      },
      // TODO rename Profile -> Account
      profile: {
        id: userSession.id,
        firstName: userSession.firstName,
        lastName: userSession.lastName,
        email: userSession.email,
        totpAuthenticatorEnabled: userSession.totpAuthenticatorEnabled,
        lang: userSession.lang,
        isSuperAdmin: userSession.isSuperAdmin,
      },
      host: {
        apiUrl: this.baseURL,
        wsUrl: this.wsUrl,
      },
    };

    sessionStorage.setItem('current_user', JSON.stringify(session));
    this._requirePasswordChange = false;

    this.userAccount = {
      keys: {
        userPrivateKey,
        userPublicKey,
        webDevicePrivateKey: devicePrivateKey,
        webDevicePublicKey: devicePrivateKey.publicKey(),
      },
      // TODO rename Profile -> Account
      profile: {
        id: userSession.id,
        firstName: userSession.firstName,
        lastName: userSession.lastName,
        email: userSession.email,
        totpAuthenticatorEnabled: userSession.totpAuthenticatorEnabled,
        lang: userSession.lang,
        isSuperAdmin: userSession.isSuperAdmin,
      },
    };

    this._superAdminMode = false;

    // Clear all previously cached project.
    this.projectList.clear();
  }

  /**
   * We've logged in from this browser/machine before. Login with the device
   * public/private key that is stored in our session storage.
   * The given password can be the user (recovery) password.
   */
  public async knownLogin(userPublicKey: PublicKey, password: string, isRecovery: boolean, totp?: string): Promise<void> {
    const req: LoginRequest = {
      device_version: '1.0.0',
      totp,
      device_os: navigator.userAgent.toString(),
      user_public_key: userPublicKey.toBase64(),
    };

    if (password.length === 0) {
      throw new LoginError('Empty password provided');
    }

    // Generate the salt from the user pub key.
    const salt = new Salt(userPublicKey);
    // Generate the DerivedKey from the given password and salt.
    const derivedKey: DerivedKey = await DerivedKey.fromPasswordWithSalt(password, salt);

    const pwHash = this.saltedPasswordHash(password);

    if (isRecovery) {
      req.hashed_recovery_password = pwHash;
    } else {
      req.hashed_password = pwHash;
    }

    // Find the encrypted session based on our user public key.
    const userDevice = this.userDeviceStorage.find(userPublicKey);
    if (!userDevice) {
      throw new LoginError('EncryptedSession not found in local storage');
    }

    const encryptedDevPrivKey = userDevice.masterPasswordLogin.encryptedDevicePrivateKey;

    if (!isRecovery) {
      try {
        // Decrypt the user private key using the recovery password.
        const userPrivateKey = derivedKey.decrypt(base64url.toBuffer(userDevice.masterPasswordLogin.encryptedUserPrivateKey));

        // Decrypt the web device pub priv key pair.
        const devicePrivateKey: PrivateKey = new PrivateKey(userPrivateKey.decrypt(base64url.toBuffer(encryptedDevPrivKey), userPublicKey));

        // Create a signature to let the server know we have the private key of the device.
        const jwtExpiration = new Date();
        jwtExpiration.setHours(jwtExpiration.getHours() + 1);
        const devJwt = JsonWebToken.generate(
          devicePrivateKey,
          { devicePublicKeyBase64Url: devicePrivateKey.publicKey().toBase64(), type: 'UserDevice' },
          jwtExpiration,
        );
        req.device_signature = devJwt.toString();
      } catch (error) {
        logger.warn('Could not decrypt private key with password');
      }
    }

    const url = formatUrl(`${this.baseURL}/user/login_known`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });

    if (response.status === 401) {
      const totpRequiredHeader = response.headers.get('totp');
      if (totpRequiredHeader) {
        if (totpRequiredHeader === LoginTotpType.Email) {
          throw new LoginError(await response.text(), LoginTotpType.Email);
        } else if (totpRequiredHeader === LoginTotpType.Device) {
          throw new LoginError(await response.text(), LoginTotpType.Device);
        }
      }

      // Check if the error type is of type DeviceNotfound
      const errorType = response.headers.get('x-exception-type');
      if (errorType === 'device-not-found') {
        throw new LoginError(await response.text(), LoginTotpType.None, LoginExceptionType.DeviceNotFound);
      } else if (errorType === 'json-webtoken-expired') {
        throw new LoginError(await response.text(), LoginTotpType.None, LoginExceptionType.JsonWebTokenExpired);
      }
      throw new LoginError(await response.text());
    } else if (!response.ok) {
      throw new LoginError(await response.text());
    }

    const userSession = (await response.json()) as UserSession;

    const storedUserDevice: StoredUserDevice | undefined = this.userDeviceStorage.find(userPublicKey);

    if (!storedUserDevice) {
      throw Error('Stored user device is not found');
    }

    // Update the master password login. The password could have been changed
    // from another device.
    storedUserDevice.masterPasswordLogin = {
      encryptedDevicePrivateKey: encryptedDevPrivKey,
      encryptedUserPrivateKey: userSession.encryptedPrivateKey,
      passwordHashingAlgo: 'blake2b', // outdated, should be updated
      keyDerivationAlgo: 'xchacha', // symmetric key algo
    };

    // Update the fields, they might have been changed from another device.
    storedUserDevice.firstName = userSession.firstName;
    storedUserDevice.lastName = userSession.lastName;
    storedUserDevice.email = userSession.email;
    storedUserDevice.lang = userSession.lang;
    storedUserDevice.version = 2;
    storedUserDevice.userPublicKey = userSession.publicKey;

    // The password might have been changed. Just update the local storage with
    // the fresh info from the server.
    this.userDeviceStorage.update(storedUserDevice);

    // Decrypt the user private key using the derived key.
    const userPrivateKey = derivedKey.decrypt(
      base64url.toBuffer(isRecovery ? userSession.encryptedPrivateKeyRecovery : userSession.encryptedPrivateKey),
    );

    // Decrypt the web device pub priv key pair.
    const devicePrivateKey: PrivateKey = new PrivateKey(
      userPrivateKey.decrypt(
        new Uint8Array(base64url.toBuffer(storedUserDevice.masterPasswordLogin.encryptedDevicePrivateKey)),
        userPublicKey,
      ),
    );

    const session: Session = {
      keys: {
        userPublicKey: userPublicKey.toBase64(),
        webDevicePrivateKey: devicePrivateKey.toBase64(),
        encryptedUserPrivKey: base64url.encode(Buffer.from(devicePrivateKey.encrypt(userPrivateKey.key()))),
      },
      // TODO rename Profile -> Account
      profile: {
        id: userSession.id,
        firstName: userSession.firstName,
        lastName: userSession.lastName,
        email: userSession.email,
        totpAuthenticatorEnabled: userSession.totpAuthenticatorEnabled,
        lang: userSession.lang,
        isSuperAdmin: userSession.isSuperAdmin,
      },
      host: {
        apiUrl: this.baseURL,
        wsUrl: this.wsUrl,
      },
    };

    // only set the current_user if we are not in a recovery mode
    if (!isRecovery) {
      sessionStorage.setItem('current_user', JSON.stringify(session));
    }

    // mark that the user need to change his password in recovery mode
    if (isRecovery) {
      this._requirePasswordChange = true;
    }

    this.userAccount = {
      keys: {
        userPrivateKey,
        userPublicKey,
        webDevicePrivateKey: devicePrivateKey,
        webDevicePublicKey: devicePrivateKey.publicKey(),
      },
      // TODO rename Profile -> Account
      profile: {
        id: userSession.id,
        firstName: userSession.firstName,
        lastName: userSession.lastName,
        email: userSession.email,
        totpAuthenticatorEnabled: userSession.totpAuthenticatorEnabled,
        lang: userSession.lang,
        isSuperAdmin: userSession.isSuperAdmin,
      },
    };

    this._superAdminMode = false;

    // Clear all previously cached project.
    this.projectList.clear();

    // set the token, this is needed to make calls to api.storro
    this.jwt = new UserDeviceApiJwtGenerator(devicePrivateKey);
    this.persistentWebSocket = await PersistentWebSocket.createConnected(this.wsUrl, this.jwt, this.webSocketEvents);
  }

  /*
   * Create a new user and realm (company). An email will be sent for validation.
   */
  public async signup(firstName: string, lastName: string, email: string, company: string, lang: string): Promise<void> {
    const url = formatUrl(`${this.baseURL}/user/signup`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email,
        company,
        lang,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Get some basic account info based on your registration public key. This can
   * be used to show some information on the 'activate account' page.
   * @param registrationPublicKey The public key of the registration private key that is sent by email.
   */
  public async getPreRegistrationInfo(registrationPublicKey: PublicKey): Promise<PreRegistrationInfo> {
    const url = formatUrl(`${this.baseURL}/user/register?registration_public_key=${registrationPublicKey.toBase64()}`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as PreRegistrationInfo;
  }

  // Create our web 'device'. For web logins.
  private updateDevice(userPrivKey: PrivateKey, devicePrivKey: PrivateKey): UserDevice {
    const devKeyUserSignature = userPrivKey.sign(devicePrivKey.publicKey().key());
    return {
      name: 'Web',
      peerKey: devicePrivKey.publicKey().toBase64(),
      userSignature: base64url.encode(Buffer.from(devKeyUserSignature)),
      publicKeySignature: base64url.encode(Buffer.from(devicePrivKey.sign(devKeyUserSignature))),
      operatingSystem: navigator.userAgent.toString(),
      version: '1.0.0',
      lastSeen: new Date().toString(),
      type: DeviceType.Web,
    };
  }

  /**
   * Register a user with a new password and create a new encrypted session in the local storage.
   * This method will return the user public key and the recovery password.
   */
  public async register(registrationPrivateKey: PrivateKey, password: string): Promise<[PublicKey, string]> {
    await this.sodiumInit;
    const hashedPassword = this.saltedPasswordHash(password);

    const userPrivateKey = PrivateKey.random();
    const salt = new Salt(userPrivateKey.publicKey());

    // Generate a random 16 byte hex recovery password.
    const recoveryPassword: string = randombytes_buf(16, 'hex');

    // Encrypt the recovery key password with our private key. We send this to
    // the api so we can decrypt it later to show the recovery password on our
    // account page.
    const encryptedRecoveryPwd = userPrivateKey.encrypt(encode(recoveryPassword));

    const hashedRecoveryPassword = this.saltedPasswordHash(recoveryPassword);
    const devicePrivKey = PrivateKey.random();

    // Do the post request for registering the user.
    const pkey = DerivedKey.fromPasswordWithSalt(password, salt);
    const rkey = DerivedKey.fromPasswordWithSalt(recoveryPassword, salt);

    const url = formatUrl(`${this.baseURL}/user/register`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        registration_private_key: registrationPrivateKey.toBase64(),
        encrypted_private_key: base64url.encode(Buffer.from((await pkey).encrypt(userPrivateKey))),
        public_key: userPrivateKey.publicKey().toBase64(),
        device: this.updateDevice(userPrivateKey, devicePrivKey),
        hashed_password: hashedPassword,
        hashed_recovery_password: hashedRecoveryPassword,
        encrypted_private_key_recovery: base64url.encode(Buffer.from((await rkey).encrypt(userPrivateKey))),
        encrypted_recovery_password: base64url.encode(Buffer.from(encryptedRecoveryPwd)),
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the User Session data out of the response
    const userSession = (await response.json()) as UserSession;

    const storedUserDevice: StoredUserDevice = {
      firstName: userSession.firstName,
      lastName: userSession.lastName,
      email: userSession.email,
      lang: userSession.lang,
      version: 2,
      userPublicKey: userSession.publicKey,
      masterPasswordLogin: {
        encryptedDevicePrivateKey: base64url.encode(Buffer.from(userPrivateKey.encrypt(devicePrivKey.key(), userPrivateKey.publicKey()))),
        encryptedUserPrivateKey: userSession.encryptedPrivateKey,
        passwordHashingAlgo: 'blake2b', // outdated, should be updated
        keyDerivationAlgo: 'xchacha', // symmetric key algo
      },
    };

    // The password might have been changed. Just update the local storage with
    // the fresh info from the server.
    this.userDeviceStorage.add(storedUserDevice);

    return [userPrivateKey.publicKey(), recoveryPassword];
  }

  /**
   * Set a pin login for this device
   */
  public async setPinLogin(newPin: string): Promise<void> {
    if (!this.keys) {
      throw Error('Not logged in');
    }
    const storedUserDev = this.userDeviceStorage.find(this.keys.userPublicKey);

    if (!storedUserDev) {
      throw Error('Logged in user not found in UserDeviceStorage');
    }

    const salt = new Salt(this.keys.userPublicKey);
    const pkey = await DerivedKey.fromPasswordWithSalt(newPin, salt);
    const encryptedUserPrivateKeyBase64 = base64url.encode(Buffer.from(pkey.encrypt(this.keys.userPrivateKey)));

    const encryptedDevicePrivateKeyBase64 = base64url.encode(
      Buffer.from(this.keys.userPrivateKey.encrypt(this.keys.webDevicePrivateKey.key(), this.keys.userPublicKey)),
    );

    const customPwdLogin: PasswordLogin = {
      encryptedDevicePrivateKey: encryptedDevicePrivateKeyBase64,
      encryptedUserPrivateKey: encryptedUserPrivateKeyBase64,
      passwordHashingAlgo: 'none', // no need to hash the password for pin login
      keyDerivationAlgo: 'xchacha', // symmetric key algo
    };

    storedUserDev.customPasswordLogin = customPwdLogin;
    this.userDeviceStorage.update(storedUserDev);
  }

  /**
   * Promote a guest user to a registerd user
   */
  public async promoteUser(realmId: number, userPublicKey: string): Promise<void> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/user/${userPublicKey}/promote`);
    const response = await this.secureFetch(url, {
      method: 'PUT',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * An admin that is scheduled for admin demotion gets an email to accept or
   * reject it. This method is used to communicate this reply to the api.
   */
  public async replyDemote(jwt: JsonWebToken): Promise<void> {
    const url = formatUrl(`${this.baseURL}/user/reply_admin_demote`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jwt: jwt.toString(),
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Update the user account of the logged in user.
   */
  public async updateUserAccount(firstName: string, lastName: string, company: string, lang: string): Promise<void> {
    const url = formatUrl(`${this.baseURL}/user`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        company,
        lang,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  public async promoteUserToAdmin(realmId: number, userPublicKey: string, signal?: AbortSignal): Promise<void> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/user/${userPublicKey}`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        is_admin: true,
      }),
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  public async demoteAdminToUser(realmId: number, userPublicKey: string, signal?: AbortSignal): Promise<void> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/user/${userPublicKey}`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        is_admin: false,
      }),
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Update the given user by userPublicKey.
   */
  public async updateUser(
    realmId: number,
    userPublicKey: string,
    firstName: string,
    lastName: string,
    company: string,
    lang: string,
    signal?: AbortSignal,
  ): Promise<void> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/user/${userPublicKey}`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        company,
        lang,
      }),
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Request an email address update for another realm user. A confirmation email
   * will be sent to the user. Once confirmed, the email address will be updated.
   * @param newEmailAddress The new email address for the user.
   */
  public async updateEmailAddressRealmUser(realmId: number, userPubKey: string, newEmailAddress: string): Promise<void> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/user/${userPubKey}/request_update_email`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: newEmailAddress,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Request an email address update for the logged in user. A confirmation email
   * will be sent to the user. Once confirmed, the email address will be updated.
   * @param newEmailAddress The new email address for the user.
   */
  public async updateEmailAddress(newEmailAddress: string): Promise<void> {
    const url = formatUrl(`${this.baseURL}/user/request_update_email`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: newEmailAddress,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  public async verifyUpdateEmailAddress(token: string, signal?: AbortSignal): Promise<void> {
    const url = formatUrl(`${this.baseURL}/user/update_email`);
    const response = await fetch(url, {
      signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  public async updatePassword(newPassword: string, currentPassword?: string): Promise<void> {
    if (!this.keys || !this.userAccount) {
      throw Error('User is not logged in');
    }

    if (!this.requirePasswordChange && !currentPassword) {
      throw Error('Current password is required');
    }

    // Find the encrypted session based on our user public key.
    const storedUserDevice = this.userDeviceStorage.find(this.keys?.userPublicKey);

    if (!storedUserDevice) {
      throw Error('UserDevice found');
    }

    // check if the password match with the current session
    if (currentPassword) {
      await this.passwordCheck(currentPassword);
    }

    const hashedPassword = this.saltedPasswordHash(newPassword);
    const userPrivateKey = this.keys.userPrivateKey;
    const salt = new Salt(userPrivateKey.publicKey());
    const derivedKey = await DerivedKey.fromPasswordWithSalt(newPassword, salt);
    const encryptedPrivateKey = base64url.encode(Buffer.from(derivedKey.encrypt(userPrivateKey)));

    const url = formatUrl(`${this.baseURL}/user/password`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        hashed_password: hashedPassword,
        encrypted_private_key: encryptedPrivateKey,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    if (this.keys) {
      storedUserDevice.masterPasswordLogin.encryptedUserPrivateKey = encryptedPrivateKey;
      this.userDeviceStorage.update(storedUserDevice);

      // if the user has to change his password after a password reset request via the recovery key
      // we should set the current_user session so the user is logged-in
      if (sessionStorage.getItem('current_user') === null && this.requirePasswordChange) {
        const session: Session = {
          keys: {
            userPublicKey: this.keys.userPublicKey.toBase64(),
            webDevicePrivateKey: this.keys.webDevicePrivateKey.toBase64(),
            encryptedUserPrivKey: base64url.encode(Buffer.from(this.keys.webDevicePrivateKey.encrypt(this.keys.userPrivateKey.key()))),
          },
          // TODO rename Profile -> Account
          profile: this.userAccount.profile,
          superAdminMode: this._superAdminMode ?? undefined,
          host: {
            apiUrl: this.baseURL,
            wsUrl: this.wsUrl,
          },
        };

        // set the current user
        sessionStorage.setItem('current_user', JSON.stringify(session));

        // remove the mark for password change
        this._requirePasswordChange = false;
      }
    }
  }

  /**
   * Returns the Two-Factor-Authentication shared secret for the logged in user.
   */
  public async get2faInfo(signal?: AbortSignal): Promise<TwoFAInfo> {
    const url = formatUrl(`${this.baseURL}/user/totp`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as TwoFAInfo;
  }

  /**
   * Enabled 2fa. Pass in a totp to make sure the shared secret is on a device of the user.
   */
  public async set2FaEnabled(token: string): Promise<void> {
    const url = formatUrl(`${this.baseURL}/user/totp`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ enable: true, token }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Disable 2fa
   */
  public async set2FaDisabled(): Promise<void> {
    const url = formatUrl(`${this.baseURL}/user/totp`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ enable: false }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * List all user realms (or all realms when superAdminMode is enabled).
   */
  public async listRealms(): Promise<Realm[]> {
    let url = formatUrl(`${this.baseURL}/realms`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    return (await response.json()) as Realm[];
  }

  /**
   * List a single realm
   */
  public async listRealm(realmId: number, signal?: AbortSignal): Promise<Realm> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as Realm;
  }

  public async listRealmUsers(realmId: number, signal?: AbortSignal): Promise<RealmUser[]> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/user`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as RealmUser[];
  }

  public async getRealmConsoleLogs(realmId: number, signal?: AbortSignal): Promise<string> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/logs`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as TEXT out of the response
    return await response.text();
  }

  /**
   * Create a realm
   */
  public async createRealm(name: string): Promise<Realm> {
    const url = formatUrl(`${this.baseURL}/realms`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as Realm;
  }

  /**
   * Update the realm policies
   */
  public async updateRealmPolicies(realmId: number, values: RealmPolicyRequest): Promise<void> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/policies`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        can_create_realm_by_realm_users: values.canCreateRealmByRealmUsers,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Update the realm QuickShare policies
   */
  public async updateRealmQuickSharePolicies(realmId: number, values: RealmQuickShareRequest): Promise<void> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/policies/quick_share`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quickshare_max_allowed_size: values.quickSharemaxAllowedSize ? values.quickSharemaxAllowedSize.toString() : undefined,
        reverse_quickshare_max_allowed_size: values.reverseQuickSharemaxAllowedSize
          ? values.reverseQuickSharemaxAllowedSize.toString()
          : undefined,
        require_password: values.requirePassword,
        require_email_otp: values.requireEmailOtp,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Update Client portal values
   */
  public async updateRealmClientPortal(realmId: number, values: RealmClientPortalRequest): Promise<void> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/client_portal`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Get the Client Portal themes that are avaiable
   */
  public async getClientPortalThemes(signal?: AbortSignal): Promise<string[]> {
    const url = formatUrl(`${this.baseURL}/realms/client_portal/available_themes`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as string[];
  }

  /**
   * Get the client portal theme
   */
  public async getClientPortalTheme(realmId: number, theme?: string, signal?: AbortSignal): Promise<ClientPortalTheme> {
    const themeQueryParam = `/theme?theme=${theme}`;
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/client_portal${theme ? themeQueryParam : ''}`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as ClientPortalTheme;
  }

  /**
   * Update a realm logo
   */
  public async updateRealmBranding(realmId: number, values: RealmBrandingRequest): Promise<void> {
    // we are catching this in the component.
    // But just in case, we are catching it also here.
    if (!values.logo && !values.color) {
      return Promise.resolve();
    }

    const formData = new FormData();
    if (values.logo) {
      formData.append('logo', values.logo);
    }
    if (values.color) {
      formData.append('color', values.color);
    }

    const url = formatUrl(`${this.baseURL}/realms/${realmId}/branding`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      body: formData,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Remove the custom realm Logo
   */
  public async removeRealmLogo(realmId: number): Promise<void> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/branding/logo`);
    const response = await this.secureFetch(url, {
      method: 'DELETE',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Update (partial) realm values
   */
  public async updateRealmName(realmId: number, newRealmName: string): Promise<Realm> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: newRealmName,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as Realm;
  }

  /**
   * Get the logo of the customer
   */
  public getLogoUrl(realmId: number, logoHash: string): string {
    const { apiUrl } = getEnvUrls();
    return `${apiUrl}/realms/${realmId}/logo/${logoHash}`;
  }

  /**
   * Get all stats, related to a realm
   */
  public async getRealmStats(realmId: number, signal?: AbortSignal): Promise<RealmStats> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/stats`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as RealmStats;
  }

  /**
   * Get the uptime for the given realm
   */
  public async getRealmUptime(realmId: number, signal?: AbortSignal): Promise<number> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/deployment/uptime`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return Number(await response.text());
  }

  /**
   * Get the deployment status
   *
   * If one of the three services is down, return the down status
   */
  public async getRealmDeploymentStatus(realmId: number, signal?: AbortSignal): Promise<DeploymentStatus> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/deployment/status`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as DeploymentStatus;
  }

  public async getRealmAboutInfo(realmId: number, signal?: AbortSignal): Promise<AboutInfo> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/command/about`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as AboutInfo;
  }

  /**
   * Restart Realm
   */
  public async restartRealm(realmId: number): Promise<void> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/restart`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'POST',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Start Realm
   */
  public async startRealm(realmId: number): Promise<void> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/deployment`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'POST',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Stop Realm
   */
  public async stopRealm(realmId: number): Promise<void> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/deployment`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'DELETE',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Get subscription Status of a realm
   */
  public async getRealmTrialStatus(realmId: number, signal?: AbortSignal): Promise<RealmStatus> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/trial_status`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as RealmStatus;
  }

  /**
   * Create a project
   *
   * @param realmId
   * @param name
   * @param description
   */
  public async createProject(realmId: number, name: string, description: string): Promise<ProjectDetails> {
    const url = formatUrl(`${this.baseURL}/projects`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        encrypted_content_key: this.generateProjectContentKey(),
        realm_id: realmId,
        name,
        description,
        is_quick_share: false,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as ProjectDetails;
  }

  /**
   * Get a single project
   */
  public async getProject(projectId: string, signal?: AbortSignal): Promise<ProjectDetails> {
    let url = formatUrl(`${this.baseURL}/projects/${projectId}`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as ProjectDetails;
  }

  /**
   * List all projects for an user.
   *
   * @param realmId
   */
  public async listProjects(realmId?: number, signal?: AbortSignal): Promise<ProjectDetails[]> {
    let url = formatUrl(`${this.baseURL}/projects`);
    if (realmId) {
      url += `?realm_id=${realmId}`;
    }
    if (this.superAdminMode) {
      url += realmId ? '&super_admin' : '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as ProjectDetails[];
  }

  /**
   * update a project
   *
   * @param projectId
   * @param name
   * @param description
   */
  public async updateProject(projectId: string, name: string, description: string): Promise<void> {
    const url = formatUrl(`${this.baseURL}/projects/${projectId}`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        description,
        is_quick_share: false, // TODO
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Delete a project by Project ID
   *
   * @param projectId
   */
  public async deleteProject(projectId: string): Promise<void> {
    const url = formatUrl(`${this.baseURL}/projects/${projectId}`);
    const response = await this.secureFetch(url, {
      method: 'DELETE',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // remove the localstorage key if this one has been set
    const currentLocalStorageId = localStorage.getItem('selectedProjectId');
    if (currentLocalStorageId === projectId) {
      localStorage.removeItem('selectedProjectId');
    }

    // Update the cached ProjectList
    this._projectList.delete(projectId);

    // fire the deleted Event
    this.projectDeletedEvent([projectId]);
  }

  /**
   * Delete a list of projects
   *
   * @param projectIds
   */
  public async deleteProjects(projectIds: string[]): Promise<void> {
    const url = formatUrl(`${this.baseURL}/projects`);
    const response = await this.secureFetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        projectIds,
      }),
    });

    // when receiving a statusCode 422, the API cannot process the list of given IDS because there are some projects
    // where the user has no admin permissions for.
    // The response in that case, would be a list of all rejected Project ids.
    if (response.status === 422) {
      const data = await response.json();
      return Promise.reject(data);
    }

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    for (const projectId of projectIds) {
      // remove the localstorage key if this one has been set
      const currentLocalStorageId = localStorage.getItem('selectedProjectId');
      if (currentLocalStorageId === projectId) {
        localStorage.removeItem('selectedProjectId');
      }

      // Update the cached ProjectList
      this._projectList.delete(projectId);
    }

    // fire the deleted Event
    this.projectDeletedEvent(projectIds);
  }

  /**
   * Leave a project
   *
   * @param projectId
   */
  public async leaveProject(projectId: string): Promise<void> {
    const url = formatUrl(`${this.baseURL}/projects/${projectId}/leave`);
    const response = await this.secureFetch(url, {
      method: 'POST',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      // Format the error to a more explainable message in case the user is the only admin in the project
      const errorType = response.headers.get('x-exception-type');
      if (errorType === 'cannot-leave-you-are-the-only-admin') {
        throw Error('You are the only admin in a project with other members. Please promote another member to admin before leaving.');
      }

      throw Error(await response.text());
    }
  }

  /**
   * Add a member to a project
   *
   * @param projectId
   * @param permissionCode
   * @param publicKey
   * @param encryptedContentKey
   */
  public async addProjectMember(
    projectId: string,
    permissionCode: ProjectPermissions,
    publicKey: string,
    encryptedContentKey: string,
  ): Promise<void> {
    const url = formatUrl(`${this.baseURL}/projects/${projectId}/members`);
    const response = await this.secureFetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        public_key: publicKey,
        encrypted_content_key: encryptedContentKey,
        permission_code: permissionCode,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Add a member to a project
   *
   * @param projects
   */
  public async addMembersToProjects(projects: { id: string; members: ProjectMembersRequest[] }[]): Promise<void> {
    const url = formatUrl(`${this.baseURL}/projects/members`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ projects }),
    });

    // when receiving a statusCode 422, the API cannot process the list of given IDS because there are some projects
    // where the user has no admin permissions for.
    // The response in that case, would be a list of all rejected Project ids.
    if (response.status === 422) {
      const data = await response.json();
      return Promise.reject(data);
    }

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Get the members of a given project
   *
   * @param projectId
   */
  public async getProjectMembers(projectId: string, signal?: AbortSignal): Promise<ProjectMember[]> {
    let url = formatUrl(`${this.baseURL}/projects/${projectId}/members`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as ProjectMember[];
  }

  /**
   * Remove a member from a project
   *
   * @param projectId
   * @param publicKeyBase64
   */
  public async removeProjectMember(projectId: string, publicKeyBase64: string): Promise<void> {
    const url = formatUrl(`${this.baseURL}/projects/${projectId}/members/${publicKeyBase64}`);
    const response = await this.secureFetch(url, {
      method: 'DELETE',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Update a project member (Permission only)
   *
   * @param projectId
   * @param publicKeyBase64
   * @param permission
   */
  public async updatedProjectMember(projectId: string, publicKeyBase64: string, permission: ProjectPermissions): Promise<void> {
    const url = formatUrl(`${this.baseURL}/projects/${projectId}/members/${publicKeyBase64}`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        permission_code: permission,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Accept or Reject a project invitation
   *
   * If the projectId is undefined and the RealmId is defined, all invitations are accepted
   * If only the projectId is given, the invitation for that projectId is only accepted
   */
  public async projectInvitation(accepted: boolean, projectId?: string, realmId?: number): Promise<void> {
    if (projectId === undefined && realmId === undefined) {
      throw new Error('Either ProjectId or RealmId should be given');
    }

    const url = formatUrl(`${this.baseURL}/${projectId ? `projects/${projectId}/invitation/` : 'projects/invitation/'}`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accepted,
        realm_id: realmId,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Get the Address Book for a realm
   *
   * @param realmId
   */
  public async addressBook(realmId: number): Promise<AddressBookRecord[]> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/address_book`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // return all members of the addressbook, except the current user.
    const members = (await response.json()) as AddressBookRecord[];
    return members.filter(member => member.publicKeyBase64 !== this.keys?.userPublicKey.toBase64());
  }

  /**
   * Get the Address Book for a realm
   *
   * @param realmId
   * @param devicePubKeyBase64url
   */
  public async addressBookRecordForDevicePubKey(
    realmId: number,
    devicePubKeyBase64url: string,
    abortSignal?: AbortSignal,
  ): Promise<AddressBookRecord> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/address_book?device_public_key=${devicePubKeyBase64url}`);
    if (this.superAdminMode) {
      url += '&super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal: abortSignal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    const data = (await response.json()) as AddressBookRecord[];
    return data[0];
  }

  /**
   * Create a guest user
   */
  public async createGuestUser(
    realmId: number,
    firstName: string,
    lastName: string,
    email: string,
    company: string,
    lang: string,
  ): Promise<UserCreateResponse> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/user/invite`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email,
        company,
        lang,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as UserCreateResponse;
  }

  /**
   * Create a user
   */
  public async createUser(
    realmId: number,
    firstName: string,
    lastName: string,
    email: string,
    company: string,
    lang: string,
    isAdmin = false,
  ): Promise<UserCreateResponse> {
    const superAdmin = this.superAdminMode ? '?super_admin' : '';
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/user${superAdmin}`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email,
        company,
        lang,
        is_admin: isAdmin,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as UserCreateResponse;
  }

  /**
   * Delete a user
   */
  public async deleteUser(realmId: number, publicKeyBase64: string): Promise<void> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/user/${publicKeyBase64}`);
    const response = await this.secureFetch(url, {
      method: 'DELETE',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Get a list of user devices
   *
   * When the realmId and userPublicKey is given, we fetch the devices for different user (as admin)
   * Otherwise, we fetch the device of the current user
   *
   * @param realmId
   * @param userPublicKey
   */
  public async listUserDevices(realmId?: number, userPublicKey?: string, signal?: AbortSignal): Promise<UserDevice[]> {
    const deviceUrl = userPublicKey && realmId ? `realms/${realmId}/user/${userPublicKey}/device` : 'user/device/';
    const superAdmin = this.superAdminMode ? '?super_admin' : '';
    const url = formatUrl(`${this.baseURL}/${deviceUrl}${superAdmin}`);

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as UserDevice[];
  }

  /**
   * Remove a user device
   *
   * @param peerKey
   */
  public async removeUserDevice(peerKey: string): Promise<void> {
    const url = formatUrl(`${this.baseURL}/user/device/${peerKey}`);
    const response = await this.secureFetch(url, {
      method: 'DELETE',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Create hostedPage object that is need to show the dialog for the checkout
   */
  public async checkout(
    realmId: number,
    licenseProductID: string,
    licenseProductQty: number,
    storageProductQty: number,
  ): Promise<HostedPage> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/checkout/`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        license_product_id: licenseProductID,
        license_product_qty: licenseProductQty,
        storage_product_qty: storageProductQty,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    const data = (await response.json()) as { hosted_page: HostedPage };
    return data.hosted_page;
  }

  /**
   * Get the billing details
   */
  public async getPlan(realmId: number, signal?: AbortSignal): Promise<RealmPlan> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/plan`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as RealmPlan;
  }

  /**
   * Get all plans
   *
   * This request is made without auth header
   */
  public async getPlans(includeTrial = false, signal?: AbortSignal): Promise<PricingModel> {
    const url = formatUrl(`${this.baseURL}/payment/plans?${includeTrial ? 'include_trial' : ''}`);
    const response = await fetch(url, { signal });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    return (await response.json()) as PricingModel;
  }

  /**
   * Get the invoices per realm
   */
  public async getInvoices(realmId: number, offset?: string, signal?: AbortSignal): Promise<InvoiceResponse> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/invoices?offset=${offset ?? ''}`);
    if (this.superAdminMode) {
      url += '&super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as InvoiceResponse;
  }

  /**
   * Get the invoices per realm
   */
  public async getInvoiceDownloadLink(realmId: number, invoiceId: string): Promise<string> {
    let url = formatUrl(`${this.baseURL}/realms/${realmId}/invoices/${invoiceId}`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }

    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as string;
  }

  /**
   * Get the activity log per project
   */
  public async getProjectActivityLog(projectId: string): Promise<ActivityLog[]> {
    const url = formatUrl(`${this.baseURL}/projects/${projectId}/activity`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as ActivityLog[];
  }

  // Gets the latest client binary version from binaries(-staging).storro.com
  public async getLatestClientVersionNumber(signal?: AbortSignal): Promise<string> {
    const response = await fetch(new URL('/index.json', this._binariesHost), {
      signal,
      method: 'GET',
    });

    if (!response.ok) {
      return Promise.reject(Error(await response.text()));
    }

    const data = (await response.json()) as string[];
    if (data.length === 0) {
      return Promise.reject(Error('No client version numbers at index.json'));
    }

    return data[data.length - 1];
  }

  // Get the download urls for our client binaries given a certain version number.
  public async getBinariesForVersionNumber(version: string, signal?: AbortSignal): Promise<BinaryDownload[]> {
    const getBinaryFilename = async (url: URL) => {
      const response = await fetch(url, { signal, method: 'GET' });
      if (!response.ok) {
        return Promise.reject(Error(await response.text()));
      }
      const data = (await response.json()) as { name: string; type: string; mtime: string; size: number }[];
      if (data.length > 1) {
        return Promise.reject(Error('Too many files in binary location directory'));
      }
      if (data[0].type !== 'file') {
        return Promise.reject(Error('Item must be of type: file'));
      }
      return data[0].name;
    };

    const urlWin = new URL(`/${version}/windows`, this._binariesHost);
    const urlLinux = new URL(`/${version}/linux`, this._binariesHost);
    const urlOsx = new URL(`/${version}/osx`, this._binariesHost);

    return [
      { operationSystem: OperationSystem.Windows, url: `${urlWin}/${await getBinaryFilename(urlWin)}` },
      { operationSystem: OperationSystem.Linux, url: `${urlLinux}/${await getBinaryFilename(urlLinux)}` },
      { operationSystem: OperationSystem.MacOS, url: `${urlOsx}/${await getBinaryFilename(urlOsx)}` },
    ];
  }

  /**
   * Decrypt a project content key
   *
   * @param encryptedContentKey
   */
  public decryptProjectContentKey(project: ProjectDetails): SymmetricKey {
    if (!this.keys) {
      throw new Error('User Keys not available');
    }
    return new SymmetricKey(this.keys.userPrivateKey.decryptSealed(base64url.toBuffer(project.encryptedContentKey)));
  }

  /**
   * Decrypt a project content key via the group key.
   */
  public decryptGroupProjectContentKey(project: ProjectDetails): SymmetricKey {
    if (!this.keys) {
      throw new Error('User keys are not available');
    }
    if (!project.encryptedGroupKey) {
      throw new Error('The encrypted group key is not supplied');
    }
    if (!project.encryptedGroupContentKey) {
      throw new Error('The group encrypted content key is not supplied');
    }
    // Decrypt the group key
    const groupKeyBytes = base64url.toBuffer(project.encryptedGroupKey);
    const groupKey = new SymmetricKey(this.keys.userPrivateKey.decryptSealed(groupKeyBytes));

    // Use the group key to decrypt the content key
    const encryptedContentKeyBytes = base64url.toBuffer(project.encryptedGroupContentKey);
    const contentKey = groupKey.decryptSimple(encryptedContentKeyBytes);
    return new SymmetricKey(contentKey);
  }

  /**
   * Generate a new encrypted contentKey for a given member publicKey
   *
   * @param projectContentKey
   * @param memberPublicKeyBase64
   */
  public generateProjectContentKeyForMember(projectContentKey: SymmetricKey, memberPublicKey: PublicKey): Uint8Array {
    if (!this.keys) {
      throw new Error('User Keys not available');
    }

    // return a newly created encrypted contentKey for this user
    return Buffer.from(memberPublicKey.encryptSealed(projectContentKey.getRawKey()));
  }

  /**
   * Generate the Project ContentKey
   *
   * @private
   */
  private generateProjectContentKey(): string {
    if (!this.keys) {
      throw new Error('User Keys not available');
    }

    const contentKey: Uint8Array = crypto_aead_xchacha20poly1305_ietf_keygen();
    const encryptedContentKey: Uint8Array = this.keys.userPrivateKey.publicKey().encryptSealed(contentKey);

    return base64url.encode(Buffer.from(encryptedContentKey));
  }

  /**
   * finalize() writes out a modified Dir to the metadata store and creates a new ProjectVersion object
   * with the new root dir. This new project version is posted to api.storro.com.
   * At that point, the version is in a queue to be merged.
   * We do not know if the merge will succeed.
   *
   *  1. How to load the root dir:
   *
   *  const version: ProjectVersion = ...
   *  ecas and contentKey are loaded like in uploadFile().
   *  const plaintextName = 'root';
   *  const convEnc: ConvergentEncryption = new ConvergentEncryption(keys);
   *
   *  const encryptName = async (name: string): Promise<Uint8Array> => {
   *    return convEnc.encrypt(encode(name));
   *  };
   *  const encryptedName = encryptName(plaintextName);
   *  const mod = Date.now()
   *  const size = 0;
   *
   *  const rootDir = new Dir(ecas, encryptedName, mod, size, version,
   *     plaintextName, contentKey);
   *
   *  plaintextName, encryptedName, mod and size can be filled in
   *  with any value. Not importent.
   *
   *  2. How to perform operations on the root dir:
   *
   *  await rootDir.insert(entry0, new Path(encryptedPath0));
   *  await rootDir.insert(entry1, new Path(encryptedPath1));
   *
   *  3. How to update the project with a new version:
   *
   *  await finalize(rootDir, project, basVersionId);
   *
   *  To catch a IsUploading error
   *  try {
   *    await finalize(rootDir, project, basVersionId);
   *  } catch (e) {
   *    if (e instanceof IsUploadingError) {
   *      // act on the error IsUploading
   *    } else {
   *      // regular error
   *    }
   *  }
   *
   */
  public async finalize(newRootDir: Dir, project: Project, baseVersion: string): Promise<ProjectVersion> {
    const keys = this.keys;
    if (!keys) throw new Error('Need keys for finalizing');

    if (!project.contentKey) {
      throw Error('Project content key is required to create a project version');
    }

    const devicePrivateKey = keys.webDevicePrivateKey;
    const rootStreamKey = await newRootDir.serializeToString(project.contentKey);
    const parents: string[] = [];
    parents.push(baseVersion);
    const jwt = JsonWebToken.generate(devicePrivateKey, {
      version: 2,
      root: rootStreamKey,
      timestamp: Date.now(),
      peerKey: devicePrivateKey.publicKey().toBase64(),
      parents: parents,
    });
    // Convert the JWT to a string and encode that to a Uint8Array.
    // Give the result to ProjectVersion.
    const ecas = project.ecas(StoreType.Meta);
    const versionId = await ecas.putValue(new EcasValue(encode(jwt.toString())));

    // Create a new Project version
    const headVersionId = base64url.encode(Buffer.from(versionId.toUint8Array()));
    const newProjectVersion = new ProjectVersion(
      project.id,
      ecas,
      project.contentKey,
      encode(jwt.toString()),
      versionId,
      project.listingCache,
      undefined,
    );

    // Post the new version to api.storro.com.
    // This new version is now queued not yet merged.
    const url = formatUrl(`${this.baseURL}/projects/${project.id}/head`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        head_version_id: headVersionId,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // Fire an event for the projectUpdated subscribers
    this.projectUpdatedEvent(project.id);

    return newProjectVersion;
  }

  // /**
  //  * Transform a userSession to an encryptedSession
  //  */
  // private static setEncryptedSessionObject(userSession: UserSession): EncryptedSession {
  //   return {
  //     keys: {
  //       userPublicKey: userSession.publicKey,
  //       encryptedUserPrivKey: userSession.encryptedPrivateKey,
  //       encryptedDevPrivKey: userSession.encryptedWebDevicePrivateKey,
  //     },
  //     profile: {
  //       id: userSession.id,
  //       email: userSession.email,
  //       firstName: userSession.firstName,
  //       lastName: userSession.lastName,
  //       company: userSession.company,
  //       lang: userSession.lang,
  //       isRegistered: userSession.isRegistered,
  //       isSuperAdmin: userSession.isSuperAdmin,
  //       totpAuthenticatorEnabled: userSession.totpAuthenticatorEnabled,
  //     },
  //   };
  // }

  public fileUrl(ecas: Ecas, contentKey: SymmetricKey | undefined, entry: Entry): Promise<URL> {
    if (this._downloader) {
      return this._downloader.fileUrl(contentKey, ecas.toJson(), entry);
    } else {
      throw new Error('Downloading not possible');
    }
  }

  /**
   * General download method.
   *
   * Accept an array of entries per project and zip the download in case of multiple files
   */
  public async download(
    contentKey: SymmetricKey | undefined,
    entrySets: { ecas: Ecas; entries: Entry[] }[],
    downloadCallback?: DownloadCallback,
    abortSignal?: AbortSignal,
    onDownloadStarted?: () => void,
  ): Promise<void> {
    if (this._downloader) {
      return await this._downloader.download(contentKey, entrySets, downloadCallback, abortSignal, onDownloadStarted);
    } else {
      throw new Error('Downloading not possible');
    }
  }

  /**
   * Open File method,
   */
  public async open(
    contentKey: SymmetricKey | undefined,
    entrySets: { ecas: Ecas; entries: Entry[] }[],
    downloadCallback?: DownloadCallback,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    if (this._downloader) {
      return await this._downloader.open(contentKey, entrySets, downloadCallback, abortSignal);
    } else {
      throw new Error('Opening not possible');
    }
  }

  /**
   * Copy a stream from one project to another
   */
  public async copyStream(projectFrom: Project, projectTo: Project, key: StreamKey): Promise<void> {
    if (!projectFrom.contentKey) {
      return Promise.reject('No content key to call key.toEncryptedString()');
    }

    const url = formatUrl(`${this.baseURL}/projects/${projectTo.id}/storage/copy_stream`);
    const response = await this.secureFetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sourceProject: projectFrom.id,
        rootKey: key.toEncryptedString(projectFrom.contentKey),
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * List all QuickShare for an user.
   *
   * @param realmId
   */
  public async listQuickShares(realmId: number, signal?: AbortSignal): Promise<QuickShareDetails[]> {
    const url = formatUrl(`${this.baseURL}/quickshare?realm_id=${realmId}`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as QuickShareDetails[];
  }

  /**
   * Get a singular quick share item
   */
  public async getQuickShareItem(quickShareId: number, signal?: AbortSignal): Promise<QuickShareDetails> {
    const url = formatUrl(`${this.baseURL}/quickshare/${quickShareId}`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as QuickShareDetails;
  }

  /**
   * Mark a quickShare content as seen
   */
  public async markQuickShareAsSeen(quickShareId: number, signal?: AbortSignal): Promise<void> {
    const url = formatUrl(`${this.baseURL}/quickshare/${quickShareId}/has_seen`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // fire the event(s) as we update the quickshare with this call
    this.quickShareUpdatedEvent(quickShareId);
  }

  /**
   * Get the access log of a quick share
   */
  public async getQuickShareAccessLog(quickShareId: number, signal?: AbortSignal): Promise<QuickShareAccessRecord[]> {
    const url = formatUrl(`${this.baseURL}/quickshare/${quickShareId}/access_log`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as QuickShareAccessRecord[];
  }

  /**
   * Get the access log of a quick share
   */
  public async getQuickShareMembers(quickShareId: number, signal?: AbortSignal): Promise<QuickShareMemberDetails[]> {
    const url = formatUrl(`${this.baseURL}/quickshare/${quickShareId}/members`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as QuickShareMemberDetails[];
  }

  /**
   * Create a QuickShare
   */
  public async createQuickShare(
    quickShareKey: SymmetricKey,
    projects: Project[],
    realmId: number,
    name: string,
    description: string,
    allowUpload: boolean,
    expiresAt: Date,
    accessLimit: number,
  ): Promise<QuickShareDetails> {
    if (projects.length === 0) {
      throw Error('Cannot create quick share without a project');
    }

    const contentKeys = projects.map(proj => {
      if (!proj.contentKey) {
        throw Error('No content key set for project');
      }
      return proj.contentKey;
    });

    if (projects.length !== 1 && allowUpload) {
      throw Error('Quick Share with "allowUpload" enabled can only have one project source');
    }

    // Encrypt the quickShare with all the related projects content keys.
    const encryptedQuickShareKey = SymmetricKey.fromMultiple(new Set(contentKeys)).encryptSimple(quickShareKey.getRawKey());

    const url = formatUrl(`${this.baseURL}/quickshare`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        project_id: allowUpload ? projects[0].id : undefined,
        realm_id: realmId,
        name,
        description,
        allow_upload: allowUpload,
        encrypted_key: base64url.encode(Buffer.from(encryptedQuickShareKey)),
        expires_at: expiresAt,
        access_limit: accessLimit,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as QuickShareDetails;
  }

  /**
   * Update basic details of a QuickShare
   */
  public async updateQuickShare(
    quickShareId: number,
    name: string,
    description: string,
    expiresAt: Date,
    accessLimit: number,
  ): Promise<void> {
    const url = formatUrl(`${this.baseURL}/quickshare/${quickShareId}`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        description,
        expires_at: expiresAt,
        access_limit: accessLimit,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  public async addQuickShareEntry(
    quickShareId: number,
    project: Project,
    quickShareKey: SymmetricKey,
    entry: Entry,
  ): Promise<QuickShareEntryDetails> {
    if (!project.contentKey) {
      throw Error('No content key set for project');
    }

    const metaStore = project.ecas(StoreType.Meta);

    let serializedEntry: EncryptedListingEntry;
    if (entry.type === EntryType.Dir) {
      const newDir = (await entry.copyRecrypt(metaStore, project.contentKey, metaStore, quickShareKey)) as Dir;
      // The argument for Dir.toListingEntry() is unused.
      // We just have to provide one since it is required for Entry interface.
      await newDir.serialize(quickShareKey);
      serializedEntry = newDir.toListingEntry(quickShareKey);
    } else if (entry.type === EntryType.File) {
      const recryptedEntry = await entry.copyRecrypt(metaStore, project.contentKey, metaStore, quickShareKey);
      serializedEntry = recryptedEntry.toListingEntry(quickShareKey);
    } else {
      throw Error('Unsupported Entry type');
    }

    const url = formatUrl(`${this.baseURL}/quickshare/${quickShareId}/entries`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entry: serializedEntry,
        total_file_size: entry.size.toString(),
        project_id: project.id,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as QuickShareEntryDetails;
  }

  public async getQuickShareMemberPassword(quickShare: QuickShareDetails, member: QuickShareMemberDetails): Promise<string | undefined> {
    if (!member.encryptedPassword) {
      return undefined;
    }
    const quickShareProjectsKey = await this.getQuickShareProjectsKey(quickShare);
    return decode(quickShareProjectsKey.decryptSimple(new Uint8Array(base64url.toBuffer(member.encryptedPassword))));
  }

  public async getQuickShareMemberUrl(quickShare: QuickShareDetails, member: QuickShareMemberDetails): Promise<string> {
    if (member.encryptedPassword && !member.hasCustomPassword) {
      const quickShareProjectsKey = await this.getQuickShareProjectsKey(quickShare);
      const pwdUtf8 = quickShareProjectsKey.decryptSimple(new Uint8Array(base64url.toBuffer(member.encryptedPassword)));
      const urlHash = QuickShareUrlHash.formatWithPassword(member.id, decode(pwdUtf8));
      return `${window.location.protocol}//${window.location.host}/qs${urlHash}`;
    } else {
      const urlHash = QuickShareUrlHash.formatWithPasswordHash(member.id, member.sharedSecret);
      return `${window.location.protocol}//${window.location.host}/qs${urlHash}`;
    }
  }

  /**
   * Creates a SymmetricKey based on all the related project content keys.
   */
  public async getQuickShareProjectsKey(quickShare: QuickShareDetails): Promise<SymmetricKey> {
    const projContKeys = new Map<string, SymmetricKey>();
    if (quickShare.allowUpload) {
      if (!quickShare.projectId) {
        throw new Error('No project id set for QuickShare');
      }
      const contKey = (await this.projectList.getProject(quickShare.projectId)).contentKey;
      if (!contKey) {
        throw new Error('No content key set for project');
      }
      projContKeys.set(quickShare.projectId, contKey);
    } else {
      for await (const qse of await this.getQuickShareEntries(quickShare.id)) {
        if (!projContKeys.has(qse.projectId)) {
          const contKey = (await this.projectList.getProject(qse.projectId)).contentKey;
          if (!contKey) {
            throw new Error('No content key set for project');
          }
          projContKeys.set(qse.projectId, contKey);
        }
      }
    }
    return SymmetricKey.fromMultiple(new Set([...projContKeys.values()]));
  }

  public async addQuickShareMember(
    quickShare: QuickShareDetails,
    password: string,
    isCustomPwd: boolean,
    emailOtpEnabled: boolean,
    email?: string,
    name?: string,
  ): Promise<QuickShareMemberDetails> {
    const quickShareProjectsKey = await this.getQuickShareProjectsKey(quickShare);
    const quickShareKey = new SymmetricKey(
      quickShareProjectsKey.decryptSimple(new Uint8Array(base64url.toBuffer(quickShare.encryptedKey))),
    );
    const memberPrivateKey = PrivateKey.random();
    const key = await DerivedKey.fromPasswordWithSalt(password, new Salt(memberPrivateKey.publicKey()));
    const encryptedPrivateKey = key.encrypt(memberPrivateKey);
    const encryptedQsKey = memberPrivateKey.encrypt(quickShareKey.getRawKey());
    const encryptedQsKeyBase64url = base64url.encode(Buffer.from(encryptedQsKey));
    const encryptedPrivateKeyBase64Url = base64url.encode(Buffer.from(encryptedPrivateKey));
    const encryptedPassword = base64url.encode(Buffer.from(quickShareProjectsKey.encryptSimple(encode(password))));

    const url = formatUrl(`${this.baseURL}/quickshare/${quickShare.id}/members`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shared_secret: QuickShareUrlHash.saltedPasswordHash(password),
        public_key: memberPrivateKey.publicKey().toBase64(),
        encrypted_private_key: encryptedPrivateKeyBase64Url,
        encrypted_quick_share_key: encryptedQsKeyBase64url,
        encrypted_password: encryptedPassword,
        email_otp_enabled: emailOtpEnabled,
        has_custom_password: isCustomPwd,
        email,
        name,
        password: email ? password : undefined,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as QuickShareMemberDetails;
  }

  public async closeQuickShare(quickShareId: number): Promise<void> {
    const url = formatUrl(`${this.baseURL}/quickshare/${quickShareId}/close`);
    const response = await this.secureFetch(url, {
      method: 'POST',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  public async deleteQuickShare(quickShareId: number): Promise<void> {
    const url = formatUrl(`${this.baseURL}/quickshare/${quickShareId}`);
    const response = await this.secureFetch(url, {
      method: 'DELETE',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  public async reopenQuickShare(quickShareId: number): Promise<void> {
    const url = formatUrl(`${this.baseURL}/quickshare/${quickShareId}/reopen`);
    const response = await this.secureFetch(url, {
      method: 'POST',
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  public async getQuickShare(
    quickShareUrlHash: QuickShareUrlHash,
    totp?: string,
    signal?: AbortSignal,
  ): Promise<AuthenticatedQuickShareDetails> {
    const headers: HeadersInit = {
      Authorization: `Bearer ${quickShareUrlHash.passwordHash}`,
    };

    if (totp) {
      headers['totp'] = totp;
    }

    const url = formatUrl(`${this.baseURL}/quickshare/auth/${quickShareUrlHash.memberId}`);
    const response = await fetch(url, {
      method: 'GET',
      headers,
      signal,
    });

    if (response.status === 401) {
      const totpRequiredHeader = response.headers.get('totp');
      if (totpRequiredHeader) {
        if (totpRequiredHeader === LoginTotpType.Email) {
          throw new QuickShareError(await response.text(), LoginTotpType.Email);
        } else if (totpRequiredHeader === LoginTotpType.Device) {
          throw new QuickShareError(await response.text(), LoginTotpType.Device);
        }
      }
      throw new QuickShareError(await response.text());
    } else if (response.status === 404) {
      // Either removed or an invalid quickShareId.
      throw new QuickShareError(await response.text(), undefined, QuickShareExceptionType.NotFound);
    } else if (!response.ok) {
      const errorType = response.headers.get('x-exception-type');
      if (errorType === 'expired') {
        // QuickShare is expired
        throw new QuickShareError(await response.text(), undefined, QuickShareExceptionType.Expired);
      } else if (errorType === 'access-limit-reached') {
        // QuickShare is expired
        throw new QuickShareError(await response.text(), undefined, QuickShareExceptionType.AccessLimitReached);
      } else {
        throw new QuickShareError(await response.text());
      }
    }

    return await response.json();
  }

  public async requestQuickShareTotp(quickShareUrlHash: QuickShareUrlHash, signal?: AbortSignal): Promise<void> {
    const url = formatUrl(`${this.baseURL}/quickshare/auth/${quickShareUrlHash.memberId}/request_token`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${quickShareUrlHash.passwordHash}`,
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Get the contents of a quick share. Note that this method will update the
   * access log the first time it gets called.
   * @param quickShareMemberId The id of the member
   * @param jwt The jwt based on the quick share member password. This jwt can
   * be generated based on the private key. But after that it should be the jwt
   * that is returned from this call. This because we it has an increased token
   * timeout and will not count as a new 'access' to the quickshare.
   * @returns The QuickShare content for the given member. It also includes
   * a new token that should to be used for other requests.
   */
  public async getQuickShareContent(
    quickShareMemberId: number,
    jwt: JsonWebToken,
    signal?: AbortSignal,
  ): Promise<QuickShareContentWithRefreshToken> {
    const url = formatUrl(`${this.baseURL}/quickshare/auth/${quickShareMemberId}/content`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwt.toString()}`,
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    return (await response.json()) as QuickShareContentWithRefreshToken;
  }

  public async submitReverseQuickShareRoot(
    quickShareId: number,
    jwt: JsonWebToken,
    quickShareKey: SymmetricKey,
    root: Dir,
    commitMessage?: string,
    signal?: AbortSignal,
  ): Promise<void> {
    await root.serialize(quickShareKey);
    const serializedEntry = root.toListingEntry(quickShareKey);
    const url = formatUrl(`${this.baseURL}/quickshare/${quickShareId}/entries`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt.toString()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entry: serializedEntry,
        total_file_size: root.size.toString(),
        comment: commitMessage === '' ? undefined : commitMessage,
      }),
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  public async getQuickShareEntries(quickShareId: number, signal?: AbortSignal): Promise<QuickShareEntryInfo[]> {
    const url = formatUrl(`${this.baseURL}/quickshare/${quickShareId}/entries`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as QuickShareEntryInfo[];
  }

  /**
   * Get the diff of a project version and its parent. A project version with
   * multiple parents is not supported.
   * @param projectId
   * @param projectVersionEcasKey The project version EcasKey with locator.
   * @param signal An optional abort signal
   * @returns A cached diff from the api. It returns undefined when the project
   * version is not (yet) generated. Calling this method will trigger the diff
   * generation at the api. So it would (probably) be available next time.
   */
  public async getCachedDiff(projectId: string, projectVersionEcasKey: EcasKey, signal?: AbortSignal): Promise<Summary[] | undefined> {
    let url = formatUrl(`${this.baseURL}/projects/${projectId}/${projectVersionEcasKey.toString()}/diff`);
    if (this.superAdminMode) {
      url += '?super_admin';
    }
    const response = await this.secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // We don't have the diff result cached on the server. The server will
    // be generating it from this point on. So it will be available soon.
    if (response.status === 202) {
      return undefined;
    }

    // get the data as JSON out of the response
    const data = (await response.json()) as DiffSummaryJson[];
    return fromJsonDiffSummary(data);
  }

  public async getEncryptedRegistrationPrivateKey(realmId: number, registrationPublicKey: PublicKey): Promise<PrivateKey | undefined> {
    if (!this.userAccount || !this.keys || !this.jwt) {
      return Promise.reject(Error('Not logged in'));
    }
    try {
      const url = formatUrl(
        `${this.baseURL}/realms/${realmId}/user/${registrationPublicKey.toBase64()}/encrypted_registration_private_key`,
      );
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.jwt.jwt().toString()}`,
        },
      });

      if (response.ok) {
        const encryptedBase64 = (await response.json()).encrypted_registration_private_key;
        const encrypted = new Uint8Array(base64url.toBuffer(encryptedBase64));
        const registrationPrivKeyBytes = this.userAccount.keys.userPrivateKey.decryptSealed(encrypted);
        return new PrivateKey(registrationPrivKeyBytes);
      } else {
        if (response.status === 401) {
          return undefined;
        } else {
          return Promise.reject(await response.text());
        }
      }
    } catch (e) {
      const error = e instanceof Error ? e.message : 'unknown error';
      return Promise.reject(Error(error));
    }
  }

  public async resendRegistrationEmail(
    realmId: number,
    registrationPublicKey: PublicKey,
    registrationPrivateKey?: PrivateKey,
    newEmail?: string,
  ): Promise<PublicKey> {
    const url = formatUrl(`${this.baseURL}/realms/${realmId}/user/${registrationPublicKey.toBase64()}/resend_registration_email`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        registration_private_key: registrationPrivateKey?.toBase64(),
        email: newEmail,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // This is either the old registration public key or a new one. This
    // depends of we've created the user or not.
    return PublicKey.fromBase64((await response.json()).registration_public_key);
  }

  /**
   * Save the FireBase token
   */
  public async saveFirebaseToken(firebaseToken: string, signal?: AbortSignal): Promise<void> {
    const url = formatUrl(`${this.baseURL}/user/push_notification`);
    const response = await this.secureFetch(url, {
      method: 'PATCH',
      signal,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firebase_token: firebaseToken,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Remove the Firebase token
   */
  public async removeFirebaseToken(signal?: AbortSignal): Promise<void> {
    const url = formatUrl(`${this.baseURL}/user/push_notification`);
    const response = await this.secureFetch(url, {
      method: 'DELETE',
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Check if the device has a Firebase Token
   */
  public async hasFirebaseToken(signal?: AbortSignal): Promise<void> {
    const url = formatUrl(`${this.baseURL}/user/push_notification`);
    const response = await this.secureFetch(url, {
      method: 'HEAD',
      signal,
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Delete a user account
   */
  public async deleteAccount(reason: string, userInput: string, confirm: boolean, signal?: AbortSignal): Promise<void> {
    const url = formatUrl(`${this.baseURL}/user`);
    const response = await this.secureFetch(url, {
      method: 'DELETE',
      signal,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reason,
        userInput,
        confirm,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }

  /**
   * Preflight check if we can delete a user account
   */
  public async canDeleteUser(signal?: AbortSignal): Promise<CanDeleteUserResponse> {
    const url = formatUrl(`${this.baseURL}/user/can_delete`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      signal,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as CanDeleteUserResponse;
  }

  /**
   * Get the available Product IDs for the InAppPurchase
   */
  public async getInAppProductIds(signal?: AbortSignal): Promise<string[]> {
    const url = formatUrl(`${this.baseURL}/payment/in_app_purchase`);
    const response = await this.secureFetch(url, {
      method: 'GET',
      signal,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }

    // get the data as JSON out of the response
    return (await response.json()) as string[];
  }

  /**
   * Create a new InAppPurchase
   */
  public async createInAppPurchase(
    realmId: number,
    appStoreReceipt: string,
    productId: string,
    currencyCode: string,
    price: number,
  ): Promise<void> {
    const url = formatUrl(`${this.baseURL}/payment/in_app_purchase`);
    const response = await this.secureFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        realmId,
        appStoreReceipt,
        productId,
        currencyCode,
        price,
      }),
    });

    // if the Status code is outside the range of 200-299
    if (!response.ok) {
      throw Error(await response.text());
    }
  }
}
