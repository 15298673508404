import classNames from 'classnames';
import React from 'react';
import GdprLogo from '../../../assets/client-portal/GDPR-ready.svg';
import IsoLogo from '../../../assets/client-portal/ISO_27001.svg';

interface Props {
  className?: string;
  ElementClassName?: string;
}

function StorroFooterBadges({ ElementClassName, className }: Props): JSX.Element {
  return (
    <div className={classNames('flex items-center gap-x-6 opacity-50', className)}>
      <div className='flex gap-2'>
        <img src={GdprLogo} alt='logo' width='40' />
        <div className={classNames('flex flex-col gap-1 justify-center', ElementClassName)}>
          <p className='uppercase font-semibold text-sm'>GDPR</p>
          <p className='text-sm'>compliant</p>
        </div>
      </div>
      <div className='flex gap-2 mr-4'>
        <img src={IsoLogo} alt='logo' width='40' />
        <div className={classNames('flex flex-col gap-1 justify-center', ElementClassName)}>
          <p className='uppercase font-semibold text-sm'>ISO 27001</p>
          <p className='text-sm'>storage</p>
        </div>
      </div>
    </div>
  );
}

export default StorroFooterBadges;
