import { equal } from './Equal';

/*
 * Uint8ArraySet works like Set<Uint8Array> but lets us check
 * existence of *equivalent* Uint8Arrays without needing to keep
 * the original objects around.
 * This is needed because new Uint8Array[1] !== new Uint8Array[1],
 * as JS only compares pointers and searching for any new Uint8Array
 * will yield false, making Set<Uint8Array> useless for our purpose.
 */
export class Uint8ArraySet {
  // We use a map internally as it has good time complexity.
  // The keys are derived from the values through computeBucket().
  map: Map<number, Array<Uint8Array>>;

  constructor() {
    this.map = new Map<number, Array<Uint8Array>>();
  }

  public insert(value: Uint8Array): void {
    const bucket = this.computeBucket(value);
    let array = this.map.get(bucket);
    if (!array) {
      // This index did not exist.
      // Create it and add the value.
      array = new Array<Uint8Array>();
      array.push(value);
      this.map.set(bucket, array);
    } else {
      // This index already existed.
      // Check the value is not already present.
      const found = array.find(e => equal(e, value));
      if (found === undefined) array.push(value);
    }
  }

  public has(value: Uint8Array): boolean {
    const bucket = this.computeBucket(value);
    const array = this.map.get(bucket);
    if (!array) return false;
    return array.find(e => equal(e, value)) !== undefined;
  }

  /*
   * Returns true when the element was removed,
   * false if the element was not present.
   */
  public remove(value: Uint8Array): boolean {
    const bucket = this.computeBucket(value);
    const array = this.map.get(bucket);
    if (!array) return false;
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (equal(element, value)) {
        // If we found the iten, splice it out and return true.
        array.splice(i, 1);
        return true;
      }
    }
    return false;
  }

  private computeBucket(value: Uint8Array): number {
    // Just turn the first 6 bytes (48 bit) into a number.
    // This should be adequate for our usage: random hashes.
    let result = 0;
    for (let i = 0; i < 6; i++) {
      result += value[i];
      result *= 256;
    }
    return result;
  }
}
