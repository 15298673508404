import React from 'react';
import { Link } from 'react-router-dom';
import useRealm from '../../../hooks/realm/UseRealm';
import useMenu, { MenuMode } from '../../../hooks/UseMenu';
import maxLength from '../../../util/MaxLength';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

interface Props {
  toAdmin: boolean;
  title: string;
  description: string;
}

export default function SwitchUserContext({ toAdmin, title, description }: Props): JSX.Element {
  const { realm } = useRealm();
  const { setIsOpen, menuMode, isOpen } = useMenu();

  const link = toAdmin ? `/realm/${realm?.publicKey}/admin/stats` : `/realm/${realm?.publicKey}`;
  const trimmedDescription = maxLength(description, 24);

  /**
   * Click event when we click on the link to swtich the user context
   */
  const onClick = () => {
    // we close the menu only when we are in the small mode
    menuMode === MenuMode.SMALL && setIsOpen(false);
  };

  // Bail out when the user is not an admin
  if (!realm?.isAdmin || !isOpen) {
    return <></>;
  }

  return (
    <Link to={link} onClick={onClick} className='flex flex-col px-5 pt-10'>
      <div className='flex items-center text-zinc-50'>
        {!toAdmin && <CaretLeft />}
        <span>{title}</span>
        {toAdmin && <CaretRight />}
      </div>

      <span className='text-xs text-gray-400 pt-1'>{trimmedDescription}</span>
    </Link>
  );
}
