import { LoginTotpType } from './LoginError';

/**
 * Interface that holds the info for the exception type of an QuickShare error
 */
export enum QuickShareExceptionType {
  Default,
  NotFound,
  Expired,
  AccessLimitReached,
}

export class QuickShareError extends Error {
  constructor(
    message: string,
    private twoFaType: LoginTotpType = LoginTotpType.None,
    private _exceptionType = QuickShareExceptionType.Default,
  ) {
    super(message); // 'Error' breaks prototype chain here
    this.name = 'QuickShareError';
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }

  // We will need to pass a one-time password to the request.
  get requireTotp(): boolean {
    return this.twoFaType !== LoginTotpType.None;
  }

  get totpAuthenticatorType(): LoginTotpType {
    return this.twoFaType;
  }

  get exceptionType(): QuickShareExceptionType {
    return this._exceptionType;
  }
}
