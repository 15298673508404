import { Project } from 'api/Project';
import { UploadQueueItem } from 'api/UploadQueue';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Button, { ButtonSize, ButtonVariant } from '../components/Button';
import FileIcon from '../components/Filemanager/FileIcon';
import Modal, { ModalWidth } from '../components/Modal';
import { Confirm, ConfirmModalType } from '../components/Modal/ConfirmModal';
import ProgressModalRowCompleted from '../components/Progress/Modal/ProgressModalRowCompleted';
import ProgressModalRowInProgress from '../components/Progress/Modal/ProgressModalRowInProgress';
import Toggle from '../components/Toggle';
import { useStorroApi } from '../context/StorroApiContext';
import { SymmetricKey } from '../util/Cryptography/SymmetricKey';
import useUploadQueue from './project/UseUploadQueue';

// A group can be i.e. a project or a quick share.
export interface UploadsByGroup {
  name: string;
  projectId: string;
  contentKey: SymmetricKey | undefined;
  items: UploadQueueItem[];
  finished: boolean;
}

enum OverwriteDecision {
  Replace,
  Rename,
  Skip,
}

/**
 * This hook will create a modal for creating a project
 */
export default function useProgressModal(): {
  dialog: JSX.Element;
  openModal: (open?: boolean) => void;
} {
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const { queue, hasDecidedOnOverwrite } = useUploadQueue();
  const [finished, setFinished] = useState<UploadsByGroup[]>([]);
  const { storroApi } = useStorroApi();
  const [doThisForAllFiles, setDoThisForAllFiles] = useState<boolean>(false);

  const grouped = useMemo<UploadsByGroup[]>(() => {
    const res: UploadsByGroup[] = [];
    for (const item of queue) {
      const found = res.find(g => g.projectId === item.projectId);
      if (found) {
        found.items.push(item);
      } else {
        res.push({
          name: item.containerName,
          projectId: item.projectId,
          contentKey: item.contentKey,
          items: [item],
          finished: false,
        });
      }
    }
    return res;
  }, [queue]);

  const undecided = useMemo<UploadQueueItem[]>(() => {
    return queue.filter(item => !item.hasExplicitOverwriteDecision);
  }, [queue]);

  const firstUndecidedName = useMemo<string>(() => {
    if (undecided.length === 0) {
      return '';
    }
    const contKey = undecided[0].contentKey;
    if (!contKey) {
      return '<undecryptable>';
    }
    return undecided[0].path.toString(contKey);
  }, [undecided]);

  useEffect(() => {
    const event = (project: Project, done: UploadQueueItem[]) => {
      setFinished(
        finished.concat({
          name: project.details.name,
          projectId: project.details.id,
          contentKey: project.contentKey,
          items: done,
          finished: true,
        }),
      );
    };
    storroApi.projectList.subscribeUploadFinishedCallback(event);
    return () => {
      storroApi.projectList.unsubscribeUploadFinishedCallback(event);
    };
  }, [storroApi.projectList, finished]);

  const resolveConflicts = useCallback(
    (decision: OverwriteDecision, forAllFiles: boolean) => {
      const resolveConflict = (decision: OverwriteDecision, uploadQueueItem: UploadQueueItem) => {
        switch (decision) {
          case OverwriteDecision.Replace:
            uploadQueueItem.setOverwrite(true);
            break;
          case OverwriteDecision.Rename:
            uploadQueueItem.setOverwrite(false);
            break;
          case OverwriteDecision.Skip:
            uploadQueueItem.cancel();
            break;
        }
      };

      if (forAllFiles) {
        undecided.forEach(item => {
          resolveConflict(decision, item);
        });
      } else if (undecided.length > 0) {
        resolveConflict(decision, undecided[0]);
      }
    },
    [undecided],
  );

  /**
   * Cancel the upload items that are in the queue
   */
  const cancelUpload = () => {
    Confirm({
      text: 'Are you sure you want to cancel the upload of the remaining files in the upload queue?',
      type: ConfirmModalType.Danger,
      onConfirm: () => {
        for (const item of queue) {
          if (!item.finished) {
            item.cancel();
          }
        }
        toast.info('All file uploads, that are not completed, are cancelled.');
      },
    });
  };

  /**
   * Fire a cancel event
   * @param item
   */
  const cancelItem = (contentKey: SymmetricKey | undefined, item: UploadQueueItem) => {
    Confirm({
      title: 'Cancel upload',
      text: (
        <>
          Are you sure you want to cancel the upload for{' '}
          <strong>{contentKey ? item.path.toString(contentKey) : item.path.toEncryptedString()}</strong>
        </>
      ),
      type: ConfirmModalType.Danger,
      confirmText: 'Cancel upload',
      onConfirm: () => {
        item.cancel();
      },
    });
  };

  if (!hasDecidedOnOverwrite) {
    return {
      openModal: (open = true) => setModalIsVisible(open),
      dialog: (
        <Modal
          isVisible={true}
          actions={[
            {
              label: 'Skip',
              onClick: () => {
                resolveConflicts(OverwriteDecision.Skip, doThisForAllFiles);
                setDoThisForAllFiles(false);
              },
              isCloseButton: true,
            },
            {
              label: 'Keep both',
              onClick: () => {
                // rename
                resolveConflicts(OverwriteDecision.Rename, doThisForAllFiles);
                setDoThisForAllFiles(false);
              },
            },
            {
              label: 'Replace',
              variant: ButtonVariant.Primary,
              onClick: () => {
                // replace
                resolveConflicts(OverwriteDecision.Replace, doThisForAllFiles);
                setDoThisForAllFiles(false);
              },
            },
          ]}
          title='File already exists'
        >
          <div>
            The following file already exists. Would you like to replace the existing file?
            <div className='flex gap-1 items-center mt-4 border rounded p-2'>
              <FileIcon name={firstUndecidedName} className='shrink-0' />
              {firstUndecidedName}
            </div>
            <div className='flex gap-2 mt-8'>
              <Toggle
                onToggle={active => {
                  setDoThisForAllFiles(active);
                }}
              />
              <span>Do this for all files</span>
            </div>
          </div>
        </Modal>
      ),
    };
  }

  return {
    openModal: (open = true) => setModalIsVisible(open),
    dialog: (
      <>
        <Modal
          title='Upload progress'
          isVisible={modalIsVisible}
          onRequestCloseModal={() => setModalIsVisible(false)}
          width={ModalWidth.sm}
          showFixedHeaderComponent={queue.length > 0 || finished.length > 0}
          fixedHeaderComponent={
            <div className='flex gap-x-1'>
              {finished.length > 0 && (
                <Button
                  size={ButtonSize.Small}
                  onClick={() => {
                    setFinished([]);
                  }}
                >
                  Clear list
                </Button>
              )}
              {queue.length > 0 && (
                <Button size={ButtonSize.Small} variant={ButtonVariant.Danger} onClick={cancelUpload}>
                  Cancel
                </Button>
              )}
            </div>
          }
        >
          {queue.length === 0 && finished.length === 0 && <>There are no uploads. Please upload some files to see the progress here.</>}
          <>
            <ul>
              {grouped.map((group, key) => (
                <React.Fragment key={`fragment-${group.projectId}-${key}`}>
                  {group.items.map(item => (
                    <ProgressModalRowInProgress
                      key={item.path.toEncryptedString()}
                      item={item}
                      group={group}
                      onCancel={() => cancelItem(group.contentKey, item)}
                    />
                  ))}
                </React.Fragment>
              ))}
            </ul>

            {queue.length > 0 && finished.length > 0 && <div className='py-3 px-2 font-medium border-b'>Finished</div>}
            <ul>
              {finished.map((group, key) => (
                <React.Fragment key={`fragment-${group.projectId}-${key}`}>
                  {group.items.map(item => (
                    <ProgressModalRowCompleted key={item.path.toEncryptedString()} item={item} group={group} />
                  ))}
                </React.Fragment>
              ))}
            </ul>
          </>
        </Modal>
      </>
    ),
  };
}
