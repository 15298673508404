// Copyright 2021 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// KeyStream class should be a simple version of
// Zooid/Source/Serialization/KeyStream.
//
// The main difference is that the TypeScript version
// can only write at the end of the stream. No mid-stream writes.
//
// Also, since we are always writing we do not need a separate MerkleTreeReader
// and do not need to switch between it and the ContentLevelChunker.
// We can simply always read from the chunker.
//
// This Typescript version uses WebAssembly code, which according to
// https://gitlab.coblue.eu/storro/app.storro/-/issues/393#note_104005
// we need to manage carefully, so the caller should call keystream.destroy()
// before letting a KeyStream object go out of scope.
//
import { ContentLevelChunker } from './ContentLevelChunker';
import { Ecas } from './Ecas';
import { MerkleTreeReader } from './MerkleTreeReader';
import { StreamKey } from './StreamKey';

export class KeyStream {
  private contentLevelChunker: ContentLevelChunker;
  private reader: MerkleTreeReader;

  /**
   * For reading or writing content streams to an Ecas.
   *
   * @param ecas The ecas to read from or write to
   * @param cancelCallback Callback method for upload cancelation
   * @param uploadCallback Optional callback method for upload progress
   * @param streamKey Optional StreamKey when reading contents. Undefined for writing.
   */
  constructor(
    ecas: Ecas,
    cancelCallback: () => Promise<boolean>,
    uploadCallback?: (uploadedSize: number) => Promise<void>,
    streamKey?: StreamKey,
  ) {
    if (streamKey) {
      // Second is the root key.
      // We want to read the stream.
      this.reader = new MerkleTreeReader(ecas, streamKey);
    } else {
      // Second is the bytes uploaded callback function
      // We want to write a new stream and use the callback
      // to notify the caller about progress.
      this.contentLevelChunker = new ContentLevelChunker(ecas, cancelCallback, uploadCallback);
    }
  }

  public async write(input: Uint8Array): Promise<void> {
    if (!this.contentLevelChunker) throw new Error('KeyStream can not write() without a ContentLevelChunker');
    return this.contentLevelChunker.addContent(input);
  }

  public async read(offset: number, length: number): Promise<Uint8Array> {
    if (this.contentLevelChunker) {
      return this.contentLevelChunker.read(offset, length);
    }
    if (this.reader) {
      return this.reader.read(offset, length);
    }
    throw new Error('KeyStream can not read() without a ContentLevelChunker or MerkleTreeReader');
  }

  public async rootKey(): Promise<StreamKey | undefined> {
    if (this.contentLevelChunker) {
      return this.contentLevelChunker.rootKey();
    }
    return this.reader.rootKey();
  }

  public async size(): Promise<number> {
    if (this.contentLevelChunker) {
      return this.contentLevelChunker.size();
    }
    return this.reader.size();
  }

  public destroy(): void {
    if (this.contentLevelChunker) {
      this.contentLevelChunker.destroy();
    }
  }
}
