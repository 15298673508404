import React, { forwardRef, ReactElement } from 'react';
import { Icon, IconBase, IconWeight } from '@phosphor-icons/react';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <>
      <path id='Path' fill='none' stroke='none' d='M 0 0 L 256 0 L 256 256 L 0 256 Z' />
      <path
        id='path1'
        fill='none'
        stroke='currentcolor'
        strokeWidth='16'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M 152 32 L 152 88 L 208 88'
      />
      <path
        id='path2'
        fill='none'
        stroke='currentcolor'
        strokeWidth='16'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M 168 224 L 200 224 C 204.418274 224 208 220.418274 208 216 L 208 88 L 152 32 L 56 32 C 51.581722 32 48 35.581726 48 40 L 48 120'
      />
      <g id='notebook'>
        <path id='path3' fill='none' stroke='none' d='M 30 128 L 142 128 L 142 240 L 30 240 Z' />
        <path
          id='path4'
          fill='none'
          stroke='currentcolor'
          strokeWidth='12.653508'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M 79 177 L 107 177'
        />
        <path
          id='path5'
          fill='none'
          stroke='currentcolor'
          strokeWidth='12.653508'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M 79 191 L 107 191'
        />
        <path
          id='path6'
          fill='none'
          stroke='currentcolor'
          strokeWidth='12.653508'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M 51 145.5 L 121 145.5 C 122.932999 145.5 124.5 147.067001 124.5 149 L 124.5 219 C 124.5 220.932999 122.932999 222.5 121 222.5 L 51 222.5 C 49.067005 222.5 47.5 220.932999 47.5 219 L 47.5 149 C 47.5 147.067001 49.067005 145.5 51 145.5 Z'
        />
        <path
          id='path7'
          fill='none'
          stroke='currentcolor'
          strokeWidth='12.653508'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M 65 145.5 L 65 222.5'
        />
      </g>
    </>,
  ],
]);

const FileBook: Icon = forwardRef((props, ref) => <IconBase ref={ref} {...props} weights={weights} />);

FileBook.displayName = 'FileBook';

export default FileBook;
