import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { logger } from '../util/Logger';

interface Props {
  showSidebar: boolean;
  sidebarTitle?: string;
  sidebarItems?: string[];
  image?: string;
  maxWidthClassName?: string;
  children: React.ReactNode;
}

type SplitScreenContextType = {
  showSidebar: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;

  sidebarTitle: string | undefined;
  setSidebarTitle: Dispatch<SetStateAction<string | undefined>>;

  image: string | undefined;
  setImage: Dispatch<SetStateAction<string | undefined>>;

  sidebarItems: string[] | undefined;
  setSidebarItems: Dispatch<SetStateAction<string[] | undefined>>;

  maxWidthClassName: string | undefined;
  setMaxWidthClassName: Dispatch<SetStateAction<string | undefined>>;
};

const SplitScreenContext = createContext<SplitScreenContextType>({
  showSidebar: false,
  setShowSidebar: () => logger.warn('No SplitScreen provider'),

  sidebarTitle: undefined,
  setSidebarTitle: () => logger.warn('No SplitScreen provider'),

  image: undefined,
  setImage: () => logger.warn('No SplitScreen provider'),

  sidebarItems: undefined,
  setSidebarItems: () => logger.warn('No SplitScreen provider'),

  maxWidthClassName: undefined,
  setMaxWidthClassName: () => logger.warn('No SplitScreen provider'),
});

export function useSplitScreenContext(): SplitScreenContextType {
  return useContext(SplitScreenContext);
}

export function SplitScreenProvider({ children }: Props): JSX.Element {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [sidebarTitle, setSidebarTitle] = useState<string>();
  const [sidebarItems, setSidebarItems] = useState<string[]>();
  const [image, setImage] = useState<string>();
  const [maxWidthClassName, setMaxWidthClassName] = useState<string>();

  return (
    <SplitScreenContext.Provider
      value={{
        showSidebar,
        setShowSidebar,
        sidebarTitle,
        setSidebarTitle,
        sidebarItems,
        setSidebarItems,
        image,
        setImage,
        maxWidthClassName,
        setMaxWidthClassName,
      }}
    >
      {children}
    </SplitScreenContext.Provider>
  );
}
