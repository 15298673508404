import { EncryptedListingEntry } from 'api/Entry';

export interface QuickShareDetails {
  id: number;
  projectId?: string; // The project which the share is related to
  name: string; // A name that can be used to identify the different shares
  description: string; // An optional description that can be used to identify the different shares
  encryptedKey: string; // A new symmetric key encrypted with the project symmetric key. Base64url encoded.
  allowUpload: boolean; // True when the QuickShare member is allowed to upload files
  createdAt: string;
  createdBy: string; // The User Public key from the ProjectMember that creates the QuickShare. Guests are not allowed.
  createdByFirstName: string;
  createdByLastName: string;
  projectName: string;
  expiresAt: string; // Date when the QuickShare is not usable anymore
  realmSalt: string; // Base64url encoded salt of the realm
  closed: boolean;
  closedAt?: string;
  accessLimit: number; // The maximum amount of times the quick share can be accessed.
  lastActivity?: string; // Date of the latest activity
  hasSeen?: boolean; // flag is the user has seen all changes. This will only be set if the user is admin of the QS
  hasSubmittedEntries: boolean;
}

export interface QuickShareAccessRecord {
  id: number;
  quickShareMemberId: number;
  ipAddress: string;
  timestamp: string;
}

export interface QuickShareEntryDetails {
  quickShareId: number;
  entry: EncryptedListingEntry;
  totalFileSize: string; // bigint as string
  comment?: string; // Optional piece of comment for the entry.
  quickShareMemberId?: number; // Filled in when the Entry is added by a QuickShareMember.
  projectId: string; // The project where the data can be accessed/stored
}

export interface QuickShareMemberDetails {
  id: number;
  quickShareId: number;
  sharedSecret: string; // A derivative of the secret. E.g. when using a password then it's a hash of the password. When we a pub/priv key then its the public key
  publicKey: string; // The public key of the quick share member
  encryptedPassword?: string; // The password of the quick share encrypted with the project content key
  hasCustomPassword: boolean; // True when a custom password is used.
  emailOtpEnabled: boolean; // True when a one time password is enforced (only applicable with email invites)
  email?: string; // The receiver email address
  name?: string; // The receiver name
}

export interface QuickShareContent {
  encryptedQuickShareKey: string;
  entries: QuickShareEntryDetails[];
  realmSalt: string;
}

export interface QuickShareContentWithRefreshToken {
  quickShareContent: QuickShareContent;
  refreshToken: string;
  quickShare: AuthenticatedQuickShareDetails;
}

export interface QuickShareEntryInfo {
  id: number;
  quickShareId: number;
  entry: EncryptedListingEntry; // The listing entry object
  totalFileSize: string; // bigint as string
  comment?: string; // Optional piece of comment for the entry.
  quickShareMemberId?: number; // Filled in when the Entry is added by a QuickShareMember.
  projectId: string; // The project where the data can be accessed/stored
}

export interface AuthenticatedQuickShareDetails {
  quickShareId: number;
  projectId: string | undefined;
  quickShareMemberId: number;
  description: string;
  allowUpload: boolean;
  createdAt: string;
  createdByPublicKey: string;
  createdByFirstName: string;
  createdByLastName: string;
  createdByEmail: string;
  expiresAt: string;
  totpRequired: boolean;
  encryptedPrivateKey: string | undefined; // Is not added when a totp is required but not provided.
  publicKey: string;
  memberEmail?: string;
  memberName?: string;
  realmId: number;
  realmName: string;
  realmClientPortalTheme: string;
  realmLogoHash?: string;
  realmColor?: string;
  reverseQuickShareMaxAllowedSize: string; // String (bigint) in byte count.
}

export enum QuickShareState {
  Closed = 'Closed',
  Open = 'Open',
  Expired = 'Expired',
}

export enum QuickShareType {
  Share,
  Request,
}
