import { SubscriptionStatus } from '../../types/Payment';

/**
 * Return a friendly name for the status of a realm
 */
export const friendlyStatusName = (status: SubscriptionStatus | undefined): string => {
  if (!status) {
    return 'Unknown';
  }

  const options = {
    active: 'Active',
    in_trial: 'In Trial',
    trial_expired: 'Trial Expired',
    cancelled: 'Cancelled',
    non_renewing: 'Non Renewing',
  };

  return options[status] ?? '';
};
