// Copyright 2019 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
import pino from 'pino';
import { envVar } from './Util/EnvVar';

/**
 * Base interface for interfacing with a remote log service
 * For now made specific for Loki, but could be expanded to a general syslog
 * service if the need arises.
 *
 * @param hostEndpoint the full endpoint for the log service, for Loki this
 * would include the api path (/loki/api/v1/push).
 */
interface LoggingDetails {
  hostEndpoint: string | undefined;
  username: string | undefined;
  password: string | undefined;
}

/**
 * Function to determine the log level based on the LOG_LEVEL and NODE_ENV environment variables.
 *
 * If the LOG_LEVEL is not set, or not among 'trace', 'debug', 'info', 'warning', or 'error' the level defaults to 'debug'.
 * When the NODE_ENV is set to 'test' the log level is overridden to 'warning'.
 *
 * Because we deal with the browser and the log levels are in fact the properties of the console object
 * we need to transform warning -> warn
 */
function getLogLevel(): string {
  let logLevel = 'debug';
  // get the env var log_level, but do not log via the logger object
  // as it has not yet initializated
  const envLogLevel = envVar('LOG_LEVEL', false);
  const logLevels = ['trace', 'debug', 'info', 'warning', 'error'];

  if (envLogLevel && logLevels.includes(envLogLevel)) {
    logLevel = envLogLevel;
  }

  // Override log level for testing to WARNING
  if (envVar('NODE_ENV', false) === 'test') {
    logLevel = 'warning';
  }

  // Because the browser console does not know warning, we should transform it to warn
  return logLevel === 'warning' ? 'warn' : logLevel;
}

/**
 * Function to retrieve the logging details for Loki from environment
 * variables.
 * Note, this function does not check if the environment variables are present.
 *
 * @returns promise of the LoggingDetails
 */
async function getLokiDetails(): Promise<LoggingDetails> {
  // do no log when the variable does not exists
  // or we could endup in a infinite loop
  return {
    hostEndpoint: envVar('LOKI_ENDPOINT', false),
    username: envVar('LOKI_USERNAME', false),
    password: envVar('LOKI_PASSWORD', false),
  };
}

/**
 * Retrieves the user public key from the session storage. This public key can
 * be used as a label in the Loki stream.
 *
 * @returns the user public key or undefined if an error occurred.
 */
async function getUserKey(): Promise<string | undefined> {
  try {
    const currentUser = sessionStorage.getItem('current_user');
    // The || '{}' is to make eslint happy, checking for undefined is not
    // enough.
    const jsonObj = JSON.parse(currentUser || '{}');
    if (!('keys' in jsonObj)) return;
    const publicKey = jsonObj['keys']['userPublicKey'];
    return publicKey;
  } catch (error) {
    return undefined;
  }
}

/**
 * Function to make logging to Loki easier. This function checks if the runtime
 * environment is 'production' or 'staging', if not nothing is logged to Loki.
 * If the runtime environment check passes the message is forwarded to Loki
 * along with a set of labels denoting the origin along with the user agent and
 * user public key.
 *
 * @param msg the message to send to Loki. JSON.stringify needs to be able to
 * stringify the message.
 * @param level the level of the message. This is set as a label, not part of
 * the message.
 */
async function postToLoki(msg: unknown, level = 'info') {
  const appEnv = envVar('APP_ENV', false); // do not log to avoid an infinite loop
  if (appEnv === undefined || !['production', 'staging'].includes(appEnv)) {
    return;
  }

  // Format loki message
  const lokiMsg = {
    streams: [
      {
        stream: {
          // Loki labels
          application: 'app',
          source: 'pino',
          level,
          user_agent: navigator.userAgent,
          publicKey: 'not set',
          platform: appEnv,
          // eslint-disable-next-line
          // @ts-ignore
          version: GIT_COMMIT_HASH,
        },
        values: [[`${Date.now()}000000`, JSON.stringify(msg)]],
      },
    ],
  };

  // Get user keys
  const userKey = await getUserKey();
  if (userKey !== undefined) {
    lokiMsg['streams'][0]['stream']['publicKey'] = userKey;
  }

  const loggingDetails = await getLokiDetails();
  if (loggingDetails.hostEndpoint !== undefined) {
    try {
      const response = await fetch(`${loggingDetails.hostEndpoint}`, {
        method: 'POST',
        body: JSON.stringify(lokiMsg),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + btoa(loggingDetails.username + ':' + loggingDetails.password),
        },
      });

      if (response.status >= 400) {
        console.warn('Failed to log to Loki');
      }
    } catch (error) {
      console.error(error);
    }
  } else {
    console.warn('Loki details not supplied');
  }
}

// We export the logger object so anything can import this object and log to
// pino and (optionally) to Loki. The benefit of this approach is that you
// don't need to pass a created object around (class based approach), this
// means that any function requiring a logger only need to import this object.
export const logger = pino({
  level: getLogLevel(),
  browser: {
    asObject: true,
    serialize: true,
    write: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      debug: async function (msg: any) {
        // Check for 'time', 'level' and 'msg' keys. These keys are parsed by
        // Loki and normally generated by Pino.
        if (!('time' in msg) || !('level' in msg) || !('msg' in msg)) {
          return;
        }

        // Log to the console
        console.debug(msg['msg']);

        // Log to loki
        await postToLoki(msg, 'debug');
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      info: async function (msg: any) {
        // Check for 'time', 'level' and 'msg' keys. These keys are parsed by
        // Loki and normally generated by Pino.
        if (!('time' in msg) || !('level' in msg) || !('msg' in msg)) {
          return;
        }

        // Log to the console
        console.info(msg['msg']);

        // Log to loki
        await postToLoki(msg, 'info');
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      warn: async function (msg: any) {
        // Check for 'time', 'level' and 'msg' keys. These keys are parsed by
        // Loki and normally generated by Pino.
        if (!('time' in msg) || !('level' in msg) || !('msg' in msg)) {
          return;
        }

        // Log to the console
        console.warn(msg['msg']);

        // Log to loki
        await postToLoki(msg, 'warning');
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: async function (msg: any) {
        // Check for 'time', 'level' and 'msg' keys. These keys are parsed by
        // Loki and normally generated by Pino.
        if (!('time' in msg) || !('level' in msg) || !('msg' in msg)) {
          return;
        }

        // Recreate an Error object to print to the console.
        // The format of the `msg` is different from the `Error` that
        // `console.error` expects.
        const logError = new Error();
        logError.message = msg['msg'];
        logError.stack = msg['stack'];

        // Log to the console
        console.error(logError.message, logError, logError.stack);

        // Log to loki
        await postToLoki(msg, 'error');
      },
    },
  },
});
