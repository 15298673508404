import React, { createContext, useContext, useState } from 'react';
import { User } from '../types';
import { logger } from '../util/Logger';

interface Props {
  user?: User | undefined;
  children: React.ReactNode;
}

export type UserContextType = {
  user: User | undefined;
  setUser: (user: User | undefined) => void;

  superAdminMode: boolean;
  setSuperAdminMode: (enabled: boolean) => void;
};

const UserContext = createContext<UserContextType>({
  user: undefined,
  setUser: () => logger.warn('no User provider'),
  superAdminMode: false,
  setSuperAdminMode: () => logger.warn('no User provider'),
});

export function useUserContext(): UserContextType {
  return useContext(UserContext);
}

export function UserProvider({ children }: Props): JSX.Element {
  const [user, setUser] = useState<User>();
  const [superAdminMode, setSuperAdminMode] = useState<boolean>(false);

  return <UserContext.Provider value={{ user, setUser, superAdminMode, setSuperAdminMode }}>{children}</UserContext.Provider>;
}
