// Copyright 2021 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
import { blake2b as blake2b_hashwasm } from 'hash-wasm';
import { crypto_generichash, crypto_generichash_BYTES } from 'libsodium-wrappers';
import { concat } from '../Util/Concat';
//
// Hash class implements Blake2b hashing
// and should function equivalent to
// Zooid/Cryptography/ConvergentEncryption.h
// We use two different implementations to optimize use cases and speed.
//
// On my laptop (i7-9750H) in tests for 1-2 MB:
// - libsodium's synchronous: 120 MB/sec
// - hash-wasm async: 600 MB/sec
//
// On smaller inputs the hash-wasm performance suffers,
// most likely due to the overhead of copying data into the wasm instance.
//
const maxTreeSegmentSize = 262144;

export class Hash {
  // This is the libsodium implementation.
  // Use this for synchronous operations or small inputs.
  public static blake2bSync(message: Uint8Array): Uint8Array {
    if (!crypto_generichash_BYTES) {
      throw Error('crypto_generichash_BYTES const is undefined');
    }
    return crypto_generichash(crypto_generichash_BYTES, message);
  }

  // blake2bTreeAsync() is a tree hash using blake2b().
  // Use this for synchronous operations or small inputs.
  public static blake2bTreeSync(input: Uint8Array): Uint8Array {
    const subResults = new Array<Uint8Array>();
    let offset = 0;
    while (offset < input.byteLength) {
      const size = Math.min(input.byteLength - offset, maxTreeSegmentSize);
      const section = new Uint8Array(input.buffer, offset, size);
      subResults.push(Hash.blake2bSync(section));
      offset += maxTreeSegmentSize;
    }
    return Hash.blake2bSync(concat(subResults));
  }

  // The asynchronous version switches between libsodium's implementation
  // for small inputs and hash-wasm's WASM implementation for larger inputs.
  //
  // Hex to Uint8Array code found on
  // https://stackoverflow.com/a/50868276/13746151
  //
  public static async blake2b(value: Uint8Array): Promise<Uint8Array> {
    // For small inputs we avoid the overhead of using WASM.
    if (value.length < 32768) return Hash.blake2bSync(value);

    const hashString = await blake2b_hashwasm(value, 256);
    if (!hashString) throw 'HashString is null';
    const matched = hashString.match(/.{1,2}/g);
    // This should never throw but we need to satisfy the compiler Gods.
    if (!matched) throw 'HashString did not match';
    const result = matched.map(byte => parseInt(byte, 16));
    return new Uint8Array(result);
  }

  // This function should work equivalent to
  // Zooid/Source/Cryptography/Hash.cpp::threadPooledBlake2b().
  // This is not thread-pooled yet but this could of course be done.
  //
  // Note: Currently in the process of renaming threadPooledBlake2b()
  // to blake2bTree(). This implementation does not need to be thread-pooled
  // but does need to be tree-hashing.
  public static async blake2bTree(input: Uint8Array): Promise<Uint8Array> {
    const promises = new Array<Promise<Uint8Array>>();
    let offset = 0;
    while (offset < input.byteLength) {
      // TODO We might need to change the length parameter for the last section.
      const size = Math.min(input.byteLength - offset, maxTreeSegmentSize);
      const section = new Uint8Array(input.buffer, offset, size);
      promises.push(Hash.blake2b(section));
      offset += maxTreeSegmentSize;
    }

    const value = await Promise.all(promises);
    return Hash.blake2b(concat(value));
  }
}
