export const INPUT_CLASSNAMES = 'grow h-8 px-2 rounded border border-grey-400 disabled:bg-gray-100';
export const TEXTAREA_CLASSNAMES = `${INPUT_CLASSNAMES} pt-1 !h-16`;
export const INPUT_WARNING_CLASSNAMES = 'border-danger-300';

export const TABLE = 'w-full table-fixed';
export const TABLE_THEAD = 'border-b cursor-pointer select-none text-gray-500';
export const TABLE_THEAD_STICKY = `${TABLE_THEAD} sticky top-0 p-10 z-50 bg-white after:absolute after:border-t after:w-full`;
export const TABLE_THEAD_NO_SORT = `${TABLE_THEAD} !cursor-default`;
export const TABLE_THEAD_TR = 'text-left h-12';
export const TABLE_THEAD_TH_SELECT = 'pr-2 w-8';
export const TABLE_THEAD_TH_NAME = 'w-5/12 2xl:w-6/12';
export const TABLE_THEAD_TH_SORT_ICON = 'inline ml-1';
export const TABLE_TBODY = 'select-none';
export const TABLE_TBODY_TR = 'border-b border-gray-100 hover:bg-gray-100 h-12 cursor-pointer';
export const TABLE_TBODY_TR_NO_CLICK = `${TABLE_TBODY_TR} !cursor-default`;

// Hide a column on smaller display sizes.
export const TABLE_HIDDEN_COLUMN_XS = 'hidden xs:table-cell';
export const TABLE_HIDDEN_COLUMN_SM = 'hidden sm:table-cell';
export const TABLE_HIDDEN_COLUMN_MD = 'hidden md:table-cell';
export const TABLE_HIDDEN_COLUMN_LG = 'hidden lg:table-cell';
export const TABLE_HIDDEN_COLUMN_XL = 'hidden xl:table-cell';

// Pagebody classes to determine the spacing
export const PAGE_BODY_SPACING_T = 'pt-3 md:pt-5';
export const PAGE_BODY_SPACING_B = 'pb-3 md:pb-5';
export const PAGE_BODY_SPACING_R = 'pr-4 md:pr-10';
export const PAGE_BODY_SPACING_L = 'pl-4 md:pl-10';
export const PAGE_BODY_SPACING_X = 'px-4 md:px-10';

// Tabs
export const TAB_WRAPPER = 'flex justify-start items-center overflow-x-auto';
export const TAB_LIST = 'shrink-0 cursor-pointer py-2 first:ml-0 first:pl-0 last:mr-0 last:pr-0 px-2 mx-2 outline-none';
export const TAB_SELECTED = 'font-bold text-primary border-b-2 border-primary';
export const TAB_NOT_SELECTED = 'font-light';
