/**
 * Generate a default error message.
 * Mostly given to errors that fetch content
 *
 * @param context will be used for giving the message a little bit of context "Unable to [context...]. Please try again or ...."
 * @param error give an optional detailed error message (e.g. from the api)
 */
export default function formatErrorMessage(context = 'proccess your request', errorMessage?: string): string {
  const specificErrorMessage = errorMessage ? ` "${errorMessage}"` : '';
  return `Unable to ${context}${specificErrorMessage}. Please try again or contact our support.`;
}
