import classNames from 'classnames';
import { IS_IOS_APP } from 'const';
import { useDialogContext } from 'context/DialogContext';
import React, { useMemo, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useBannerContext } from '../../../context/BannerContext';
import { useSidebarLeftContext } from '../../../context/SidebarLeftContext';
import { useSidebarRightContext } from '../../../context/SidebarRightContext';
import useRealm from '../../../hooks/realm/UseRealm';
import useIsMobileDevice from '../../../hooks/UseIsMobileDevice';
import useMenu, { MenuMode, MenuWidth } from '../../../hooks/UseMenu';
import useScreenSize from '../../../hooks/UseScreenSize';
import { ScreenSize } from '../../../types/ScreenSize';
import Banner from '../../Banner';
import MobileMenu from '../../MobileMenu';
import Progress from '../../Progress';
import SidebarLeft from '../../SidebarLeft';
import SidebarRight, { SIDEBAR_WIDTH } from '../../SidebarRight';
import ResizableDiv from 'components/ResizeableDiv';

/**
 * Wrap a route with some basic components
 */
export default function RouteWrapper(): JSX.Element {
  const mainRef = useRef<HTMLElement>(null);
  const mobileMenuRef = useRef<HTMLElement>(null);

  const { isOpen: menuIsOpen, menuMode } = useMenu();
  const { realm } = useRealm();
  const { width } = useScreenSize();
  const { itemCount, isHidden } = useSidebarRightContext();
  const { secondaryPane } = useSidebarLeftContext();
  const { bannerIsVisible } = useBannerContext();
  const isMobileDevice = useIsMobileDevice();
  const { dialogsItems } = useDialogContext();

  // We should only care for dialogItems that do not have the remainSizeOnSmallerScreens set (or false)
  // as those dialog are placed to to bottom of the screen
  const filteredDialogsItems = dialogsItems.filter(item => !item.remainSizeOnSmallerScreens);

  // Calculate the padding on the right side of the main element based on the rightSidebar width
  const paddingRight =
    itemCount > 0 && !isHidden
      ? width > ScreenSize['3xl']
        ? SIDEBAR_WIDTH.Open
        : !isMobileDevice
          ? SIDEBAR_WIDTH.Closed
          : SIDEBAR_WIDTH.Hidden
      : 0;

  // Calculate the Padding for the x-ass. This padding is used when there is a modal openend and
  // we should create a layered effect on the <main /> element
  const paddingX = isMobileDevice && filteredDialogsItems.length > 0 ? `${filteredDialogsItems.length * 6 + 12}px` : undefined;

  /**
   * Calculate the left padding based on the menuMode
   */
  const paddingLeft = useMemo(() => {
    if (menuMode === MenuMode.HIDDEN) {
      return undefined;
    }

    if (menuMode === MenuMode.LARGE) {
      return menuIsOpen ? MenuWidth.LARGE : MenuWidth.SMALL;
    }

    return MenuWidth.SMALL;
  }, [menuIsOpen, menuMode]);

  return (
    <>
      {/* show the mobile menu at the bottom */}
      {isMobileDevice && <MobileMenu mobileMenuRef={mobileMenuRef} />}

      <SidebarLeft mainRef={mainRef} />
      <Banner />
      <main
        ref={mainRef}
        style={{
          paddingRight,
          marginLeft: paddingX,
          marginRight: paddingX,
          paddingBottom: isMobileDevice ? mobileMenuRef.current?.offsetHeight : 0,
          // we set a padding left here so whe have some space for rendering the menu
          paddingLeft: paddingLeft ? `${paddingLeft}px` : undefined,
        }}
        className={classNames('bg-white pt-safe h-screen flex transition-all duration-[350ms]', {
          '2xl:blur-none blur-[1px] ': menuIsOpen,
          'pt-16 md:pt-8': bannerIsVisible || (realm && realm.scheduledForAdminDemote), // make room for the top bar

          // if the modal is open, we create a nice animation just like Apple does on the iPhone
          // the background element create padding on the x-ass and to the top
          // so you have the feeling you the modal overlaps the background element in a layered way
          'rounded-md bg-white mt-3 overflow-hidden': isMobileDevice && filteredDialogsItems.length > 0,
          // for a real mobile device, we should also care about the safe-area to the top
          'mt-[calc(env(safe-area-inset-top))]': IS_IOS_APP && filteredDialogsItems.length > 0,
        })}
      >
        {secondaryPane && (
          <ResizableDiv minWidth={250} maxWidth={500} className='shrink-0 grow-0 w-64 2xl:w-96 flex flex-col border-r bg-gray-50'>
            {secondaryPane}
          </ResizableDiv>
        )}
        <div className='grow flex flex-col overflow-x-hidden'>
          <Outlet />
          <Progress mobileMenuRef={mobileMenuRef} />
        </div>
      </main>
      <SidebarRight mobileMenuRef={mobileMenuRef} />
    </>
  );
}
