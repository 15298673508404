// Copyright 2021 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.

import { S3 } from '@aws-sdk/client-s3';
import base64url from 'base64url';
import { logger } from 'util/Logger';
import { buf2hex } from '../Util/buf2hex';
import { KeyValueStore } from './KeyValueStore';
import { S3Credentials } from './S3Credentials';
import { createEndpoint, S3Service } from './S3Factory';
import { StoreConfig } from './StoreFactory';
import { UAParser } from 'ua-parser-js';

export class S3Store implements KeyValueStore {
  credentials: S3Credentials;
  bucket: string;
  prefix: string;
  s3: S3;
  private isFirefox = false;

  // A time on the `await getResponse.Body.transformToByteArray` call. This is
  // made as workaround on Firefox that could encounter a hanging bug.
  private readonly firefoxS3GetObjectTimeout = 16000;

  constructor(
    credentials: S3Credentials,
    bucket: string,
    prefix: string,
    private uuid?: string,
    private storageLimit?: number,
  ) {
    this.credentials = credentials;
    this.bucket = bucket;
    this.prefix = prefix;
    if (!this.credentials) throw new Error('Need valid credentials');
    if (!this.bucket) throw new Error('Need a bucket');
    if (this.bucket.length === 0) throw new Error('Need a non-empty bucket');
    if (!this.prefix) throw new Error('Need a prefix');
    if (this.prefix.length === 0) throw new Error('Need a non-empty prefix');

    const s3Endpoint = createEndpoint(this.credentials, S3Service.S3);
    this.s3 = new S3({
      endpoint: s3Endpoint,
      credentials: {
        accessKeyId: this.credentials.access_key,
        secretAccessKey: this.credentials.secret_key,
        sessionToken: this.credentials.session_token,
      },
      region: this.credentials.region,
      // This changes the url from http://<bucket>.endpoint/key
      // to http://endpoint/bucket/key.
      forcePathStyle: true,
    });

    // Check if we're on Firefox
    const ua = new UAParser(navigator.userAgent);
    this.isFirefox = ua.getBrowser().name === 'Firefox';
  }

  public async putValue(key: Uint8Array, value: Uint8Array): Promise<void> {
    if (key.length === 0) throw new Error('Can not store empty value');
    if (value.length === 0) throw new Error('Can not store empty value');
    const keyString = this.encodeKey(key);
    const ok = await this.s3.putObject({ Bucket: this.bucket, Key: keyString, Body: value });
    if (!ok) throw new Error('Could not put object');
  }

  public async getValue(key: Uint8Array): Promise<Uint8Array> {
    if (key.length === 0) throw Error('Can not get empty value');
    try {
      const keyString = this.encodeKey(key);
      const getResponse = await this.s3.getObject({ Bucket: this.bucket, Key: keyString });
      if (!getResponse) throw Error('Could not get value');
      if (!getResponse.Body) throw Error('Could not get value (body)');

      if (this.isFirefox) {
        // There is a bug where firefox combined with getResponse.Body.transformToByteArray
        // results in a promise that never finishes. This is a safety mechanism
        // for that. It's a timeout on a promise.
        // This is possibly the bug https://github.com/aws/aws-sdk-js/issues/2087
        const timeout = (prom: Promise<Uint8Array>, time: number, exception: symbol): Promise<Uint8Array> => {
          let timer: NodeJS.Timeout;
          return Promise.race([prom, new Promise<Uint8Array>((_r, rej) => (timer = setTimeout(rej, time, exception)))]).finally(() =>
            clearTimeout(timer),
          );
        };

        const timeoutError = Symbol();
        try {
          return await timeout(getResponse.Body.transformToByteArray(), this.firefoxS3GetObjectTimeout, timeoutError);
        } catch (e) {
          if (e === timeoutError) {
            logger.error(`Timeout on getting ByteArray from S3 getObject body for key ${base64url.encode(Buffer.from(key))}. Retrying.`);
            return this.getValue(key);
          }
          throw e;
        }
      } else {
        return await getResponse.Body.transformToByteArray();
      }
    } catch (e) {
      logger.error('Could not get value', e);
      throw Error('Could not get value');
    }
  }

  async hasValue(key: Uint8Array): Promise<boolean> {
    const keyString = this.encodeKey(key);
    try {
      const response = await this.s3.headObject({ Bucket: this.bucket, Key: keyString });
      const result = response.$metadata.httpStatusCode === 200;
      return result;
    } catch (error) {
      // The error is NotFound for missing key-value pairs.
      // TODO: How can we check for other errors and throw an exception?
      //
      // Also: headObject() prints a HEAD 404 error message to console.
      // How to avoid this? Custom logger for AWS.logger.logger:
      // https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/logging-sdk-calls.html
      if (error instanceof Error && error.name === 'NotFound') return false;
      throw error;
    }
  }

  private encodeKey(from: Uint8Array): string {
    return this.prefix + '/' + buf2hex(from);
  }

  toStoreConfig(): StoreConfig {
    return {
      type: 'S3',
      bucket: this.bucket,
      prefix: this.prefix,
      uuid: this.uuid,
      storageLimit: this.storageLimit,
      ...this.credentials,
    };
  }
}
