import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import NameBadge from '../components/Badge/NameBadge';
import DropdownMenu from '../components/DropdownMenu';
import { Confirm, ConfirmModalType } from '../components/Modal/ConfirmModal';
import { MenuContextType, useMenuContext } from '../context/MenuContext';
import { useStorroApi } from '../context/StorroApiContext';
import useRealm from './realm/UseRealm';
import useDesktopClientModal from './UseDesktopClientModal';
import useUser from './useUser';
import {
  BellSimple,
  CaretRight,
  CurrencyCircleDollar,
  Faders,
  Folder,
  Gauge,
  GearSix,
  Laptop,
  LinkSimple,
  List,
  ListChecks,
  ShieldCheckered,
  SignOut,
  UploadSimple,
  User as UserIcon,
  UsersThree,
} from '@phosphor-icons/react';
import useScreenSize from './UseScreenSize';
import { ScreenSize } from 'types/ScreenSize';

export interface MenuItem {
  path: string;
  title: string;
  shortName?: string;
  icon: ReactNode;
  badgeCount?: number;
}

interface MobileMenuItem extends Pick<MenuItem, 'title' | 'icon'> {
  onClick?: () => void;
  path?: string;
  className?: string;
}

export enum MenuMode {
  HIDDEN = 'hidden', // the menu is hidden
  SMALL = 'small', // this is the small mode, e.g. when we just show the icons in the left sidebar
  LARGE = 'large', // the default mode, e.g. we show the full menu
}

export enum MenuWidth {
  SMALL = 50,
  LARGE = 210,
}

function useMenu(): MenuContextType & {
  menuItems: MenuItem[];
  mobileMenuItems: MenuItem[];
  adminMenuItems: MenuItem[];
  accountMenuItemMobile: MobileMenuItem[];
  logoutMenuItemMobile: MobileMenuItem;
  accountMenuItemWeb: () => JSX.Element;
  menuMode: MenuMode;
} {
  const { isOpen, setIsOpen } = useMenuContext();
  const { storroApi } = useStorroApi();
  const { realm: loadedRealm, realms, loadRealms, setRealm, setRealms } = useRealm();
  const { user, superAdminMode, setSuperAdminMode, setUser } = useUser();
  const { openModal: openDesktopClientModal, dialog: desktopClientDialog } = useDesktopClientModal();
  const navigate = useNavigate();
  const { width } = useScreenSize();

  /**
   * Switch to super admin mode
   */
  const enableSuperAdminMode = useCallback(() => {
    Confirm({
      title: 'Super admin mode',
      text: <>Are you sure you want to switch to super admin mode?</>,
      confirmText: 'Switch to super admin mode',
      onConfirm: () => {
        storroApi.setSuperAdminMode(true);
        setSuperAdminMode(true);
        loadRealms();
        if (realms.length > 0) {
          setRealm(realms[0]);
          navigate(`/realm/${realms[0].publicKey}`);
        }
      },
    });
  }, [loadRealms, navigate, realms, setRealm, setSuperAdminMode, storroApi]);

  const logout = useCallback(
    () =>
      Confirm({
        title: 'Logout',
        text: 'Are you sure you want to logout?',
        type: ConfirmModalType.Danger,
        onConfirm: () => {
          setUser(undefined);
          setRealms([]);
          setRealm(undefined);
          storroApi.logout();

          // set the path to / so the user can login
          // and won't be navigated to the current url
          navigate('/');
        },
      }),
    [navigate, setRealm, setRealms, setUser, storroApi],
  );

  // when the realm is not loaded, e.g. because the url is not pointing to the correct realm and the user is on a different url (e.g. /account)
  // we need to return the first realm from the realm lists.
  const realm = useMemo(() => {
    if (loadedRealm) return loadedRealm;

    return realms.length > 0 ? realms[0] : undefined;
  }, [loadedRealm, realms]);

  // Menu items that we use for the left-sidebar and in the mobile-overview page
  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        path: `/realm/${realm?.publicKey}/projects`,
        title: 'Files',
        icon: <Folder />,
      },
      // {
      //   path: `/realm/${realm?.publicKey}/recent`,
      //   title: 'Recent',
      //   icon: <ClockCounterClockwise />,
      // },
      {
        path: `/realm/${realm?.publicKey}/quickshare`,
        title: 'QuickShare',
        icon: <LinkSimple />,
      },
      {
        path: `/realm/${realm?.publicKey}/request`,
        title: 'File Requests',
        shortName: 'Requests',
        icon: <UploadSimple />,
      },
    ];
  }, [realm?.publicKey]);

  // Menu items for the mobile menu bar in the footer
  const mobileMenuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        path: `/realm/${realm?.publicKey}/projects`,
        title: 'Files',
        icon: <Folder />,
      },
      {
        path: `/realm/${realm?.publicKey}/quickshare`,
        title: 'QuickShare',
        icon: <LinkSimple />,
      },
      {
        path: `/realm/${realm?.publicKey}/request`,
        title: 'File Requests',
        shortName: 'Requests',
        icon: <UploadSimple />,
      },
      {
        path: '/overview',
        title: 'More',
        shortName: 'More',
        icon: <List />,
      },
    ];
  }, [realm?.publicKey]);

  const adminMenuItems = useMemo<MenuItem[]>(() => {
    const items: MenuItem[] = [
      {
        path: `/realm/${realm?.publicKey}/admin/stats`,
        title: 'Statistics',
        icon: <Gauge />,
      },
      {
        path: `/realm/${realm?.publicKey}/admin/settings`,
        title: 'Settings',
        icon: <GearSix />,
      },
      {
        path: `/realm/${realm?.publicKey}/admin/policies`,
        title: 'Policies',
        icon: <ListChecks />,
      },
      {
        path: `/realm/${realm?.publicKey}/admin/users`,
        title: 'Users',
        icon: <UsersThree />,
      },
      {
        path: `/realm/${realm?.publicKey}/admin/billing`,
        title: 'Billing',
        icon: <CurrencyCircleDollar />,
      },
    ];

    if (storroApi.superAdminMode && user?.isSuperAdmin) {
      items.push({
        path: `/realm/${realm?.publicKey}/admin/console`,
        title: 'Console',
        icon: <Faders />,
      });
    }
    return items;
  }, [realm?.publicKey, storroApi.superAdminMode, user?.isSuperAdmin]);

  /**
   * Menu items for the mobile view
   */
  const accountMenuItemMobile = useMemo<MobileMenuItem[]>(() => {
    return [
      {
        title: 'Edit Account',
        icon: <UserIcon />,
        path: '/account/manage',
      },
      {
        title: 'Devices',
        icon: <Laptop />,
        path: '/account/devices',
      },
      {
        title: 'Security',
        icon: <ShieldCheckered weight='fill' />,
        path: '/account/security',
      },
      {
        title: 'Notification',
        icon: <BellSimple />,
        path: '/account/notifications',
      },
    ];
  }, []);

  /**
   * The logout button should be separated on mobile
   */
  const logoutMenuItemMobile = useMemo<MobileMenuItem>(() => {
    return {
      title: 'Logout',
      icon: <SignOut />,
      onClick: logout,
      className: 'text-red-500',
    };
  }, [logout]);

  /**
   * Set the menu mode based on the screen size
   * When the screen size is between md and lg, we should use the small mode
   */
  const menuMode = useMemo(() => {
    return width > ScreenSize['2xl'] ? MenuMode.LARGE : width > ScreenSize['md'] ? MenuMode.SMALL : MenuMode.HIDDEN;
  }, [width]);

  /**
   * Ensure the menu is open/closed based on the menu mode
   */
  useEffect(() => {
    // check also the local storage if the user has set the menu
    // to open we can open it if possible on large screens
    const currentLocalState = localStorage.getItem('leftMenuState');
    if (currentLocalState === 'open') {
      setIsOpen(menuMode === MenuMode.LARGE);
    }
  }, [menuMode, setIsOpen]);

  /**
   * This return an Element with a Badge and name of the user
   * If the user click on this element we show a dropDown menu
   */
  const accountMenuItemWeb = useCallback(() => {
    return (
      <>
        {storroApi.keys && (
          <DropdownMenu
            buttonClassName='w-full'
            menuPlacement='right'
            menuItems={[
              [
                {
                  element: 'Account',
                  isHeader: true,
                },
                {
                  isVisible: !superAdminMode && user?.isSuperAdmin === true,
                  element: <>Super admin mode</>,
                  onClick: () => {
                    enableSuperAdminMode();
                    menuMode === MenuMode.SMALL && setIsOpen(false);
                  },
                },
                {
                  element: <>Edit account</>,
                  onClick: () => {
                    navigate('/account/personal');
                    menuMode === MenuMode.SMALL && setIsOpen(false);
                  },
                },
                {
                  element: <>Devices</>,
                  onClick: () => {
                    navigate('/account/devices');
                    menuMode === MenuMode.SMALL && setIsOpen(false);
                  },
                },
                {
                  element: <>Desktop client</>,
                  onClick: openDesktopClientModal,
                },
                {
                  element: <>Create Realm</>,
                  onClick: () => {
                    navigate('/realm/create');
                    menuMode === MenuMode.SMALL && setIsOpen(false);
                  },
                },
              ],
              [
                {
                  element: <span className='text-danger-700'>Logout</span>,
                  onClick: logout,
                },
              ],
            ]}
          >
            <a className='flex items-center gap-x-2' href='#' onClick={event => event.preventDefault()}>
              <NameBadge
                size={isOpen ? 'base' : 'sm'}
                userPublicKeyBase64={storroApi.keys.userPublicKey.toBase64()}
                firstName={user?.firstName}
                lastName={user?.lastName}
              />
              {isOpen && (
                <div className='text-sm text-left truncate'>
                  <p title={`${user?.firstName} ${user?.lastName}`}>
                    {user?.firstName} {user?.lastName}
                  </p>
                  <p className='text-xs'>{user?.email}</p>
                </div>
              )}
              <CaretRight />
            </a>
          </DropdownMenu>
        )}

        {desktopClientDialog}
      </>
    );
  }, [
    desktopClientDialog,
    enableSuperAdminMode,
    isOpen,
    logout,
    menuMode,
    navigate,
    openDesktopClientModal,
    setIsOpen,
    storroApi.keys,
    superAdminMode,
    user?.email,
    user?.firstName,
    user?.isSuperAdmin,
    user?.lastName,
  ]);

  return {
    isOpen,
    setIsOpen,
    menuItems,
    mobileMenuItems,
    adminMenuItems,
    accountMenuItemWeb,
    accountMenuItemMobile,
    logoutMenuItemMobile,
    menuMode,
  };
}

export default useMenu;
