import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { logger } from '../util/Logger';
import { PageButtonAction } from 'components/Page/PageButtons';

interface Props {
  actions?: PageButtonAction[];
  selectionActions?: PageButtonAction[];
  selectionCount?: number;
  children: React.ReactNode;
}

type PageActionContextType = {
  actions: PageButtonAction[] | undefined;
  setActions: Dispatch<SetStateAction<PageButtonAction[] | undefined>>;

  selectionActions: PageButtonAction[] | undefined;
  setSelectionActions: Dispatch<SetStateAction<PageButtonAction[] | undefined>>;

  selectionCount: number;
  setSelectionCount: Dispatch<SetStateAction<number>>;
};

const PageActionContext = createContext<PageActionContextType>({
  actions: undefined,
  setActions: () => logger.warn('no Page provider'),

  selectionActions: undefined,
  setSelectionActions: () => logger.warn('no Page provider'),

  selectionCount: 0,
  setSelectionCount: () => logger.warn('no Page provider'),
});

export function usePageActionContext(): PageActionContextType {
  return useContext(PageActionContext);
}

export function PageActionProvider({ children }: Props): JSX.Element {
  const [actions, setActions] = useState<PageButtonAction[] | undefined>(undefined);
  const [selectionActions, setSelectionActions] = useState<PageButtonAction[] | undefined>(undefined);
  const [selectionCount, setSelectionCount] = useState<number>(0);

  return (
    <PageActionContext.Provider value={{ actions, setActions, selectionActions, setSelectionActions, selectionCount, setSelectionCount }}>
      {children}
    </PageActionContext.Provider>
  );
}
