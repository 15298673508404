import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import Storro from '../api';
import { logger } from '../util/Logger';

interface Props {
  storroApi: Storro;
  children: React.ReactNode;
}

type StorroApiContextType = {
  storroApi: Storro;
  setStorroApi: Dispatch<SetStateAction<Storro>>;
};

const StorroApiContext = createContext<StorroApiContextType>({
  storroApi: {} as Storro,
  setStorroApi: () => logger.warn('No storroApi provider'),
});

export function useStorroApi(): StorroApiContextType {
  return useContext(StorroApiContext);
}

export function StorroApiProvider({ children, storroApi: givenStorroApi }: Props): JSX.Element {
  const [storroApi, setStorroApi] = useState<Storro>(givenStorroApi);

  return <StorroApiContext.Provider value={{ storroApi, setStorroApi }}>{children}</StorroApiContext.Provider>;
}
