import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { logger } from '../util/Logger';

interface Props {
  bannerIsVisible?: boolean;
  children: React.ReactNode;
}

type BannerContextType = {
  bannerIsVisible: Props['bannerIsVisible'];
  setBannerIsVisible: Dispatch<SetStateAction<Props['bannerIsVisible']>>;
};

const BannerContext = createContext<BannerContextType>({
  bannerIsVisible: undefined,
  setBannerIsVisible: () => logger.warn('no Banner provider'),
});

export function useBannerContext(): BannerContextType {
  return useContext(BannerContext);
}

export function BannerProvider({ children }: Props): JSX.Element {
  const [bannerIsVisible, setBannerIsVisible] = useState<Props['bannerIsVisible']>();

  return <BannerContext.Provider value={{ bannerIsVisible, setBannerIsVisible }}>{children}</BannerContext.Provider>;
}
