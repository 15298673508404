// Copyright 2021 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// Because there is no way to easily compare two different but potentially equivalent
// Uint8Arrays (or any other object), just like the rest of the internet,
// we have to write our own function...
//
// https://stackoverflow.com/questions/21553528/how-to-test-for-equality-in-arraybuffer-dataview-and-typedarray
//
export function equal(left: Uint8Array | undefined, right: Uint8Array | undefined): boolean {
  // undefined
  if (left === undefined) {
    return right === undefined;
  }
  // Uint8Array
  const leftIsUint8Array = left instanceof Uint8Array;
  if (leftIsUint8Array) {
    if (right === undefined) return false;
    const rightIsUint8Array = right instanceof Uint8Array;
    if (!rightIsUint8Array) {
      throw new Error('left and right need both be a Uint8Array');
    }
    if (left.length !== right.length) return false;
    for (let i = 0; i < left.length; i++) {
      if (left[i] !== right[i]) return false;
    }
    return true;
  }
  throw new Error('Unsupported types');
}
