import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Portal } from 'react-portal';
import { Project } from 'api/Project';
import { UploadQueueItem } from 'api/UploadQueue';
import { useStorroApi } from 'context/StorroApiContext';
import useUploadQueue from 'hooks/project/UseUploadQueue';
import useMenu from 'hooks/UseMenu';
import useProgressModal from 'hooks/UseProgressModal';
import { logger } from 'util/Logger';
import { humanFileSize } from 'util/Util/HumanReadableSize';
import Spinner, { SpinnerSize } from '../Spinner';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';

interface FinishedSummary {
  success: boolean;
  message: string;
}

interface Props {
  mobileMenuRef: React.RefObject<HTMLElement | null>;
}

export default function Progress({ mobileMenuRef }: Props): JSX.Element {
  const [finishedSummary, setFinishedSummary] = useState<FinishedSummary | undefined>(undefined);

  const { queue, speed, progress } = useUploadQueue();
  const { isOpen: menuIsOpen } = useMenu();
  const { openModal, dialog } = useProgressModal();
  const { storroApi } = useStorroApi();
  const isMobileDevice = useIsMobileDevice();

  useEffect(() => {
    const event = (project: Project, done: UploadQueueItem[]) => {
      let success = 0;
      let failed = 0;
      let cancelled = 0;
      done.forEach(item => {
        if (item.cancelled) {
          cancelled += 1;
        } else if (item.failed) {
          failed += 1;
        } else {
          success += 1;
        }
      });

      if (success === 0 && failed === 0 && cancelled === 0) {
        logger.error('Upload finished with empty upload queue');
        setFinishedSummary(undefined);
      } else if (success > 0 && failed === 0) {
        if (success === 1) {
          setFinishedSummary({ success: true, message: 'Upload finished' });
        } else {
          setFinishedSummary({ success: true, message: `Upload finished, all ${success} files uploaded` });
        }
      } else if (success === 0 && failed > 0) {
        if (failed === 1) {
          setFinishedSummary({ success: false, message: 'Upload failed' });
        } else {
          setFinishedSummary({ success: false, message: 'All uploads failed' });
        }
      } else if (success === 0 && failed === 0 && cancelled > 0) {
        // We cancelled all uploads, just hide the bar.
        setFinishedSummary({ success: false, message: 'Upload cancelled' });
      } else if (success > 0 && failed > 0) {
        setFinishedSummary({ success: false, message: `${failed} upload(s) failed, the other ${success} succeeded` });
      }
    };
    storroApi.projectList.subscribeUploadFinishedCallback(event);
    return () => {
      storroApi.projectList.unsubscribeUploadFinishedCallback(event);
    };
  }, [storroApi.projectList, setFinishedSummary]);

  if (queue.length > 0 || finishedSummary) {
    return (
      <Portal node={document && document.getElementById('root')}>
        <div
          // Use the mobile menu height to position the progress bar
          style={{ bottom: mobileMenuRef.current?.offsetHeight ?? 0 }}
          className={classNames('fixed bottom-0 w-full z-40', {
            'z-10': menuIsOpen,
          })}
        >
          <div
            className={classNames(
              'w-full md:w-1/2 z-10 flex justify-between items-center bg-gray-800 opacity-95 text-white px-4 py-3 relative',
              {
                'left-[50%] -translate-x-[50%]': !isMobileDevice,
              },
            )}
          >
            <div
              className={classNames('absolute top-0 left-0 h-[4px] transition-[width] duration-700', {
                'bg-green-600': queue.length === 0 && finishedSummary?.success,
                'bg-red-600': queue.length === 0 && !finishedSummary?.success,
                'bg-primary': queue.length !== 0,
              })}
              style={{ width: `${queue.length === 0 ? 100 : progress}%` }}
            />
            <div onClick={() => openModal()}>
              <span>
                {queue.length !== 0 && (
                  <>
                    Uploading {queue.length} file(s) with {humanFileSize(speed)}/s
                  </>
                )}
              </span>
              <span>{queue.length === 0 && <>{finishedSummary?.message}</>}</span>
            </div>

            <div className='flex items-center'>
              {!isMobileDevice && (
                <span className='mr-2 cursor-pointer' onClick={() => openModal()}>
                  View details
                </span>
              )}

              {queue.length !== 0 && (
                <div className='flex items-center'>
                  {progress}% <Spinner inverseColor={true} size={SpinnerSize.Small} />
                </div>
              )}

              {queue.length === 0 && (
                <span
                  className='cursor-pointer'
                  onClick={() => {
                    setFinishedSummary(undefined);
                  }}
                >
                  Close
                </span>
              )}
            </div>
          </div>
        </div>

        {dialog}
      </Portal>
    );
  }

  return <></>;
}
