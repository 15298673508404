import React from 'react';
import useIsMobileDevice from '../../hooks/UseIsMobileDevice';

interface Props {
  webComponent: JSX.Element;
  mobileComponent: JSX.Element;
}

/**
 * This hook is able to switch between 2 components
 * by checking if it is an mobile device
 */
function MobileComponentSwitcher({ webComponent, mobileComponent }: Props): JSX.Element {
  const isMobileDevice = useIsMobileDevice();

  if (isMobileDevice === null) {
    return <></>;
  }

  if (isMobileDevice) {
    return mobileComponent;
  }

  return webComponent;
}

export default MobileComponentSwitcher;
