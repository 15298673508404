import React, { createContext, useContext, useState } from 'react';
import { logger } from '../util/Logger';

interface Props {
  loading?: boolean;
  loadingText?: string;
  children: React.ReactNode;
}

type LoadingContextType = {
  loading: boolean | undefined;
  setLoading: (loading: boolean) => void;
  loadingText: string | undefined;
  setLoadingText: (loadingText: string | undefined) => void;
};

const LoadingContext = createContext<LoadingContextType>({
  loading: undefined,
  setLoading: () => logger.warn('no PageLoader provider'),
  loadingText: undefined,
  setLoadingText: () => logger.warn('no PageLoader provider'),
});

export function usePageLoader(): LoadingContextType {
  return useContext(LoadingContext);
}

export function PageLoaderProvider({ children, loading }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean | undefined>(loading);
  const [loadingText, setLoadingText] = useState<string | undefined>();

  return (
    <LoadingContext.Provider
      value={{
        loading: isLoading,
        setLoading: setIsLoading,
        loadingText,
        setLoadingText,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}
