import base64url from 'base64url';
import {
  crypto_box_easy,
  crypto_box_MACBYTES,
  crypto_box_NONCEBYTES,
  crypto_box_open_easy,
  crypto_box_seal_open,
  crypto_sign,
  crypto_sign_ed25519_pk_to_curve25519,
  crypto_sign_ed25519_sk_to_curve25519,
  crypto_sign_keypair,
  KeyPair,
  randombytes_buf,
} from 'libsodium-wrappers';
import { PublicKey } from './PublicKey';

// A private key helper class that is compatible with the c++ client code.
export class PrivateKey {
  private privateCryptoKey: Uint8Array;

  constructor(private privateSignatureKey: Uint8Array) {
    if (this.privateSignatureKey.length !== 64) {
      throw Error(`Invalid private key length of ${this.privateSignatureKey.length}`);
    }
    this.privateCryptoKey = crypto_sign_ed25519_sk_to_curve25519(this.privateSignatureKey);
    if (this.privateCryptoKey.length !== 32) {
      throw Error('Invalid private key crypto length');
    }
  }

  public key(): Uint8Array {
    return this.privateSignatureKey;
  }

  public toBase64(): string {
    return base64url.encode(Buffer.from(this.privateSignatureKey));
  }

  // Generate a new random private key.
  public static random(): PrivateKey {
    let keyPair: KeyPair = crypto_sign_keypair();
    while (base64url.encode(Buffer.from(keyPair.publicKey)).startsWith('-')) {
      keyPair = crypto_sign_keypair();
    }
    return new PrivateKey(keyPair.privateKey);
  }

  public publicKey(): PublicKey {
    // Copied the implementation of libSodiums crypto_sign_ed25519_sk_to_pk() for
    // this because it's not available in libsodium-wrappers.

    const seedBytes = 32; // crypto_sign_ed25519_SEEDBYTES
    const pubKeyBytes = 32; // crypto_sign_ed25519_PUBLICKEYBYTES
    return new PublicKey(this.privateSignatureKey.slice(seedBytes, seedBytes + pubKeyBytes));
  }

  private concatByteArrays(left: Uint8Array, right: Uint8Array) {
    const res = new Uint8Array(left.length + right.length);
    res.set(left, 0);
    res.set(right, left.length);
    return res;
  }

  // Encrypt a message for the receiver. If the receiver is empty then our
  // public key is used.
  public encrypt(message: Uint8Array, receiverKey?: PublicKey): Uint8Array {
    const pubKey = receiverKey ?? this.publicKey();
    if (!crypto_box_NONCEBYTES) {
      throw Error('crypto_box_NONCEBYTES const is undefined');
    }
    const nonce: Uint8Array = randombytes_buf(crypto_box_NONCEBYTES);
    const encrypted: Uint8Array = crypto_box_easy(
      message,
      nonce,
      crypto_sign_ed25519_pk_to_curve25519(pubKey.key()),
      this.privateCryptoKey,
    );
    return this.concatByteArrays(nonce, encrypted);
  }

  // Decrypt a message that was meant for this PrivateKey. If the senderKey is
  // empty then our public key is used.
  public decrypt(message: Uint8Array, senderKey?: PublicKey): Uint8Array {
    const pubKey = senderKey ?? this.publicKey();

    if (!crypto_box_NONCEBYTES) {
      throw Error('crypto_box_NONCEBYTES const is undefined');
    }

    if (!crypto_box_MACBYTES) {
      throw Error('crypto_box_MACBYTES const is undefined');
    }

    if (message.length <= crypto_box_NONCEBYTES + crypto_box_MACBYTES) {
      throw Error('Box is too small for encrypted data');
    }
    const nonce: Uint8Array = message.slice(0, crypto_box_NONCEBYTES);
    const cipherText: Uint8Array = message.slice(crypto_box_NONCEBYTES);

    const decrypted: Uint8Array = crypto_box_open_easy(
      cipherText,
      nonce,
      crypto_sign_ed25519_pk_to_curve25519(pubKey.key()),
      this.privateCryptoKey,
    );

    return decrypted;
  }

  public sign(message: Uint8Array): Uint8Array {
    return crypto_sign(message, this.privateSignatureKey);
  }

  /**
   * Sealed boxes are designed to anonymously send messages to a recipient
   * given its public key. Only the recipient can decrypt these messages, using
   * its private key. While the recipient can verify the integrity of the
   * message, it cannot verify the identity of the sender.
   *
   * @param ciphertext
   */
  public decryptSealed(ciphertext: Uint8Array): Uint8Array {
    return crypto_box_seal_open(ciphertext, crypto_sign_ed25519_pk_to_curve25519(this.publicKey().key()), this.privateCryptoKey);
  }
}
