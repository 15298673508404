import { encode } from '@stablelib/utf8';
import base64url from 'base64url';
import { crypto_generichash, crypto_generichash_BYTES_MIN } from 'libsodium-wrappers';
import { concat } from '../util/Util/Concat';
import { randomUint8Array } from '../util/Util/RandomUint8Array';

/**
 * A helper class for generating and parsing QuickShare url hashes
 *
 * A QuickShare url hash is provided at the end of the quick share url. We make
 * use of this mechanism because the information after a # in an url is not sent
 * to the server, thus can contain information that only the client should know.
 *
 * = QuickShare without a password =
 * When a quick share is generated without a password we generate a random
 * password. This is required because otherwise the api could read the contents
 * of a quick share which is not e2e encryption. The 'P' indicates that the
 * that it's the plaintext (random) password.
 * [ http://url/qs ] #${quickShareMemberId}P${randomGeneratedPassword}
 *
 * = QuickShare with a password =
 * When a quick share is generated with a password we pass the password hash in
 * the url. The 'H' indicates that the that it's the hash of the password.
 * [ http://url/qs ] #${quickShareMemberId}H${hashOfUserPickedPassword}
 *
 */
export class QuickShareUrlHash {
  public readonly memberId: number;
  public readonly passwordHash: string;
  public readonly password?: string;
  public readonly encryptedPath: string;

  constructor(urlHash: string) {
    if (!urlHash.startsWith('#')) {
      throw new Error('Url hash should start with a #');
    }

    // Chop of the #
    urlHash = urlHash.substring(1);

    // The hash includes also the encrypted path. Grab that part and restore the encrypted path.
    const hashSgments = urlHash.split('/');
    const quickShareHash = hashSgments.shift() as string;

    // assign the encryptedPath to the class
    this.encryptedPath = '/' + hashSgments.join('/');

    // Find the P or H. Indicating that the plaintext pwd or pwd hash is provided.
    const indexOfP = quickShareHash.indexOf('P');
    const indexOfH = quickShareHash.indexOf('H');

    if (indexOfP !== -1 && (indexOfH === -1 || indexOfP < indexOfH)) {
      this.memberId = parseInt(quickShareHash.substring(0, indexOfP));
      if (isNaN(this.memberId)) {
        throw new Error('Invalid url hash');
      }
      this.password = quickShareHash.substring(indexOfP + 1);
      this.passwordHash = QuickShareUrlHash.saltedPasswordHash(this.password);
    } else if (indexOfH !== -1 && (indexOfP === -1 || indexOfH < indexOfP)) {
      this.memberId = parseInt(quickShareHash.substring(0, indexOfH));
      if (isNaN(this.memberId)) {
        throw new Error('Invalid url hash');
      }
      this.passwordHash = quickShareHash.substring(indexOfH + 1);
    } else {
      throw new Error('Invalid url hash');
    }
  }

  public static saltedPasswordHash(password: string): string {
    // We use a static client side salt for our password to prevent generic
    // rainbow table attacks.
    const salt = new Uint8Array(base64url.toBuffer('ZO3bOorBRrs1Zw5pA7t5Er1FwEq6aJZ1DNAIAmHnw3k'));

    if (!crypto_generichash_BYTES_MIN) {
      throw Error('crypto_generichash_BYTES_MIN const is undefined');
    }

    const hash = crypto_generichash(crypto_generichash_BYTES_MIN, concat([encode(password), salt]));
    return base64url.encode(Buffer.from(hash));
  }

  public static format(memberId: number, password: string, includePwd: boolean): string {
    if (includePwd) {
      return `#${memberId}P${password}`;
    } else {
      return `#${memberId}H${QuickShareUrlHash.saltedPasswordHash(password)}`;
    }
  }

  public static formatWithPassword(memberId: number, password: string): string {
    return `#${memberId}P${password}`;
  }
  public static formatWithPasswordHash(memberId: number, passwordHash: string): string {
    return `#${memberId}H${passwordHash}`;
  }

  public static generateWithoutPassword(quickShareMemberId: number): string {
    const pwd = base64url.encode(Buffer.from(randomUint8Array(16)));
    return `#${quickShareMemberId}P${pwd}`;
  }

  public static generateWithPassword(quickShareMemberId: number, password: string): string {
    const pwdHash = QuickShareUrlHash.saltedPasswordHash(password);
    return `#${quickShareMemberId}H${pwdHash}`;
  }
}
