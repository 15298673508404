import Extensions from './extensionList.json';

export type ExtensionTypes =
  | 'archive'
  | 'audio'
  | 'book'
  | 'code'
  | 'exec'
  | 'font'
  | 'image'
  | 'excel'
  | 'slide'
  | 'word'
  | 'pdf'
  | 'text'
  | 'video'
  | 'web'
  | 'adobePhotoshop'
  | 'adobePremiere'
  | 'adobeIllustrator'
  | 'adobeInDesign'
  | 'adobeAfterEffects'
  | 'adobeXD'
  | 'default';

/**
 * Get the type from an extension
 */
export default function getFileExtensionType(extension: string): ExtensionTypes | undefined {
  for (const [type, extensions] of Object.entries(Extensions)) {
    if (extensions.includes(extension.toLocaleLowerCase())) {
      return type as ExtensionTypes;
    }
  }
}
