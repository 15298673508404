import React from 'react';
import Loading from './components/Loading';
import { usePageLoader } from './context/PageLoaderContext';

interface Props {
  children?: React.ReactNode;
}

/**
 * The full page loader that will be wrapped around <App />
 * We put it in a separated module so the <App /> can return the routes early without complex conditions
 */
export default function LoaderWrapper({ children }: Props): JSX.Element {
  const { loading, loadingText } = usePageLoader();

  return (
    <>
      <Loading initialWithoutFade={true} visible={loading} loadingText={loadingText} />
      {children}
    </>
  );
}
