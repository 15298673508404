import base64url from 'base64url';
import { PrivateKey } from './PrivateKey';
import { PublicKey } from './PublicKey';

export function twoHoursFromNow(): Date {
  return new Date(Date.now() + 2 * 60 * 60 * 1000);
}

export class JsonWebToken {
  private payload: any; /* eslint-disable-line @typescript-eslint/no-explicit-any */
  private token: string;
  private signature: string;

  static parse(jwt: string): JsonWebToken {
    const token = new JsonWebToken();
    const splits = jwt.split('.');
    if (splits.length !== 3) throw Error('Invalid token');

    token.payload = JSON.parse(base64url.decode(splits[1]));
    token.token = `${splits[0]}.${splits[1]}`;
    token.signature = splits[2];

    return token;
  }

  /**
   * This function converts an object to a base64url string sorting it before
   * converting.
   *
   * @param object the object to encode to a base64url string.
   */
  private static toBase64Url(object: any): string /* eslint-disable-line @typescript-eslint/no-explicit-any */ {
    return base64url.encode(JSON.stringify(object));
  }

  /**
   * This method accepts a payload and an expiration time and returns a json web
   * token containing the payload signed with Ed25519 algorithm.
   *
   * @param privateKey the private key used to sign the token.
   * @param payload the json web token payload.
   * @param expirationDate the date time after which the token expires.
   */
  static generate(privateKey: PrivateKey, payload: unknown, expirationDate = twoHoursFromNow()): JsonWebToken {
    const token = new JsonWebToken();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    token.payload = Object.assign(payload, { exp: Math.floor(expirationDate.getTime() / 1000) });
    token.token = `${this.toBase64Url(
      sortObject({
        alg: 'Ed25519',
        typ: 'JWT',
      }),
    )}.${this.toBase64Url(sortObject(token.payload))}`;
    const encoder = new TextEncoder();
    const encoded = new Uint8Array(encoder.encode(token.token));
    const signed = privateKey.sign(encoded);
    token.signature = base64url.encode(Buffer.from(signed));
    return token;
  }

  toString(): string {
    return `${this.token}.${this.signature}`;
  }

  getValue(key: string): string | undefined {
    return this.payload[key];
  }

  /**
   * This function is used to decode the json web token
   * and verify the expiration date and the signature.
   */
  verify(publicKey: PublicKey): boolean {
    const encoder = new TextEncoder();
    return publicKey.verify(encoder.encode(this.token), base64url.toBuffer(this.signature));
  }

  isExpired(): boolean {
    return Number(this.getValue('exp')) <= Math.floor(Date.now() / 1000);
  }
}

/**
 * This function sorts the object by keys alphabetically
 *
 * @param object object to be sorted and then encoded to base64Url.
 */
function sortObject(object: any) /* eslint-disable-line @typescript-eslint/no-explicit-any */ {
  // This type is required to add support for index operator to Object.
  type Dictionary = { [index: string]: string };
  const entries = Object.entries(object);
  entries.sort();
  const sortedObject = entries.reduce((sortedObject: Dictionary, item) => {
    sortedObject[item[0]] = item[1] as any; /* eslint-disable-line @typescript-eslint/no-explicit-any */
    return sortedObject;
  }, {});

  return sortedObject;
}
