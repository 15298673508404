import base64url from 'base64url';
import { crypto_box_seal, crypto_sign_ed25519_pk_to_curve25519, crypto_sign_open } from 'libsodium-wrappers';

// A public key helper class that is compatible with the c++ client code.
export class PublicKey {
  constructor(private rawKey: Uint8Array) {}

  key(): Uint8Array {
    return this.rawKey;
  }

  toBase64(): string {
    return base64url.encode(Buffer.from(this.rawKey));
  }

  static fromBase64(value: string): PublicKey {
    return new PublicKey(base64url.toBuffer(value));
  }

  verify(originalMessage: Uint8Array, signedMessage: Uint8Array): boolean {
    try {
      return Buffer.from(crypto_sign_open(signedMessage, this.rawKey)).equals(originalMessage);
    } catch (err) {
      // crypto_sign_open threw
      return false;
    }
  }

  /**
   * Sealed boxes are designed to anonymously send messages to a recipient
   * given its public key. Only the recipient can decrypt these messages, using
   * its private key. While the recipient can verify the integrity of the
   * message, it cannot verify the identity of the sender.
   *
   * @param message
   */
  public encryptSealed(message: Uint8Array): Uint8Array {
    return crypto_box_seal(message, crypto_sign_ed25519_pk_to_curve25519(this.rawKey));
  }
}
