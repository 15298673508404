import { encode } from '@stablelib/utf8';
import base64url from 'base64url';
import {
  crypto_aead_xchacha20poly1305_ietf_ABYTES,
  crypto_aead_xchacha20poly1305_ietf_decrypt,
  crypto_aead_xchacha20poly1305_ietf_encrypt,
  crypto_aead_xchacha20poly1305_ietf_KEYBYTES,
  crypto_aead_xchacha20poly1305_ietf_NPUBBYTES,
  crypto_generichash,
  crypto_pwhash,
  crypto_pwhash_ALG_ARGON2I13,
  crypto_pwhash_SALTBYTES,
  randombytes_buf,
} from 'libsodium-wrappers';

export enum Algorithm {
  XCHACHA = 1,
  AESGCM = 2,
}

function concatByteArrays(left: Uint8Array, right: Uint8Array) {
  const res = new Uint8Array(left.length + right.length);
  res.set(left, 0);
  res.set(right, left.length);
  return res;
}

//
// We would like to support AES GCM from libsodium too
// but for that we need these symbols, which are not exported. -- sad!
//
// crypto_aead_aes256gcm_ABYTES,
// crypto_aead_aes256gcm_decrypt,
// crypto_aead_aes256gcm_encrypt,
// crypto_aead_aes256gcm_KEYBYTES,
// crypto_aead_aes256gcm_NPUBBYTES,
//

// We define this interface for implementations of symmetric key to use (internally for SymmetricKey)
interface Inner {
  encrypt(plaintext: Uint8Array, nonce: Uint8Array, associated: Uint8Array | null): Uint8Array;
  decrypt(ciphertext: Uint8Array, nonce: Uint8Array, associated: Uint8Array | null): Uint8Array;
  encryptSimple(plaintext: Uint8Array): Uint8Array;
  decryptSimple(message: Uint8Array): Uint8Array;
  nonceBytes(): number;
  tagBytes(): number;
  getRawKey(): Uint8Array;
}

export class XChaCha implements Inner {
  rawKey: Uint8Array;

  constructor(rawKey: Uint8Array) {
    if (!crypto_aead_xchacha20poly1305_ietf_KEYBYTES) {
      throw Error('crypto_aead_xchacha20poly1305_ietf_KEYBYTES const is undefined');
    }

    if (rawKey.length !== crypto_aead_xchacha20poly1305_ietf_KEYBYTES) throw Error('Key is of incorrect size for XChaCha');
    this.rawKey = rawKey;
  }

  public encrypt(plaintext: Uint8Array, nonce: Uint8Array, associated: Uint8Array | null): Uint8Array {
    if (!crypto_aead_xchacha20poly1305_ietf_NPUBBYTES) {
      throw Error('crypto_aead_xchacha20poly1305_ietf_NPUBBYTES const is undefined');
    }

    if (nonce.length !== crypto_aead_xchacha20poly1305_ietf_NPUBBYTES) {
      throw Error('Nonce is of incorrect size for XChaCha');
    }
    return crypto_aead_xchacha20poly1305_ietf_encrypt(plaintext, associated, null, nonce, this.rawKey);
  }

  public encryptSimple(plaintext: Uint8Array): Uint8Array {
    const nonce = randombytes_buf(crypto_aead_xchacha20poly1305_ietf_NPUBBYTES);
    return concatByteArrays(nonce, this.encrypt(plaintext, nonce, null));
  }

  public decrypt(ciphertext: Uint8Array, nonce: Uint8Array, associated: Uint8Array | null): Uint8Array {
    if (nonce.length !== crypto_aead_xchacha20poly1305_ietf_NPUBBYTES) {
      throw Error('Nonce is of incorrect size for XChaCha');
    }
    return crypto_aead_xchacha20poly1305_ietf_decrypt(null, ciphertext, associated, nonce, this.rawKey);
  }

  public decryptSimple(message: Uint8Array): Uint8Array {
    if (!crypto_aead_xchacha20poly1305_ietf_NPUBBYTES) {
      throw Error('crypto_aead_xchacha20poly1305_ietf_NPUBBYTES const is undefined');
    }

    const nonce = message.slice(0, crypto_aead_xchacha20poly1305_ietf_NPUBBYTES);
    const ciphertext = message.slice(crypto_aead_xchacha20poly1305_ietf_NPUBBYTES);
    return this.decrypt(ciphertext, nonce, null);
  }

  nonceBytes(): number {
    if (!crypto_aead_xchacha20poly1305_ietf_NPUBBYTES) {
      throw Error('crypto_aead_xchacha20poly1305_ietf_NPUBBYTES const is undefined');
    }
    return crypto_aead_xchacha20poly1305_ietf_NPUBBYTES;
  }

  tagBytes(): number {
    if (!crypto_aead_xchacha20poly1305_ietf_ABYTES) {
      throw Error('crypto_aead_xchacha20poly1305_ietf_ABYTES const is undefined');
    }
    return crypto_aead_xchacha20poly1305_ietf_ABYTES;
  }

  getRawKey(): Uint8Array {
    return this.rawKey;
  }
}

// A SymmetricKey helper class that is compatible with our c++ client code.
// This SymmetricKey is meant for the user account only.
export class SymmetricKey {
  static keyBytes = 32;
  private algo: Algorithm;
  private inner: Inner;

  constructor(rawKey: Uint8Array, algo: Algorithm = Algorithm.XCHACHA) {
    if (algo === Algorithm.XCHACHA) this.inner = new XChaCha(rawKey);
    else throw Error('Unknown algorithm');
    this.algo = algo;
  }

  static createUsingPassword(password: string, salt: Uint8Array): SymmetricKey {
    if (password.length === 0) {
      throw Error('Empty password is not allowed.');
    }

    if (!crypto_pwhash_SALTBYTES) {
      throw Error('crypto_pwhash_SALTBYTES const is undefined');
    }

    if (!crypto_pwhash_SALTBYTES) {
      throw Error('crypto_pwhash_SALTBYTES const is undefined');
    }

    if (salt.length !== crypto_pwhash_SALTBYTES) {
      throw Error('invalid salt length.');
    }

    // Have a fixed limit that is equal to our cpp implementation. The
    // crypto_pwhash_OPSLIMIT_MODERATE var from libSodium differs from the cpp
    // var crypto_pwhash_argon2i_OPSLIMIT_MODERATE.
    const opsLimit = 6;

    // Have a fixed limit that is equal to our cpp implementation. The
    // crypto_pwhash_MEMLIMIT_MODERATE var from libSodium differs from the cpp
    // var crypto_pwhash_argon2i_MEMLIMIT_MODERATE.
    const memLimit = 134217728;

    let rawKey: Uint8Array;
    if (process.env.NODE_ENV === 'test') {
      // Use faster hashing when in test mode for faster CI.
      rawKey = crypto_generichash(SymmetricKey.keyBytes, password);
    } else {
      rawKey = crypto_pwhash(SymmetricKey.keyBytes, encode(password), salt, opsLimit, memLimit, crypto_pwhash_ALG_ARGON2I13);
    }
    return new SymmetricKey(rawKey, Algorithm.XCHACHA);
  }

  public static fromBase64(input: string): SymmetricKey {
    return new SymmetricKey(base64url.toBuffer(input));
  }

  private static xor(a: Uint8Array, b: Uint8Array): Uint8Array {
    if (a.length !== b.length) {
      throw new Error('Inputs should have the same length');
    }
    const result = new Uint8Array(a.length);
    for (let i = 0; i < a.length; i++) {
      result[i] = a[i] ^ b[i];
    }
    return result;
  }

  // Generate a symmetric key based on multiple SymmetricKeys
  public static fromMultiple(keys: Set<SymmetricKey>): SymmetricKey {
    if (keys.size === 0) {
      throw Error('Key count should be more than 0');
    }
    let xORedKey: Uint8Array | undefined = undefined;
    let algo: Algorithm | undefined = undefined;
    keys.forEach(key => {
      if (xORedKey) {
        if (key.algo !== algo) {
          throw Error('Key algorithms do not match');
        }
        xORedKey = this.xor(xORedKey, key.getRawKey());
      } else {
        algo = key.algo;
        xORedKey = key.getRawKey();
      }
    });

    if (!xORedKey || !algo) {
      throw Error('Failed to create SymmetricKey');
    }

    return new SymmetricKey(xORedKey, algo);
  }

  // Encrypt() amd decrypt() delegate to the inner implementation.
  public encrypt(plaintext: Uint8Array, nonce: Uint8Array, associated: Uint8Array | null = null): Uint8Array {
    return this.inner.encrypt(plaintext, nonce, associated);
  }

  public encryptSimple(plaintext: Uint8Array): Uint8Array {
    return this.inner.encryptSimple(plaintext);
  }

  public decrypt(ciphertext: Uint8Array, nonce: Uint8Array, associated: Uint8Array | null = null): Uint8Array {
    return this.inner.decrypt(ciphertext, nonce, associated);
  }

  public decryptSimple(message: Uint8Array): Uint8Array {
    return this.inner.decryptSimple(message);
  }

  public nonceBytes(): number {
    return this.inner.nonceBytes();
  }

  public tagBytes(): number {
    return this.inner.tagBytes();
  }

  public getRawKey(): Uint8Array {
    return this.inner.getRawKey();
  }

  public static keySizeForAlgo(algo: Algorithm): number {
    if (algo === Algorithm.XCHACHA) return 32;
    throw new Error('Algo not supported');
  }

  public static tagBytesForAlgo(algo: Algorithm): number {
    if (algo === Algorithm.XCHACHA) return 16;
    throw new Error('Algo not supported');
  }

  public static nonceBytesForAlgo(algo: Algorithm): number {
    if (algo === Algorithm.XCHACHA) return 24;
    throw new Error('Algo not supported');
  }

  /////////// New things ///////////////////////////////////////////////////
}
