// Copyright 2022 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// DelayStore is a type of KeyValueStore used for unit tests and debugging.
// You can specify a delay for the operations to simulate network and disk latency.
import { KeyValueStore } from './KeyValueStore';
import { StoreConfig } from './StoreFactory';

export class DelayStore implements KeyValueStore {
  private delay: number; // The delay in milliseconds
  private variance: number; // The variance in milliseconds

  /* eslint-disable-next-line */
  constructor(private child: KeyValueStore, config: any = {}) {
    if (config.delay) {
      this.delay = config.delay;
    } else {
      this.delay = 1000;
    }

    if (config.variance) {
      this.variance = config.variance;
    } else {
      this.variance = 0;
    }
  }

  private getDelay(): number {
    if (this.variance === 0) return this.delay;
    return this.delay - this.variance + 2 * Math.random() * this.variance;
  }

  async hasValue(key: Uint8Array): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => resolve(this.child.hasValue(key)), this.getDelay());
    });
  }

  async getValue(key: Uint8Array): Promise<Uint8Array> {
    return new Promise(resolve => {
      setTimeout(() => resolve(this.child.getValue(key)), this.getDelay());
    });
  }

  public async putValue(key: Uint8Array, value: Uint8Array): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => resolve(this.child.putValue(key, value)), this.getDelay());
    });
  }

  toStoreConfig(): StoreConfig {
    throw Error('DelayStore toStoreConfig not supported');
  }
}
