import classNames from 'classnames';
import { useDialogContext } from 'context/DialogContext';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';
import React, { memo } from 'react';
import { useSidebarRightContext } from 'context/SidebarRightContext';
import useScreenSize from 'hooks/UseScreenSize';
import { ScreenSize } from 'types/ScreenSize';
import touchEnabled from 'util/Util/TouchEnabled';
import Tooltip from '../Tooltip';
import Block from './Block';
import { useBannerContext } from 'context/BannerContext';
import { TextOutdent } from '@phosphor-icons/react';

export const SIDEBAR_WIDTH = {
  Open: 400,
  Closed: 50,
  Hidden: 0,
};

interface Props {
  mobileMenuRef: React.RefObject<HTMLElement | null>;
}

function SidebarRight({ mobileMenuRef }: Props): JSX.Element {
  const { width } = useScreenSize();
  const { items, setIsOpen, isOpen, isHidden } = useSidebarRightContext();
  const { dialogsItems } = useDialogContext();
  const { bannerIsVisible } = useBannerContext();
  const isMobileDevice = useIsMobileDevice();
  const isTouchEnabled = touchEnabled();

  return (
    <div
      style={{ height: `calc(100vh - ${mobileMenuRef.current?.offsetHeight ?? 0}px + 1px)` }}
      className={classNames(
        'fixed right-0 z-10  border-l bg-white border-t',
        'top-0', // mobile
        {
          'md:top-[106px] md:!h-[calc(100vh-106px)]': !bannerIsVisible, // non-mobile
          'md:top-[154px] md:!h-[calc(100vh-154px)]': bannerIsVisible, // non-mobile
          'shadow-xl 2xl:shadow-none': isOpen,
          // we also hide the sidebar when there is a dialog op on mobile
          hidden: items.length === 0 || isHidden || (isMobileDevice && dialogsItems.length > 0),
        },
      )}
    >
      <div
        // Set the width depending on the state
        // for mobile we full
        style={{ width: isOpen ? SIDEBAR_WIDTH.Open : !isMobileDevice ? SIDEBAR_WIDTH.Closed : SIDEBAR_WIDTH.Hidden }}
        className={classNames(
          'flex flex-col h-full transition-fast transition-[width] relative overflow-hidden pt-[calc(env(safe-area-inset-top))]',
          {
            'translate-x-[10px]': !isOpen && isMobileDevice, // on mobile we should remove the sidebar totally to the right
          },
        )}
      >
        <div
          style={{ visibility: width < ScreenSize['3xl'] ? 'visible' : 'hidden' }}
          className={classNames({
            'absolute z-50 right-1 top-[calc(4px+env(safe-area-inset-top))]': isOpen,
            'border-b mb-1 flex justify-center': !isOpen,
          })}
        >
          <Tooltip disabled={isTouchEnabled} arrow={false} content={isOpen ? 'close pane' : 'open pane'} mode='dark' placement='left'>
            <button className='p-3' onClick={() => setIsOpen(prevState => !prevState)}>
              {/* with -scale-x-[1] we flip the icon */}
              <TextOutdent size={16} className={classNames({ '-scale-x-[1]': isOpen })} />
            </button>
          </Tooltip>
        </div>
        {items.map(item => (
          <Block key={item.title} item={item} isOpen={isOpen} setIsOpen={setIsOpen} />
        ))}
      </div>
    </div>
  );
}

// avoid rerendering when props are changed but not value of the props
// this will mostly caused by the context API hooks
export default memo(SidebarRight);
