import classNames from 'classnames';
import React from 'react';
import BrokenBulb from '../../assets/broken-bulb.svg';
import BrokenBulbWhite from '../../assets/broken-bulb-white.svg';
import LogoLight from '../../assets/storro-logo-and-text-light.svg';
import Button, { ButtonVariant } from '../Button';

interface Props {
  title: string;
  text: string;
  showGoHomeButton?: boolean;
  onGoHomeButtonClick?: () => void;
  external?: boolean;
}

export default function PageError({ title, text, showGoHomeButton, external, onGoHomeButtonClick }: Props): JSX.Element {
  return (
    <div className='relative'>
      <div
        className={classNames('min-h-screen w-full flex items-center justify-center', {
          'bg-white': !external,
          'bg-[#263641]': external,
        })}
      >
        <div className='flex flex-col md:flex-row gap-x-10 items-center p-6'>
          <div className='relative md:w-64 w-32 flex justify-center '>
            <div className='bg-primary/10 rounded-full md:w-64 md:h-64 w-52 h-52 absolute' />
            <img src={external ? BrokenBulbWhite : BrokenBulb} alt='Broken Bulb' />
          </div>
          <div
            className={classNames('text-center md:text-left', {
              'text-gray-200': external,
            })}
          >
            <h1
              className={classNames('text-8xl', {
                'text-primary font-thin': !external,
                'text-gray-200 font-semibold': external,
              })}
            >
              {title}
            </h1>
            <div className='text-xl mb-8 max-w-[500px]'>{text}</div>
            {showGoHomeButton && (
              <Button variant={ButtonVariant.Primary} onClick={onGoHomeButtonClick}>
                Go Home
              </Button>
            )}
          </div>
        </div>
      </div>

      {external && (
        <div className='absolute bottom-4 flex w-full justify-center'>
          <div className='flex items-center gap-x-2 justify-center opacity-75'>
            <img className='hidden xs:block ' src={LogoLight} alt='logo' width='75' />
            <span className='text-gray-200 text-xs lg:text-sm'>Copyright © {new Date().getFullYear()} Storro B.V.</span>
          </div>
        </div>
      )}
    </div>
  );
}
