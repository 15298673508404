import base64url from 'base64url';
import { releaseProxy } from 'comlink';
import { Hash } from '../util/Cryptography/Hash';
import { Algorithm, SymmetricKey } from '../util/Cryptography/SymmetricKey';
import { envVar } from '../util/Util/EnvVar';
import initWebWorker from '../util/WebWorker/InitWebWorker';
import { PrivateKey } from './PrivateKey';
import { Salt } from './Salt';

// Derive a key from a password and salt, using Argon2. This class
// is compatible with our c++ client code.
export class DerivedKey {
  private readonly encryptionKey: SymmetricKey;

  constructor(encryptionKey: SymmetricKey) {
    this.encryptionKey = encryptionKey;
  }

  public encrypt(privateKey: PrivateKey): Uint8Array {
    return this.encryptionKey.encryptSimple(privateKey.key());
  }

  public decrypt(encryptedUserPrivateKey: Uint8Array): PrivateKey {
    return new PrivateKey(this.encryptionKey.decryptSimple(encryptedUserPrivateKey));
  }

  static fromBase64(value: string): DerivedKey {
    return new DerivedKey(new SymmetricKey(base64url.toBuffer(value), Algorithm.XCHACHA));
  }

  static async fromPasswordWithSalt(password: string, salt: Salt): Promise<DerivedKey> {
    if (envVar('NODE_ENV') === 'test') {
      // If we're (unit) testing using Node, do not use WebWorkers to handle this, just do it ourselves:
      return new DerivedKey(SymmetricKey.createUsingPassword(password, salt.bytes()));
    }

    // Generating a derived key form the password can be quite a cpu and memory intense process.
    // We do not want to block the main thread for too long so we perform this call in a Worker process.
    const { worker, workerApi } = await initWebWorker<import('../util/WebWorker').StorroWebWorker>();

    // We are using salt.bytes() to pass a Uint8Array instead of the *object* Salt as
    // for some reason Comlink.proxy(salt) does not provide a proper object on the other side.
    const resultRawKey = await workerApi.symmetricKeyCreateUsingPassword(password, salt.bytes());
    const result = new DerivedKey(new SymmetricKey(resultRawKey));

    // Clean the worker object up before we return our result.
    workerApi[releaseProxy]();
    worker.terminate();
    return result;
  }

  public getKey(): Uint8Array {
    return this.encryptionKey.getRawKey();
  }
  public toBase64(): string {
    return base64url.encode(Buffer.from(this.encryptionKey.getRawKey()));
  }

  public toBase64Hash(): string {
    return base64url.encode(Buffer.from(Hash.blake2bSync(this.encryptionKey.getRawKey())));
  }
}
