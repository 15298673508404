import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { logger } from '../util/Logger';

interface DialogItem {
  id: string;
  remainSizeOnSmallerScreens?: boolean;
}

interface Props {
  dialogsItems: DialogItem[];
  children: React.ReactNode;
}

type DialogContextType = {
  dialogsItems: Props['dialogsItems'];
  setDialogsItems: Dispatch<SetStateAction<Props['dialogsItems']>>;
  lastDialogItem: DialogItem | undefined;
};

const DialogContext = createContext<DialogContextType>({
  dialogsItems: [],
  setDialogsItems: () => logger.warn('No Dialog provider'),
  lastDialogItem: undefined,
});

export function useDialogContext(): DialogContextType {
  return useContext(DialogContext);
}

export function DialogProvider({ children }: Props): JSX.Element {
  const [dialogsItems, setDialogsItems] = useState<Props['dialogsItems']>([]);
  const lastDialogItem = dialogsItems.at(dialogsItems.length - 1);

  return <DialogContext.Provider value={{ dialogsItems, setDialogsItems, lastDialogItem }}>{children}</DialogContext.Provider>;
}
