import React from 'react';
import Badge from './index';

export interface NameBadgeProps {
  userPublicKeyBase64: string;
  firstName?: string;
  lastName?: string;
  initials?: string;
}

interface Props extends NameBadgeProps {
  size?: 'base' | 'sm' | 'xs' | '2xs';
  className?: string;
  isGray?: boolean;
  isOnline?: boolean;
  loading?: boolean;
}

/**
 * Shows a circle with a generated background color for the first char of the firstname and the first char of the lastname
 *
 */
export default function NameBadge({
  userPublicKeyBase64,
  className,
  firstName,
  lastName,
  isGray,
  isOnline,
  size,
  initials,
  loading,
}: Props): JSX.Element {
  let chars = '';

  if (firstName || lastName) {
    if (firstName) {
      chars = firstName[0].toUpperCase();
    }

    if (lastName) {
      chars += lastName[0].toUpperCase();
    }
  }

  if (initials) {
    chars = initials;
  }

  return (
    <Badge
      loading={loading}
      userPublicKeyBase64={userPublicKeyBase64}
      className={className}
      isGray={isGray}
      isOnline={isOnline}
      size={size}
    >
      {chars}
    </Badge>
  );
}
