import { decode } from '@stablelib/utf8';
import { Ecas } from '../util/Serialization/Ecas';
import { KeyStream } from '../util/Serialization/KeyStream';
import { StreamKey } from '../util/Serialization/StreamKey';
import { EncryptedListing } from './Entry';

// A cache of Listings. This cache is used by Dirs to have a shared cache. This
// is desirable (for performance) because we don't need to download the same
// listings over and over when loading multiple project versions with lots of
// file structure overlaps.
export class ListingCache {
  private cache = new Map<string, Promise<EncryptedListing>>();

  constructor(private metaEcas: Ecas) {}

  public async getListing(streamKey: StreamKey): Promise<EncryptedListing> {
    const plaintextKey = streamKey.toPlaintextString();
    const res = this.cache.get(plaintextKey);
    if (res) {
      return res;
    }

    const listingPromise = this.loadListing(streamKey);
    this.cache.set(plaintextKey, listingPromise);

    return listingPromise;
  }

  private async loadListing(streamKey: StreamKey): Promise<EncryptedListing> {
    // There is a content key but no cached sub-entries. Load and cache them.
    let keyStream: KeyStream | undefined = undefined;

    try {
      keyStream = new KeyStream(this.metaEcas, async (): Promise<boolean> => false, undefined, streamKey);
      const streamSize = await keyStream.size();
      const value = await keyStream.read(0, streamSize);

      // Parse the bytes into a string and the string into json.
      return JSON.parse(decode(value.slice(1))) as EncryptedListing;
    } finally {
      if (keyStream) {
        keyStream.destroy();
      }
    }
  }
}
