// Copyright 2022 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
import base64url from 'base64url';
import { Hash } from '../Cryptography/Hash';
import { Algorithm, SymmetricKey } from '../Cryptography/SymmetricKey';
import { equal } from '../Util/Equal';
import { Cas } from './Cas';
import { EcasKey } from './EcasKey';

// We can not make StreamKey travel between WebWorker threads and main thread
// without the StreamKey object losing it's functions in between.
// PODStreamKey exists to make it easy, intentional and type-safe to convert
// to a plain old data (POD) type to move between threads.
export class PODStreamKey {
  public constructor(
    public readonly locator: Uint8Array,
    public readonly metaKey: Uint8Array | undefined,
    public readonly contentKey: Uint8Array,
  ) {}
}

export class StreamKey {
  private metaKey: Uint8Array;

  constructor(
    private locator: Uint8Array,
    private contentKey: Uint8Array = new Uint8Array(),
  ) {
    if (locator.length !== Cas.keySize()) throw new Error('locator is of wrong size');
  }

  public getLocator(): Uint8Array {
    return this.locator;
  }

  public getContentKey(): Uint8Array {
    return this.contentKey;
  }

  public setContentKey(newKey: Uint8Array): void {
    if (newKey.length !== 32) throw new Error('Content key needs to be 32 bytes');
    this.contentKey = newKey;
  }

  public getMetaKey(): Uint8Array {
    return this.metaKey;
  }

  public setMetaKey(newKey: Uint8Array): void {
    if (newKey.length !== 32) throw new Error('Meta key needs to be 32 bytes');
    this.metaKey = newKey;
  }

  public toEcasKey(): EcasKey {
    if (this.metaKey && this.metaKey.length > 0) {
      return new EcasKey(this.locator, this.metaKey);
    }
    if (this.contentKey && this.contentKey.length > 0) {
      return new EcasKey(this.locator, this.contentKey);
    }
    throw Error('StreamKey can not be converted to EcasKey');
  }

  public isEqual(other: StreamKey | undefined): boolean {
    if (!other) return false;
    if (!equal(this.metaKey, other.metaKey)) return false;
    if (!equal(this.locator, other.locator)) return false;
    if (!equal(this.contentKey, other.contentKey)) return false;
    return true;
  }

  public compare(other: StreamKey): number {
    function compareUint8Array(left: Uint8Array, right: Uint8Array): number {
      const minLength = Math.min(left.length, right.length);
      for (let i = 0; i < minLength; i++) {
        if (left[i] !== right[i]) return left[i] - right[i];
      }
      return left.length - right.length;
    }
    const a = compareUint8Array(this.locator, other.locator);
    if (a !== 0) return a;
    const b = compareUint8Array(this.metaKey, other.metaKey);
    if (b !== 0) return b;
    const c = compareUint8Array(this.contentKey, other.contentKey);
    if (c !== 0) return c;
    return 0;
  }

  // @TODO Rename to toString() when the false references are gone.
  public toEncryptedString(contentLayerKey: SymmetricKey): string {
    const locator = base64url.encode(Buffer.from(this.locator));
    if (this.contentKey.length === 0) {
      throw Error('StreamKey has not content key to encrypt');
    }
    const metaKey = this.metaKey ? base64url.encode(Buffer.from(this.metaKey)) : '';
    const nonce = Hash.blake2bSync(this.locator).slice(0, SymmetricKey.nonceBytesForAlgo(Algorithm.XCHACHA));
    const contentKey = base64url.encode(Buffer.from(contentLayerKey.encrypt(this.contentKey, nonce)));
    return locator + ' ' + metaKey + ' ' + contentKey;
  }

  public static fromEncryptedString(input: string, contentLayerKey?: SymmetricKey): StreamKey {
    const pieces = input.split(' ');
    if (pieces.length !== 3) throw new Error('Input does not consist of three pieces');
    // @TODO Apparently we need to wrap the Buffer object inside a Uint8Array,
    // otherwise libsodium's hash crypto_generichash() function will not grok it.
    const locator = new Uint8Array(base64url.toBuffer(pieces[0]));
    const metaKey = new Uint8Array(base64url.toBuffer(pieces[1]));

    if (contentLayerKey) {
      // If we have the contentLayerKey (= project/quickshare key),
      // we can decrypt the encrypted content layer key (= part of the StreamKey).
      // This is the scenario for Users, because they have a contentLayerKey.
      const c = new Uint8Array(base64url.toBuffer(pieces[2]));
      const nonce = Hash.blake2bSync(locator).slice(0, SymmetricKey.nonceBytesForAlgo(Algorithm.XCHACHA));
      const contentKey = contentLayerKey.decrypt(c, nonce);
      const streamKey = new StreamKey(locator, contentKey);
      if (metaKey.length > 0) streamKey.setMetaKey(metaKey);
      return streamKey;
    }

    // This is the Facilitator case: It can still parse a StreamKey from an encrypted string,
    // but it cannog de crypt the encrypted content key inside of it. This will result in a
    // StreamKey that contains only a locator and optionally a meta key (if it's a tree and not one chunk).
    // As such this loses information. We could keep the encrypted part around
    // so it's safe to serialize again.
    const streamKey = new StreamKey(locator);
    if (metaKey.length > 0) streamKey.setMetaKey(metaKey);
    return streamKey;
  }

  // Dir Listings are serialized using plain text string, which means the content key
  // within the StreamKey is not encrypted. This is needed because the Facilitator
  // must also be able to read and write the Listings.
  public toPlaintextString(): string {
    const locator = base64url.encode(Buffer.from(this.locator));
    const metaKey = this.metaKey ? base64url.encode(Buffer.from(this.metaKey)) : '';
    const contentKey = base64url.encode(Buffer.from(this.contentKey));
    return locator + ' ' + metaKey + ' ' + contentKey;
  }

  // Inverse of toPlaintextString().
  public static fromPlaintextString(input: string): StreamKey {
    const pieces = input.split(' ');
    if (pieces.length !== 3) throw new Error('Input does not consist of three pieces');
    // @TODO Apparently we need to wrap the Buffer object inside a Uint8Array,
    // otherwise libsodium's hash crypto_generichash() function will not grok it.
    const locator = new Uint8Array(base64url.toBuffer(pieces[0]));
    const metaKey = new Uint8Array(base64url.toBuffer(pieces[1]));
    const contentKey = new Uint8Array(base64url.toBuffer(pieces[2]));
    const streamKey = new StreamKey(locator, contentKey);
    if (metaKey.length > 0) streamKey.setMetaKey(metaKey);
    return streamKey;
  }

  // We quite often need to compare root keys which are of type 'StreamKey | null'
  public static areEqual(left: StreamKey | undefined, right: StreamKey | undefined): boolean {
    if (!left) return !right;
    if (!right) return false;
    return left.isEqual(right);
  }

  toPod(): PODStreamKey {
    return new PODStreamKey(this.locator, this.metaKey, this.contentKey);
  }

  static fromPod(source: PODStreamKey): StreamKey {
    const result = new StreamKey(source.locator);
    if (source.metaKey) {
      result.setMetaKey(source.metaKey);
    }
    result.setContentKey(source.contentKey);
    return result;
  }
}
