import { useEffect } from 'react';

/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 *
 * Example:
 *  useKeypress('Escape', () => {
 *    alert('you pressed escape!')
 *  });
 */
export default function useKeypress(key: string, action: () => void): void {
  useEffect(() => {
    function onKeyup(event: KeyboardEvent) {
      if (event.key === key) {
        action();
      }
    }

    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [action, key]);
}
