import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { ClientPortalTheme } from '../../../types';

interface Props {
  theme?: ClientPortalTheme;
  preview?: boolean;
  className?: string;
  classNameFooterCopyright?: string;
  children: React.ReactNode;
}

export default function ClientPortalBackground({ theme, className, children }: Props): JSX.Element {
  const bgImageRef = useRef<HTMLDivElement>(null);

  /**
   * Preload the image for a smooth experience
   */
  useEffect(() => {
    // small timer to give an even better experience
    const timer = setTimeout(() => {
      if (!theme || !theme.background) return;

      const img = new Image();
      img.src = theme.background.imageUrl;
      img.onload = () => {
        if (bgImageRef.current) {
          bgImageRef.current.style.opacity = '1';
        }
      };
    }, 1000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [theme]);

  return (
    <div className={classNames('w-full grow-0 bg-[#263641]', className)}>
      {/* The background image */}

      <div
        ref={bgImageRef}
        style={{
          backgroundImage: theme && theme.background ? 'url("' + theme.background.imageUrl + '")' : undefined,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundColor: '#263641',
        }}
        className='z-0 absolute right-0 bottom-0 top-0 left-0 opacity-0 transition-opacity duration-1000 w-full h-full'
      />

      <div className='relative z-10 w-full h-full flex flex-col bg-gradient-to-b to-[#263641] from-transparent'>
        <div className='grow h-full w-full'>{children}</div>
      </div>
    </div>
  );
}
