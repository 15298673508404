import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { logger } from '../util/Logger';

interface Props {
  isOpen?: boolean;
  children: React.ReactNode;
}

export type MenuContextType = {
  isOpen: boolean | undefined;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const MenuContext = createContext<MenuContextType>({
  isOpen: undefined,
  setIsOpen: () => logger.warn('No Menu provider'),
});

export function useMenuContext(): MenuContextType {
  return useContext(MenuContext);
}

export function MenuProvider({ children }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return <MenuContext.Provider value={{ isOpen, setIsOpen }}>{children}</MenuContext.Provider>;
}
