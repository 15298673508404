import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSplitScreenContext } from '../../../context/SplitScreenContext';
import SplitScreenWrapper from '../SplitScreenWrapper';

interface Props {
  showSidebar?: boolean;
}

export default function RouteSplitScreenWrapper({ showSidebar }: Props): JSX.Element {
  const { setShowSidebar } = useSplitScreenContext();

  /**
   * Enable the sidebar if needed
   */
  useEffect(() => {
    if (showSidebar !== undefined) {
      setShowSidebar(showSidebar);
    }
  }, [setShowSidebar, showSidebar]);

  return (
    <SplitScreenWrapper>
      <Outlet />
    </SplitScreenWrapper>
  );
}
