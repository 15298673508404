// Copyright 2022 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.

import base64url from 'base64url';
import { SymmetricKey } from '../Cryptography/SymmetricKey';
import { concat } from '../Util/Concat';
import { equal } from '../Util/Equal';
import { Cas } from './Cas';

// This is supposed to work like the EcasKey class in cpp code.
// https://gitlab.coblue.eu/storro/storro/-/blob/master/Base/Serialization/EcasKey.h
//
// The locator should be used to locate the EcasValue inside the CAS
// and the valueHash is needed to derive the encryption key and decrypt the value.
// (That's the 'encrypted' part in encrypted content addressed store - ecas)
//
export class EcasKey {
  constructor(
    private locator: Uint8Array,
    private valueHash: Uint8Array,
  ) {
    if (this.locator.length !== Cas.keySize()) throw new Error('Locator should be of Cas.keysize size');
    if (this.valueHash.length !== SymmetricKey.keyBytes) throw new Error('ValueHash should be of SymmetricKey.keyBytes size');
  }

  getLocator(): Uint8Array {
    return this.locator;
  }

  getValueHash(): Uint8Array {
    return this.valueHash;
  }

  toUint8Array(): Uint8Array {
    return concat([this.locator, this.valueHash]);
  }

  static fromUint8Array(source: Uint8Array): EcasKey {
    const a = source.slice(0, Cas.keySize());
    const b = source.slice(Cas.keySize());
    return new EcasKey(a, b);
  }

  toString(): string {
    return base64url.encode(Buffer.from(this.toUint8Array()));
  }

  static fromString(source: string): EcasKey {
    return this.fromUint8Array(base64url.toBuffer(source));
  }

  isEqual(other: EcasKey): boolean {
    if (!equal(this.locator, other.locator)) return false;
    if (!equal(this.valueHash, other.valueHash)) return false;
    return true;
  }
}
