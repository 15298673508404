import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { logger } from '../util/Logger';

interface Props {
  children: React.ReactNode;
}

type SidebarLeftContextType = {
  secondaryPane: React.ReactNode | undefined;
  setSecondaryPane: Dispatch<SetStateAction<React.ReactNode | undefined>>;
};

const SidebarLeftContext = createContext<SidebarLeftContextType>({
  secondaryPane: undefined,
  setSecondaryPane: () => logger.warn('no SidebarLeft provider'),
});

export function useSidebarLeftContext(): SidebarLeftContextType {
  return useContext(SidebarLeftContext);
}

export function SidebarLeftProvider({ children }: Props): JSX.Element {
  const [secondaryPane, setSecondaryPane] = useState<React.ReactNode>();

  return (
    <SidebarLeftContext.Provider
      value={{
        secondaryPane,
        setSecondaryPane,
      }}
    >
      {children}
    </SidebarLeftContext.Provider>
  );
}
