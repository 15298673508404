import { Capacitor } from '@capacitor/core';
import { ScreenSize } from './types/ScreenSize';

// Pages that are standalone (or redirected), for logged-in or not logged-in users.
// This will prevent that those pages are redirected to /realm/:realmId/*
export const STANDALONE_PAGES = ['/admin/stats', '/admin/users', '/account'];

// Pages that are accessable for non-loggedin users
export const NON_SECURE_PATHS = [
  '/auth/login',
  '/auth/register',
  '/auth/forgot',
  '/auth/signup',
  '/auth/email',
  '/auth/demote',
  '/binaries',
  '/qs',
];

// primary colors
export const PRIMARY_HEX_COLOR = '#2563EB';
export const PRIMARY_RGB_COLOR = '37,99,235';

export const MEGA_BYTE = 1_000_000;
export const GIGA_BYTE = MEGA_BYTE * 1_000;
export const TERA_BYTE = GIGA_BYTE * 1_000;

export const PROJECT_SIDEBAR_BREAKPOINT = ScreenSize.md;

export const IS_MOBILE_APP = Capacitor.getPlatform() !== 'web';
export const IS_WEB_APP = Capacitor.getPlatform() === 'web';
export const IS_IOS_APP = Capacitor.getPlatform() === 'ios';
export const IS_ANDROID_APP = Capacitor.getPlatform() === 'android';

// A list of conditions what is needed for the web app
export const IS_UNSUPPORTED_WEB_BROWSER = !('serviceWorker' in navigator) && IS_WEB_APP;
