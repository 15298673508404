// Copyright 2021 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// MemoryStore is a type of IAsyncStore used for unit tests and debugging.
import base64url from 'base64url';
import { logger } from '../Logger';
import { equal } from '../Util/Equal';
import { KeyValueStore } from './KeyValueStore';
import { StoreConfig } from './StoreFactory';

export class MemoryStore implements KeyValueStore {
  private map: Map<Uint8Array, Uint8Array>;
  private debug: boolean;

  /* eslint-disable-next-line */
  constructor(config: any = {}) {
    // We currently do not use anything from 'config' argument
    // but this is the way other stores get their json and
    // we might use it in the future.
    this.map = new Map<Uint8Array, Uint8Array>();
    this.debug = false;
  }

  setDebug(enabled: boolean): void {
    this.debug = enabled;
  }

  async hasValue(key: Uint8Array): Promise<boolean> {
    if (this.debug) logger.debug(`HAS ${base64url.encode(Buffer.from(key))}`);
    return this.map.has(key);
  }

  async getValue(key: Uint8Array): Promise<Uint8Array> {
    if (this.debug) logger.debug(`GET ${base64url.encode(Buffer.from(key))}: size: ${this.map.size}`);

    for (const [k, v] of this.map) {
      if (equal(k, key)) {
        if (this.debug) logger.debug(`GET ${base64url.encode(Buffer.from(key))}: ${v.length}`);
        return v;
      }
    }

    if (this.debug) logger.debug(`GET ${base64url.encode(Buffer.from(key))}: MISSING`);
    throw new Error('Missing key: ' + base64url.encode(Buffer.from(key)));
  }

  public async putValue(key: Uint8Array, value: Uint8Array): Promise<void> {
    this.map.set(key, value);
    if (this.debug) logger.debug(`PUT ${base64url.encode(Buffer.from(key))}: ${value.length} size: ${this.map.size}`);
  }

  toStoreConfig(): StoreConfig {
    throw Error('MemoryStore toStoreConfig not supported');
  }
}
