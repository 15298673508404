export enum DeviceType {
  Web,
  Desktop,
}

export interface UserDevice {
  name: string;
  peerKey: string;
  userSignature: string;
  publicKeySignature: string;
  lastSeen: string;
  version: string;
  operatingSystem: string;
  ip?: string;
  type: DeviceType;
}
