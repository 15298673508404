import { TypedEmitter } from 'tiny-typed-emitter';
import { File as EntryFile } from './Entry';

interface EventActions {
  uploadComplete: (projectId: string, newHeadVersionId: string, uploadedFiles: EntryFile[]) => void;
}

/**
 * The event class holds in the interface a list of events that can be triggered and listen to.
 *
 * Example:
 *
 * Emit an event:
 * storroApi.events.emit(
 *  'uploadComplete',
 *  'projectId',
 *  'HeadVersionId',
 *  [],
 * );
 *
 * Listen to events:
 * storroApi.events.addListener('uploadComplete', event);
 * storroApi.events.removeListener('uploadComplete', event);
 */
export class Events extends TypedEmitter<EventActions> {
  constructor() {
    super();
  }
}
