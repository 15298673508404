import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IS_UNSUPPORTED_WEB_BROWSER } from '../../const';
import { useBannerContext } from '../../context/BannerContext';
import { useStorroApi } from '../../context/StorroApiContext';
import useRealm from '../../hooks/realm/UseRealm';
import useModal from '../../hooks/UseModal';
import useUser from '../../hooks/useUser';
import { logger } from '../../util/Logger';
import { BrowserSupportModal } from '../BrowserSupport/BrowserSupportModal';

enum BannerPrio {
  'High',
  'Default',
}

interface BannerProps {
  title: string;
  buttonTitle?: string;
  onClick?: () => void;
  prio?: BannerPrio;
}

function Banner(): JSX.Element {
  const [bannerOptions, setBannerOptions] = useState<BannerProps>();

  const { superAdminMode, setSuperAdminMode } = useUser();
  const { storroApi } = useStorroApi();
  const { loadRealms, setRealm, realm } = useRealm();
  const navigate = useNavigate();
  const { setBannerIsVisible } = useBannerContext();

  const { modalIsVisible: browserModalIsVisible, showModal: showBrowserModal, closeModal: closeBrowseModal } = useModal();

  /**
   * Exit a superadmin mode
   */
  const onExitSuperAdminMode = useCallback(() => {
    // unset the SuperAdminMode in the session storage
    storroApi.setSuperAdminMode(false);

    // useUser state should be updated as well
    setSuperAdminMode(false);

    // clear the current banner
    setBannerOptions(undefined);

    // Reload the realms as a normal user.
    loadRealms()
      .then(realms => {
        if (realms.length === 0) {
          navigate(`/account`);
        } else {
          setRealm(realms[0]);
          navigate(`/realm/${realms[0].publicKey}`);
        }
      })
      .catch(e => {
        logger.error('Failed to fetch realms after exiting super admin mode.', e);
        navigate(`/account`);
      });
  }, [loadRealms, navigate, setRealm, setSuperAdminMode, storroApi]);

  /**
   * Check what kind of message we show in the banner (order matters)
   * - No Service Worker error (only on Web)
   * - Demote Admin message
   * - Or SuperAdmin message
   */
  useEffect(() => {
    if (IS_UNSUPPORTED_WEB_BROWSER) {
      setBannerOptions({
        title: 'Your browser is not compatible with Storro for Web.',
        buttonTitle: 'More info',
        onClick: showBrowserModal,
        prio: BannerPrio.High,
      });
    } else if (realm && realm.scheduledForAdminDemote) {
      // TODO a browser compatible message will now be on top of the demote message (change is very unlikely that both are shown at the same time)
      // Ideal would be that we stack those 2 messages --> https://gitlab.coblue.eu/storro/app.storro/-/issues/456
      setBannerOptions({
        title: `You are scheduled to be demoted as admin of ${realm.name} on ${DateTime.fromISO(
          realm.scheduledForAdminDemote,
        ).toLocaleString(DateTime.DATE_MED)}`,
        buttonTitle: undefined,
        onClick: undefined,
        prio: BannerPrio.High,
      });
    } else if (superAdminMode) {
      setBannerOptions({
        title: 'You are in Super Admin Mode.',
        buttonTitle: 'Exit Super Admin Mode',
        onClick: onExitSuperAdminMode,
        prio: BannerPrio.Default,
      });
    }
  }, [onExitSuperAdminMode, realm, superAdminMode]); // eslint-disable-line

  /**
   * set the context
   */
  useEffect(() => {
    setBannerIsVisible(!!bannerOptions);
  }, [bannerOptions, setBannerIsVisible]);

  if (!bannerOptions) {
    return <></>;
  }

  return (
    <>
      <div
        className={classNames('text-white flex gap-2 text-sm justify-center items-center z-[61] fixed top-0 right-0 left-0 py-4 h-10', {
          'bg-primary': bannerOptions.prio === BannerPrio.Default,
          'bg-red-500': bannerOptions.prio === BannerPrio.High,
        })}
      >
        <span className='text-center'>{bannerOptions.title}</span>
        {bannerOptions.buttonTitle && bannerOptions.onClick && (
          <button className='underline' onClick={bannerOptions.onClick}>
            {bannerOptions.buttonTitle}
          </button>
        )}
      </div>
      <BrowserSupportModal isVisible={browserModalIsVisible} onRequestCloseModal={closeBrowseModal} />
    </>
  );
}

export default Banner;
