import classNames from 'classnames';
import React from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { UploadQueueItem } from 'api/UploadQueue';
import { UploadsByGroup } from 'hooks/UseProgressModal';
import { humanFileSize } from 'util/Util/HumanReadableSize';
import FileIcon from '../../Filemanager/FileIcon';
import ProgressModalRowSkeleton from './ProgressModalRowSkeleton';
import { Check } from '@phosphor-icons/react';

interface Props {
  item: UploadQueueItem;
  group: UploadsByGroup;
}

function ProgressModalRowCompleted({ item, group }: Props): JSX.Element {
  const { ref, isIntersecting: isInViewport } = useIntersectionObserver();

  return (
    <li className='border-b last:border-b-0 last:mb-5 relative h-[55px] overflow-hidden' ref={ref}>
      {!isInViewport && <ProgressModalRowSkeleton />}
      {isInViewport && (
        <div className='hover:bg-gray-50 flex items-center py-1 my-1 px-2'>
          <FileIcon name={group.contentKey ? item.path.toString(group.contentKey) : item.path.toEncryptedString()} />
          <div className='ml-2 flex flex-col w-5/6'>
            <span
              className='mb-1 cursor-default truncate'
              title={group.contentKey ? item.path.toString(group.contentKey) : item.path.toEncryptedString()}
            >
              {group.name}
              {group.contentKey ? item.path.toString(group.contentKey) : item.path.toEncryptedString()}
            </span>
            {item.finished && !item.cancelled && !item.failed && <span className='text-sm text-primary'>{humanFileSize(item.size)}</span>}
            {item.cancelled && <span className='text-sm text-primary'>Cancelled</span>}
            {item.failureMessage && !item.cancelled && <span className='text-sm text-red-700'>{item.failureMessage}</span>}
          </div>

          {item.finished && !item.cancelled && !item.failed && <Check className='ml-auto' />}

          <div
            className={classNames('absolute bottom-0 left-0 h-[1px] transition-[width]', {
              'bg-transparent': item.finished,
              'bg-primary': !item.finished,
            })}
            style={{ width: `${item.progress}%` }}
          />
        </div>
      )}
    </li>
  );
}

export default ProgressModalRowCompleted;
