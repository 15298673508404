import classNames from 'classnames';
import React from 'react';
import UAParser from 'ua-parser-js';
import ChromeIcon from '../../assets/os-logos/chrome.svg';
import EdgeIcon from '../../assets/os-logos/edge.svg';
import FirefoxIcon from '../../assets/os-logos/firefox.svg';
import OperaIcon from '../../assets/os-logos/opera.svg';
import SafariIcon from '../../assets/os-logos/safari.svg';

/**
 * Generate a list of browser we are supporting for Storro for Web
 *
 * Based on:
 * - https://caniuse.com/serviceworkers
 * - https://caniuse.com/webworkers
 */

interface Props {
  introText?: string;
}

const ua = new UAParser(navigator.userAgent);
const browsers = [
  {
    name: 'Chrome',
    icon: ChromeIcon,
    minVersion: 45,
  },
  {
    name: 'Firefox',
    icon: FirefoxIcon,
    minVersion: 44,
    notes: ['private browsing is not supported'],
  },
  {
    name: 'Safari',
    icon: SafariIcon,
    minVersion: 11.1,
  },
  {
    name: 'Opera',
    icon: OperaIcon,
    minVersion: 32,
  },
  {
    name: 'Edge',
    icon: EdgeIcon,
    minVersion: 17,
  },
];

export function BrowserSupport({ introText }: Props): JSX.Element {
  return (
    <>
      {introText && <p className='my-3'>{introText}</p>}
      We currently supporting the following browsers:
      <ul className='mt-3'>
        {browsers.map(browser => (
          <li key={browser.name} className='text-gray-700 [&:not(:last-child)]:border-b'>
            <div
              className={classNames('flex py-2 gap-x-2 border-gray-100 items-center', {
                'text-gray-800 font-semibold': ua.getBrowser().name === browser.name,
              })}
            >
              <img width='17' src={browser.icon} alt={browser.name} /> {browser.name} version {browser.minVersion} or higher
            </div>

            {browser.notes && (
              <div className='pl-6 mb-2'>
                <ul>
                  {browser.notes.map((note, index) => (
                    <li key={note}>
                      <span className='text-sm italic'>
                        note {browser.notes.length > 1 ? index + 1 : ''}: {note}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
      </ul>
      <p className='mt-4 text-gray-400 text-sm italic'>
        Your are using <strong>{ua.getBrowser().name}</strong> version <strong>{ua.getBrowser().version}</strong>
      </p>
    </>
  );
}
