import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { TABLE_THEAD_TH_SORT_ICON } from 'helpers/CssClasses';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { SortOrder } from 'types';

interface SortConfig<T> {
  fieldName: keyof T;
  direction: SortOrder;
}

interface ReturnType<T> {
  items: T[];
  sortConfig: SortConfig<T>;
  requestSort: (key: keyof T, direction?: SortOrder) => void;
  getSortElement: (key: keyof T) => ReactNode;
}

interface Props<T> {
  items: T[];
  config: SortConfig<T>;
  sortingOptions?: { fieldName: keyof T; sortFn: (a: T, b: T) => number }[];
}

/**
 * Hook that will deal with the sorting of a list of items
 */
function useTableSort<T>({ config, items, sortingOptions }: Props<T>): ReturnType<T> {
  const [sortConfig, setSortConfig] = useState<SortConfig<T>>(config);

  /**
   * Sort the items based on the sortConfig
   */
  const sortedItems = useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        // check if there is a custom sorting function
        if (sortingOptions) {
          const sortOption = sortingOptions.find(option => option.fieldName === sortConfig.fieldName);
          if (sortOption) {
            if (sortConfig.direction === SortOrder.Descending) {
              return sortOption.sortFn(a, b);
            } else {
              return sortOption.sortFn(b, a);
            }
          }
        }

        let aValue = a[sortConfig.fieldName];
        let bValue = b[sortConfig.fieldName];

        // in case of string, make it lowercase
        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase() as T[keyof T];
        }
        if (typeof bValue === 'string') {
          bValue = bValue.toLowerCase() as T[keyof T];
        }

        // because undefined will by default fall to the bottom,
        // we need to convert undefined to empty string to make it sortable
        if (typeof aValue === 'undefined' || aValue === null) {
          aValue = '' as T[keyof T];
        }
        if (typeof bValue === 'undefined' || bValue === null) {
          bValue = '' as T[keyof T];
        }

        // default sorting
        if (aValue < bValue) {
          return sortConfig.direction === SortOrder.Ascending ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === SortOrder.Ascending ? 1 : -1;
        }

        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig, sortingOptions]);

  /**
   * Request the sort of the table
   */
  const requestSort = (fieldName: keyof T, direction?: SortOrder) => {
    // if no direction is given
    // fallback on the default direction
    if (!direction) {
      direction = SortOrder.Ascending;

      if (sortConfig && sortConfig.fieldName === fieldName && sortConfig.direction === SortOrder.Ascending) {
        direction = SortOrder.Descending;
      }
    }
    setSortConfig({ fieldName, direction });
  };

  /**
   * return the sort element to make it visible to the user what is the current sort
   */
  const getSortElement = useCallback(
    (fieldName: keyof T): ReactNode => {
      {
        if (fieldName === sortConfig.fieldName) {
          return sortConfig.direction === SortOrder.Ascending ? (
            <CaretUp className={TABLE_THEAD_TH_SORT_ICON} />
          ) : (
            <CaretDown className={TABLE_THEAD_TH_SORT_ICON} />
          );
        }
      }
    },
    [sortConfig.direction, sortConfig.fieldName],
  );

  return { items: sortedItems, requestSort, sortConfig, getSortElement };
}

export default useTableSort;
