import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { ClientPortalTheme } from 'types';
import { logger } from '../util/Logger';

interface Props {
  realmId?: number;
  realmName?: string;
  logoHash?: string;
  color?: string;
  theme?: ClientPortalTheme;
  children: React.ReactNode;
}

type ClientPortalContextType = {
  realmId: number | undefined;
  setRealmId: Dispatch<SetStateAction<number | undefined>>;

  realmName: string | undefined;
  setRealmName: Dispatch<SetStateAction<string | undefined>>;

  logoHash: string | undefined;
  setLogoHash: Dispatch<SetStateAction<string | undefined>>;

  color: string | undefined;
  setColor: Dispatch<SetStateAction<string | undefined>>;

  theme: ClientPortalTheme | undefined;
  setTheme: Dispatch<SetStateAction<ClientPortalTheme | undefined>>;
};

const ClientPortalContext = createContext<ClientPortalContextType>({
  realmId: undefined,
  setRealmId: () => logger.warn('No ClientPortal provider'),

  realmName: undefined,
  setRealmName: () => logger.warn('No ClientPortal provider'),

  logoHash: undefined,
  setLogoHash: () => logger.warn('No ClientPortal provider'),

  color: undefined,
  setColor: () => logger.warn('No ClientPortal provider'),

  theme: undefined,
  setTheme: () => logger.warn('No ClientPortal provider'),
});

export function useClientPortalContext(): ClientPortalContextType {
  return useContext(ClientPortalContext);
}

export function ClientPortalProvider({ children }: Props): JSX.Element {
  const [realmId, setRealmId] = useState<number>();
  const [realmName, setRealmName] = useState<string>();
  const [logoHash, setLogoHash] = useState<string>();
  const [color, setColor] = useState<string>();
  const [theme, setTheme] = useState<ClientPortalTheme>();

  return (
    <ClientPortalContext.Provider
      value={{
        realmId,
        setRealmId,
        realmName,
        setRealmName,
        logoHash,
        setLogoHash,
        color,
        setColor,
        theme,
        setTheme,
      }}
    >
      {children}
    </ClientPortalContext.Provider>
  );
}
