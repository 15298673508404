import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  htmlFor?: string;
  className?: string;
  children: ReactNode;
}

export default function FormLabel({ children, htmlFor, className }: Props): JSX.Element {
  return (
    <label className={classNames('block text-gray-700 mb-2 font-semibold', className)} htmlFor={htmlFor}>
      {children}
    </label>
  );
}
