export enum OperationSystem {
  Linux = 'Linux',
  Windows = 'Windows',
  MacOS = 'MacOS',
  Unknown = 'Unknown',
}

export const detectOperationSystem = (): OperationSystem => {
  const operationSystems: { label: OperationSystem; key: string }[] = [
    { label: OperationSystem.Windows, key: 'Windows' },
    { label: OperationSystem.MacOS, key: 'Mac' },
    { label: OperationSystem.Linux, key: 'Linux' },
  ];

  const operationSystem = operationSystems.find(v => navigator.userAgent.indexOf(v.key) >= 0);

  if (!operationSystem) {
    return OperationSystem.Unknown;
  }

  return operationSystem.label;
};
