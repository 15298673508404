import React from 'react';
import Skeleton from '../../Skeleton';

function ProgressModalRowSkeleton(): JSX.Element {
  return (
    <div className='py-1 my-1 px-2 flex flex-row'>
      <Skeleton className='w-8 h-8 mr-2' animated={false} />
      <div className='w-full'>
        <Skeleton className='w-3/5 h-3 mb-2' animated={false} />
        <Skeleton className='w-1/5 h-3' animated={false} />
      </div>
      <Skeleton className='w-4 h-4 justify-self-end self-center' animated={false} />
    </div>
  );
}

export default ProgressModalRowSkeleton;
