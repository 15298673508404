import { X } from '@phosphor-icons/react';
import Logo from 'assets/logo.svg';
import { IS_WEB_APP } from 'const';
import { useStorroApi } from 'context/StorroApiContext';
import useDesktopClientModal from 'hooks/UseDesktopClientModal';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';
import React, { useEffect, useState } from 'react';
import { Portal } from 'react-portal';
import { toast } from 'react-toastify';
import { DeviceType } from 'types';
import formatErrorMessage from 'util/FormatErrorMessage';
import { logger } from 'util/Logger';

export default function DesktopClientNotification(): JSX.Element {
  const [alreadyInstalled, setAlreadyInstalled] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const { storroApi } = useStorroApi();
  const isMobileDevice = useIsMobileDevice();
  const { openModal: openDesktopClientModal, dialog: desktopClientDialog } = useDesktopClientModal();

  /**
   * Close the notification
   */
  const closeNotification = () => setShowNotification(false);

  /**
   * Skip the notification while the localStorage ignoreDesktopClientNotification key has been set
   */
  const skipNotification = () => {
    closeNotification();
    localStorage.setItem('ignoreDesktopClientNotification', '1');
  };

  /**
   * Show the notification in case the user is using a Web device or
   * when the localStorage item "ignoreDesktopClientNotification" has not been set
   */
  useEffect(() => {
    const ignoreDesktopClientNotification = localStorage.getItem('ignoreDesktopClientNotification');
    if (IS_WEB_APP && !ignoreDesktopClientNotification) {
      setShowNotification(true);
    }
  }, []);

  /**
   * List the user devices and check if the user already installed storro client
   * if not, show the notification to install storro
   */
  useEffect(() => {
    const abortController = new AbortController();

    if (showNotification) {
      storroApi
        .listUserDevices(undefined, undefined, abortController.signal)
        .then(devices => setAlreadyInstalled(devices.some(device => device.type === DeviceType.Desktop)))
        .catch(error => {
          if (!abortController.signal.aborted) {
            toast.error(formatErrorMessage('fetch the user devices'));
            logger.error(error);
          }
        });
    }

    return () => abortController.abort();
  }, [showNotification, storroApi]);

  // do not show the modal
  // - if we are on a mobile device
  // - if we already installed the client
  // - if we ignored the notification
  if (isMobileDevice || alreadyInstalled || !showNotification) {
    return <></>;
  }

  return (
    <Portal node={document && document.getElementById('root')}>
      <div className='fixed bottom-5 right-5 z-10 p-4 shadow rounded border border-gray-200 bg-white w-80'>
        <div className='flex border-b gap-x-4 relative pb-3'>
          <button className='absolute -right-1 -top-1' onClick={closeNotification}>
            <X />
          </button>
          <img src={Logo} alt='logo' width='40' />
          <a
            href='#'
            onClick={event => {
              event.preventDefault();
              openDesktopClientModal();
            }}
            className='leading-4'
          >
            <strong className='block mb-1'>Get the Desktop client</strong>
            <span className='text-sm'>Download the Storro Desktop client for better control of your files.</span>
          </a>
        </div>
        <div className='flex items-center pt-3 text-sm gap-x-2'>
          <input type='checkbox' className='h-3' id='ignoreMessage' onChange={skipNotification} />
          <label htmlFor='ignoreMessage' className='cursor-pointer'>
            Do not show this message again
          </label>
        </div>
      </div>

      {desktopClientDialog}
    </Portal>
  );
}
