export enum LoginTotpType {
  Email = 'required-via-email',
  Device = 'required-via-device',
  None = '',
}

/**
 * Interface that holds the info for the exception type of an error
 */
export enum LoginExceptionType {
  Default,
  DeviceNotFound,
  JsonWebTokenExpired,
}

export class LoginError extends Error {
  constructor(
    message: string,
    private twoFaType: LoginTotpType = LoginTotpType.None,
    private _exceptionType = LoginExceptionType.Default,
  ) {
    super(message); // 'Error' breaks prototype chain here
    this.name = 'LoginError';
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }

  // We will need to pass a one-time password to the request.
  get requireTotp(): boolean {
    return this.twoFaType !== LoginTotpType.None;
  }

  get totpAuthenticatorType(): LoginTotpType {
    return this.twoFaType;
  }

  get exceptionType(): LoginExceptionType {
    return this._exceptionType;
  }
}
