import { proxy, releaseProxy, Remote } from 'comlink';
import { BytesPerSecond } from '../util/BytesPerSecond';
import { ConvergentEncryption } from '../util/Cryptography/ConvergentEncryption';
import { SymmetricKey } from '../util/Cryptography/SymmetricKey';
import { logger } from '../util/Logger';
import { Ecas } from '../util/Serialization/Ecas';
import { StreamKey } from '../util/Serialization/StreamKey';
import initWebWorker from '../util/WebWorker/InitWebWorker';
import { Dir, Path } from './Entry';

type UploadWorker = { worker: Worker; workerApi: Remote<import('../util/WebWorker').StorroWebWorker> };

export class UploadQueue {
  // The maximum amount of simultaneous uploads at the same time.
  // Do not make this >50 or Chromium/Chrome will start having WebAssembly instance errors.
  // Twenty looks like a fine value to not have WebAssembly issues.
  private readonly maxActive = 20;

  private _items: UploadQueueItem[] = [];

  // Perform some comlink Web Worker magic here.
  private worker: Promise<UploadWorker> | undefined = undefined;

  private bytesPerSecond = new BytesPerSecond();

  constructor(
    public groupName: string,
    public contentKey: SymmetricKey | undefined,
    public projectId: string,
    private rootDir: Dir, // for duplicate file checking
    private ecas: Ecas,
    private progressCallback: () => void,
    private commitCallback: () => void,
  ) {}

  private static generateWorker(): Promise<UploadWorker> {
    return initWebWorker<import('../util/WebWorker').StorroWebWorker>();
  }

  private itemCallback(bytesAdded: number) {
    this.bytesPerSecond.addBytes(bytesAdded);
    this.progressCallback();
  }

  // In bytes per second
  public get uploadSpeed(): number {
    return this.bytesPerSecond.bytesPerSecond();
  }

  public async add(path: Path, file: File): Promise<void> {
    // True when there is already a file/dir at the path.
    if (path.length() === 0) {
      throw Error('File path should be provided');
    }

    const altPath = path.clone();
    let altPathCounter = 0;
    while ((await this.rootDir.findEntry(altPath)) !== undefined) {
      if (altPathCounter !== 0) {
        if (!this.contentKey) {
          throw Error('Calculating the alternative path requires the content key');
        }
        let filename = ConvergentEncryption.decryptName(this.contentKey, path.lastComponent());
        const lastDot = filename.lastIndexOf('.');
        if (lastDot === -1) {
          filename += `(${altPathCounter})`;
        } else {
          filename = filename.slice(0, lastDot) + `(${altPathCounter})` + filename.slice(lastDot);
        }
        altPath.pop();
        altPath.push(ConvergentEncryption.encryptName(this.contentKey, filename));
      }
      altPathCounter += 1;
    }

    this._items.push(
      new UploadQueueItem(
        path,
        altPath,
        file,
        this,
        bytesAdded => {
          this.itemCallback(bytesAdded);
        },
        this.groupName,
        this.contentKey,
        this.projectId,
      ),
    );
    this.startNext();
  }

  /**
   * Uploads the content of a File to the content ecas in the cloud (S3 store).
   * Returns the content locator of the file.
   */
  public async uploadFile(file: File, cancelCallback: () => boolean, uploadCallback: (uploadedSize: number) => void): Promise<StreamKey> {
    // We call the function on the worker thread through Comlink and wait for the result.
    // For more info see:
    // https://blog.logrocket.com/comlink-web-workers-match-made-in-heaven/
    // Treating function as data with Comlink

    if (!this.worker) {
      this.worker = UploadQueue.generateWorker();
    }

    const podStreamKey = await (
      await this.worker
    ).workerApi.uploadFile(file, this.ecas.toJson(), proxy(cancelCallback), proxy(uploadCallback));
    return StreamKey.fromPod(podStreamKey);
  }

  public remove(path: Path): void {
    this._items = this._items.filter(i => {
      if (path.isParentOf(i.path) || path.isEqualTo(i.path)) {
        i.cancel();
        return false;
      } else {
        return true;
      }
    });
  }

  private startNext(): void {
    if (this._items.filter(i => i.started && !i.finished).length >= this.maxActive) {
      return;
    }
    const item = this._items.find(i => !i.started);
    if (!item) {
      return;
    }
    item.start();
    this.startNext();
  }

  public get items(): Array<UploadQueueItem> {
    return this._items;
  }

  public checkFinished(): void {
    // First check if all files are explicitly marked to overwrite or not (if needed).
    if (this._items.filter(item => !item.hasExplicitOverwriteDecision).length === 0) {
      // Commit if all uploads succeeded.
      const found = this._items.find(item => !item.finished);
      if (!found) {
        if (this.worker) {
          this.worker.then(w => {
            w.workerApi[releaseProxy]();
            w.worker.terminate();
            this.worker = undefined;
          });
        }

        // All uploads are finished.
        this.commitCallback();
      }
    }
    this.startNext();
  }
}

export class UploadQueueItem {
  public size: number;
  public at: number; // At what byte index are we at the moment. In other words, how many bytes have we uploaded yet.
  public finished = false;
  public streamKey: StreamKey | undefined = undefined;
  public started = false;
  public failureMessage: string | undefined;
  private abortController = new AbortController();
  private overwriteInDir: boolean | undefined = undefined;

  constructor(
    private originalPath: Path,
    private altPath: Path,
    private file: File,
    private queue: UploadQueue,
    private progressCallback: (bytesAdded: number) => void,
    public containerName: string,
    public contentKey: SymmetricKey | undefined,
    public projectId: string,
  ) {
    this.size = file.size;
    this.at = 0;
  }

  // Returns true if the user has explicitly decided if the file should be
  // overwritten or not. Always returns true when the file doesn't exist.
  public get hasExplicitOverwriteDecision(): boolean {
    if (!this.alreadyExists || this.cancelled) {
      return true;
    }
    return this.overwriteInDir !== undefined;
  }

  // Decide on overwriting the file in the (root) dir. Throws if the file doesn't
  // already exists.
  public setOverwrite(overwrite: boolean): void {
    if (!this.alreadyExists) {
      throw Error('This file does not exist, no override decision has to be made');
    }
    this.overwriteInDir = overwrite;
    this.queue.checkFinished();
    this.progressCallback(0);
  }

  /**
   * Overwrite the file if the file already exists.
   * We overwrite when true OR undefined.
   */
  public get overwrite(): boolean {
    if (!this.alreadyExists) {
      return false;
    }

    // We implicitly overwrite when no overwrite decision has been made.
    if (this.overwriteInDir === undefined) {
      return true;
    }

    return this.overwriteInDir === true;
  }

  /** Returns true if the file already exists in the (root) dir */
  public get alreadyExists(): boolean {
    return !this.originalPath.isEqualTo(this.altPath);
  }

  public get path(): Path {
    if (this.overwrite) {
      return this.originalPath;
    } else {
      return this.altPath;
    }
  }

  public get cancelled(): boolean {
    return this.abortController.signal.aborted;
  }

  public get failed(): boolean {
    return this.failureMessage !== undefined;
  }

  public start(): void {
    // We do not need to upload an empty File.
    if (this.file.size === 0) {
      this.started = true;
      this.finished = true;
      this.queue.checkFinished();
      this.progressCallback(0);
      return;
    }

    this.queue
      .uploadFile(
        this.file,
        (): boolean => this.abortController.signal.aborted,
        (uploadedSize: number): void => {
          const bytesAdded = uploadedSize - this.at;
          this.at = uploadedSize;
          this.progressCallback(bytesAdded);
        },
      )
      .then((streamKey: StreamKey) => (this.streamKey = streamKey))
      .catch(e => {
        this.progressCallback(0);
        this.failureMessage = e instanceof Error ? e.message : `Unknown failure`;
        logger.error(`Failed to upload ${this.file.name}.`, e);
      })
      .finally(() => {
        this.finished = true;
        this.queue.checkFinished();
        this.progressCallback(0);
      });
    this.started = true;
  }

  // The progress from 0 to 100
  public get progress(): number {
    if (this.abortController.signal.aborted) return 0;
    if (this.size === 0 || this.size === this.at || this.at > this.size) return 100;
    if (this.at === 0) return 0;
    return Math.round((this.at / this.size) * 100);
  }

  public cancel(): void {
    this.abortController.abort();
    this.finished = true;
    this.at = 0;
    this.queue.checkFinished();
    this.progressCallback(0);
  }
}
