import { ReactNode } from 'react';

export interface SidebarItem {
  title: string;
  icon: ReactNode;
  isActive?: boolean;
  contextButtonText?: string;
  children: ReactNode;
}

export default class Sidebar {
  private _items: Map<string, SidebarItem> = new Map();
  private _contextButtonHandlers: Map<string, { (): void }[]> = new Map();

  /**
   *
   * @param updatedCallback an update callback that has been fired when we update something in the list
   */
  constructor(private updatedCallback: (items: SidebarItem[]) => void) {}

  /**
   * Save an array of sidebarItems
   */
  saveItems(items: SidebarItem[]): void {
    for (const item of items) {
      this._items.set(item.title, item);
    }

    this.updatedCallback(this.getItems());
  }

  /**
   * Make a specific item active
   */
  active(title: string): void {
    this._items.forEach((item, key) => this._items.set(item.title, { ...item, isActive: key === title }));
    this.updatedCallback(this.getItems());
  }

  /**
   * Make a specific item inactive
   */
  inactive(title: string): void {
    const item = this._items.get(title);
    if (item) {
      this._items.set(title, { ...item, isActive: false });
    }
    this.updatedCallback(this.getItems());
  }

  /**
   * Clear the current list of items
   */
  clear(): void {
    this._items.clear();

    this.updatedCallback(this.getItems());
  }

  /**
   * Return the current list of items
   */
  getItems(): SidebarItem[] {
    return [...this._items].map(([, item]) => item);
  }

  /**
   * Register context button
   */
  registerContextButton(title: string, callback: () => void): void {
    const handlers = this._contextButtonHandlers.get(title);
    if (handlers) {
      handlers.push(callback);
      this._contextButtonHandlers.set(title, handlers);
    } else {
      this._contextButtonHandlers.set(title, [callback]);
    }
  }

  /**
   * Unregister context button
   */
  unRegisterContextButton(title: string, callback: () => void): void {
    const handlers = this._contextButtonHandlers.get(title);
    if (handlers) {
      const updatedHandlers = handlers.filter(fn => fn !== callback);
      this._contextButtonHandlers.set(title, updatedHandlers);
    }
  }

  /**
   * Fire the context Button
   */
  fireContextButton(title: string): void {
    const handlerFn = this._contextButtonHandlers.get(title);
    if (handlerFn) {
      handlerFn.forEach(fn => fn());
    }
  }
}
