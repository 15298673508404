import React, { useEffect } from 'react';
import { Confirm, ConfirmModalType } from '../components/Modal/ConfirmModal';
import { logger } from '../util/Logger';
import { envVar } from '../util/Util/EnvVar';

export default function useDetectVersionUpdate(): void {
  /**
   * Check if the user is using an old version --> commit-hash.json
   * If so, show a modal with a message to refresh the page
   */
  useEffect(() => {
    if (envVar('NODE_ENV') !== 'development') {
      const abortController = new AbortController();
      let modalIsVisible = false;
      const interval = setInterval(() => {
        fetch(`/commit-hash.json?nocache=${Date.now()}`, { signal: abortController.signal })
          .then(res => res.text())
          .then(res => {
            // eslint-disable-next-line
            // @ts-ignore
            const currentGitCommitHash = `${GIT_COMMIT_HASH}`;
            const newGitCommitHash = JSON.parse(res).commitHash;

            if (newGitCommitHash && newGitCommitHash !== currentGitCommitHash && !modalIsVisible) {
              modalIsVisible = true;
              Confirm({
                showSideIcon: true,
                type: ConfirmModalType.Warning,
                title: 'Outdated version',
                text: (
                  <div className='flex items-center gap-x-2'>
                    <p>You are using an outdated version of the App. Please refresh the page to load the new version of the App.</p>
                  </div>
                ),
                showCancelButton: false,
                confirmText: 'Refresh now',
                onConfirm: () => document.location.reload(),
              });
            }
          })
          .catch(error => logger.error('Cannot detect current commit-hash', error));
      }, 1000 * 60);

      return () => {
        clearInterval(interval);
        abortController.abort();
      };
    }
  }, []);
}
