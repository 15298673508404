import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import LogoLight from '../../../assets/storro-logo-and-text-light.svg';
import useScreenSize from '../../../hooks/UseScreenSize';
import { ClientPortalTheme } from '../../../types';
import { ScreenSize } from '../../../types/ScreenSize';
import Spinner, { SpinnerSize } from '../../Spinner';
import Advertise, { AdvertiseSize } from './Advertise';
import ClientPortalBackground from './Background';
import StorroFooterBadges from './StorroFooterBadges';
import { Info, TextOutdent } from '@phosphor-icons/react';

interface Props {
  theme?: ClientPortalTheme;
  logoUrl?: string;
  loading?: boolean;
  loadingText?: string;
  preview?: boolean;
  sidebarDetails?: React.ReactNode;
  smallContentBox?: boolean; // set the content box to a max fixed size
  children: React.ReactNode;
}

export default function ClientPortal({
  theme,
  logoUrl,
  preview,
  children,
  sidebarDetails,
  loading,
  loadingText,
  smallContentBox,
}: Props): JSX.Element {
  const [showSidebar, setShowSidebar] = useState<boolean>();
  const [showAds, setShowAds] = useState<boolean>();

  const sidbarRef = useRef<HTMLDivElement>(null);

  const { height, width } = useScreenSize();

  /**
   * Toggle the sidebar (for mobile)
   */
  useEffect(() => {
    setShowSidebar(width > ScreenSize.md ? true : false);
  }, [width]);

  /**
   * Define if we need to show the ads block. Based on the calculation of the free space
   */
  useEffect(() => {
    if (!sidbarRef.current) return;
    setShowAds(height && height > sidbarRef.current.clientHeight ? true : false);
  }, [height]);

  return (
    <div className='min-h-screen flex items-stretch'>
      <ClientPortalBackground theme={width > ScreenSize.xs ? theme : undefined} preview={preview}>
        <div
          className={classNames('h-full w-full flex flex-col', {
            '!h-[calc(100%-50px)]': preview,
          })}
        >
          <div className='grow flex items-center justify-center'>
            <div
              className={classNames(
                'w-full sm:w-3/4 mb-3 sm:mb-0',
                'md:w-full md:max-w-5xl md:mr-[calc(300px+50px)] md:ml-[50px]',
                'lg:mr-[calc(400px+50px)]',
                {
                  '!max-w-[500px]': smallContentBox && width > 500,
                },
              )}
            >
              {loading && (
                <div className='flex flex-col items-center justify-center'>
                  <Spinner inverseColor={false} size={SpinnerSize['2xlarge']} />
                  <span className='text-white mt-5'>{loadingText}</span>
                </div>
              )}

              {!loading && <div className='w-full bg-gray-100 shadow-xl rounded border p-5 sm:p-10'>{children}</div>}
            </div>
          </div>
          <div className='flex flex-col-reverse xl:flex-row sm:items-center text-white w-[calc(100%-14px)] md:w-[calc(100%-310px)] lg:w-[calc(100%-410px)] relative mb-10 lg:mb-4'>
            <div
              className={classNames(
                'hidden absolute xs:flex text-xs opacity-40 justify-center sm:justify-start',
                'top-24 sm:top-16 md:top-24 lg:top-1',
                'lg:translate-x-0 left-4',
              )}
            >
              {theme && theme.background && (
                <p>
                  Photo by{' '}
                  <a href={`${theme.background.userLink}?utm_source=storro&utm_medium=referral`} rel='noreferrer' target='_blank'>
                    {theme.background.user}
                  </a>{' '}
                  on{' '}
                  <a href='https://unsplash.com/?utm_source=storro&utm_medium=referral' rel='noreferrer' target='_blank'>
                    Unsplash
                  </a>
                </p>
              )}
            </div>
            <div className='flex flex-col-reverse sm:flex-row md:flex-col-reverse lg:flex-row justify-evenly w-full gap-4'>
              <div className='flex items-center gap-x-2 justify-center opacity-75'>
                <img className='hidden xs:block ' src={LogoLight} alt='logo' width='75' />
                <span className='text-gray-200 text-xs lg:text-sm'>Copyright © {new Date().getFullYear()} Storro B.V.</span>
              </div>
              {width < ScreenSize.lg && <StorroFooterBadges className='justify-center' />}
            </div>

            {width >= ScreenSize.lg && (
              <div className='absolute justify-end -top-4 right-4'>
                <StorroFooterBadges ElementClassName='hidden xl:flex ' />
              </div>
            )}
          </div>
        </div>
      </ClientPortalBackground>

      <div
        className={classNames(
          'p-5 bg-gray-100 fixed z-10 right-0 h-full flex justify-center items-center bg-gradient-to-r from-gray-100 to-white drop-shadow-lg transition-all animate-slow',
          {
            'w-[300px] lg:w-[400px]': showSidebar,
            'w-72 !-right-[290px] sm:!-right-[260px]': !showSidebar,
          },
        )}
      >
        <button
          onClick={() => setShowSidebar(prevState => !prevState)}
          className={classNames(
            'md:hidden cursor-pointer bg-gray-100 absolute top-24 -left-10 h-10 w-10 flex items-center justify-center rounded-l',
          )}
        >
          {/* with -scale-x-[1] we flip the icon */}
          {showSidebar ? <TextOutdent className='-scale-x-[1]' /> : <Info />}
        </button>

        <div ref={sidbarRef} className='w-[250px]'>
          {logoUrl && <img className='mt-10 mx-auto mb-16 max-w-[200px] max-h-[125px]' src={logoUrl} alt='Logo' />}

          {sidebarDetails}
          <Advertise
            size={AdvertiseSize.Small}
            inverseColor={true}
            className={classNames('mt-10 lg:mt-18 xl:mt-28 animate-fadeInUp animate-fast w-[250px]', {
              'animate-delay-[2s]': !preview,
              'animate-delay-[0.3s]': preview,
              hidden: !showAds,
            })}
          />
        </div>
      </div>
    </div>
  );
}
