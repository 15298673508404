// Copyright 2021 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
//
// WebAssemblyMemory is a wrapper around managing memory
// copying to/from WebAssembly module memory.
//
// Usage:
//
// Creation / allocation:
//    var a = new WebAssemblyMemory(instance, size);
//    var b = WebAssemblyMemory.fromUint8Array(instance, myUint8Array);
//
// Passing the memory address to a C function:
//    instance._my-c-function(a.pointer, a.size);
//
// Create a Uint8Array copy:
//    var result = a.toUint8Array();
//
// Destruction / deallocation:
//    a.free();
//    b.free();
//
export class WebAssemblyMemory {
  /** instance is an Emscripten WebAssembly module. We need it to call the expoted C(++) functions and access wasm's memory. */
  instance: any; /* eslint-disable-line @typescript-eslint/no-explicit-any */

  pointer: number;
  size: number;

  /**
   * @constructor
   * @param instance - The WebAssembly instance we need to call malloc() and free() from.
   * @param size - The number of bytes we need.
   */
  constructor(instance: any, size: number) { /* eslint-disable-line */
    this.instance = instance;

    // Allocate the memory inside of the WebAssembly module and copy the key in.
    if (size > 0) {
      this.pointer = instance._malloc(size);
      this.size = size;
    } else {
      this.pointer = 0;
      this.size = 0;
    }
  }

  static fromUint8Array(instance: any, source: Uint8Array): WebAssemblyMemory { /* eslint-disable-line */
    const result = new WebAssemblyMemory(instance, source.length);
    if (source.length > 0) result.setMemory(source);
    return result;
  }

  setMemory(source: Uint8Array): void {
    if (this.size < source.length) throw 'setMemory() requires the source to be equal or smaller than the destination';
    this.instance.writeArrayToMemory(source, this.pointer);
  }

  // Copy the memory back from WebAssembly module into a Uint8Array().
  toUint8Array(): Uint8Array {
    // We need to manually make the copy instead of making a view.
    const result = new Uint8Array(this.size);
    for (let i = 0; i < this.size; i++) result[i] = this.instance.HEAPU8[this.pointer + i];
    return result;
  }

  free(): void {
    if (this.size > 0) {
      this.instance._free(this.pointer);
      this.size = 0;
    }
  }
}
