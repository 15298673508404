import classNames from 'classnames';
import { IS_MOBILE_APP } from 'const';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';
import { nanoid } from 'nanoid';
import React, { memo, useEffect, useRef, useState } from 'react';
import * as ReactIs from 'react-is';
import { Portal } from 'react-portal';
import { useDialogContext } from 'context/DialogContext';
import useKeypress from 'hooks/UseKeypress';
import Button, { ButtonVariant } from '../Button';
import { TooltipProps } from '../Tooltip';
import Spinner from 'components/Spinner';
import { X } from '@phosphor-icons/react';

// this is the time for the delay and duration of the animation
export const MODAL_DELAY_MS = 400;

export interface ModalAction {
  label: string;
  disabled?: boolean;
  loading?: boolean;
  variant?: ButtonVariant;
  onClick?: () => void;
  formId?: string;
  className?: string;
  isCloseButton?: boolean;
  tooltip?: TooltipProps;
}

export enum ModalWidth {
  xs = 'w-full xs:w-modal-xs',
  sm = 'w-full xs:w-modal-xs md:w-modal-sm',
  md = 'w-full xs:w-modal-xs md:w-modal-sm',
  lg = 'w-full xs:w-modal-xs md:w-modal-sm lg:w-modal-lg',
}

export interface ModalProps {
  title?: string;
  isVisible?: boolean;
  actions?: ModalAction[];
  action?: ModalAction;
  // fired when the modal will be openend
  onOpen?: () => void;
  // fired when the user click the close/cancel button
  // this event does not close actually the modal. The caller should
  // implement the actual close logic
  onRequestCloseModal?: () => void;
  // fired when the modal has been closed
  onClosed?: () => void;
  // render a side element next to the content
  sideNode?: React.ReactNode;
  // Set the width based on the ModalWidth enum
  width?: ModalWidth;
  // Should the box be fixed, or can it grow
  fixedHeight?: boolean;
  className?: string;
  // Render a fixed element at the top of the modal-body
  fixedHeaderComponent?: React.ReactNode;
  // Toggle the visibility of the fixed header component
  showFixedHeaderComponent?: boolean;
  // Render a fixed element in the footer
  fixedFooterComponent?: React.ReactNode;
  // A (help)text that will be placed in the footer bar at the left side
  helpText?: string;
  // A element, that will be placed in the footer bar at the left side
  footerSideNode?: React.ReactNode;
  // Mark the modal as a fullscreen modal
  fullscreen?: boolean;
  // make sure the size of the modal keep the same on larger screens and not growing into the space that is left
  remainSizeOnSmallerScreens?: boolean;
  // in some situations (confirmModal) we need to have the buttons at the bottom
  // instead at the top of the modal
  showButtonInFooterOnMobile?: boolean;
  children: React.ReactNode;
}

function Modal({
  title,
  isVisible: givenIsVisible = false,
  actions: givenActions,
  action,
  onOpen,
  onRequestCloseModal,
  onClosed,
  sideNode,
  width = ModalWidth.xs,
  fixedHeight = false,
  className,
  fixedHeaderComponent,
  showFixedHeaderComponent = true, // Because the fixedHeaderComponent can deal with an empty fragment, we cannot check on emptiness of fixedHeaderComponent
  fixedFooterComponent,
  helpText,
  children,
  footerSideNode,
  fullscreen,
  remainSizeOnSmallerScreens,
  showButtonInFooterOnMobile,
}: ModalProps): JSX.Element {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [actions, setActions] = useState<ModalAction[]>([]);

  const isMobileDevice = useIsMobileDevice();
  const { setDialogsItems, lastDialogItem, dialogsItems } = useDialogContext();

  const dialogId = useRef<string>(nanoid());

  // get the button ref so we can calculate the height that can be used
  // to set the top of the dialog when stacked on mobile
  const [buttonWrapperRef, setButtonWrapperRef] = useState<HTMLDivElement | null>(null);

  // set the animation classes
  // avoid the states as this trigger more rerenders
  let animationBackgroundClass: string | undefined = undefined;
  let animationModalClass: string | undefined = undefined;
  let animationSpeedClass: string | undefined = undefined;
  // when visible, set the modal as visible and apply the correct animations
  if (givenIsVisible) {
    animationSpeedClass = isMobileDevice ? 'animate-fast' : 'animate-faster';
    animationModalClass = isMobileDevice ? 'animate-slideInUp' : 'animate-zoomIn';
    animationBackgroundClass = 'animate-fadeIn';
  } else if (!givenIsVisible) {
    // when the user set the modal as closed
    // apply the correct animations and execute the cleanup function
    animationSpeedClass = 'animate-fast';
    animationModalClass = isMobileDevice ? 'animate-slideOutDown' : 'animate-zoomOut';
    animationBackgroundClass = 'animate-fadeOut';
  }

  // check if we have a close button that can be used for the X close button
  // if there are multiple actions that implement isCloseButton flag
  // we get the first hit
  const closeButton = actions.find(action => action.isCloseButton);
  // get the first action button, this one will be used as the action button that will be placed on the right side.
  // if ofc showButtonInFooter is calculated to false
  const actionButtons = actions.filter(action => !action.isCloseButton);
  const actionButton = actionButtons.at(0);

  // if we are on a mobile device, we placed the buttons in the top location of the modal
  // however, there are 2 situation when we place the buttons in the footer
  // - when remainSizeOnSmallerScreens we show the button in the footer instead
  // - when we have more than 1 action button
  const showButtonInFooter = remainSizeOnSmallerScreens || showButtonInFooterOnMobile || actionButtons.length > 1 || !isMobileDevice;

  /**
   * Add the actionButton and the close/cancel button to the actions array
   * If the actions property is given with an array of actions, we will use
   * that instead.
   */
  useEffect(() => {
    // Use the givenAction as the default actions set
    if (givenActions && givenActions.length > 0) {
      setActions(givenActions);
    } else {
      const newActions: ModalAction[] = [];

      // If no action is given, we should name the button "Close"
      // otherwise the button is named "Cancel"
      if (onRequestCloseModal !== undefined) {
        if (!action) {
          newActions.push({
            label: 'Close',
            onClick: onRequestCloseModal,
            isCloseButton: true,
          });
        } else {
          newActions.push({
            label: 'Cancel',
            onClick: onRequestCloseModal,
            disabled: action.loading,
            isCloseButton: true,
          });
        }
      }

      // use the default button type 'primary'
      if (action) {
        newActions.push({ ...action, variant: action.variant ?? ButtonVariant.Primary });
      }

      setActions(newActions);
    }
  }, [action, givenActions, onRequestCloseModal]);

  /**
   * When the modal is set to visible, apply animations and set the local state for showing the modal
   */
  useEffect(() => {
    // when visible, set the modal as visible and apply the correct animations
    if (givenIsVisible && !isVisible) {
      setIsVisible(true);

      // add the id to the list of dialogs that are open
      setDialogsItems(prevState => {
        const newState = [...new Set([...prevState, { id: dialogId.current, remainSizeOnSmallerScreens }])];
        // When we open the first modal
        // make sure we add an overflow-hidden class to the #root element
        // that prevent the body below from scrolling
        if (newState.length === 1) {
          const container = document.getElementById('root');
          container?.classList.add('overflow-hidden');
        }

        return newState;
      });

      // Cause we set the animation speed to .animate-faster we are sure that the animation is end withing .4 sec
      // We tried before using the onAnimationEnd event, but that create a loop where it close all modals
      // TODO find out how to use onAnimationEnd correctly without closing all modals
      setTimeout(() => {
        onOpen?.();
      }, MODAL_DELAY_MS);
    } else if (!givenIsVisible && isVisible) {
      // remove the current ID of the openedDialogs
      setDialogsItems(prevState => {
        const newState = prevState.filter(item => item.id !== dialogId.current);

        // if there are no modals open
        // remove the overflow-hidden class so the user can scroll
        if (newState.length === 0) {
          const container = document.getElementById('root');
          container?.classList.remove('overflow-hidden');
        }

        return newState;
      });

      // Cause we set the animation speed to .animate-faster we are sure that the animation is end withing .4 sec
      // We tried before using the onAnimationEnd event, but that create a loop where it close all modals
      // TODO find out how to use onAnimationEnd correctly without closing all modals
      setTimeout(() => {
        setIsVisible(false);
        onClosed?.();
      }, MODAL_DELAY_MS);
    }

    // cache it, so we can use it in the onDestruct function
    const currentDialogId = dialogId.current;

    // Not the polite way, but it could be that
    // the user is navigate from a modal via navigate() to a new route
    // This is basically a onClose event for our modal and we should act on it
    return () => {
      // only execute if the modal is visible
      if (isVisible) {
        // remove the current ID of the openedDialogs
        setDialogsItems(prevState => {
          const newState = prevState.filter(item => item.id !== currentDialogId);

          // if there are no modals open
          // remove the overflow-hidden class so the user can scroll
          if (newState.length === 0) {
            const container = document.getElementById('root');
            container?.classList.remove('overflow-hidden');
          }

          return newState;
        });
      }
    };
  }, [isVisible, setDialogsItems, givenIsVisible]); //eslint-disable-line

  /**
   * Support to close the modal with the ESC key
   */
  useKeypress('Escape', () => {
    if (lastDialogItem && lastDialogItem.id === dialogId.current) {
      const closeButton = actions?.find(action => action.isCloseButton);
      if (closeButton) {
        closeButton.onClick?.();
      }
    }
  });

  /**
   * Set the top Padding in case the dialog are layered/stacked
   *
   * we just push the last item forward so we only got 2 levels stacked
   * which gives as a nice animation when we close the dialog and does not consume to much space
   */
  const lastItem = dialogsItems.at(-1);
  let paddingTopLayered = 0;
  if (lastItem && lastItem.id === dialogId.current) {
    paddingTopLayered = 20;
  }
  const calcPaddingTop = isMobileDevice ? `calc(${IS_MOBILE_APP ? '0px' : '10px'} + ` : '';
  const paddingLayeredTop = `${calcPaddingTop}${paddingTopLayered}px + env(safe-area-inset-top))`;

  /**
   * Set the X-ass Padding in case the dialog are layered/stacked
   *
   * We are reversing the dialogsItems because we are calculating with the index.
   * in this case the last item is the current/last openened dialog. There for we reverse
   * the array so we can get a lower number
   */
  const dialogReverseIndex = [...dialogsItems].reverse().findIndex(curDialog => curDialog.id === dialogId.current);
  let paddingLayeredX = 0;
  if (dialogReverseIndex !== -1) {
    paddingLayeredX = dialogReverseIndex * 10;
  }

  /**
   * Return a closeButton element
   *
   * @param asCross if true, return the button with a closeIcon
   */
  const HeaderCloseButtonElement = ({ asCross = false }: { asCross: boolean }) => {
    if (!closeButton) return <></>;

    if (asCross) {
      return (
        <button
          type='button'
          onClick={closeButton.onClick}
          disabled={closeButton.disabled || closeButton.loading}
          className={classNames('absolute z-40 p-5 right-0 top-0 focus:outline-none', {
            'cursor-not-allowed': closeButton.loading,
          })}
        >
          <X />
        </button>
      );
    }

    return (
      <button
        className={classNames('w-20 overflow-hidden text-left', {
          'text-red-700 cursor-pointer': !closeButton.disabled && !closeButton.loading,
          'text-blue-400': closeButton.disabled || closeButton.loading,
        })}
        disabled={closeButton.disabled || closeButton.loading}
        onClick={closeButton.onClick}
      >
        {closeButton.label}
      </button>
    );
  };

  return (
    <>
      {isVisible && (
        <Portal node={document && document.getElementById('root')}>
          <div
            onDragEnter={event => {
              // Prevent the dragEnter bubbling, which can trigger child elements
              event.preventDefault();
              event.stopPropagation();
            }}
            className={classNames(
              'fixed z-[100] inset-0 w-screen flex justify-center',
              // 'overflow-hidden', // Notice!! Overflow-hidden will break the transform animations. Dont use it!
              {
                // center the modal for non mobile devices
                'items-center': !isMobileDevice,
                // if the modal has been marked as remainSizeOnSmallerScreens,
                // we should move the modal to the bottom on mobile devices
                'items-end': isMobileDevice && remainSizeOnSmallerScreens,
              },
            )}
            aria-labelledby='modal-title'
            aria-describedby='modal-body'
            role='dialog'
            aria-modal='true'
            data-dialog-id={dialogId.current}
          >
            {/*Create a gray background as overlay*/}
            <div
              className={classNames(
                animationBackgroundClass,
                animationSpeedClass,
                'modal-background fixed inset-0 bg-gray-500 bg-opacity-75',
              )}
              aria-hidden='true'
            />
            <div
              style={{
                marginTop: !fullscreen ? paddingLayeredTop : undefined,
                marginLeft: !fullscreen ? `${paddingLayeredX}px` : undefined,
                marginRight: !fullscreen ? `${paddingLayeredX}px` : undefined,
              }}
              className={classNames(
                'modal',
                animationModalClass,
                animationSpeedClass,
                'max-h-screen max-w-screen backface-hidden flex flex-col relative rounded-md bg-white overflow-hidden shadow-xl transition-all',
                {
                  // only apply custom width classes on non-mobile and non-fullscreen mode
                  [width]: !isMobileDevice && !fullscreen,
                  // for mobile we should let the content grow
                  grow: isMobileDevice,
                  'w-full': fullscreen,
                },
              )}
            >
              {!isMobileDevice && closeButton && <HeaderCloseButtonElement asCross={true} />}

              {/*This element is the head of the modal*/}
              {/* We either show this if the title is set or when we are on mobile */}
              {/* for mobile we need this to set the close button in the top left, for web we used the X in the top right */}
              {(title || (!showButtonInFooter && isMobileDevice)) && (
                <div
                  className={classNames('shrink-0 w-full bg-white px-4 pt-2 relative z-30 h-[50px]', {
                    // make the box a flexbox so we can center the title
                    // and put the close and action button next to each other
                    'flex justify-between gap-x-1 items-center': isMobileDevice,
                    'shadow-sm': title && (!fixedHeaderComponent || !showFixedHeaderComponent),
                    'border-gray-100 border-b': fixedHeaderComponent && showFixedHeaderComponent,
                  })}
                >
                  {/* Show the CloseButton on the left side in the header */}
                  {/* But only when there are also other buttons to show */}
                  {!showButtonInFooter && actionButtons.length >= 1 && <HeaderCloseButtonElement asCross={false} />}

                  <h3
                    className='text-xl leading-10 font-medium text-gray-900 w-full text-center md:text-left whitespace-nowrap truncate'
                    id='modal-title'
                  >
                    {title}
                  </h3>

                  {!showButtonInFooter && (
                    <div className='shrink-0 min-w-10 overflow-hidden text-right'>
                      {/* Show the actual action button, e.g. the submit button */}
                      {/* When there is no action button left, we place the close button here as a X icon */}
                      {actionButtons.length <= 1 && actionButton ? (
                        <button
                          className={classNames({
                            'text-blue-700 cursor-pointer': !actionButton.disabled,
                            'text-blue-400': actionButton.disabled,
                          })}
                          disabled={actionButton.disabled || actionButton.loading}
                          onClick={actionButton.onClick}
                          form={actionButton.formId}
                        >
                          {actionButton.loading && <Spinner className='ml-auto' inverseColor={true} />}
                          {!actionButton.loading && actionButton.label}
                        </button>
                      ) : (
                        <HeaderCloseButtonElement asCross={true} />
                      )}
                    </div>
                  )}
                </div>
              )}
              <div
                className={classNames('relative z-20 bg-white overflow-y-auto overflow-x-hidden', className, {
                  // make it possible to let the content grow, but not outgrow
                  // the 200px is space that is from the top of the content to the top of the page and vice versa for the bottom
                  // We need to subtract those 200px in order to have the correct max-height for our inner content
                  'md:max-h-[calc(100vh-200px)]': !fullscreen,
                  'mt-5': !title,
                  'md:h-[30rem] xs:h-[20rem] grow': !fullscreen && fixedHeight,
                })}
              >
                {/*This is a fixed header component, stays on top of the scrollable content*/}
                {fixedHeaderComponent && showFixedHeaderComponent && (
                  <div className='sticky top-0 bg-white z-50 w-full px-4 py-2 shadow-sm border-gray-100 border-b'>
                    {fixedHeaderComponent}
                  </div>
                )}

                <div
                  className={classNames('md:flex md:items-start', {
                    'mb-[3.7rem]': !isMobileDevice && fixedFooterComponent && !ReactIs.isFragment(fixedFooterComponent),
                    'w-full h-full': fullscreen,
                    'p-4 pt-1': !fullscreen,
                  })}
                >
                  {sideNode && <div className='mt-1'>{sideNode}</div>}
                  <div
                    className={classNames('w-full md:text-left', {
                      'h-full': fullscreen,
                    })}
                  >
                    {/*This element is the body of the modal*/}
                    <div
                      id='modal-body'
                      className={classNames('relative', {
                        'w-full h-full': fullscreen,
                        'mt-2': !fullscreen,
                        // in case we have a sidenode, push the body a bit more so we have some space for the side icon
                        '!mt-3': !fullscreen && sideNode,
                      })}
                    >
                      {children}
                    </div>
                  </div>
                </div>
              </div>

              {/*This is a fixed header component, stays on top of the scrollable content*/}
              {fixedFooterComponent && !ReactIs.isFragment(fixedFooterComponent) && (
                <div
                  // we calculate the placement based on the height of the buttonWrapperRef
                  // and also include the padding that we used
                  style={{
                    bottom: `${buttonWrapperRef?.offsetHeight ?? 0}px`,
                  }}
                  className={classNames('absolute bg-white z-20 w-full px-4 py-2 border-gray-100 border-t', {
                    // center the elements on mobile
                    'flex justify-center items-center': isMobileDevice,
                  })}
                >
                  {fixedFooterComponent}
                </div>
              )}

              {/*Render the buttons*/}
              {/*We do not render the buttons in fullscreen mode*/}
              {showButtonInFooter && !fullscreen && actions && actions.length > 0 && (
                <div
                  ref={setButtonWrapperRef}
                  className={classNames(
                    'mt-auto md:mt-0 border-t border-gray-100 bg-gray-50 px-4 py-3 md:px-4 md:flex flex-row-reverse items-center justify-between',
                    // env() intial value is not working with safe-area-inset-bottom as it return 0
                    // therefor, we can use max to determing an initial value when safe-area-inset-bottom=0
                    // by using max()
                    'pb-[max(env(safe-area-inset-bottom),10px)]',
                  )}
                >
                  <div
                    className={classNames('flex flex-col md:flex-row md:items-center md:justify-end gap-1', {
                      // for 2 items we placed them next to eachother, otherwise we put them on separated lines
                      '!flex-row items-center': isMobileDevice && actions.length <= 2,
                    })}
                  >
                    {actions.map(action => (
                      <Button
                        key={action.label}
                        fullWidth={isMobileDevice === true}
                        // We use a link for the close/cancel button as it is not an action
                        // but rather a cancel of your action.
                        // See https://uxdesign.cc/cancel-as-a-button-or-a-link-67ccbf9df81e
                        variant={action.isCloseButton ? ButtonVariant.Link : action.variant}
                        onClick={action.onClick}
                        loading={action.loading}
                        disabled={action.disabled}
                        htmlForm={action.formId}
                        className={action.className}
                        tooltip={action.tooltip}
                      >
                        {action.label}
                      </Button>
                    ))}
                  </div>

                  {!isMobileDevice && footerSideNode && <div className='mt-2 md:mt-0'>{footerSideNode}</div>}

                  {!isMobileDevice && !footerSideNode && helpText && <span className='text-sm text-gray-400'>{helpText}</span>}
                </div>
              )}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
}

export default memo(Modal);
