import React, { useState } from 'react';
import DesktopClientDownloadsForRealm from '../components/DesktopClient/DesktopClientDownloadsForRealm';
import Modal from '../components/Modal';

/**
 * Custom hook for updating the user account
 */
export default function useDesktopClientModal(): {
  dialog: JSX.Element;
  openModal: () => void;
} {
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);

  return {
    openModal: () => setModalIsVisible(true),
    dialog: (
      <Modal title='Download and install Storro' isVisible={modalIsVisible} onRequestCloseModal={() => setModalIsVisible(false)}>
        <DesktopClientDownloadsForRealm />
      </Modal>
    ),
  };
}
