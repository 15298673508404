import { Notches } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { useState, useEffect, useCallback, ReactNode, useRef } from 'react';

interface Props {
  minWidth: number;
  maxWidth: number;
  children: ReactNode;
  className?: string;
}

function ResizableDiv({ children, className, minWidth, maxWidth }: Props): JSX.Element {
  const [width, setWidth] = useState<number>();
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  /**
   * Event when start dragging on mouseDown
   */
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  /**
   * event to set the width of the resizable div
   */
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        // Nieuwe breedte berekenen op basis van de muispositie
        const offset = ref.current?.getBoundingClientRect().left ?? 0;
        const newWidth = e.clientX - offset;
        if (newWidth > minWidth && newWidth < maxWidth) {
          setWidth(newWidth);
        }
      }
    },
    [isDragging, maxWidth, minWidth],
  );

  /**
   * Onmouseup event, stop dragging
   */
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  /**
   * Add the events to the document
   */
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    // Cleanup function
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, isDragging]);

  return (
    <div
      ref={ref}
      className={classNames('relative', className)}
      style={{
        width: `${width}px`,
      }}
    >
      {/* Drag handle, on the edge from top to bottom as a small handle */}
      <div
        className={classNames('absolute right-0 top-0 bottom-0 w-1 cursor-ew-resize z-50', {
          'bg-gray-300': isDragging,
        })}
        onMouseDown={handleMouseDown}
      />

      {/* Drag handle, a small notch icon at the right-bottom */}
      <div
        className={classNames('hover:text-gray-900 text-gray-500 absolute right-0 bottom-0 cursor-ew-resize z-40 pl-3 bg-gray-50')}
        onMouseDown={handleMouseDown}
      >
        <Notches />
      </div>
      {children}
    </div>
  );
}

export default ResizableDiv;
