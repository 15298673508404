import { FileAdobeAe, FileAdobeAi, FileAdobeId, FileAdobePr, FileAdobePs, FileAdobeXd, FileBook, FileFont } from 'customIcons';
import getFileExtension from './GetFileExtension';
import getFileExtensionType, { ExtensionTypes } from './GetFileExtensionType';
import { ReactNode } from 'react';
import {
  FileAudio,
  FileCode,
  FileDoc,
  File as FileIcon,
  FileImage,
  FilePdf,
  FileText,
  FileVideo,
  FileXls,
  FileZip,
  FilePpt,
} from '@phosphor-icons/react';
import React from 'react';

const colors: { [key in ExtensionTypes]: string } = {
  archive: 'text-[#eaba35]',
  audio: 'text-[#1eca5e]',
  book: 'text-gray-500',
  code: 'text-[#572c8c]',
  exec: 'text-gray-500',
  font: 'text-gray-500',
  word: 'text-[#015195]',
  excel: 'text-[#1c6740]',
  pdf: 'text-[#aa0b00]',
  image: 'text-gray-500',
  slide: 'text-[#e54221]',
  text: 'text-gray-500',
  video: 'text-[#f20101]',
  web: 'text-gray-500',
  adobeAfterEffects: 'text-[#31a8ff]',
  adobeIllustrator: 'text-[#ff9a00]',
  adobeInDesign: 'text-[#ff3365]',
  adobePhotoshop: 'text-[#31a8ff]',
  adobePremiere: 'text-[#9999ff]',
  adobeXD: 'text-[#ff61f6]',
  default: 'text-gray-500',
};

const iconifyIcons: { [key in ExtensionTypes]: ReactNode } = {
  archive: <FileZip />,
  audio: <FileAudio />,
  book: <FileBook />,
  code: <FileCode />,
  exec: <FileIcon />,
  font: <FileFont />,
  word: <FileDoc />,
  excel: <FileXls />,
  pdf: <FilePdf />,
  image: <FileImage />,
  slide: <FilePpt />,
  text: <FileText />,
  video: <FileVideo />,
  web: <FileCode />,
  adobeAfterEffects: <FileAdobeAe />,
  adobeIllustrator: <FileAdobeAi />,
  adobeInDesign: <FileAdobeId />,
  adobePhotoshop: <FileAdobePs />,
  adobePremiere: <FileAdobePr />,
  adobeXD: <FileAdobeXd />,
  default: <FileIcon />,
};

/**
 * Get the IconifyIcon and color from a filename (based on the extension)
 */
export default function getExtensionIcon(filename: string): { color: string; icon: ReactNode } {
  const extension = getFileExtension(filename);
  const extensionType = getFileExtensionType(extension) ?? 'default';
  const color = colors[extensionType] ?? 'text-gray-500';
  const icon = iconifyIcons[extensionType] ?? <FileIcon />;

  return {
    color,
    icon,
  };
}
