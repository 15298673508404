import { IconContext, Warning, WarningCircle, XCircle } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

export enum SideIconType {
  Notice,
  Warning,
  Danger,
}

interface Props {
  type?: SideIconType;
  icon?: ReactNode;
}

function SideIcon({ type, icon }: Props): JSX.Element {
  let sideIcon: ReactNode;

  if (!icon) {
    switch (type) {
      case SideIconType.Danger:
        sideIcon = <XCircle />;
        break;
      case SideIconType.Notice:
        sideIcon = <WarningCircle />;
        break;
      case SideIconType.Warning:
      default:
        sideIcon = <Warning />;
        break;
    }
  } else {
    sideIcon = icon;
  }

  return (
    <div
      className={classNames(
        'mx-auto',
        'sm:mr-2',
        'shrink-0',
        'flex',
        'items-center',
        'justify-center',
        'h-12',
        'w-12',
        'rounded-full',
        'sm:mx-0',
        'sm:h-10',
        'sm:w-10',
        {
          'bg-danger-100': type === SideIconType.Danger,
          'bg-notice-100': type === SideIconType.Notice,
          'bg-warning-100': type === SideIconType.Warning,
        },
      )}
    >
      <IconContext.Provider value={{ size: 20 }}>{sideIcon}</IconContext.Provider>
    </div>
  );
}

export default SideIcon;
