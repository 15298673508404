import { IconContext, Plus } from '@phosphor-icons/react';
import classNames from 'classnames';
import DropdownMenu, { DropdownMenuItemArray } from 'components/DropdownMenu';
import { PageButtonAction } from 'components/Page/PageButtons';
import Tooltip from 'components/Tooltip';
import { MenuItem } from 'hooks/UseMenu';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  menuItem?: MenuItem;
  actions?: PageButtonAction[];
}

/**
 * Render a single button for in the mobile menu
 */
function MobileMenuItem({ actions, menuItem }: Props): JSX.Element {
  const className = 'inline-flex flex-col items-center justify-center text-gray-500 hover:text-primary';

  const dropdownItems = useMemo((): DropdownMenuItemArray[] => {
    if (!actions) return [];
    return [
      actions?.map(action => ({ element: action.label, onClick: action.onClick, isDisabled: action.disabled, tooltip: action.tooltip })),
    ];
  }, [actions]);

  // If there are actions defined, we deal with a plus icon
  if (actions) {
    // show a single button if there is only one action
    if (actions.length === 1) {
      return (
        <Tooltip disabled={!actions[0].disabled} {...actions[0].tooltip}>
          <button
            // we cannot use disabled here, as it will not show the tooltip
            // so whe have to use the onClick event to prevent the action
            onClick={!actions[0].disabled ? actions[0].onClick : undefined}
            className={classNames('rounded-full w-14 h-14', className, {
              'bg-gray-400': actions[0].disabled,
              'bg-primary': !actions[0].disabled,
            })}
          >
            <Plus className='text-gray-100' size={25} />
          </button>
        </Tooltip>
      );
    }

    // show a dropdown menu if there are multiple actions
    return (
      <DropdownMenu ulClassName='px-0' menuItems={dropdownItems}>
        <button className={classNames('bg-primary rounded-full w-14 h-14', className)}>
          <Plus className='text-gray-100' size={25} />
        </button>
      </DropdownMenu>
    );
  }

  // If there are no actions, we deal with a regular menu item
  if (menuItem) {
    return (
      <NavLink
        key={menuItem.path}
        to={menuItem.path}
        className={navData =>
          classNames(className, {
            'text-primary': navData.isActive,
          })
        }
      >
        <div className='mb-1'>
          <IconContext.Provider value={{ size: 25 }}>{menuItem.icon}</IconContext.Provider>
        </div>
        <span className='text-xs font-normal'>{menuItem.shortName ?? menuItem.title}</span>
      </NavLink>
    );
  }

  return <></>;
}

export default MobileMenuItem;
