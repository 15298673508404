import React, { useState } from 'react';
import Modal, { ModalWidth } from '../../components/Modal';
import RealmTableUser from '../../components/Realm/RealmTableUser';

/**
 * This hook will create a modal for listing the user realms
 */
export default function useListRealmsModal(): {
  dialog: JSX.Element;
  openModal: () => void;
} {
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);

  /**
   * Callback when we close the modal
   */
  const onCancel = () => setModalIsVisible(false);

  /**
   * Callback when we switch to another realm
   */
  const onSwitchRealm = () => setModalIsVisible(false);

  return {
    openModal: () => setModalIsVisible(true),
    dialog: (
      <>
        <Modal title='All Realms' isVisible={modalIsVisible} onRequestCloseModal={onCancel} width={ModalWidth.lg}>
          <RealmTableUser onSwitchRealm={onSwitchRealm} />
        </Modal>
      </>
    ),
  };
}
