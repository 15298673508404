import { decode, encode } from '@stablelib/utf8';
import base64url from 'base64url';
import { crypto_generichash } from 'libsodium-wrappers';
import { ConvergentEncryption } from '../util/Cryptography/ConvergentEncryption';
import { SymmetricKey } from '../util/Cryptography/SymmetricKey';
import { logger } from '../util/Logger';
import { Ecas } from '../util/Serialization/Ecas';
import { KeyStream } from '../util/Serialization/KeyStream';
import { StreamKey } from '../util/Serialization/StreamKey';
import { concat } from '../util/Util/Concat';
import { equal } from '../util/Util/Equal';
import { ListingCache } from './ListingCache';
import { isSuperAdmin } from '../util/SuperAdmin';

export enum EntryType {
  Dir = '0',
  File = '1',
}

const spaces = (d: number): string => {
  if (d <= 0) return '';
  return '   ' + spaces(d - 1);
};

// TargetExistsError is thrown when we try to move/copy
// an Entry and the destination already exists.
// https://stackoverflow.com/a/64866823/13746151
export class TargetExistsError extends Error {
  constructor(...args: string[] | undefined[]) {
    super(...args);
    this.name = 'TargetExistsError';
  }
}

// EncryptedListing and EncryptedListingEntry need to convert to json.
// That is why they do not have Uint8Array but base64 encoded strings.
// Basically this defines the JSON format.
export class EncryptedListing {
  recursive_count: string; // Amount of entries in listing and sub-listings (as string because numeric limits).
  entries: EncryptedListingEntry[];
}

// EncryptedListingEntry is part of the JSON format.
// TODO Can't we make the key optional for empty items?
export class EncryptedListingEntry {
  name: string; // Base64Url of encrypted (with content symmetric key) file/dir name.
  size: string; // Size of file/dir in bytes (as string because numeric limits). Dir size is accumulation of all sub items.
  mod: string; // modification time (ISO 8601 extended format, including milliseconds)
  type: EntryType; // dir ('0') or file ('1') type
  key: string | null; // Base64url of: content key from the content store when it's a file, meta key of Listing when it's a dir.
}

// Returns a positive integer when left is greater,
// zero when they are equal and negative when right is greater.
function compareUint8Array(left: Uint8Array, right: Uint8Array): number {
  const minLength = Math.min(left.length, right.length);
  for (let i = 0; i < minLength; i++) {
    if (left[i] !== right[i]) return left[i] - right[i];
  }
  return left.length - right.length;
}

function compareDates(left: Date, right: Date): number {
  if (left > right) return 1;
  if (left < right) return -1;
  return 0; // Equal
}

function compareEntryType(left: EntryType, right: EntryType): number {
  if (left === right) return 0;
  if (left === EntryType.Dir) return -1;
  return 1;
}

function compareEntry(left: Entry, right: Entry): number {
  // First sort on Type (Dir, File)
  if (left.type !== right.type) return compareEntryType(left.type, right.type);

  // The name is an encrypted Uint8Array.
  if (!equal(left.encryptedName, right.encryptedName)) return compareUint8Array(left.encryptedName, right.encryptedName);
  if (left.size !== right.size) {
    if (left.size > right.size) return 1;
    return -1;
  }
  if (left.mod !== right.mod) return compareDates(left.mod, right.mod);
  if (left.key) {
    if (!right.key) return 1;
    return left.key.compare(right.key);
  } else {
    if (right.key) return -1;
  }
  return 0; // Equal.
}

export function fromListingEntry(
  listingCache: ListingCache | undefined,
  ecas: Ecas,
  contentLayerKey: SymmetricKey | undefined,
  listingEntry: EncryptedListingEntry,
): Entry {
  const encryptedName = new Uint8Array(base64url.toBuffer(listingEntry.name));
  const size = BigInt(listingEntry.size);
  const mod = new Date(listingEntry.mod);

  if (listingEntry.type === EntryType.File) {
    const key = listingEntry.key ? StreamKey.fromEncryptedString(listingEntry.key, contentLayerKey) : undefined;
    return new File(encryptedName, mod, size, key);
  } else if (listingEntry.type === EntryType.Dir) {
    const key = listingEntry.key ? StreamKey.fromPlaintextString(listingEntry.key) : undefined;
    return new Dir(listingCache, ecas, contentLayerKey, encryptedName, mod, size, key);
  } else {
    throw Error('Unsupported listing type');
  }
}

export function entriesEqual(a: Entry, b: Entry): boolean {
  return compareEntry(a, b) === 0;
}

export interface Entry {
  readonly encryptedName: Uint8Array; // The encrypted name.
  type: EntryType; // dir ('0') or file ('1') type
  mod: Date; // modification time (ISO 8601 extended format, including milliseconds)
  size: bigint; // Size of file/dir in bytes (as string because numeric limits). Dir size is accumulation of all sub items.
  key: StreamKey | undefined; // key from the content store when it's a file, meta key of Listing when it's a dir.
  // An undefined key means empty file or empty Dir.

  // Changes the encrypted name of the entry and if possible also the plaintext name.
  setName(newEncryptedName: Uint8Array): void;

  // Returns the name for display to the user.
  // If we have the content key it will be the plaintext name,
  // otherwise base64 of the encrypted name.
  name(contentKey?: SymmetricKey): string;

  toShortEncryptedName(): Uint8Array;

  toDir(): Dir; // Downcast to Dir type. Throws for other types.
  toListingEntry(contentLayerKey: SymmetricKey): EncryptedListingEntry; // Export to a json structure description of this Entry. Works for File and Dir.

  // Amount of entries including the Entry itself.
  // File and empty Dir return 1.
  // Dir with 2 Files returns 3.
  recursiveEntryCount(): Promise<bigint>;

  // Needed to avoid unintentional sharing.
  // This is used in the Dir.copy().
  clone(): Entry;

  // copy and re-crypt an Entry recursively between meta stores.
  // While Dir only needs a destination (already has source private),
  // File has no idea about source and destination and needs both
  // source and destination content keys to re-crypt.
  copyRecrypt(
    source: Ecas,
    sourceContentLayerKey: SymmetricKey,
    destination: Ecas,
    destinationContentLayerKey: SymmetricKey,
  ): Promise<Entry>;

  // This returns the way in which two Entries are different.
  // If the values are equal returns undefined.
  // Currently used for testing.
  deepCompare(other: Entry): Promise<string | undefined>;

  // Debug functionality
  printTree(depth: number, contentKey?: SymmetricKey): Promise<string>;

  // Check that the data structure makes sense.
  checkOk(): Promise<void>;
}

export class File implements Entry {
  public encryptedName: Uint8Array; // The encrypted name.
  public size: bigint; // Size of file/dir in bytes (as string because numeric limits). Dir size is accumulation of all sub items.
  public mod: Date; // modification time (ISO 8601 extended format, including milliseconds)
  public type: EntryType; // file ('1') type
  public key: StreamKey | undefined; // key from the content store when it's a file, meta key of Listing when it's a dir.

  private cachedName?: string = undefined;
  // Empty File will have undefined key.

  constructor(encryptedName: Uint8Array, mod: Date, size?: number | bigint, key?: StreamKey) {
    if (encryptedName.length < 16) throw new Error('Encryptedname is too short');
    this.encryptedName = encryptedName;
    if (typeof size === 'undefined') {
      this.size = 0n;
    } else if (typeof size === 'bigint') {
      this.size = size;
    } else if (typeof size === 'number') {
      this.size = BigInt(size);
    }
    this.mod = mod;
    this.type = EntryType.File;
    if (typeof key !== 'undefined') {
      this.key = key;
    }

    // Do some invariant checking
    if (this.size === BigInt(0)) {
      if (this.key) throw new Error('File should not have a content key while having zero size');
    } else {
      if (!this.key) throw new Error('File should not have an empty content key while having non-zero size');
      if (!this.key.getContentKey().length) {
        if (!isSuperAdmin()) {
          const encrFileNameB64 = base64url.encode(Buffer.from(encryptedName));
          logger.error(`Broken file loaded. File (${encrFileNameB64}) has a non-zero size but the stream key has no content key`);
        }
      }
    }
  }

  public name(contentKey?: SymmetricKey): string {
    if (contentKey) {
      if (this.cachedName) {
        return this.cachedName;
      }
      try {
        const name = ConvergentEncryption.decryptName(contentKey, this.encryptedName);
        this.cachedName = name;
        return name;
      } catch (e) {
        logger.error(e);
        return 'Undecryptable file name';
      }
    }
    return base64url.encode(Buffer.from(this.encryptedName));
  }

  public setName(newEncryptedName: Uint8Array): void {
    if (newEncryptedName.length < 16) {
      // It should be fairly impossible to generate such a short encrypted name.
      throw new Error('Encryptedname must be at least 16 characters');
    }
    this.cachedName = undefined;
    this.encryptedName = newEncryptedName;
  }

  public toShortEncryptedName(): Uint8Array {
    return crypto_generichash(6, this.encryptedName);
  }

  public toDir(): Dir {
    throw new Error('File could not be cast to Dir');
  }

  public toListingEntry(contentLayerKey: SymmetricKey): EncryptedListingEntry {
    if (this.size !== 0n && !this.key) {
      throw Error('File has a size but no StreamKey');
    }

    if (this.size !== 0n && !this.key?.getContentKey().length) {
      throw Error('File has a size but no content key set in its StreamKey');
    }

    return {
      name: base64url.encode(Buffer.from(this.encryptedName)),
      size: this.size.toString(),
      mod: this.mod.toISOString(),
      type: this.type,
      key: this.key ? this.key.toEncryptedString(contentLayerKey) : '',
    };
  }

  public async recursiveEntryCount(): Promise<bigint> {
    return 1n;
  }

  public clone(): Entry {
    return new File(this.encryptedName, this.mod, this.size, this.key);
  }

  public async copyRecrypt(
    source: Ecas,
    sourceContentLayerKey: SymmetricKey,
    destination: Ecas,
    destinationContentLayerKey: SymmetricKey,
  ): Promise<Entry> {
    // Re-crypt the filename
    const filename = ConvergentEncryption.decryptName(sourceContentLayerKey, this.encryptedName);
    const recryptedFilename = ConvergentEncryption.encryptName(destinationContentLayerKey, filename);

    if (this.key) {
      // If there is a key for the stream it should be plaintext (user).
      // and not an encrypted content key (facilitator).
      // FYI: Since we have the source ecas we could decrypt an encrypted content key too,
      // just that this should not happen.
      if (this.key.getContentKey().length === 0) throw new Error('File should have a plaintext content key');
    }
    return new File(recryptedFilename, this.mod, this.size, this.key);
  }

  public async deepCompare(other: Entry): Promise<string | undefined> {
    if (this === other) return undefined;
    if (other.type !== EntryType.File) return 'File: Type is not equal';
    if (!equal(this.encryptedName, other.encryptedName)) return 'File: Encryptedname is not equal';
    if (this.mod.getTime() !== other.mod.getTime()) return 'File: mod is not equal';
    if (this.size !== other.size) return 'File: size is not equal';
    if (!StreamKey.areEqual(this.key, other.key)) return 'File: key is not equal';
    return undefined;
  }

  async printTree(depth = 0, contentKey?: SymmetricKey): Promise<string> {
    const s = spaces(depth);
    const date = this.mod.toISOString();
    const k = this.key ? this.key.toString() : '';
    const encname = base64url.encode(Buffer.from(this.encryptedName)).slice(0, 6);
    if (contentKey) {
      const name = ConvergentEncryption.decryptName(contentKey, this.encryptedName);
      return `\n ${s} F ${name} ${encname} ${this.size} ${date}\n ${s}    ${k} (${this.size} bytes)`;
    }
    return `\n ${s} F ${this.name()} ${encname} ${this.size} ${date}\n ${s}    ${k} (${this.size} bytes)`;
  }

  public async checkOk(): Promise<void> {
    // A File can only have a non-empty key when the size is non-zero.
    if (this.size === BigInt(0)) {
      if (this.key) throw new Error('Empty File should not have a content key');
    } else {
      if (!this.key) throw new Error('Non-empty File should have a content key');
    }
  }
}

/*
 * Path accepts path in the form of '/a/b/c'.
 */
export class Path {
  private components: Array<Uint8Array>;

  constructor(encryptedPath: string | Array<Uint8Array> = '') {
    if (typeof encryptedPath === 'string') {
      if (encryptedPath === '' || encryptedPath === '/') {
        // Both '' and '/' are the empty path pointing at root dir.
        this.components = new Array<Uint8Array>();
        return;
      }
      // If the path ends in a slash remove it.
      if (encryptedPath.endsWith('/')) encryptedPath = encryptedPath.slice(0, encryptedPath.length - 1);
      const pathComponents = encryptedPath.split('/');
      if (pathComponents.length < 2) throw new Error('Path needs at least one slash');
      const empty = pathComponents.shift();
      if (empty !== '') throw new Error('Path should start with a slash');

      // Parse and perform copy-and-swap.
      this.components = pathComponents.map(comp => new Uint8Array(base64url.toBuffer(comp)));
      if (this.components.find(e => e.length === 0)) throw new Error('Path must not contain empty components');
      return;
    }
    // The encryptedPath is Array<Uint8Array>
    this.components = encryptedPath;
    if (this.components.find(e => e.length === 0)) throw new Error('Path must not contain empty components');
  }

  public static fromPlaintext(path: string, contentKey: SymmetricKey): Path {
    const segments: Uint8Array[] = [];
    if (path === '' || path === '/') {
      return new Path(segments);
    }
    for (const s of path.split('/')) {
      if (s.length !== 0) {
        segments.push(ConvergentEncryption.encryptName(contentKey, s));
      }
    }
    return new Path(segments);
  }

  public clone(): Path {
    const result = new Path();
    result.components = this.components.map(c => new Uint8Array(c));
    return result;
  }

  public length(): number {
    return this.components.length;
  }

  public push(e: Uint8Array): void {
    this.components.push(e);
  }

  // Add a segment at the beginning of the path.
  public unshift(e: Uint8Array): void {
    this.components.unshift(e);
  }

  // Returns the last pushed (deepest) component.
  public pop(): Uint8Array {
    const result = this.components.pop();
    if (!result) throw new Error('Could not pop from Path');
    return result;
  }

  // Returns the uppermost component.
  public lastComponent(): Uint8Array {
    if (this.components.length === 0) throw new Error('Could not see last component from empty path');
    return this.components[this.components.length - 1];
  }

  // Returns the uppermost component and removes it from the stack.
  public takeComponent(): Uint8Array {
    if (this.components.length === 0) throw new Error('Could not take from empty path');
    const result = this.components.shift();
    if (!result) throw new Error('Path component could not be taken');
    return result;
  }

  /*
   * toString() returns the path as a string (always starting with '/')
   */
  public toEncryptedString(): string {
    if (this.components.length === 0) return '/';
    return (
      '/' +
      this.components
        .map((comp: Uint8Array) => {
          return base64url.encode(Buffer.from(comp));
        })
        .join('/')
    );
  }

  /*
   * Returns the 'short name' of an Entry (first 6 bytes).
   * Always begins with a slash '/'.
   */
  public toShortEncryptedString(): string {
    if (this.components.length === 0) return '/';
    return '/' + this.components.map((comp: Uint8Array) => base64url.encode(Buffer.from(crypto_generichash(6, comp)))).join('/');
  }

  get segments(): Array<Uint8Array> {
    return this.components;
  }

  public toJSON(): string {
    return this.toEncryptedString();
  }

  public toString(contentKey: SymmetricKey): string {
    if (this.components.length === 0) return '/';
    return '/' + this.components.map((comp: Uint8Array) => ConvergentEncryption.decryptName(contentKey, comp)).join('/');
  }

  public isEqualTo(other: Path): boolean {
    if (this.segments.length !== other.segments.length) {
      return false;
    }
    for (let i = 0; i < this.segments.length; i++) {
      if (!equal(this.segments[i], other.segments[i])) {
        return false;
      }
    }
    return true;
  }

  public isParentOf(other: Path): boolean {
    if (this.length() >= other.length()) {
      return false;
    }
    for (let i = 0; i < this.segments.length; i++) {
      if (!equal(this.segments[i], other.segments[i])) {
        return false;
      }
    }
    return true;
  }
}

export class Dir implements Entry {
  // Dir represents a Directory with entries in it.
  // Dirs can nest recursively, and this means a Dir can be a complete filesystem.
  //
  // Because a Dir is generally saved on content-addressed storage,
  // one needs to perform the operations on the root object rather than
  // any intermediate Dir object:
  //
  // Incorrect:
  //
  //     const path = new Path('/some/path/and/subpath');
  //     const newFile = newFile(...);
  //     const rootdir = ...
  //     const subdir = rootdir.getEntry('/some/path/and/subpath');
  //     subdir.insert(newFile);
  //
  // Correct: // rootdir also updates recursively
  //
  //     const path = new Path('/some/path/and/subpath');
  //     const newFile = newFile(...);
  //     const rootdir = ...
  //     rootdir.insert(newFile, path);
  //
  // Lazy loading:
  // A Dir can have a `key` which can be used to load it from the ecas lazily.
  // Once loaded, the subEntries are populated. subEntries stays undefined before that.
  // This gives us the following states:
  //
  // Key empty, subEntries empty:
  //     Empty Dir.
  // Key empty, subEntries not empty:
  //     Dir with unflushed changes. Needs flush for new key.
  // Key not empty, subEntries empty:
  //     Lazily loaded Dir. Entries will be loaded when needed.
  // Key not empty, entries not empty:
  //     Populated Dir with no changes compared to disk.
  //
  // We need the ecas for serializing to and lazy loading from.
  private ecas: Ecas;

  // The encrypted filename
  public encryptedName: Uint8Array;
  public size: bigint; // Size of file/dir in bytes. Dir size is accumulation of all sub items.
  public mod: Date; // modification time (ISO 8601 extended format, including milliseconds)
  public type: EntryType; // dir ('0') type
  public key: StreamKey | undefined; // key from the content store when it's a file, meta key of Listing when it's a dir.

  private cachedName?: string = undefined;
  // Empty File will have undefined key.

  // A manual downcasting operation.
  public toDir(): Dir {
    return this;
  }

  // All the Entries in this Dir
  // Do *not* use this directly, always go through entries() function,
  // which lazily loads the entries.
  private subEntries: Array<Entry> | null;

  // The recursive entry count is the number of entries (File or Dir) below this Dir.
  // This is encoded along with the subEntries.
  // This also means subEntries and myRecursiveEntryCount will both be
  // either null or well-defined at the same time. They are paired.
  private myRecursiveEntryCount: bigint | null;

  /**
   * A directory in a Storro project.
   * @param listingCache A caching class for listing objects.
   * @param ecas The meta ecas of the project
   * @param contentLayerKey The content and filename decryption key. Should be added when you're not a facilitator.
   * @param encryptedName The encrypted dir name.
   * @param mod The modification time of the dir.
   * @param size The size of the dir.
   * @param key StreamKey of the dir (undefined when empty)
   */
  constructor(
    private listingCache: ListingCache | undefined,
    ecas: Ecas,
    private contentLayerKey: SymmetricKey | undefined,
    encryptedName: Uint8Array,
    mod: Date,
    size?: bigint,
    key?: StreamKey,
  ) {
    // if (encryptedName.length < 16) throw new Error('Encrypted name must not be empty');

    this.ecas = ecas;
    this.encryptedName = encryptedName;
    this.size = typeof size !== 'undefined' ? size : 0n;
    this.mod = typeof mod !== 'undefined' ? mod : new Date();
    this.type = EntryType.Dir;
    this.subEntries = null;
    this.myRecursiveEntryCount = null;
    if (typeof key !== 'undefined') this.key = key;
  }

  public static fromListingEntry(
    ecas: Ecas,
    contentLayerKey: SymmetricKey | undefined,
    listingCache: ListingCache | undefined,
    e: EncryptedListingEntry,
  ): Dir {
    const encryptedName = new Uint8Array(base64url.toBuffer(e.name));
    const size = BigInt(e.size);
    const mod = new Date(e.mod);
    if (e.type !== EntryType.Dir) throw new Error('Dir can only be constructed from Dir type');
    const key = e.key ? StreamKey.fromPlaintextString(e.key) : undefined;
    return new Dir(listingCache, ecas, contentLayerKey, encryptedName, mod, size, key);
  }

  //eslint-disable-next-line
  public toListingEntry(contentLayerKey: SymmetricKey): EncryptedListingEntry {
    // A sanity check
    if (this.size > 0 && !this.key) {
      // It could be that the Dir object in question was modified and
      // needs to be serialize()d before converting.
      throw new Error('Dir needs to be serialized() and have a StreamKey before converting to EncryptedListingEntry');
    }

    return {
      name: base64url.encode(Buffer.from(this.encryptedName)),
      size: this.size.toString(),
      mod: this.mod.toISOString(),
      type: this.type,
      // We can use this.key.toPlaintextString() here. The content layer key is
      // not required for dirs, only for files.
      key: this.key ? this.key.toPlaintextString() : null,
    };
  }

  public name(contentKey?: SymmetricKey): string {
    if (contentKey) {
      if (this.cachedName) {
        return this.cachedName;
      }
      try {
        const name = ConvergentEncryption.decryptName(contentKey, this.encryptedName);
        this.cachedName = name;
        return name;
      } catch (e) {
        logger.error(e);
        return 'Undecryptable dir name';
      }
    }
    return base64url.encode(Buffer.from(this.encryptedName));
  }

  // TODO: Remove async from this function once we have synchronous ConvergentEncryption.
  public async setName(newEncryptedName: Uint8Array): Promise<void> {
    if (newEncryptedName.length < 16) {
      // It should be fiarly impossible to generate such a short encrypted name.
      throw new Error('Encryptedname must be at least 16 characters');
    }
    this.cachedName = undefined;
    // After passing the checks, update the name(s).
    this.encryptedName = newEncryptedName;
  }

  public toShortEncryptedName(): Uint8Array {
    return crypto_generichash(6, this.encryptedName);
  }

  // serialize() writes the Dir to the ECAS and returns the root key of the sub-entries Listing.
  //
  // Note! This serializing and reading back does not preserve the details of this Dir object,
  // only of the sub-entries. This name, mod, size and content key are not saved.
  public async serialize(contentLayerKey: SymmetricKey): Promise<StreamKey | undefined> {
    // If we already have the content key just return it.
    if (this.key) return this.key;

    // No key and no sub-entries means empty Dir.
    if (!this.subEntries) return undefined;

    // Having entries but no key means: We have unwritten changes.
    // Write out the changes recursively and cache and return the key.
    for (const entry of this.subEntries) {
      if (entry.type === EntryType.Dir) {
        // TODO 1: Arthur: I wonder if the original object state will be mutated
        // after we perform the cast and then serialize(). We want it to be cached.
        const subdir = entry.toDir();
        await subdir.serialize(contentLayerKey);
      }
    }

    // All the sub-entries are updated and serialized.
    // Now write out our own list of Entries and cache and return the root key.
    // Make sure the sorting is consistent.
    this.subEntries.sort(compareEntry);
    const listing: EncryptedListing = {
      recursive_count: (await this.recursiveEntryCount()).toString(),
      entries: this.subEntries.map((e: Entry) => {
        return e.toListingEntry(contentLayerKey);
      }),
    };
    const versionByte = new Uint8Array(1);
    versionByte[0] = 1; // Version one
    const bytes = concat([versionByte, encode(JSON.stringify(listing))]);

    // Declare keyStream here so it is available in both try and catch clauses.
    let keyStream: KeyStream | undefined = undefined;
    try {
      keyStream = new KeyStream(this.ecas, async (): Promise<boolean> => false);
      await keyStream.write(bytes);
      this.key = await keyStream.rootKey();
      return this.key;
    } finally {
      if (keyStream) {
        keyStream.destroy();
      }
    }
  }

  // @TODO: Check if this is still needed since we do
  // not use the ecas' content key here anymore.
  public async serializeToString(contentLayerKey: SymmetricKey): Promise<string | undefined> {
    const r = await this.serialize(contentLayerKey);
    if (!r) return undefined;
    return r.toPlaintextString();
  }

  public async entries(): Promise<Array<Entry>> {
    // If we already loaded and cache the sub entries, just return them.
    if (this.subEntries) return this.subEntries;

    // If there is no content key return an empty list of sub entries.
    if (!this.key) return new Array<Entry>();

    // If needed lazyLoad() the subentries from ecas.
    await this.lazyLoad();

    if (!this.subEntries) throw new Error('Should have cached the sub entries but failed');

    return this.subEntries;
  }

  private async updateRecursiveEntryCount(): Promise<bigint> {
    // The Dir itself has count 1.
    let entryCount = 1n;
    for (const e of await this.entries()) {
      entryCount += await e.recursiveEntryCount();
    }
    this.myRecursiveEntryCount = entryCount;
    return entryCount;
  }

  public async recursiveEntryCount(): Promise<bigint> {
    // If we already cached this information just return it.
    if (this.myRecursiveEntryCount) return this.myRecursiveEntryCount;

    // If there is no key then check the sub entries.
    if (!this.key) {
      // No key and no entries means empty Dir.
      if (!this.subEntries) return 1n;

      // No key but entries means local changes. Recalculate the entry count.
      return this.updateRecursiveEntryCount();
    }

    // We have a key and can load from ecas.
    await this.lazyLoad();
    if (this.myRecursiveEntryCount === null) throw new Error('Should have cached the entry count but failed');
    return this.myRecursiveEntryCount;
  }

  /*
   * Updates the size field based on the current entries.
   */
  private async updateSize(): Promise<void> {
    // Recalculate the total file size.
    let totalSize = 0n;
    const entries = await this.entries();
    for (const e of entries) {
      totalSize += e.size;
    }
    this.size = totalSize;
  }

  /*
   * lazyLoad() loads the sub entries and the recursive entry count.
   * Both are stored in the Listing object in the ecas and is lazily loaded here.
   * It should be safe to call this function at any time (also multiple times),
   * although one should only call this when needing access to the subEntries,
   * recursive entry count, or making a change to the subEntries.
   */
  private async lazyLoad(): Promise<void> {
    if (!this.key) {
      // No key means either empty dir or non-empty dir with local changes.
      // In both cases we do not need to do anything.
      return;
    }
    if (this.subEntries) {
      // If there is a key and there are subEntries, we already lazily
      // loaded this Dir. We can return.
      return;
    }

    let listing: EncryptedListing;
    if (this.listingCache) {
      // Use the listing cache for loading listings. They might already have been
      // downloaded therefor we don't need to do it again.
      listing = await this.listingCache.getListing(this.key);
    } else {
      // There is a content key but no cached sub-entries. Load and cache them.
      const keyStream = new KeyStream(
        this.ecas,
        async (): Promise<boolean> => {
          return false;
        },
        undefined,
        this.key,
      );
      const streamSize = await keyStream.size();
      const value = await keyStream.read(0, streamSize);

      // Parse the bytes into a string and the string into json.
      listing = JSON.parse(decode(value.slice(1)));
    }

    const entries = new Array<Entry>();
    for (const item of listing.entries) {
      const encryptedName = new Uint8Array(base64url.toBuffer(item.name));
      let k: StreamKey | undefined;
      if (item.key && item.key.length > 0) {
        if (item.type === EntryType.Dir) {
          // Dir StreamKeys are stored plaintext because the Facilitator needs to read/write them too.
          k = StreamKey.fromPlaintextString(item.key);
        } else {
          // File StreamKeys are partly encrypted stored. The facilitator needs
          // to iterate its merkle tree but not see the content. The contentLayerKey
          // is undefined when we're a psp or super admin.
          k = StreamKey.fromEncryptedString(item.key, this.contentLayerKey);
        }
      }

      if (item.type === EntryType.File) {
        entries.push(new File(encryptedName, new Date(item.mod), BigInt(item.size), k));
      } else if (item.type === EntryType.Dir) {
        entries.push(new Dir(this.listingCache, this.ecas, this.contentLayerKey, encryptedName, new Date(item.mod), BigInt(item.size), k));
      } else {
        throw new Error('Unknown type of Entry found');
      }
    }

    // Every Listing object needs to contain the recursive entry count.
    if (!listing.recursive_count) throw new Error('Listing did not contain recursive entry count');

    // Only update the sub entries and entry count when all went well.
    this.subEntries = entries;
    this.myRecursiveEntryCount = BigInt(listing.recursive_count);
  }

  // Returns an Entry with that name if there is one. Otherwise undefine.
  // Does _not_ do recursive searching.
  private async getEntry(encryptedName: Uint8Array): Promise<Entry | undefined> {
    const myEntries = await this.entries();
    if (!myEntries) return undefined;
    for (const entry of myEntries) {
      if (equal(entry.encryptedName, encryptedName)) {
        return entry;
      }
    }
    return undefined;
  }

  // Returns an Entry with that short encrypted name if there is one. Otherwise undefined.
  // Does _not_ do recursive searching.
  public async getShortEntry(shortName: Uint8Array): Promise<Entry | undefined> {
    const myEntries = await this.entries();
    if (!myEntries) return undefined;
    let result: Entry | undefined = undefined;
    for (const entry of myEntries) {
      if (equal(shortName, crypto_generichash(shortName.length, entry.encryptedName))) {
        // If we already found a result before there are multiple matches. Error out.
        if (result) {
          throw new Error('Multiple entries found');
        } else {
          // This is the first match we found. Store it in result and search for more.
          result = entry;
        }
      }
    }
    // In the best case we found one match.
    // Could also still be undefined (no matches).
    return result;
  }

  // Returns an Entry from the given path if there is one.
  // Returns undefined if not found.
  // Does recursive searching.
  public async findEntry(path: Path | string): Promise<Entry | undefined> {
    if (typeof path === 'string') {
      // Semi-automatic type casting from string to Path.
      return this.findEntry(new Path(path));
    }

    // We do not want to mess up the caller's path.
    path = path.clone();

    if (path.length() < 0) throw new Error('Invalid Path');
    if (path.length() === 0) return this;
    const component = path.takeComponent();

    const entry = await this.getEntry(component);
    if (!entry) return undefined;

    // We need to go deepah!
    if (path.length() > 0) {
      // it seems we can't go deeper.
      if (entry.type !== EntryType.Dir) {
        return undefined;
      }

      return entry.toDir().findEntry(path);
    }

    // This is the Entry we're looking for
    return entry;
  }

  // Inserts the Entry into the current Dir, or a sub-path if it is provided.
  public async insert(newEntry: Entry, overwrite = false, path: Path | string | undefined = undefined): Promise<void> {
    // Always lazyLoad() before making any changes.
    await this.lazyLoad();

    // Reset the key on any change.
    this.key = undefined;

    if (typeof path === 'string') {
      // Semi-automatic type casting from string to Path.
      return this.insert(newEntry, overwrite, new Path(path));
    }

    if (path) {
      // We do not want to mess up the caller's path.
      path = path.clone();

      if (path.length() === 0) return this.insert(newEntry, overwrite);
      const subEntry = await this.getEntry(path.takeComponent());
      if (!subEntry) throw new Error('insert(): Could not find sub entry');
      if (subEntry.type !== EntryType.Dir) throw new Error('Path is not a Dir');
      const subDir = subEntry.toDir();
      await subDir.insert(newEntry, overwrite, path);
      await this.updateRecursiveEntryCount();
      await this.updateSize();
      return;
    }

    let myEntries = await this.entries();
    if (overwrite) {
      // Filter out any item with the same name
      myEntries = myEntries.filter(e => {
        return !equal(e.encryptedName, newEntry.encryptedName);
      });
    } else {
      // Check if the name is not already taken.
      for (const e of myEntries) {
        if (equal(e.encryptedName, newEntry.encryptedName)) {
          throw new TargetExistsError('Could not insert new Entry: Entry with name already exists');
        }
      }
    }
    myEntries.push(newEntry);
    this.subEntries = myEntries;
    await this.updateRecursiveEntryCount();
    await this.updateSize();
  }

  // Removes an Entry from the current Dir or a sub-path.
  public async remove(path: Path | string): Promise<void> {
    // Always lazyLoad() before making any changes.
    await this.lazyLoad();

    if (typeof path === 'string') {
      // Semi-automatic type casting from string to Path.
      return this.remove(new Path(path));
    }

    // There must be at least one path component.
    if (path.length() < 1) throw new Error('remove(): Path error');

    // Reset the key on any change.
    this.key = undefined;

    // We do not want to mess up the caller's path.
    path = path.clone();

    // Get the first path component.
    const component = path.takeComponent();
    const subEntry = await this.getEntry(component);
    if (!subEntry) throw new Error('remove(): Path not found');

    if (path.length() > 0) {
      // If there are more path components we need to go deepah.
      if (!subEntry) throw new Error('Could not find Entry');
      if (subEntry.type !== EntryType.Dir) throw new Error('Path is not a Dir');
      const subDir = subEntry.toDir();
      await subDir.remove(path);
      await this.updateRecursiveEntryCount();
      await this.updateSize();
      return;
    }

    // There are no more path components:
    // We need to remove this entry from our Entries list.
    const currentEntries = await this.entries();
    const filteredEntries = currentEntries.filter((entry: Entry) => {
      return !equal(entry.encryptedName, component);
    });
    if (filteredEntries.length === currentEntries.length) throw new Error('Could not remove entry from subEntries');

    if (filteredEntries.length > 0) {
      this.subEntries = filteredEntries;
    } else {
      this.subEntries = null;
    }
    await this.updateRecursiveEntryCount();
    await this.updateSize();
  }

  /*
   * Both the source and destination path should point towards the full path of the entry being moved.
   */
  public async copy(source: Path | string, destination: Path | string, overwrite = false): Promise<void> {
    const from = await this.findEntry(source);
    if (from === undefined) throw new Error('Could not find source');

    if (typeof destination === 'string') {
      destination = new Path(destination);
    } else {
      destination = destination.clone();
    }

    // Clone the source to avoid unintentionally sharing updates with the source Dir.
    const newEntry = from.clone();

    // Set the new entry's new name taken from the destination path.
    const newEntryName = destination.pop();
    if (!newEntryName) throw new Error('copy(): Destination should have at least one component');
    if (!equal(newEntry.encryptedName, newEntryName)) {
      newEntry.setName(newEntryName);
    }

    // Delegate to insert().
    await this.insert(newEntry, overwrite, destination);
  }

  /* move() takes two Paths and returns successful when successful.
   * Both the source and destination path should point towards the full path of the entry being moved.
   */
  public async move(source: Path | string, destination: Path | string, overwrite = false): Promise<void> {
    if (typeof source === 'string') {
      return this.move(new Path(source), destination);
    }
    if (typeof destination === 'string') {
      destination = new Path(destination);
    } else {
      destination = destination.clone();
    }

    const found = await this.findEntry(source);
    if (found === undefined) throw new Error('Could not find source');

    if (source.isEqualTo(destination)) {
      throw new Error('Source and destination are equal');
    }

    if (source.isParentOf(destination)) {
      throw new Error('Cannot move into itself');
    }

    // We do two things here: Rename the Entry
    // and drop the final name component from destination
    // to be able to use destination as a path in insert().
    const entry = found.clone();

    await entry.setName(destination.pop());
    await this.insert(entry, overwrite, destination);
    await this.remove(source);
  }

  /* Returns the Entries of a Path as an array.
   * This is useful for making the breadcrumb trail.
   */
  public async entriesForPath(path: Path): Promise<Array<Entry>> {
    let currentDir: Dir;
    currentDir = this; //eslint-disable-line
    const myPath = path.clone();
    const result = new Array<Entry>();

    while (myPath.length() > 0) {
      const component = myPath.takeComponent();
      const entry = await currentDir.findEntry(new Path([component]));
      if (!entry) throw new Error('entriesForPath(): Could not find path component');
      result.push(entry);

      if (myPath.length() > 0) {
        // We need to go deepah.
        if (entry.type !== EntryType.Dir) throw new Error('Path component is not a Dir');
        const subDir = entry.toDir();
        currentDir = subDir;
      }
    }
    return result;
  }

  public async copyRecrypt(
    source: Ecas,
    sourceContentLayerKey: SymmetricKey,
    destination: Ecas,
    destinationContentLayerKey: SymmetricKey,
  ): Promise<Entry> {
    // Re-crypt the filename
    const name = ConvergentEncryption.decryptName(sourceContentLayerKey, this.encryptedName);
    const recryptedName = ConvergentEncryption.encryptName(destinationContentLayerKey, name);

    // Re-crypt all entries recursively.
    const result = new Dir(this.listingCache, destination, destinationContentLayerKey, recryptedName, this.mod, this.size);
    const oldEntries = await this.entries();
    for (const oldEntry of oldEntries) {
      const newEntry = await oldEntry.copyRecrypt(source, sourceContentLayerKey, destination, destinationContentLayerKey);
      await result.insert(newEntry);
    }

    // Write the new Dir out to the destination store.
    await result.serialize(destinationContentLayerKey);
    return result;
  }

  public clone(): Entry {
    // If we have un-committed changes, we can not clone:
    // Serialize first!
    if (this.subEntries && !this.key) throw new Error('Can not clone() entry with uncommitted changes');
    return new Dir(this.listingCache, this.ecas, this.contentLayerKey, this.encryptedName, this.mod, this.size, this.key);
  }

  // Testing function
  public async deepCompare(other: Entry): Promise<string | undefined> {
    if (this === other) return undefined;
    if (other.type !== EntryType.Dir) return 'Type is not equal';
    const otherDir = other.toDir();
    if (this.ecas !== otherDir.ecas) return 'Ecas is not equal';
    if (!equal(this.encryptedName, otherDir.encryptedName)) return 'Encrypted name is not equal';
    if (this.size !== otherDir.size) return 'Size is not equal';
    if (this.mod.getTime() !== otherDir.mod.getTime()) return this.name() + ': Mod is not equal';
    if (!StreamKey.areEqual(this.key, otherDir.key)) return 'Key is not equal';
    if ((await this.recursiveEntryCount()) !== (await otherDir.recursiveEntryCount()))
      return 'Recursive entry count is not equal: ' + this.myRecursiveEntryCount + ' <> ' + otherDir.myRecursiveEntryCount;
    const myEntries = await this.entries();
    const otherEntries = await otherDir.entries();
    if (myEntries.length !== otherEntries.length) return 'entries length is not equal';
    const subResults = new Array<string>();
    for (let i = 0; i < myEntries.length; i++) {
      const subResult = await myEntries[i].deepCompare(otherEntries[i]);
      if (subResult) subResults.push(subResult);
    }
    if (subResults.length > 0) return 'sub entries are not equal: "' + subResults.join('", "') + '"';
    return undefined;
  }

  // Debug function
  async printTree(depth = 0, contentKey?: SymmetricKey): Promise<string> {
    await this.lazyLoad();
    const s = spaces(depth);
    const date = this.mod.toISOString();
    const k = this.key ? this.key.toString() : '<nokey>';

    const encname = base64url.encode(Buffer.from(this.encryptedName)).slice(0, 6);
    let result = '';
    if (contentKey) {
      const name = ConvergentEncryption.decryptName(contentKey, this.encryptedName);
      result = `\n ${s} D ${name} ${encname} ${date} ${k} (${this.size} bytes)`;
    } else {
      result = `\n ${s} D ${this.name()} ${encname} ${date} ${k} (${this.size} bytes)`;
    }

    const myEntries = await this.entries();
    if (myEntries) {
      for (const e of myEntries) {
        result += await e.printTree(depth + 1, contentKey);
      }
    }
    return result;
  }

  public async checkOk(): Promise<void> {
    await this.lazyLoad();
    // When the recursive entry count is zero, there should be no listing key.
    const ec = await this.recursiveEntryCount();
    if (ec === BigInt(0) && this.subEntries) throw new Error('subEntries must be undefined with zero entry count');

    if (typeof this.key !== 'undefined') {
      // If there is a content key of any sort, there must be entries.
      // Check that there are no double names.
      const es = await this.entries();
      if (es.length === 0) throw new Error('Dir with key should have at least one Entry');
      const names = new Array<Uint8Array>();
      let totalSize = 0n;
      for (const entry of es) {
        if (names.includes(entry.encryptedName)) throw new Error('Double name in Dir found');
        names.push(entry.encryptedName);
        totalSize += entry.size;
      }
      if (totalSize !== this.size) throw new Error(`Size does not match (calculated: ${totalSize} !== reported: ${this.size})`);

      // Check the entries themselves recursively.
      for (const entry of es) await entry.checkOk();
    }
  }
}
