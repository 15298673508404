import './tooltip.css';
import { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react/headless';
import classNames from 'classnames';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import { Instance } from 'tippy.js';

export interface TooltipProps extends TippyProps {
  mode?: 'dark' | 'light';
  arrow?: boolean;
  animationDuration?: number;
  disabled?: boolean;
}

/**
 * Basic tooltip implementation
 *
 * @see https://github.com/atomiks/tippyjs-react
 */
export default function Tooltip({
  children,
  content,
  mode = 'light',
  arrow = true,
  animationDuration = 100,
  disabled,
  ...rest
}: TooltipProps): JSX.Element {
  // if we're using an arrow, we do not animate the tooltip
  const shouldAnimate = !arrow;

  const config = { tension: 300, friction: 15, duration: animationDuration };
  const initialStyles = { opacity: shouldAnimate ? 0 : 1, transform: shouldAnimate ? 'scale(0.5)' : '' };
  const [props, api] = useSpring(() => initialStyles);

  /**
   * On mount, we start the animation/spring
   */
  const onMount = () => {
    if (shouldAnimate) {
      api.start({
        opacity: 1,
        transform: 'scale(1)',
        config,
      });
    }
  };

  /**
   * on Hide, we animate the tooltip and hide it
   */
  const onHide = ({ unmount }: Instance) => {
    if (shouldAnimate) {
      api.start({
        ...initialStyles,
        onRest: unmount,
        config: { ...config, clamp: true },
      });
    }
  };

  return (
    <Tippy
      disabled={disabled}
      animation={shouldAnimate}
      onMount={onMount}
      onHide={onHide}
      {...rest}
      zIndex={9999999}
      render={attrs => (
        <animated.div
          style={props}
          className={classNames('tooltip rounded-lg border shadow-sm text-sm p-2', {
            'bg-white text-gray-900 border-gray-200': mode === 'light',
            'bg-gray-700 text-white border-gray-100': mode === 'dark',
          })}
          tabIndex={-1}
          {...attrs}
        >
          {content}
          {arrow && (
            <div
              className={classNames(
                'tooltip-arrow',
                'absolute w-[8px] h-[8px] bg-inherit invisible',
                ' before:absolute before:w-[8px] before:h-[8px] before:bg-inherit before:visible before:rotate-45',
                {
                  'before:border-gray-200': mode === 'light',
                  'before:border-gray-100': mode === 'dark',
                },
              )}
              data-popper-arrow=''
            />
          )}
        </animated.div>
      )}
    >
      {children}
    </Tippy>
  );
}
