/**
 * Format bytes as human-readable text.
 *
 * @param sizeInBytes
 * @param dp Number of decimal places to display.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 *
 * @return Formatted string.
 */
export function humanFileSize(sizeInBytes: number | bigint, dp = 2, si = true, inclTB = false): string {
  const units = si ? ['bytes', 'kB', 'MB', 'GB'] : ['bytes', 'KiB', 'MiB', 'GiB'];

  if (inclTB) {
    units.push(si ? 'TB' : 'TiB');
  }

  if (typeof sizeInBytes === 'bigint') {
    sizeInBytes = Number(sizeInBytes);
  }

  const thresh = si ? 1000 : 1024;
  if (Math.abs(sizeInBytes) < thresh) {
    return sizeInBytes + ' B';
  }

  let u = 0;
  const r = 10 ** dp;

  do {
    sizeInBytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(sizeInBytes) * r) / r >= thresh && u < units.length - 1);

  return sizeInBytes.toFixed(dp) + ' ' + units[u];
}
