import * as React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import StackTrace from 'stacktrace-js';
import { logger } from '../../util/Logger';
import PageError from '../PageError';

interface Props {
  children?: React.ReactNode;
}

export default function ErrorBoundary({ children }: Props): JSX.Element {
  function ErrorFallback() {
    return (
      <PageError title='Error' text='The application encountered an error and cannot run. Please try again later or contact our support' />
    );
  }

  async function errorHandler(error: Error) {
    try {
      error.stack = (await StackTrace.fromError(error)).join('\n');
      logger.error(error);
    } catch (error) {
      // ignore any failures
    }
  }

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
      {children}
    </ReactErrorBoundary>
  );
}
