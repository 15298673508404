// Copyright 2022 Storro B.V.
// All rights reserved.
// Dit werk is auteursrechtelijk beschermd.
import { equal } from '../Util/Equal';

export class EcasValue {
  constructor(
    private value: Uint8Array,
    private associated: Uint8Array = new Uint8Array(),
  ) {}

  public isEmpty(): boolean {
    return this.value.length === 0 && this.associated.length === 0;
  }

  public getValue(): Uint8Array {
    return this.value;
  }

  public getAssociated(): Uint8Array {
    return this.associated;
  }

  public isEqual(other: EcasValue): boolean {
    if (!equal(this.value, other.value)) return false;
    if (!equal(this.associated, other.associated)) return false;
    return true;
  }
}
